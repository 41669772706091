import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';

import { Formik, Field, Form, useFormik } from 'formik';
import Axios from 'axios';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { convertFromRaw, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import TransitionsModal from '../../components/TransitionsModal'

import Contracts from '../../assets/icons/Contracts.svg';

import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { moneyMask } from '../../utils/Masks'

import api from '../../services/api';

import {
  Container, ContainerColumn,
  ContainerBackground, ContainerForms,
  ContainerHeader, ContainerInformations,
  HeaderInformations, LineVertical,
  LineHorizontal, SubmitButton,
  EditButton, DarkContainer,
  NextStepContainer, GalleryDiv,
  ContractsDiv, BackButton,
  CloseIconSpan

} from './styles';

import getValidationErros from '../../utils/getValidationErrors';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import SuccessPopUp from '../../components/SuccessPopUp';

import ToastContainer from '../../components/ToastContainer';


function index() {
  let history = useHistory();
  const [errors, setErrors] = useState({});
  const [employees, setEmployees] = useState([])
  const [customers, setCustomers] = useState([])
  const [templatesContracts, setTemplatesContracts] = useState([])
  const [job, setJob] = useState([])

  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [openModal, setOpenModal] = useState(false);
  const [contractType, setContractType] = useState('');
  const [contractContent, setContractContent] = useState('');
  const [loading, setLoading] = useState(false)
  const [ip, setIp] = useState();
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const formik = useFormik({
    initialValues: {
      propackSignerName: '',
      propackSignerEmail: '',
      customerSignerName: '',
      customerSignerEmail: '',
      contractContent: ''
    },
    onSubmit: values => {

      handleSubmit(values);
    },
  });

  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  async function handleSubmit(values) {
    let customer = customers.find(customer => customer.customer_id == job.customer_id)
    let employee = employees.find(employee => employee.employee_id == job.project_manager)
    values.propackSignerName = `${employee.first_name} ${employee.last_name}`
    values.customerSignerName = `${customer.first_name} ${customer.last_name}`
    values.propackSignerEmail = `${employee.email}`
    values.customerSignerEmail = `${customer.email}`
    // values.contractContent = contractContent
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        propackSignerName: Yup.string().required('Propack Signer Name is required'),
        propackSignerEmail: Yup.string().required('Propack Signer Email is required'),
        customerSignerName: Yup.string().required('Customer Signer Name is required'),
        customerSignerEmail: Yup.string().required('Customer Signer Email is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });
      createContract(values);
    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }


  async function createContract(values) {
    setLoading(true);
    await api
      .post('/signing-via-email', {
        job_number: localStorage.getItem('@Propack:job_number'),
        employee_id: JSON.parse(localStorage.getItem('@Propack:employee')).id,
        propackSignerName: values.propackSignerName,
        propackSignerEmail: values.propackSignerEmail,
        customerSignerName: values.customerSignerName,
        customerSignerEmail: values.customerSignerEmail,
        contractContent: values.contractContent
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          // 'content-type': 'multipart/form-data',
        },
      })
      .then(response => {
        setShowToast({
          show: true,
          type: 'success',
          title: 'Success',
          description: 'Contract created successfully.',
        });
        setLoading(false);
        history.goBack()
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: 'Error in creating contract.',
        });
        setLoading(false);
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getEmployee() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEmployees(response.data);
  }

  async function getCustomers() {
    api
      .get('/customer', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setCustomers(res.data)
      });
  }

  async function getTemplatesContracts() {
    api
      .get('/contract-template/all', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setTemplatesContracts(res.data.contractTemplates)
      });
  }

  async function getJobDetails() {
    api
      .get(`/job/${localStorage.getItem('jobId')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        
        setJob(res.data)
      });
  }

  async function getDocusignDocuments() {
    api
      .get(`/docusign-documents/${localStorage.getItem('@Propack:job_number')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    getIP()
    getEmployee()
    getCustomers()
    getTemplatesContracts()
    getJobDetails()
    getDocusignDocuments()
  }, []);

  const handleClick = (contract) => {
    let customer = customers.find(customer => customer.customer_id == job.customer_id)
    let employee = employees.find(employee => employee.employee_id == job.project_manager)
    let template_contentFormated = contract.template_content.replace(/@customer_name/g, `${customer.first_name} ${customer.last_name}`)
      .replace(/@employee_name/g, `${employee.first_name} ${employee.last_name}`)
      .replace(/@customer_email/g, customer.email)
      .replace(/@employee_email/g, employee.email)
      .replace(/@amount/g, job.amount)
      .replace(/@job_number/g, job.job_number)
    setContractType(contract)
    setEditorState(EditorState.createWithContent(
      convertFromRaw(JSON.parse(template_contentFormated))
    ))

  }

  const contractInformation = () => {
    return (
      <Formik>
        <Form onSubmit={formik.handleSubmit}>
          <ContainerInformations>
            <HeaderInformations>
              <div>
                <img height={15} src={Contracts} color={'red'} style={{ marginRight: '10px', filter: 'grayscale(0%)' }}></img>
                <text>Contract Templates</text>
              </div>
            </HeaderInformations>
            <LineHorizontal />

            <ContractsDiv>
              {templatesContracts.map((contract, i) => {
                return (
                  <GalleryDiv
                    onClick={() => handleClick(contract)}
                    key={i}
                    style={contractType.template_name == contract.template_name ? { color: 'white', backgroundColor: '#6096BA' } : null}
                  >
                    {contract.template_name}
                  </GalleryDiv>
                )
              })}
            </ContractsDiv>
          </ContainerInformations>
          <LineHorizontal style={{ marginBottom: '30px' }} />
          <NextStepContainer >
            {/* <SubmitButton type="button">
              {loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                'Save'
              )}
            </SubmitButton> */}
            <SubmitButton
              type="button"
              onClick={() => handleOpenModal()}
            >
              View Contract
            </SubmitButton>
          </NextStepContainer>
        </Form>
      </Formik >
    )
  }

  const handleOpenModal = () => {
    if (contractType != '') {
      setOpenModal(true)
    } else {
      return setShowToast({
        show: true,
        type: 'error',
        title: 'Select Contract',
        description: 'Please select a contract.',
      });
    }
  }

  const handleCreate = (values) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    let hashtagConfig
    const markup = draftToHtml(
      rawContentState,
      hashtagConfig = {
        trigger: '#',
        separator: ' ',
      },
      true,
    );
    values.contractContent = JSON.stringify(markup)
    handleSubmit(values)
  }

  const content = () => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <body style={{
            fontFamily: 'sans-serif',
            height: '90vh',
            overflowY: 'auto',
            width: '80%',
            margin: '0 auto',
            border: '2px solid #618095',
            borderRadius: '5px',
            padding: '5px',
          }
          }>
            <div
              style={{
                border: "1px solid black", minHeight: "6em", cursor: "text",
                width: '100%', height: '100%', overflowY: 'auto'
              }}
            >

              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
          </body >
          <CloseIconSpan style={{ width: '25px', height: 'max-content' }}>
            <CloseIcon fontSize="medium" onClick={() => setOpenModal(false)} />
          </CloseIconSpan>
        </div>

        <div style={{ textAlign: 'center', margin: '10px 0' }}>
          <SubmitButton
            type="button"
            onClick={() => handleCreate(formik.values)}
          >
            {loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              'Create Contract'
            )}
          </SubmitButton>
        </div>
      </>
    )
  }

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} content={content()} />}

          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Contract Registration</text>
              </div>
              <BackButton type="button" onClick={() => {
                history.goBack()
              }}>
                <ArrowBackIcon />
                Back to Jobs
              </BackButton>
            </ContainerHeader>
            {contractInformation()}
          </ ContainerForms>
        </ ContainerBackground>
      </ ContainerColumn>
    </Container>
  );
}

export default index;