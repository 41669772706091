import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import Popover from '@material-ui/core/Popover';

import Header from '../../components/Header';
import SideBar from '../../components/SideBar';


import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  EditButton,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  ContainerInformations,
  DarkContainer,
  PopoverDiv,
  MoreHorizon,
  DivEmployeeName
} from './styles';

import ConfirmModal from '../../components/ConfirmModal';
import ToastContainer from '../../components/ToastContainer';

import searchByIcon from '../../assets/icons/searchBy.png';
import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import api from '../../services/api';

function Employee() {
  let history = useHistory();

  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [showToast, setShowToast] = useState({ show: false });
  const [employees, setEmployees] = useState([]);
  const [employeesId, setEmployeesId] = useState([]);
  const [ip, setIp] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);

  async function getEmployee() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEmployees(response.data);
  }

  async function deleteEmployee() {
    api
      .delete(
        `/employee/${employeeId}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        window.location.reload();
        setOpenPopUp({ show: false })
        setEmployeeId(null)
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Employee successfully deleted',
        });
      })
      .catch(err => {
        console.error(err);
        window.location.reload();
        setOpenPopUp({ show: false })
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting employee',
        });
      });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function goEdit(id) {
    const employee = employees.find(items => items.employee_id === id);
    history.push({
      pathname: "/employee/edit",
      state: employee
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    handleClick(event)
    setEmployeeId(value)

  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeEmployee = () => {
    setAnchorEl(false)
    setOpenPopUp({ show: true })

  }
  const openPop = Boolean(anchorEl);

  const columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <DivEmployeeName onClick={() => goEdit(tableMeta.rowData[5])}>
              {tableMeta?.rowData[0]}
            </DivEmployeeName>
          )
        }
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "job_title",
      label: "Job Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "office",
      label: "Office",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'employee_id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
              paddingLeft: '14px'
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => goEdit(employeeId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                    Edit
                  </PopoverDiv>
                <PopoverDiv onClick={() => removeEmployee()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                    Delete
                  </PopoverDiv>
              </Popover>
            </div>
            // <EditButton onClick={() => goEdit(value)} type="button">
            //   Edit
            // </EditButton>
          )
        }
      },
    },
  ];

  const options = {
    filter: "false",
    selectableRows: "none",
  };


  useEffect(() => {
    getEmployee();
    getIP();
  }, []);

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {showToast.show && (
            <ToastContainer
              messages={showToast}
              show={() => handleToast()}
            />
          )}
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show &&
            <ConfirmModal
              show={() => handlePopUp()}
              deleteUser={() => deleteEmployee()}
            />
          }
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Employees</text>
              </div>
            </ContainerHeader>
            <ContainerInformations>
              <HeaderInformations>
                <Link to="/employee/register">
                  <button type="button">New Employee</button>
                </Link>
              </HeaderInformations>
              <LineHorizontal />

              {
                <MUIDataTable
                  title={"Employee List"}
                  data={employees}
                  columns={columns}
                  options={options}
                />
              }
            </ContainerInformations>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default Employee;
