import React from 'react';

import {
  Container,
  Title,
  ComponentItems
} from './styles';

function DashboardComponent({ title, activities }) {
  return (
    <Container>
      <Title>
        {title} ({activities.length})
      </Title>
      {activities.map(activity => {
        return (
          <ComponentItems style={{ color: activity.color }}>
            <span >
              Name: {activity.activity_name} <br />
            </span>
            Date: {(activity.date).split('T')[0]} <br />
            Type: {activity.activity_type} <br />
            Priority: {activity.priority}
          </ComponentItems>
        )
      })}
    </Container>
  );
}

export default DashboardComponent;