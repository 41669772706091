import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import { Formik, Field, Form, useFormik } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import momentTZ from 'moment-timezone';

import { moneyMask } from '../../utils/Masks'

import {
  Container,
  ContainerColumn,
  ContainerForms,
  ContainerBackground,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  ContainerInformations,
  HeaderInformations,
  DivForms,
  DivInput,
  Label,
  UploadButton,
  DivPicture,
  SubmitButton,
  DeleteButton,
  DarkContainer,
  DenyButton,
  ConfirmButton,
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  FifthSection,
  SixthSection,
  SeventhSection,
  EighthSection,
  NinthSection,
  TenthSection,
  EleventhSection

} from './styles';

import getValidationErros from '../../utils/getValidationErrors';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import ToastContainer from '../../components/ToastContainer';
import ConfirmModal from '../../components/ConfirmModal';
import TransitionsModal from '../../components/TransitionsModal'

import detailsIcon from '../../assets/icons/employeedetails.png';
import avatar from '../../assets/icons/avatar.png';
import addressIcon from '../../assets/icons/address.png';
import profileDetailsIcon from '../../assets/icons/profileDetails.png';
import deleteIcon from '../../assets/icons/deleteIcon.png';

import api from '../../services/api';

function EmployeeEdit() {
  const location = useLocation();
  const employee = location.state;

  let autoComplete;
  const autoCompleteRef = useRef(null);

  const defaultTimeZone = momentTZ.tz.guess();
  const timeZonesList = momentTZ.tz.names();

  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [offices, setOffices] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [ip, setIp] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showToast, setShowToast] = useState({ show: false });
  const [errors, setErrors] = useState({});
  const [deactivate, setDeactivate] = useState(employee.canceled_at);
  const [openModal, setOpenModal] = useState(false)


  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  const formik = useFormik({
    initialValues: {
      office_id: employee.office_id,
      job_title_id: employee.job_title_id,
      permission_groups_id: employee.permission_groups_id,
      first_name: employee.first_name,
      last_name: employee.last_name,
      username: 'exclude',
      email: employee.email,
      timezone: employee.timezone,
      percentage_comission: employee.percentage_comission,
      value_comission: employee.value_comission,
      hourly_cost: employee.hourly_cost,
      hourly_charges: employee.hourly_charges,
      anniversary: employee.anniversary,
      date_of_birth: employee.date_of_birth,
      department: employee.department,
      address: employee.address,
      street_number: '',
      postal_code: employee.postal_code,
      city: employee.city,
      region: employee.region,
      country: employee.country,
      state: employee.state,
      business_phone: employee.business_phone,
      pager_phone: employee.pager_phone,
      home_phone: employee.home_phone,
      mobile_phone: employee.mobile_phone,
      fax_phone: employee.fax_phone,
      other_phone: employee.other_phone,
      avatar: employee.avatar,
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  async function handlePlaceSelect(setFormik) {
    const addressObject = autoComplete.getPlace();
    let streetNumber = ''
    addressObject.address_components.map(component => {
      if (component.types[0] === 'locality') {
        setFormik('city', component.long_name)
      }
      if (component.types[0] === 'administrative_area_level_1') {
        setFormik('state', component.long_name)
      }
      if (component.types[0] === 'country') {
        setFormik('country', component.long_name)
      }
      if (component.types[0] === 'postal_code') {
        setFormik('postal_code', component.long_name)
      }
      if (component.types[0] === 'street_number') {
        streetNumber = component.long_name
      }
      if (component.types[0] === 'route') {
        setFormik('address', `${streetNumber} ${component.long_name}`)
      }
    })
  }

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(setFormik, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(setFormik)
    );
  }


  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getOffices() {
    const response = await api.get('/office', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setOffices(response.data);
  }

  async function getJobTitles() {
    const response = await api.get('/job-title', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setJobTitles(response.data);
  }

  async function getPermissions() {
    const response = await api.get('/permissionGroup', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setPermissionGroups(response.data);
  }

  useEffect(() => {
    getOffices();
    getJobTitles();
    getPermissions();
    getIP();
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Z-ODAYUDEjetAqP-tuuH4m1w5Xx2RgU&libraries=places`,
      () => handleScriptLoad(formik.setFieldValue, autoCompleteRef)
    );
  }, []);

  async function handleSubmit(values) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        office_id: Yup.string().required('Office is required'),
        job_title_id: Yup.number().required('Job Title is required'),
        permission_groups_id: Yup.number().required('Permission is required'),
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string()
          .required('E-mail is required')
          .email('Invalid e-mail'),
        password: Yup.string().min(6),
        timezone: Yup.string().required('Timezone is required'),
        address: Yup.string().required('address is required'),
        postal_code: Yup.string().required('Postal code is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        state: Yup.string().required('State is required'),
        business_phone: Yup.string().required('Business phone is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      // if (
      //   !values.business_phone &&
      //   !values.mobile_phone &&
      //   !values.fax_phone &&
      //   !values.other_phone &&
      //   !values.home_phone &&
      //   !values.pager_phone
      // ) {
      //   setErrors({ phone: 'phone is required' });
      //   setShowToast({
      //     show: true,
      //     type: 'error',
      //     title: 'Required fields',
      //     description: 'Phone is required',
      //   });
      //   setLoading(false);
      // } else {
      // }
      editemployee(values);
    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      console.error(errors);
      if (errors.email === 'Invalid e-mail') {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Invalid fields',
          description: 'E-mail',
        });
      } else {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Required fields',
          description: 'There are unfilled required fields',
        });
      }
    }
  }

  async function editemployee(values) {
    setLoading(true);
    const data = new FormData();

    data.append('employee_id', employee.employee_id);
    data.append('office_id', values.office_id);
    data.append('job_title_id', values.job_title_id);
    data.append('permission_groups_id', values.permission_groups_id);
    data.append('first_name', values.first_name);
    data.append('last_name', values.last_name);
    data.append('username', 'exclude');
    data.append('email', values.email);
    data.append('password', values.password);
    data.append('timezone', values.timezone);
    data.append('percentage_comission', moneyMask(values.percentage_comission));
    data.append('value_comission', moneyMask(values.value_comission));
    data.append('hourly_cost', moneyMask(values.hourly_cost));
    data.append('hourly_charges', moneyMask(values.hourly_charges));
    data.append('anniversary', values.anniversary);
    data.append('date_of_birth', values.date_of_birth);
    data.append('department', values.department);
    data.append('address', values.address);
    data.append('postal_code', values.postal_code);
    data.append('city', values.city);
    data.append('state', values.state);
    data.append('country', values.country);
    data.append('region', values.region);
    data.append('business_phone', values.business_phone);
    data.append('mobile_phone', values.mobile_phone);
    data.append('home_phone', values.home_phone);
    data.append('fax_phone', values.fax_phone);
    data.append('pager_phone', values.pager_phone);
    data.append('other_phone', values.other_phone);
    data.append('deactivate', deactivate);
    if (values.avatar && typeof values.avatar !== 'string') {
      data.append('avatar', values.avatar);
    }
    data.append('ip', ip);

    await api
      .patch('/employee/update', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then(() => {
        setLoading(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/employee/`;
        setShowToast({
          show: true,
          type: 'success',
          title: 'Updated successfully',
          description: 'Employee successfully updated',
        });
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Updated error',
          description: 'error updating employee',
        });
      });
  }

  async function getPostalCodeInformation(postalCode) {
    if (postalCode.toString().length >= 5) {
      Axios
        .get(
          `https://www.zipcodeapi.com/rest/js-If0vBk90TlD6WE5F7aCDEAJbwRLRYNWbFGYqgHyiVZxpteCFW120VS9RBER5fJVd/info.json/${postalCode}/radians`
        )
        .then(res => {
          formik.setFieldValue("city", res.data.city);
          formik.setFieldValue("state", res.data.state);
          formik.setFieldValue("country", 'US');
          if (errors.postal_code === 'invalid zip code') {
            setErrors({ postal_code: false })
          }
        }).catch(err => {
          console.error(err)
          setErrors({ postal_code: 'invalid zip code' })
          formik.setFieldValue("city", '');
          formik.setFieldValue("state", '');
          formik.setFieldValue("country", '');
        })
    }
  }

  async function deleteEmployee() {
    setLoadingDelete(true);
    api
      .delete(
        `/employee/${employee.employee_id}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/employee/`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Employee successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting employee',
        });
      });
  }
  
  async function resetEmployee() {
    setLoading(true);
    api
      .post(
        `/send-mail`,
        {
          employee_id: employee.employee_id,
          ip
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoading(false);
        setOpenModal(false)
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Reset password successfully',
          description: 'Password reseted successfully',
        });
      })
      .catch(err => {
        setLoading(false);
        setOpenModal(false)
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error seset password',
          description: 'Error password reseting',
        });
      });
  }

  const content = () => {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center',
        alignItems: 'center', flexDirection: 'column',
        height: '150px', fontSize: '20px'
      }}>
        <div>
          By clicking on this button you will reset your password
          and receive a new one in your email.
        </div>
        <div>
          Do you want to proceeed?
        </div>
        <div style={{ marginTop: '5px' }}>
          <DenyButton style={{
            marginRight: '10px'
          }}
            onClick={() => setOpenModal(false)}>No</DenyButton>
          <ConfirmButton
            onClick={() => resetEmployee()}
          >
            {loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              'Yes'
            )}
          </ConfirmButton>
        </div>
      </div>
    )
  }

  return (
    <>
      {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} content={content()} />}

      <Container>
        <SideBar />
        <ContainerColumn>
          <Header />
          <ContainerBackground>
            {openPopUp.show && <DarkContainer />}
            {
              openPopUp.show &&
              <ConfirmModal
                show={() => handlePopUp()}
                deleteUser={() => deleteEmployee()}
              />
            }
            {showToast.show && (
              <ToastContainer messages={showToast} show={() => handleToast()} />
            )}
            <ContainerForms>
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Employee Edit</text>
                </div>
              </ContainerHeader>
              <Formik>
                <Form onSubmit={formik.handleSubmit}>
                  <ContainerInformations>
                    <HeaderInformations>
                      <img src={detailsIcon} alt="detailsIcon" />
                      <text>Employee Details</text>
                    </HeaderInformations>
                    <LineHorizontal />

                    <FirstSection>
                      <DivInput>
                        <Label
                          style={errors.office_id ? { color: 'red' } : null}
                        >
                          Office Name
                        </Label>
                        <Field
                          name="office_id"
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.office_id}
                        >
                          <option value="" disabled selected>
                            Choose an office name
                          </option>
                          {offices.map(office => {
                            return (
                              <option
                                key={office.office_id}
                                value={office.office_id}
                              >
                                {office.name}
                              </option>
                            );
                          })}
                        </Field>
                      </DivInput>
                      <DivInput>
                        <Label
                          style={errors.job_title_id ? { color: 'red' } : null}
                        >
                          Job Title
                        </Label>
                        <Field
                          name="job_title_id"
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.job_title_id}
                        >
                          <option value="" disabled selected>
                            Choose a Job Title
                          </option>
                          {jobTitles.map(jobTitle => {
                            return (
                              <option
                                key={jobTitle.job_title_id}
                                value={jobTitle.job_title_id}
                              >
                                {jobTitle.value}
                              </option>
                            );
                          })}
                        </Field>
                      </DivInput>
                      <DivInput>
                        <Label
                          style={
                            errors.permission_groups_id
                              ? { color: 'red' }
                              : null
                          }
                        >
                          Permission
                        </Label>
                        <Field
                          name="permission_groups_id"
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.permission_groups_id}
                        >
                          <option value="" disabled selected>
                            Choose a title
                          </option>
                          {permissionGroups.map(permissionGroup => {
                            return (
                              <option
                                key={permissionGroup.permission_groups_id}
                                value={permissionGroup.permission_groups_id}
                              >
                                {permissionGroup.name}
                              </option>
                            );
                          })}
                        </Field>
                      </DivInput>
                      <DivInput>
                        <Label
                          style={errors.timezone ? { color: 'red' } : null}
                        >
                          Time Zone
                        </Label>
                        <Field
                          name="timezone"
                          as="select"
                          onChange={formik.handleChange}
                          defaultValue={formik.values.timezone}
                        >
                          <option value="" disabled selected>
                            Choose a timezone
                          </option>
                          {timeZonesList.map(timezone => {
                            return (
                              <option
                                key={timezone}
                                value={timezone}
                              >
                                {timezone}
                              </option>
                            );
                          })}
                        </Field>
                      </DivInput>
                    </FirstSection>
                    <SecondSection>
                      <DivInput >
                        <Label
                          style={errors.first_name ? { color: 'red' } : null}
                        >
                          First name
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <Field
                          name="first_name"
                          placeholder="Employee's first name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.first_name}
                          style={
                            errors.first_name
                              ? { border: '1px solid red' }
                              : null
                          }
                        />
                      </DivInput>
                      <DivInput >
                        <Label
                          style={errors.last_name ? { color: 'red' } : null}
                        >
                          Last name
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <Field
                          name="last_name"
                          placeholder="Employee's last name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.last_name}
                          style={
                            errors.last_name
                              ? { border: '1px solid red' }
                              : null
                          }
                        />
                      </DivInput>
                    </SecondSection>
                    <ThirdSection >
                      <DivInput >
                        <Label style={errors.email ? { color: 'red' } : null}>
                          Email
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <Field
                          name="email"
                          placeholder="Employee's email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          style={{
                            border: `${errors.email ? '1px solid red' : 'none'
                              }`,
                          }}
                        />
                      </DivInput>
                      <DivInput>
                        <SubmitButton type="button"
                          onClick={() => setOpenModal(true)}>
                          Reset Password
                      </SubmitButton>
                      </DivInput>
                    </ThirdSection>
                    <FourthSection>
                      <DivInput>
                        <Label>Employee Picture</Label>
                        <DivPicture>
                          <img
                            className="rounded-circle"
                            src={
                              typeof formik.values.avatar === 'string'
                                ? formik.values.avatar
                                : formik.values.avatar
                                  ? URL.createObjectURL(formik.values.avatar)
                                  : avatar
                            }
                            alt="Avatar"
                          />
                          <UploadButton for="upload-photo" style={{width: '250px'}}>
                            <text>Choose File</text>
                          </UploadButton>
                          <Field
                            name="avatar"
                            type="file"
                            className="overflow-hidden"
                            id="upload-photo"
                            style={{ opacity: 0, zIndex: -1 }}
                            onChange={event => {
                              formik.setFieldValue(
                                'avatar',
                                event.currentTarget.files[0],
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </DivPicture>
                      </DivInput>
                    </FourthSection>
                  </ContainerInformations>
                  <ContainerInformations>
                    <HeaderInformations>
                      <img
                        src={addressIcon}
                        alt="addressIcon"
                        style={{ width: '14px', height: '13px' }}
                      />
                      <text>Address Information</text>
                    </HeaderInformations>
                    <LineHorizontal />
                    <FifthSection>
                      <DivInput >
                        <Label style={errors.address ? { color: 'red' } : null}>
                          Address
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <input
                          name="address"
                          placeholder="Employee's address"
                          ref={autoCompleteRef}
                          style={{
                            border: `${errors.address ? '1px solid red' : 'none'
                              }`,
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.address}
                        />
                      </DivInput>
                      <DivInput>
                        <Label
                          style={errors.postal_code ? { color: 'red' } : null}
                        >
                          ZIP/Postal Code
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <Field
                          name="postal_code"
                          placeholder="e.g 'xxxxx-xxx'"
                          style={{
                            border: `${errors.postal_code ? '1px solid red' : 'none'
                              }`,
                          }}
                          onChange={(event) => {
                            formik.setFieldValue("postal_code", event.currentTarget.value);
                            getPostalCodeInformation(event.currentTarget.value)
                          }}
                          value={formik.values.postal_code}
                        />
                      </DivInput>
                    </FifthSection>
                    <SixthSection>
                      <DivInput>
                        <Label style={errors.city ? { color: 'red' } : null}>
                          City
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <Field
                          name="city"
                          placeholder="Employee's city"
                          style={{
                            border: `${errors.city ? '1px solid red' : 'none'}`,
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.city}
                        />
                      </DivInput>
                      <DivInput>
                        <Label>
                          Region
                        </Label>
                        <Field
                          name="region"
                          placeholder="Employee's region"
                          style={{
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.region}
                        />
                      </DivInput>
                      <DivInput>
                        <Label style={errors.country ? { color: 'red' } : null}>
                          Country
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <Field
                          name="country"
                          placeholder="Employee's country"
                          style={{
                            border: `${errors.country ? '1px solid red' : 'none'
                              }`,
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.country}
                        />
                      </DivInput>
                      <DivInput>
                        <Label style={errors.state ? { color: 'red' } : null}>
                          State/Province
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <Field
                          name="state"
                          placeholder="Employee's state/province"
                          style={{
                            border: `${errors.state ? '1px solid red' : 'none'
                              }`,
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.state}
                        />
                      </DivInput>
                    </SixthSection>
                    <SeventhSection>
                      <DivInput>
                        <Label style={errors.phone ? { color: 'red' } : null}>
                          Phone
                          <span style={{ color: 'red' }}> *</span>
                        </Label>
                        <Field
                          name="business_phone"
                          placeholder="Business phone number"
                          style={{
                            border: `${errors.business_phone ? '1px solid red' : 'none'
                              }`,
                          }}
                          onChange={formik.handleChange}
                          value={formik.values.business_phone}
                        />
                      </DivInput>
                      <DivInput>
                        <Field
                          name="mobile_phone"
                          placeholder="Mobile phone number"
                          onChange={formik.handleChange}
                          value={formik.values.mobile_phone}
                          style={{
                            marginTop: '30px',
                            border: `${errors.phone ? '1px solid red' : 'none'
                              }`,
                          }}
                        />
                      </DivInput>
                      <DivInput>
                        <Field
                          name="home_phone"
                          placeholder="Home phone number"
                          onChange={formik.handleChange}
                          value={formik.values.home_phone}
                          style={{
                            marginTop: '30px',
                            border: `${errors.phone ? '1px solid red' : 'none'
                              }`,
                          }}
                        />
                      </DivInput>
                      <DivInput>
                        <Field
                          name="fax_phone"
                          placeholder="FAX phone number"
                          onChange={formik.handleChange}
                          value={formik.values.fax_phone}
                          style={{
                            marginTop: '30px',
                            border: `${errors.phone ? '1px solid red' : 'none'
                              }`,
                          }}
                        />
                      </DivInput>
                    </SeventhSection>
                    <EighthSection>
                      <DivInput >
                        <Field
                          name="pager_phone"
                          placeholder="Pager phone number"
                          onChange={formik.handleChange}
                          value={formik.values.pager_phone}
                          style={{
                            border: `${errors.phone ? '1px solid red' : 'none'
                              }`,
                          }}
                        />
                      </DivInput>
                      <DivInput >
                        <Field
                          name="other_phone"
                          placeholder="Other phone number"
                          onChange={formik.handleChange}
                          value={formik.values.other_phone}
                          style={{
                            border: `${errors.phone ? '1px solid red' : 'none'
                              }`,
                          }}
                        />
                      </DivInput>
                    </EighthSection>
                  </ContainerInformations>
                  <ContainerInformations>
                    <HeaderInformations>
                      <img
                        src={profileDetailsIcon}
                        alt="profileDetailsIcon"
                        style={{ width: '14px', height: '17px' }}
                      />
                      <text>Profile Details</text>
                    </HeaderInformations>
                    <LineHorizontal />
                    <NinthSection>
                      <DivInput>
                        <Label>
                          Commission (%)
                        </Label>
                        <Field
                          name="percentage_comission"
                          placeholder="e.g $00,00"
                          onChange={formik.handleChange}
                          value={moneyMask(formik.values.percentage_comission)}
                          
                        />
                      </DivInput>
                      <DivInput>
                        <Label>
                          Commission ($)
                        </Label>
                        <Field
                          name="value_comission"
                          placeholder="e.g $00,00"
                          onChange={formik.handleChange}
                          value={moneyMask(formik.values.value_comission)}
                          
                        />
                      </DivInput>
                      <DivInput>
                        <Label>
                          Hourly Cost
                        </Label>
                        <Field
                          name="hourly_cost"
                          placeholder="e.g $00,00"
                          onChange={formik.handleChange}
                          value={moneyMask(formik.values.hourly_cost)}
                          
                        />
                      </DivInput>
                      <DivInput>
                        <Label>
                          Hourly Charges
                        </Label>
                        <Field
                          name="hourly_charges"
                          placeholder="e.g $00,00"
                          onChange={formik.handleChange}
                          value={moneyMask(formik.values.hourly_charges)}
                          
                        />
                      </DivInput>
                    </NinthSection>
                    <TenthSection>
                      <DivInput >
                        <Label>
                          Anniversary
                        </Label>
                        <Field
                          name="anniversary"
                          type="date"
                          onChange={formik.handleChange}
                          value={
                            formik.values.anniversary &&
                            formik.values.anniversary.split('T')[0]
                          }
                          style={{
                            textAlign: 'center',
                            padding: 'inherit'
                          }}
                        />
                      </DivInput>
                      <DivInput >
                        <Label>
                          Date of Birth
                        </Label>
                        <Field
                          name="date_of_birth"
                          type="date"
                          onChange={formik.handleChange}
                          value={
                            formik.values.date_of_birth &&
                            formik.values.date_of_birth.split('T')[0]
                          }
                          style={{
                            textAlign: 'center',
                            padding: 'inherit'
                          }}
                        />
                      </DivInput>
                      <DivInput >
                        <Label>
                          Department
                        </Label>
                        <Field
                          name="department"
                          placeholder="Employee's department..."
                          onChange={formik.handleChange}
                          value={formik.values.department}
                          
                        />
                      </DivInput>
                    </TenthSection>
                    <EleventhSection>
                      <DeleteButton
                        type="button"
                        onClick={() => setOpenPopUp({ show: true })}
                      // style={{backgroundColor: '#FF7777'}}
                      >
                        Delete Customer
                    </DeleteButton>
                      <SubmitButton type="submit">
                        {loading ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          'Update Employee'
                        )}
                      </SubmitButton>
                    </EleventhSection>
                  </ContainerInformations>
                </Form>
              </Formik>
            </ContainerForms>
          </ContainerBackground>
        </ContainerColumn>
      </Container>
    </>
  );
}

export default EmployeeEdit;
