import React, { useContext } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../contexts/Auth';

function Router({ isPrivate = false, component: Component, ...rest }) {
  const { signed } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() => {
        return isPrivate === signed ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: isPrivate ? '/' : '/jobs' }} />
        );
      }}
    />
  );
}

export default Router;
