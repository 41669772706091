import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Container,
  SearchIcon,
  FlagImg,
  Button,
  ArrowIcon,
  NotificationIcon,
  AvatarImg,
  DropdownContainer,
  DropdownItem,
  Text
} from './styles';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';

import search from '../../assets/icons/search.png';
import usaflag from '../../assets/icons/usaflag.png';
import arrow from '../../assets/icons/arrow.png';
import notification from '../../assets/icons/notification.png';
import avatar from '../../assets/icons/avatar.png';
import HamburgerMenu from '../HamburgerMenu'

import { AuthContext } from '../../contexts/Auth';

function Header() {
  const [dropProfile, setDropProfile] = useState(false);
  const { signOut, open, setOpen } = useContext(AuthContext);
  let history = useHistory();

  const user = JSON.parse(localStorage.getItem('@Propack:employee'));

  return (
    <>
      <Container className="container-fluid">
        <HamburgerMenu open={open} setOpen={setOpen} />
        <div>
          <Button>
            <SearchIcon src={search} alt="search" />
          </Button>
          <Button>
            <FlagImg src={usaflag} alt="search" />
            <Text>English</Text>
            <ArrowIcon src={arrow} alt="search" />
          </Button>
          <Button>
            <NotificationIcon src={notification} alt="search" />
          </Button>
          <Button onClick={() => setDropProfile(!dropProfile)}>
            <AvatarImg
              className="rounded-circle"
              src={user.avatar ? user.avatar : avatar}
              alt="avatar"
            />
            <Text>{`${user.first_name} ${user.last_name}`}</Text>
            <ArrowIcon src={arrow} alt="search" />
          </Button>
          {dropProfile ? (
            <DropdownContainer>
              <DropdownItem onClick={() => signOut()}>
                <ExitToAppIcon style={{ color: "#618095" }} />
                <span>Log Out</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  history.push({
                    pathname: `/profile/${user.first_name}-${user.last_name}`
                  });
                }}
              >
                <PersonIcon style={{ color: "#618095" }} />
                <span>Profile</span>
              </DropdownItem>
            </DropdownContainer>
          ) : (
            <DropdownContainer style={{ display: 'none' }} />
          )}
        </div>

      </Container>
    </>
  );
}

export default Header;
