import styled from 'styled-components';

export const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 150px;
  border-radius: 5px;
  max-width: 311px;
  background: white;
  margin-top: 15px;

  &:hover {
    cursor: pointer;
  }

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 15px;
    font-weight: 400px;
    color: #7d7d7d;
    word-break: break-all;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  justify-content: space-between;

  span {
    font-size: 10px;
    color: #7d7d7d;
  }
`;
