import React from 'react';

import { DivPopUp, PopUpButton } from './styles';

import success from '../../assets/icons/success.png';

function SuccessPopUp({ show }) {
  return (
    <DivPopUp>
      <img src={success} alt="success" />
      <text>You have successfully registered the new customer</text>

      <PopUpButton onClick={() => show()}>OK</PopUpButton>
    </DivPopUp>
  );
}

export default SuccessPopUp;
