import styled from 'styled-components';
import { lighten, darken } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  background-color: transparent;
  padding: 0px;

  img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;

  border: 1px solid #eeeeee;
`;


export const DivInput = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  @media(max-width: 960px) {
   width: 100%;
    
  }

  input {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ececec;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

  select {
    margin-top: 10px;
    display: block;
    width: 100%;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;


export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #8c8c8c;
`;

export const SubmitButton = styled.button`
  border: none;
  text-align: center;
  align-self: flex-end;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-top: 5px;
  width: 197px;
  height: 41px;
  color: #ffffff;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const DarkContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;

  height: 2000px;
  width: 2500px;
`;


export const FullDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  div:nth-child(1) {
    width: 30%;
  }
  
`
export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: 20px;
  border-radius: 5px;
  /* padding: 15px; */
`;

export const MoreHorizon = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export const PopoverDiv = styled.div`
  border-bottom: 1px solid #eee;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`

export const EmployeeNameDiv = styled.div`

  &:hover {
    cursor: pointer;
  }
`


export const DeleteButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  width: 156px;
  height: 41px;
  color: #ffffff;
  background-color: #FF5656;
  margin-top: 10px;

  font-weight: bold;
  font-size: 15px;
  line-height: 18px;

  &:hover {
    background: ${lighten(0.15, '#FF5656')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;


export const AddButton = styled.button `
  width: 156px;
  height: 41px;
  justify-self: flex-end;
  color: #fff;
  border: none;

  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  background: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: ${lighten(0.06, '#82ce5e')};
  }

  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`

export const HeaderInformations = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;

  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    height: 100px;
    align-items: center;
  }
`;


export const NotesDiv = styled.div`
  
  word-wrap: break-word;
  width: 50%;
  height: 50vh;
  overflow-y: auto;
  
`

export const DivButtons = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: 750px) {
    flex-direction: column;
    margin: 10px;
    height: 100px;
    button {
      width: 100%;
    }
  }
`
export const StoragesDiv = styled.div`
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  /* border: 1px solid black; */
  background-color: #ececec;
  border-radius: 16px;
  text-align: center;
  width: 23%;
  max-width: 200px;
  height: 32px;
  margin: 5px;
  
  div {
    &:hover {
      cursor: pointer;
    }
  }
`;