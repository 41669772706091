import React, {useState} from 'react';

import { DivPopUp, PopUpButton } from './styles';

import success from '../../assets/icons/success.png';
import { CircularProgress } from '@material-ui/core';

function ConfirmModal({ show, deleteUser }) {
  const [loading, setLoading] = useState(false)

  return (
    <DivPopUp>
      <strong> Are you sure? </strong>

      <p>You won't be able to revert this!</p>

      <div>
        <PopUpButton onClick={() => {
          deleteUser()
          setLoading(!loading)  
        }}>
          {loading 
            ? <CircularProgress size={25}/>
            : 'Yes'
          }
        </PopUpButton>
        <PopUpButton 
          style={{background: '#FF5656'}} 
          onClick={() => show()}
        >
          No
        </PopUpButton>
      </div>
    </DivPopUp>
  );
}

export default ConfirmModal;
