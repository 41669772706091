import React, { useState, useRef, useEffect, useContext } from 'react';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik, Field, Form, useFormik } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';

import api from '../../../../services/api';
import { JobContext } from '../../../../contexts/Job';

import getValidationErros from '../../../../utils/getValidationErrors';

import ReactToPrint from 'react-to-print';
import { jsPDF } from "jspdf";

import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PrintIcon from '@material-ui/icons/Print';

import { moneyMask } from '../../../../utils/Masks'
import ComponentToPrint from '../../../../utils/ComponentToPrint'
import TransitionsModal from '../../../../components/TransitionsModal'

import generalInfo from '../../../../assets/icons/generalInfo.png';

import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  ContainerInformations,
  HeaderInformations,
  LineVertical,
  LineHorizontal,
  DivForms,
  DivInput,
  Label,
  SubmitButton,
  DarkContainer,
  StepText,
  NextStepContainer,
  NavTab,
  AddButton,
  PreviousButton,
  TransferListDiv,
  TransferInput,
  TransferItem,
  TransferButton,
  DivInputText,
  FourthSection,
  DenyButton,
  ConfirmButton,
  DivisionDiv,
  GI1, GI2,
  GI5, CI1,
  CI2, CI3,
  JA1, JA2,
  JA3, IT1,
  PrintDiv, PdfGenerateDiv,
  PdfGenerateDivTable, CheckBoxesDiv


} from './styles';

import ToastContainer from '../../../../components/ToastContainer';

function Details() {
  let autoComplete;
  const autoCompleteRef = useRef(null);
  const componentRef = useRef();
  const { jobId, setJobId } = useContext(JobContext)

  const [pdfDiv, setPdfDiv] = useState(true)
  const [showDetailsPdf, setShowDetailsPdf] = useState(false)
  const [showActivitiesPdf, setShowActivitiesPdf] = useState(false)
  const [showDocumentsPdf, setShowDocumentsPdf] = useState(false)
  const [showNotesPdf, setShowNotesPdf] = useState(false)

  const [loading, setLoading] = useState(false)
  const [showToast, setShowToast] = useState({ show: false });

  const [job, setJob] = useState([]);
  const [activities, setActivities] = useState([])
  const [contracts, setContracts] = useState([])
  const [notes, setNotes] = useState([])

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([0, 1, 2, 3, 4]);
  const [right, setRight] = useState([]);

  const [customerTypes, setCustomerTypes] = useState([]);
  const [customerTitles, setCustomerTitles] = useState([]);
  const [employees, setEmployees] = useState([])

  const [lossCategories, setLossCategories] = useState([]);
  const [lossTypes, setLossTypes] = useState([]);
  const [sourcesOfLoss, setSourcesOfLoss] = useState([]);
  const [jobSizes, setJobSizes] = useState([]);
  const [roomsAffected, setRoomsAffected] = useState([]);
  const [roomsAffectedSelect, setRoomsAffectedSelect] = useState([]);

  const [sameAsCustomerAddress, setSameAsCustomerAddress] = useState(false);


  const [offices, setOffices] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [ip, setIp] = useState('0.0.0.0');
  const [errors, setErrors] = useState({});
  const [environmentalCode, setEnvironmentalCode] = useState([]);

  const priorityArray = ['Low', 'Medium', 'High']
  const referredArray = ['Carrier', 'TPA (Third Party Admin)', 'Contractor'];
  const statusArray = ['In Progress', 'Completed', 'Not Started', 'Canceled']

  const [divisionsArray, setDivisionsArray] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const [openModal, setOpenModal] = useState(false)
  const [openPdfModal, setOpenPdfModal] = useState(false)

  const [options, setOptions] = useState([]);

  function setOptionsSearch(data) {
    let arrayOptions = []
    arrayOptions.push({
      label: 'Choose a option',
      value: 'null'
    })
    data.map(item => {
      arrayOptions.push({
        label: item.first_name + ' ' + item.last_name,
        value: item.customer_id
      })
    })
    setOptions(arrayOptions);
  }

  async function getCustomerTypes() {
    const response = await api.get('/customer-type', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setCustomerTypes(response.data);
  }

  async function getEmployees() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEmployees(response.data);
  }

  async function getCustomerTitles() {
    const response = await api.get('/customer-title', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setCustomerTitles(response.data);
  }

  async function getCustomer(id) {
    api
      .get(`/customer/customer-list/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setCustomer(res.data);
      });
  }

  async function getJob() {
    api
      .get(`/job/${localStorage.getItem('jobId')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setJob(res.data)
        getCustomer(res.data.customer_id)
        setDivisionsArray(res.data.division)
        setRoomsAffectedSelect(res.data.rooms_affected)
      });
  }

  async function getRoomsAffected() {

    const response = await api.get('/room-affected', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    const array = response.data && response.data.map(room => room.name)

    setRoomsAffected(array);

  }

  function checkRooms() {
    if (job) {
      const arrayList = roomsAffected.filter(item => {
        const checked = roomsAffectedSelect.find(room => {
          return item === room
        })
        if (checked) {
          return false
        }
        return true
      })
      setRoomsAffected(arrayList);
    }
  }

  async function getDivisions() {
    const response = await api.get('/division', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setDivisions(response.data);
  }

  async function getActivities() {
    api
      .get(`/activity/${localStorage.getItem('jobId')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        if (res.data.length) {
          setActivities(res.data.reverse())
        }
        else
          setActivities([])
      });
  }

  async function getContracts() {
    api
      .get(`/contracts/${localStorage.getItem('@Propack:job_number')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setContracts(res.data)
      });
  }

  async function getNotes() {
    api
      .get(`/general-note/jobnumber/${localStorage.getItem('@Propack:job_number')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        
        setNotes(res.data)

      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    checkRooms();
  }, [roomsAffectedSelect]);

  useEffect(() => {
    getNotes()
    getContracts()
    getActivities()
    getIP();
    getOffices();
    getEmployees();
    getLossCategories();
    getLossTypes();
    getSourcesOfLoss();
    getCustomerTypes();
    getCustomerTitles();
    getJobSizes();
    getRoomsAffected();
    getJob();
    getDivisions();
    getEnvironmentalCode();
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Z-ODAYUDEjetAqP-tuuH4m1w5Xx2RgU&libraries=places`,
      () => handleScriptLoad(formik.setFieldValue, autoCompleteRef)
    );
  }, []);

  function checkedCheckbox(name) {
    const check = divisionsArray.find(item => item === name)
    if (!check) {
      return false
    }
    return true
  }

  function handleDivisionCheckbox(name, state) {
    if (state) {
      setDivisionsArray(prevState => [...prevState, name])
    } else {
      setDivisionsArray(divisionsArray.filter(item => item !== name))
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      job_name: job.job_name,
      office_id: job.office_id,
      job_size: job.job_size,
      received_by: job.received_by,
      assigned_to: job.assigned_to,
      referred_by: job.referred_by,
      referred_field: job.referred_field,
      status: job.status,
      date_of_loss: job.date_of_loss,
      loss_category: job.loss_category,
      loss_type: job.loss_type,
      secondary_loss_type: job.secondary_loss_type,
      source_of_loss: job.source_of_loss,
      environmental_code: job.environmental_code,
      priority: job.priority,
      customer: job.customer,
      customer_type: job.customer_type,
      title: job.title,
      customer_id: job.customer_id,
      street_number: job.street_number,
      first_name: job.first_name,
      last_name: job.last_name,
      address: job.address,
      street_number: job.street_number,
      postal_code: job.postal_code,
      city: job.city,
      region: job.region,
      country: job.country,
      state: job.state,
      business_phone: job.business_phone,
      mobile_phone: job.mobile_phone,
      fax_phone: job.fax_phone,
      other_phone: job.other_phone,
      director: job.director,
      project_manager: job.project_manager,
      lead_tech: job.lead_tech,
      controller: job.controller,
      division: job.division,
      job_number: job.job_number,
      policy_number: job.policy_number,
      claim_number: job.claim_number,
      year_built: job.year_built,
      policy_expiration_date: job.policy_expiration_date,
      policy_start_date: job.policy_start_date,
      collect_when: job.collect_when,
      dwelling_limits: job.dwelling_limits,
      contents_limits: job.contents_limits,
      other_structures_limits: job.other_structures_limits,
      deductible_required: job.deductible_required,
      amount: job.amount,
      self_pay: job.self_pay,
      account_record_no: job.account_record_no,
      loss_description: job.loss_description,
      special_instruction: job.special_instruction,
      rooms_affected: job.rooms_affected,
      same_as_customer_address: job.sameAsCustomerAddress,
      name: job.name,
      phone: job.phone,
      email: job.email,
      customer_first_name: customer.first_name,
      customer_last_name: customer.last_name,
      customer_email: customer.email,
      customer_business_phone: customer.business_phone,
      customer_mobile_phone: customer.mobile_phone,
      customer_other_phone: customer.other_phone,
    },
    onSubmit: values => {
      // values.amount = `$ ${}`
      checkFormGeneralInfo(values);
    },
  });

  function handleUpdateJob(values) {
    setLoading(true);
    api
      .patch(
        '/job/update',
        {
          job_id: jobId,
          job_name: values.job_name,
          office_id: values.office_id,
          job_size: values.job_size,
          received_by: values.received_by,
          assigned_to: values.assigned_to,
          referred_by: values.referred_by,
          referred_field: values.referred_field,
          status: values.status,
          date_of_loss: values.date_of_loss,
          loss_category: values.loss_category,
          loss_type: values.loss_type,
          secondary_loss_type: values.secondary_loss_type,
          source_of_loss: values.source_of_loss,
          environmental_code: values.environmental_code,
          priority: values.priority,
          customer: values.customer,
          customer_type: values.customer_type,
          title: values.title,
          customer_id: values.customer_id,
          first_name: values.first_name,
          last_name: values.last_name,
          address: values.address,
          street_number: values.street_number,
          postal_code: values.postal_code,
          city: values.city,
          region: values.region,
          country: values.country,
          state: values.state,
          business_phone: values.business_phone,
          mobile_phone: values.mobile_phone,
          fax_phone: values.fax_phone,
          other_phone: values.other_phone,
          director: values.director,
          project_manager: values.project_manager,
          lead_tech: values.lead_tech,
          controller: values.controller,
          job_number: values.job_number,
          policy_number: values.policy_number,
          claim_number: values.claim_number,
          year_built: values.year_built,
          policy_expiration_date: values.policy_expiration_date,
          policy_start_date: values.policy_start_date,
          collect_when: values.collect_when,
          dwelling_limits: moneyMask(values.dwelling_limits),
          contents_limits: moneyMask(values.contents_limits),
          other_structures_limits: moneyMask(values.other_structures_limits),
          amount: moneyMask(values.amount),
          deductible_required: values.deductible_required,
          self_pay: values.self_pay,
          account_record_no: values.account_record_no,
          loss_description: values.loss_description,
          special_instruction: values.special_instruction,
          rooms_affected: roomsAffectedSelect,
          division: divisionsArray,
          name: values.name,
          phone: values.phone,
          email: values.email,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoading(false);
        setOpenModal(false)
        // window.location.href = "${process.env.REACT_APP_WEB_URL}/jobs/";
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Updated successfully',
          description: 'Customer successfully updated',
        });
      })
      .catch(err => {
        setLoading(false);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error updating',
          description: 'Error updating customer',
        });
      });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  function setValueInRerredSelect() {
    const search = formik.values.referred_field;
    const value = options.find(item => item.value === search);
    if (value)
      return value.value;
  }

  async function checkFormGeneralInfo(values) {
    try {
      const schema = Yup.object().shape({
        // job_name: Yup.string().required(),
        office_id: Yup.string().required(),
        // job_size: Yup.string().required(),
        // received_by: Yup.string().required(),
        assigned_to: Yup.string().required(),
        referred_by: Yup.string().required(),
        date_of_loss: Yup.date().required(),
        // loss_category: Yup.string().required(),
        loss_type: Yup.string().required(),
        // secondary_loss_type: Yup.string().required(),
        // source_of_loss: Yup.string().required(),
        claim_number: Yup.string().required(),
        // environmental_code: Yup.string().required(),
        priority: Yup.string().required(),
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        // email: Yup.string().required(),
        address: Yup.string().required(),
        city: Yup.string().required(),
        country: Yup.string().required(),
        state: Yup.string().required(),
        postal_code: Yup.string().required(),
        // sameAsCustomerAddress: Yup.boolean(),
        // job_first_name: Yup.string().when("sameAsCustomerAddress", {
        //   is: false,
        //   then: Yup.string().required()
        // }),
        // job_last_name: Yup.string().when("sameAsCustomerAddress", {
        //   is: false,
        //   then: Yup.string().required()
        // }),
        // job_address: Yup.string().when("sameAsCustomerAddress", {
        //   is: false,
        //   then: Yup.string().required()
        // }),
        // job_city: Yup.string().when("sameAsCustomerAddress", {
        //   is: false,
        //   then: Yup.string().required()
        // }),
        // job_country: Yup.string().when("sameAsCustomerAddress", {
        //   is: false,
        //   then: Yup.string().required()
        // }),
        // job_state: Yup.string().when("sameAsCustomerAddress", {
        //   is: false,
        //   then: Yup.string().required()
        // }),
        // job_postal_code: Yup.string().when("sameAsCustomerAddress", {
        //   is: false,
        //   then: Yup.string().required()
        // }),
      });

      await schema.validate(values, {
        abortEarly: false,
      });
      handleUpdateJob(values)
      setOpenModal(false)
    } catch (error) {
      // console.error(err)
      setErrors(getValidationErros(error));
      setOpenModal(false)
      return setShowToast({
        show: true,
        type: 'error',
        title: 'Error',
        description: 'Please fill the required inputs.',
      });
    }
  }

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const leftChecked = intersection(checked, roomsAffected);
  const rightChecked = intersection(checked, roomsAffectedSelect);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRoomsAffectedSelect(roomsAffectedSelect.concat(leftChecked));
    setRoomsAffected(not(roomsAffected, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setRoomsAffected(roomsAffected.concat(rightChecked));
    setRoomsAffectedSelect(not(roomsAffectedSelect, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <List
        dense
        component="div"
        role="list"
        style={{
          height: '200px',
          width: '490px',
          overflow: 'hidden',
          overflowY: 'scroll'
        }}
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        // console.error(err);
      });
  }

  async function getLossCategories() {

    const response = await api.get('/loss-category', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setLossCategories(response.data);

  }

  async function getLossTypes() {

    const response = await api.get('/loss-type', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setLossTypes(response.data);

  }

  async function getSourcesOfLoss() {

    const response = await api.get('/source-of-loss', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setSourcesOfLoss(response.data);

  }

  async function getJobSizes() {

    const response = await api.get('/job-size', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setJobSizes(response.data);

  }
  async function getEnvironmentalCode() {
    // setLoadingInformations(true);
    const response = await api.get('/environmental-code', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEnvironmentalCode(response.data);
    // setLoadingInformations(false);
  }

  async function getOffices() {
    const response = await api.get('/office', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setOffices(response.data);
  }


  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(setFormik, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(setFormik)
    );
  }

  async function handlePlaceSelect(setFormik) {
    const addressObject = autoComplete.getPlace();
    let streetNumber = ''
    addressObject.address_components.map(component => {
      if (component.types[0] === 'locality') {
        setFormik('city', component.long_name)
      }
      if (component.types[0] === 'administrative_area_level_1') {
        setFormik('state', component.long_name)
      }
      if (component.types[0] === 'country') {
        setFormik('country', component.long_name)
      }
      if (component.types[0] === 'postal_code') {
        setFormik('postal_code', component.long_name)
      }
      if (component.types[0] === 'street_number') {
        streetNumber = component.long_name
      }
      if (component.types[0] === 'route') {
        setFormik('address', `${streetNumber} ${component.long_name}`)
      }
    })
  }

  const content = () => {
    return (
      <div style={{
        display: 'flex', justifyContent: 'center',
        alignItems: 'center', flexDirection: 'column',
        height: '150px', fontSize: '20px'
      }}>
        <div>
          Do you want to update the job details?
        </div>
        <div>
          <DenyButton style={{
            marginRight: '10px'
          }}
            onClick={() => setOpenModal(false)}>No</DenyButton>
          <ConfirmButton
            onClick={() => checkFormGeneralInfo(formik.values)}>
            {loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              'Yes'
            )}
          </ConfirmButton>
        </div>
      </div>
    )
  }

  const parseValue = (value, array, key) => {
    const parsedValue = array.find(item => item[key] === value)
    if (parsedValue) {
      if (key === "employee_id" || key === "customer_id")
        return parsedValue.first_name + ' ' + parsedValue.last_name
      else
        return parsedValue.name
    }
    else return ""
  }

  const contentToPrint = () => {
    setLoading(true)
    const doc = new jsPDF('p', 'pt', 'a4');
    const pageHeight = doc.internal.pageSize.height;
    const y = 500
    doc.html(document.querySelector('#pdfGenerate'), {
      callback: function (doc) {
        if (y >= pageHeight) {
          doc.addPage('a4');
          y = 10 // Restart height position
        }
        doc.save("details-ProPack.pdf");
        setPdfDiv(false)
        setLoading(false)
        handleClosePdfModal()

      },
      x: 10,
      y: 10
    });

  }

  const pdfCheckBoxes = () => {
    return (
      <CheckBoxesDiv>
        <div>
          <Label style={{ fontSize: 17 }}>Select PDF informations: </Label>
        </div>
        <div>
          <div>
            <Checkbox
              color={'primary'}
              name={'Details'}
              checked={showDetailsPdf}
              onChange={e => {
                setShowDetailsPdf(!showDetailsPdf)
              }}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Label>
              Details
            </Label>
          </div>
          <div>
            <Checkbox
              color={'primary'}
              name={'Activities'}
              checked={showActivitiesPdf}
              onChange={e => {
                setShowActivitiesPdf(!showActivitiesPdf)
              }}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Label>
              Activities
            </Label>
          </div>
          <div>
            <Checkbox
              color={'primary'}
              name={'Documents'}
              checked={showDocumentsPdf}
              onChange={e => {
                setShowDocumentsPdf(!showDocumentsPdf)
              }}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Label>
              Documents
            </Label>
          </div>
          <div>
            <Checkbox
              color={'primary'}
              name={'Notes'}
              checked={showNotesPdf}
              onChange={e => {
                setShowNotesPdf(!showNotesPdf)
              }}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Label>
              Notes
            </Label>
          </div>
        </div>
        <FourthSection style={{ justifyContent: 'center' }}>
          <button type="button" onClick={() => contentToPrint()}>
            {loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              'Download PDF'
            )}
          </button>
        </FourthSection>
      </CheckBoxesDiv>
    )
  }

  const handleClosePdfModal = () => {
    setPdfDiv(false)
    setOpenPdfModal(false)
    setShowDetailsPdf(false)
    setShowActivitiesPdf(false)
    setShowDocumentsPdf(false)
    setShowNotesPdf(false)
  }

  return (
    <>
      {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} 
      content={content()} widthProp="50%"/>}
      {openPdfModal && <TransitionsModal openModal={true} closeModal={handleClosePdfModal}
        content={pdfCheckBoxes()} widthProp="50%" />}
      {showToast.show && (
        <ToastContainer messages={showToast} show={() => handleToast()} />
      )}
      <ContainerInformations style={{ marginTop: '20px' }}>
        <HeaderInformations>
          <img src={generalInfo} alt="generalInfo" />
          <text>General Information</text>
        </HeaderInformations>
        <LineHorizontal />
        <form onSubmit={formik.handleSubmit} >
          <GI1 style={{ alignItems: 'center' }}>
            <DivInput >
              <Label style={errors.office_id ? { color: 'red' } : null}>
                Branch
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="office_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.office_id}
                style={
                  errors.office_id
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option value="" disabled selected>
                  Choose an option
                </option>
                {offices.map(item => {
                  return (
                    <option key={item.office_id} value={item.office_id}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
            </DivInput>

            <PrintDiv onClick={() => {
              setPdfDiv(true)
              setOpenPdfModal(true)
            }}>
              <PrintIcon fontSize={'40px'} style={{
                color: '#618095',
                fontSize: '30px', marginLeft: 10
              }} />
            </PrintDiv>
          </GI1>
          <GI1>
            <DivInput>
              <Label style={errors.loss_type ? { color: 'red' } : null}>
                Loss Type
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="loss_type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loss_type}
                style={
                  errors.loss_type
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  lossTypes.map(item => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))
                }
              </select>
            </DivInput>
          </GI1>
          <GI2 >
            <DivInput >
              <Label style={errors.job_size ? { color: 'red' } : null}>
                Job Size
              </Label>
              <select
                name="job_size"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.job_size}
                style={
                  errors.job_size
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {jobSizes.map(item => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                )
                )}
              </select>
            </DivInput>
            <DivInput >
              <Label style={errors.received_by ? { color: 'red' } : null}>
                Sold By
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="received_by"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.received_by}
                style={
                  errors.received_by
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option key={item.employee_id} value={item.employee_id}>
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
            <DivInput >
              <Label style={errors.assigned_to ? { color: 'red' } : null}>
                Assigned To
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="assigned_to"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.assigned_to}
                style={
                  errors.assigned_to
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option key={item.employee_id} value={item.employee_id}>
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>

          </GI2>
          <GI1 style={{ marginBottom: 20 }}>
            <DivInput >
              <Label style={errors.referred_by ? { color: 'red' } : null}>
                Referred By
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="referred_by"
                onChange={e => {
                  formik.setFieldValue('referred_by', e.target.value);
                  formik.setFieldValue('referred_field', 'null');
                }}
                onBlur={formik.handleBlur}
                value={formik.values.referred_by}
                style={
                  errors.referred_by
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  referredArray.map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))
                }
              </select>
            </DivInput>
            <DivInput>
              <select
                // options={options} 
                disabled={
                  formik.values.referred_by === 'null' ||
                    !formik.values.referred_by ||
                    formik.values.referred_by === "Choose an option"
                    ? true
                    : false
                }
                placeholder='choose a option'
                name='referred_field'
                onChange={e => formik.setFieldValue('referred_field', e.target.value)}
                value={setValueInRerredSelect()}
              >
                {
                  options.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))
                }
              </select>
            </DivInput>
          </GI1>
          <Label>
            Adjuster Information
          </Label>
          <GI5 >
            <DivInput >
              <Label>
                Name
              </Label>
              <input
                name="name"
                placeholder="Adjuster Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
            </DivInput>
            <DivInput >
              <Label>
                Phone
              </Label>
              <input
                name="phone"
                placeholder="Adjuster phone number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
            </DivInput>
            <DivInput >
              <Label>
                Email
              </Label>
              <input
                name="email"
                placeholder="Adjuster Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </DivInput>
          </GI5>
          <GI1 >
            <DivInput >
              <Label style={errors.date_of_loss ? { color: 'red' } : null}>
                Date of loss
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="date_of_loss"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.date_of_loss && formik.values.date_of_loss.split('T')[0]}
                style={
                  errors.date_of_loss
                    ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                    : { textAlign: 'center', padding: 'inherit' }
                }
              />
            </DivInput>
            <DivInput >
              <Label style={errors.status ? { color: 'red' } : null}>
                Status
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="status"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
                style={
                  errors.status
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  statusArray.map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))
                }
              </select>
            </DivInput>
          </GI1>
          <GI5 >

            <DivInput>
              <Label
                style={errors.secondary_loss_type ? { color: 'red' } : null}
              >
                Secondary Loss Type
              </Label>
              <select
                name="secondary_loss_type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondary_loss_type}
                style={
                  errors.secondary_loss_type
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  lossTypes.map(item => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))
                }
              </select>
            </DivInput>
            <DivInput>
              <Label
                style={errors.source_of_loss ? { color: 'red' } : null}
              >
                Source of Loss
              </Label>
              <select
                name="source_of_loss"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.source_of_loss}
                style={
                  errors.source_of_loss
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  sourcesOfLoss.map(item => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))
                }
              </select>
            </DivInput>
            <DivInput>
              <Label >
                Loss Category
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <select
                name="loss_category"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loss_category}
                style={
                  errors.loss_category
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  lossCategories.map(item => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))
                }
              </select>
            </DivInput>
          </GI5>
          <GI1 >
            <DivInput >
              <Label
                style={errors.environmental_code ? { color: 'red' } : null}
              >
                Environmental Code
              </Label>
              <select
                name="environmental_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.environmental_code}
                style={
                  errors.environmental_code
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  environmentalCode.map(item => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))
                }
              </select>
            </DivInput>
            <DivInput >
              <Label style={errors.priority ? { color: 'red' } : null}>
                Priority
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="priority"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.priority}
                style={
                  errors.priority
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  priorityArray.map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))
                }
              </select>
            </DivInput>
          </GI1>
          <HeaderInformations style={{ marginTop: '20px' }}>
            <img src={generalInfo} alt="generalInfo" />
            <text>Customer Information</text>
          </HeaderInformations>
          <LineHorizontal />
          <GI2 >
            <DivInput >
              <Label>
                First Name
              </Label>
              <input
                disabled
                value={formik.values.customer_first_name}
              />
            </DivInput>
            
            <DivInput >
              <Label>
                Last name
              </Label>
              <input
                disabled
                value={formik.values.customer_last_name}
              />
            </DivInput>
            
            <DivInput >
              <Label>
                Email
              </Label>
              <input
                disabled
                value={formik.values.customer_email}
              />
            </DivInput>
          </GI2>
          <GI2 >
            <DivInput >
              <Label>
                Telephone Numbers
              </Label>
              <input
                name="customer_business_phone"
                disabled
                value={
                  formik.values.customer_business_phone
                }
              />
            </DivInput>
            <DivInput >
              <input
                name="customer_mobile_phone"
                disabled
                value={formik.values.customer_mobile_phone}
                placeholder="Customer's mobile phone"
                style={{ marginTop: '30px' }}
              />
            </DivInput>
            <DivInput >
              <input
                name="customer_other_phone"
                placeholder="Customer's other phone"
                disabled
                value={formik.values.customer_other_phone}
                style={{ marginTop: '30px' }}
              />
            </DivInput>
          </GI2>
          <HeaderInformations style={{ marginTop: '20px' }}>
            <img src={generalInfo} alt="generalInfo" />
            <text>Job Address Information</text>
          </HeaderInformations>
          <LineHorizontal />
          <GI1 >
            <DivInput >
              <Label
                style={errors.first_name ? { color: 'red' } : null}
              >
                First Name
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="first_name"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="Customer's first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                style={
                  errors.first_name
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label
                style={errors.last_name ? { color: 'red' } : null}
              >
                Last Name
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="last_name"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="Customer's last name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                style={
                  errors.last_name
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
          </GI1>
          <CI3>
            <DivInput >
              <Label
                style={errors.address ? { color: 'red' } : null}
              >
                Address
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="address"
                ref={autoCompleteRef}
                // disabled={formik.values.sameAsCustomerAddress}
                placeholder="Job's address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                style={
                  errors.address
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label
                style={errors.postal_code ? { color: 'red' } : null}
              >
                Postal Code
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="postal_code"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="e.g 'xxxxx-xxx'"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postal_code}
                style={
                  errors.postal_code
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
          </CI3>
          <GI5 >
            <DivInput >
              <Label
                style={errors.city ? { color: 'red' } : null}
              >
                City
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="city"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="Job's address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                style={
                  errors.city
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label>
                Region
              </Label>
              <input
                name="region"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="e.g 'xxxxx-xxx'"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.region}
              />
            </DivInput>
            <DivInput >
              <Label
                style={errors.country ? { color: 'red' } : null}
              >
                Country
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="country"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="e.g 'xxxxx-xxx'"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                style={
                  errors.country
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label
                style={errors.state ? { color: 'red' } : null}
              >
                State/Province
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="state"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="e.g 'xxxxx-xxx'"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                style={
                  errors.state
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
          </GI5>
          <GI5 >
            <DivInput >
              <Label>
                Telephone Numbers
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="business_phone"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="Business phone number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.business_phone}
              />
            </DivInput>
            <DivInput >
              <input
                name="mobile_phone"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="Mobile phone number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile_phone}
                style={{ marginTop: '30px' }}
              />
            </DivInput>
            <DivInput >
              <input
                name="fax_phone"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="FAX phone number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fax_phone}
                style={{ marginTop: '30px' }}
              />
            </DivInput>
            <DivInput >
              <input
                name="other_phone"
                disabled={formik.values.sameAsCustomerAddress}
                placeholder="Other phone number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.other_phone}
                style={{ marginTop: '30px' }}
              />
            </DivInput>
          </GI5>
          <HeaderInformations style={{ marginTop: '20px' }}>
            <img src={generalInfo} alt="generalInfo" />
            <text>Internal Teams</text>
          </HeaderInformations>
          <LineHorizontal />
          <GI5 >
            <DivInput>
              <Label>
                Director
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="director"
                placeholder="Customer's first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.director}
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option key={item.employee_id} value={item.employee_id}>
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
            <DivInput>
              <Label>
                Project Manager
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="project_manager"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.project_manager}
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option key={item.employee_id} value={item.employee_id}>
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
            <DivInput>
              <Label>
                Lead Tech
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="lead_tech"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lead_tech}
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option key={item.employee_id} value={item.employee_id}>
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
            <DivInput>
              <Label>
                Controller
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="controller"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.controller}
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option key={item.employee_id} value={item.employee_id}>
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
          </GI5>
          <HeaderInformations style={{ marginTop: '20px' }}>
            <img src={generalInfo} alt="generalInfo" />
            <text>Policy Information</text>
          </HeaderInformations>
          <LineHorizontal />
          <GI5 >
            <DivInput>
              <Label>
                Job Number
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="job_number"
                placeholder="Job number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.job_number}
                style={
                  errors.job_number
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput>
              <Label>
                Policy Number
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="policy_number"
                placeholder="Policy number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.policy_number}
              />
            </DivInput>
            <DivInput>
              <Label>
                Claim Number
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="claim_number"
                placeholder="Claim number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.claim_number}
                style={
                  errors.claim_number
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput>
              <Label>
                Year Built
              </Label>
              <input
                name="year_built"
                placeholder="Year built"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.year_built}
              />
            </DivInput>
          </GI5>
          <GI1 >
            <DivInput >
              <Label>
                Policy Start Date
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="policy_start_date"
                placeholder="Job number"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.policy_start_date && formik.values.policy_start_date.split('T')[0]}
                style={{ textAlign: 'center', padding: 'inherit' }}
              />
            </DivInput>
            <DivInput >
              <Label>
                Policy Expiration Date
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="policy_expiration_date"
                placeholder="Policy number"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.policy_expiration_date && formik.values.policy_expiration_date.split('T')[0]}
                style={{ textAlign: 'center', padding: 'inherit' }}
              />
            </DivInput>
          </GI1>
          <HeaderInformations style={{ marginTop: '20px' }}>
            <img src={generalInfo} alt="generalInfo" />
            <text>Division</text>
          </HeaderInformations>
          <LineHorizontal />
          <DivForms style={{ flexWrap: 'wrap' }}>
            {
              divisions.map((item, i) =>
              (
                <DivisionDiv key={i}>
                  <Checkbox
                    color={'primary'}
                    name={item.name}
                    checked={checkedCheckbox(item.name)}
                    onChange={e => {
                      handleDivisionCheckbox(e.target.name, e.target.checked)
                    }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <Label>{item.name}</Label>
                </DivisionDiv>
              )
              )
            }
          </DivForms>
          <HeaderInformations style={{ marginTop: '20px' }}>
            <img src={generalInfo} alt="generalInfo" />
            <text>Payment Services</text>
          </HeaderInformations>
          <LineHorizontal />
          <GI5 >
            <DivInput>
              <Label>
                Collect When
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="collect_when"
                type="date"
                placeholder="Collect When"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.collect_when && formik.values.collect_when.split('T')[0]}
                style={{ textAlign: 'center', padding: 'inherit' }}
              />
            </DivInput>
            <DivInput>
              <Label>
                Dwelling Limits
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="dwelling_limits"
                placeholder="Dwelling Limits"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={moneyMask(formik.values.dwelling_limits)}

              />
            </DivInput>
            <DivInput>
              <Label>
                Contents Limits
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="contents_limits"
                placeholder="Contents limits"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={moneyMask(formik.values.contents_limits)}

              />
            </DivInput>
            <DivInput>
              <Label>
                Other Structures Limits
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="other_structures_limits"
                placeholder="Other structures limits"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={moneyMask(formik.values.other_structures_limits)}
              />
            </DivInput>
          </GI5>
          <GI2>
            <DivInput >
              <Label>
                Deductible Required
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <select
                name="deductible_required"
                placeholder="Choose an option"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.deductible_required}
              >
                <option>
                  Choose an option
                </option>
                <option value="yes">
                  Yes
                </option>
                <option value="no">
                  No
                </option>
              </select>
            </DivInput>
            <DivInput >
              <Label>
                Amount
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="amount"
                placeholder="e.g '$00,00'"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={moneyMask(formik.values.amount)}
              />
            </DivInput>
            <DivInput >
              <Label>
                Accounting Record No
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="account_record_no"
                placeholder="e.g 'xxx.xxx.xxx.xxx'"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_record_no}
              />
            </DivInput>
          </GI2>
          <DivInput style={{ marginTop: '20px' }}>
            <div>
              <Checkbox
                name="self_pay"
                onChange={e => {
                  formik.setFieldValue("self_pay", e.target.checked)
                }}
                checked={formik.values.self_pay}
                value={formik.values.self_pay}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Self Pay?</Label>
            </div>
          </DivInput>
          <HeaderInformations style={{ marginTop: '20px' }}>
            <img src={generalInfo} alt="generalInfo" />
            <text>{'Loss Description & Special Instruction'}</text>
          </HeaderInformations>
          <LineHorizontal />
          <DivForms className="col-12">
            <div className="col-12  row">
              <DivInput className="col-sm-12 col-md-6 col-xl-6">
                <Label>
                  Loss Description
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <textarea
                  name="loss_description"
                  rows="5" cols="30"
                  placeholder="Enter special instruction"
                  style={{ backgroundColor: '#ececec' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.loss_description}
                />
              </DivInput>
              <DivInput className="col-sm-12 col-md-6 col-xl-6">
                <Label>
                  Special Instruction
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <textarea
                  name="special_instruction"
                  rows="5" cols="30"
                  placeholder="Enter special instruction"
                  style={{ backgroundColor: '#ececec' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.special_instruction}
                />
              </DivInput>
            </div>
          </DivForms>
          <DivForms className="col-12">
            <div className="col-12  row">
              <DivInput className="col-sm-12 col-md-6 col-xl-12">
                <Label>
                  Loss Description
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <TransferListDiv>
                  {customList('Choices', roomsAffected)}
                  <div style={{ width: '90px' }}>
                    <TransferButton type="button" onClick={handleCheckedRight}>
                      left
                    </TransferButton>
                    <TransferButton type="button" onClick={handleCheckedLeft}>
                      right
                    </TransferButton>
                  </div>
                  {customList('Chosen', roomsAffectedSelect)}
                </TransferListDiv>
              </DivInput>
            </div>
          </DivForms>
        </form>
        {pdfDiv &&
          <div style={{ zIndex: 1, width: 595, padding: 10 }} id='pdfGenerate'>
            {showDetailsPdf &&
              <>
                <Label>
                  General Information - Job Number: {localStorage.getItem('@Propack:job_number')}
                </Label>
                <PdfGenerateDiv >
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Branch
                    </Label>
                    {parseValue(job.office_id, offices, "office_id")}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Loss Type
                    </Label>
                    {job.loss_type}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Job Size
                    </Label>
                    {job.job_size || " n.e"}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Sold By
                    </Label>
                    {parseValue(job.received_by, employees, "employee_id")}
                  </DivInput>
                </PdfGenerateDiv>
                <PdfGenerateDiv style={{ marginTop: 10 }}>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Assigned To
                    </Label>
                    {parseValue(job.assigned_to, employees, "employee_id")}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Referred By
                    </Label>
                    {parseValue(job.referred_field, customers, "customer_id")}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Date of Loss
                    </Label>
                    {job?.date_of_loss?.split('T')[0]}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Status
                    </Label>
                    {job.status}
                  </DivInput>
                </PdfGenerateDiv>
                <PdfGenerateDiv style={{ marginTop: 10 }}>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Secondary Loss Type
                    </Label>
                    {job.secondary_loss_type}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Source of Loss
                    </Label>
                    {job.source_of_loss}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Loss Category
                    </Label>
                    {job.loss_category}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Environmental Code
                    </Label>
                    {job.environmental_code}
                  </DivInput>
                </PdfGenerateDiv>
                <PdfGenerateDiv style={{ margin: '10px 0' }}>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Priority
                    </Label>
                    {job.priority}
                  </DivInput>
                </PdfGenerateDiv>
                <Label >
                  Customer Information
                </Label>
                <PdfGenerateDiv style={{ margin: '10px 0' }}>
                  <DivInput style={{ width: '100%' }}>
                    <Label>
                      Customer
                    </Label>
                    {parseValue(job.customer_id, customers, "customer_id")}
                  </DivInput>
                </PdfGenerateDiv>
                <Label>
                  Job Address Information
                </Label>
                <PdfGenerateDiv style={{ margin: '10px 0' }}>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      First Name
                    </Label>
                    {job.first_name}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Last Name
                    </Label>
                    {job.last_name}
                  </DivInput>

                </PdfGenerateDiv>
                <PdfGenerateDiv>
                  <DivInput style={{ width: '100%' }}>
                    <Label>
                      Address
                    </Label>
                    {job.address}, {job.postal_code}, {job.city} - {job.state}, {job.country}
                  </DivInput>
                </PdfGenerateDiv>
                <PdfGenerateDiv style={{ margin: '10px 0' }}>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Telephone Number
                    </Label>
                    {job.business_phone}
                  </DivInput>
                </PdfGenerateDiv>
                <Label>
                  Internal Teams
                </Label>
                <PdfGenerateDiv style={{ marginTop: 10 }}>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Director
                    </Label>
                    {parseValue(job.director, employees, "employee_id")}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Project Manager
                    </Label>
                    {parseValue(job.project_manager, employees, "employee_id")}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Lead Tech
                    </Label>
                    {parseValue(job.lead_tech, employees, "employee_id")}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Controller
                    </Label>
                    {parseValue(job.controller, employees, "employee_id")}
                  </DivInput>
                </PdfGenerateDiv>
                <Label>
                  Policy Information
                </Label>
                <PdfGenerateDiv >
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Job Number
                    </Label>
                    {job.job_number}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Policy Number
                    </Label>
                    {job.policy_number}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Claim Number
                    </Label>
                    {job.claim_number}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Year Built
                    </Label>
                    {job.year_built}
                  </DivInput>
                </PdfGenerateDiv>
                <PdfGenerateDiv >
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Policy Start Date
                    </Label>
                    {job?.policy_start_date?.split('T')[0]}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Policy Expiration Date
                    </Label>
                    {job?.policy_expiration_date?.split('T')[0]}
                  </DivInput>

                </PdfGenerateDiv>
                <PdfGenerateDiv >
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Division
                    </Label>
                    {job?.division?.[0]}
                  </DivInput>
                </PdfGenerateDiv>
                {/* <PdfGenerateDiv style={{ height: 50 }}></PdfGenerateDiv> */}
                <Label>
                  Payment Services
                </Label>
                <PdfGenerateDiv >
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Collect When
                    </Label>
                    {job?.collect_when?.split('T')[0]}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Dwelling Limits
                    </Label>
                    {job.dwelling_limits}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Contents Limits
                    </Label>
                    {job.contents_limits}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Other Structures Limits
                    </Label>
                    {job.other_structures_limits}
                  </DivInput>
                </PdfGenerateDiv>
                <PdfGenerateDiv >
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Deductible Required
                    </Label>
                    {job.deductible_required}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Amount
                    </Label>
                    {job.amount}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Accounting Record No
                    </Label>
                    {job.account_record_no}
                  </DivInput>
                  <DivInput style={{ width: '25%' }}>
                    <Label>
                      Year Built
                    </Label>
                    {job.year_built}
                  </DivInput>
                </PdfGenerateDiv>
              </>
            }
            {showActivitiesPdf &&
              <>
                <Label style={{ marginTop: 10 }}>
                  Activities
                </Label>
                <PdfGenerateDivTable style={{ fontSize: 12 }}>
                  <table>
                    <tr>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Priority</th>
                      <th>Team Member</th>
                    </tr>
                    {activities.map(activity => {
                      return (
                        <tr>
                          <td>{activity.activity_type}</td>
                          <td>{activity.activity_name}</td>
                          <td>{activity.description}</td>
                          <td>{activity.status}</td>
                          <td>{activity?.date.split('T')[0]}</td>
                          <td>{activity.priority}</td>
                          <td>{activity.team_member_name}</td>
                        </tr>
                      )
                    })}
                  </table>
                </PdfGenerateDivTable>
              </>
            }
            {showDocumentsPdf &&
              <>
                <Label style={{ marginTop: 10 }}>
                  Documents
                </Label>
                <PdfGenerateDivTable style={{ fontSize: 12 }}>
                  <table>
                    <tr>
                      <th>Doc Name</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Sign Date</th>
                    </tr>
                    {contracts.map(document => {
                      return (
                        <tr>
                          <td>{document.document_name}</td>
                          <td>{document.document_description}</td>
                          <td>{document.status}</td>
                          <td>{document.sign_date}</td>
                        </tr>
                      )
                    })}
                  </table>
                </PdfGenerateDivTable>
              </>
            }
            {showNotesPdf &&
              <>
                <Label style={{ marginTop: 10 }}>
                  Notes
                </Label>
                <PdfGenerateDivTable style={{ fontSize: 12 }}>
                  <table>
                    <tr>
                      <th>Created By</th>
                      <th>Note</th>
                      <th>Date</th>
                    </tr>
                    {notes.map(note => {
                      return (
                        <tr>
                          <td>{note.employee_name}</td>
                          <td>{note.note}</td>
                          <td>{note.date}</td>
                        </tr>
                      )
                    })}
                  </table>
                </PdfGenerateDivTable>
              </>
            }
          </div>
        }
      </ContainerInformations>
      <LineHorizontal style={{ marginBottom: '10px' }} />
      <FourthSection style={{ marginBottom: '10px' }}>
        <button type="button" onClick={() => setOpenModal(true)}>
          {loading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            'Save & Go to Dashboard'
          )}
        </button>
      </FourthSection>
    </>
  );
}

export default Details;