import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Axios from 'axios';

import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

import Board, { moveCard } from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'

import MUIDataTable from "mui-datatables";

import * as Yup from 'yup';
import { Formik, Field, Form, useFormik } from 'formik';

import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';

import TransitionsModal from '../../components/TransitionsModal'

import ComponentToPrint from '../../utils/ComponentToPrint'

import PrintIcon from '@material-ui/icons/Print';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';


import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';



import api from '../../services/api';

import {
  Container, ContainerColumn,
  ContainerBackground, ContainerForms,
  ContainerHeader, ContainerInformations,
  HeaderInformations, LineVertical,
  LineHorizontal, DivInput,
  Label, SubmitButton,
  DarkContainer, NavTab,
  FullDiv, Half2Div,
  Half2Div2, MoreHorizon,
  PopoverDiv, StorageNameDiv,
  StorageQrCodeDiv, Button,
  AddButton, PrintDiv,
  ContainerKanban, KanbanItemDiv,
  AddItemDiv, DeleteButton,
  RemoveItemDiv,
  ListSettingsWrapper, ListSettings

} from './styles';

import getValidationErros from '../../utils/getValidationErrors';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import SuccessPopUp from '../../components/SuccessPopUp';

import ToastContainer from '../../components/ToastContainer';

function index() {
  let history = useHistory();
  const componentRef = useRef();

  const [tabInformation, setTabInformation] = useState('storageDetails')
  const [storageId, setStorageId] = useState(null)
  const [inventoriesItems, setInventoriesItems] = useState([])
  const [storages, setStorages] = useState([])
  const [storageQrCodeInfo, setStorageQrCodeInfo] = useState(null)
  const [productTypeInput, setProductTypeInput] = useState(null)
  const [subTypeInput, setSubTypeInput] = useState({})
  const [productTypeItems, setProductTypeItems] = useState([])
  const [subTypeItems, setSubTypeItems] = useState([])
  const [addItem, setAddItem] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingProduct, setLoadingProduct] = useState(false)
  const [loadingProductType, setLoadingProductType] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null);
  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [ip, setIp] = useState();

  const openPop = Boolean(anchorEl);

  const inventoryResponsibleArray = []
  const inventoryManagerArray = []
  const primaryOfficeArray = []

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <StorageNameDiv
              onClick={() =>
                history.push({
                  pathname: `/inventory/storageedit/${tableMeta.rowData[2]}`,
                })
              }>
              {tableMeta.rowData[0]}
            </StorageNameDiv >
          )
        }
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        viewColumns: false,

      },
    },
    {
      name: "storage_id",
      label: "Storage ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "qrcode",
      label: "QR Code",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <StorageQrCodeDiv
              onClick={() => HandleViewQrCode(tableMeta.rowData)}>
              <div style={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', height: 35, color: '#6096BA',
                border: '1px solid #6096BA', borderRadius: 5
              }}>View QR Code</div>
            </StorageQrCodeDiv >
          )
        }
      },
    },
    {
      name: 'storage_id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex'
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => goEdit()}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                  Edit
                </PopoverDiv>
                <PopoverDiv onClick={() => deleteStorage()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </PopoverDiv>
              </Popover>
            </div>
          )
        }
      },
    },
  ];

  const options = {
    filter: "true",
    print: "false",
    search: "true",
    download: "false",
    filterType: 'checkbox',
    viewColumns: "false",
    selectableRows: "none",
    // onRowsDelete: (rowsDeleted) => {
    //   rowsDeleted.data.map(d => {
    //     // activityId(prevState => [...prevState, customers[d.dataIndex].costumer_id])
    //     setOpenPopUp({ show: true })
    //   })
    // }
  };

  const goEdit = () => {
    history.push({
      pathname: `/inventory/storageedit/${storageId}`,
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    handleClick(event)
    setStorageId(value)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };



  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function handleCreateProductType() {
    let productType = productTypeItems.find(item => (item.name).toUpperCase() === productTypeInput.toUpperCase())
    if (productType) {
      setShowToast({
        show: true,
        type: 'error',
        title: 'Created error',
        description: 'This product type already exists.',
      });
      return
    } else {
      setLoadingProduct(true)
      await api
        .post(
          '/product-type',
          {
            name: productTypeInput,
            ip,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
            },
          },
        )
        .then((res) => {
          setLoadingProduct(false)
          setShowToast({
            show: true,
            type: 'success',
            title: 'Created successfully',
            description: 'Product type  created.',
          });
          setProductTypeItems(prev => [...prev, { name: res.data.name, id: res.data.id }])
          setProductTypeInput("")
          // location.reload();
        })
        .catch(err => {
          setLoadingProduct(false)
          setShowToast({
            show: true,
            type: 'error',
            title: 'Created error',
            description: 'error created customer type',
          });
          // setLoading(false);
        });
    }
  }

  async function handleCreateSubType(productTypeId) {
    setLoading(true)
    await api
      .post(
        '/sub-product-type',
        {
          name: subTypeInput.value,
          productTypeId: productTypeId,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        setLoading(false);
        
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Sub type created.',
        });
        setSubTypeItems(prev => [...prev,
        { name: res.data.name, product_type_id: productTypeId, id: res.data.id }])
        setSubTypeInput("")
        setAddItem(null)
        // location.reload();
      })
      .catch(err => {
        setLoading(false)
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created customer type',
        });
        // setLoading(false);
      });
  }

  async function handleDeleteProductType(productTypeId) {
    setLoadingProductType(productTypeId)
    await api
      .delete(
        `/product-type/delete/${productTypeId}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        setLoadingProductType(null)
        setShowToast({
          show: true,
          type: 'success',
          title: 'Deleted successfully',
          description: 'Product type deleted.',
        });
        let newProductTypeItems = productTypeItems.filter(productType => productType.id !== productTypeId)
        setProductTypeItems(newProductTypeItems)
        setSubTypeInput("")
        setAddItem(null)
        // location.reload();
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error delete product type.',
        });
        // setLoading(false);
      });
  }

  async function handleDeleteSubType(subProductTypeId) {
    setShowToast({
      show: true,
      type: 'success',
      title: 'Deleting',
      description: 'Deleting sub type item...',
    });
    await api
      .delete(
        `/sub-product-type/delete/${subProductTypeId}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        setShowToast({
          show: true,
          type: 'success',
          title: 'Deleted successfully',
          description: 'Sub type item deleted.',
        });
        let newItems = subTypeItems.filter(item => item.id !== subProductTypeId)
        setSubTypeItems(newItems)
        setSubTypeInput("")
        setAddItem(null)
        // location.reload();
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error delete sub type item.',
        });
        // setLoading(false);
      });
  }



  function productType() {
    return (
      <>
        <HeaderInformations>
          <text>Type Settings</text>
        </HeaderInformations>
        <LineHorizontal />
        <Half2Div>
          <DivInput >
            <Label >
              Product Type
            </Label>
            <input
              name="productType"
              placeholder="Enter the product type"
              value={productTypeInput}
              onChange={(e) => setProductTypeInput(e.target.value)}
            />
          </DivInput>
          <Button type="button" onClick={() => handleCreateProductType()}>
            {loadingProduct ? (
              <CircularProgress color="inherit" size={25} />
            )
              :
              'Add'
            }
          </Button>
        </Half2Div>
        <ListSettingsWrapper>
          {productTypeItems.map(productType => {
            return (
              <ListSettings key={productType.id}>
                <text>
                  <input
                    style={{ fontSize: '14px' }}
                    placeholder={`Enter the ${productType.name} items`}
                    value={subTypeInput.name === productType.name ? subTypeInput.value : ""}
                    onChange={(e) =>
                      setSubTypeInput({ value: e.target.value, name: subTypeInput.name })}
                    onClick={() => setSubTypeInput({ name: productType.name })}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleCreateSubType(productType.id)
                      }
                    }}
                  />
                  <AddButton
                    type='button'
                    style={{ marginLeft: '5px' }}
                    onClick={() => handleCreateSubType(productType.id)}

                  // disabled={loading}
                  >
                    {loading && subTypeInput.name === productType.name ? (
                      <CircularProgress color="inherit" size={25} />
                    )
                      :
                      'Add'
                    }
                  </AddButton>
                  <div style={{ color: 'rgb(97, 128, 149)' }}>
                    {loadingProductType == productType.id ?
                      <CircularProgress style={{ color: 'rgb(97, 128, 149)' }} size={25} />
                      :
                      <CloseIcon
                        onClick={() => handleDeleteProductType(productType.id)}
                        fontSize="small" style={{ marginLeft: '5px' }} />
                    }
                  </div>
                  {/* <DeleteIcon onClick={() => handleDeleteProductType(productType.id)} /> */}
                </text>
                {subTypeItems.map(item => {
                  if (item.product_type_id === productType.id) {
                    return (
                      <>
                        <h3 key={item.id}>
                          {item.name}
                          <div>
                            <ClearIcon onClick={() => handleDeleteSubType(item.id)} />
                          </div>
                        </h3>
                      </>
                    )
                  }
                })}
              </ListSettings>
            )
          })}
        </ListSettingsWrapper>
        {/* <ContainerKanban>
            {productTypeItems.map(productType => {
              return (
                <KanbanItemDiv>
                <div style={{ display: 'flex' }}>
                  <div style={{
                    display: 'flex', justifyContent: 'center',
                    fontWeight: '600', fontSize: '18px', marginLeft: 5,
                    width: '90%'
                  }}>
                    {productType.name}
                  </div>
                  <RemoveItemDiv style={{ fontSize: '18px' }}>
                    <DeleteIcon onClick={() => handleDeleteProductType(productType.id)} />
                  </RemoveItemDiv>
                </div>
                <hr />
                <AddItemDiv onClick={() => setAddItem(productType.name)}>
                  Add Item
                </AddItemDiv>
                <hr />
                <div style={{ display: addItem === productType.name ? 'inline' : 'none' }}>
                  <input
                    name="subItem"
                    placeholder="Enter the sub type"
                    value={subTypeInput}
                    onChange={(e) => setSubTypeInput(e.target.value)}
                    style={{ marginLeft: 5 }}

                  />
                  <div style={{
                    display: 'flex', justifyContent: 'space-between',
                    margin: 5, width: '80%',
                  }}>
                    <DeleteButton
                      style={{ width: '45%' }}
                      type="button"
                      onClick={() => { setAddItem(null), setSubTypeInput("") }}
                    >Close
                    </DeleteButton>
                    <Button
                      style={{ width: '45%' }}
                      type="button"
                      onClick={() => handleCreateSubType(productType.id)}>
                      {loading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : (
                        'Add'
                      )}
                    </Button>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 10px' }}>
                  {subTypeItems.map(item => {
                    if (item.product_type_id === productType.id)
                      return (
                        <>
                          <div style={{
                            display: 'flex', justifyContent: 'space-between',
                            fontSize: '18px', marginTop: '5px'
                          }}>
                            {item.name}
                            <RemoveItemDiv onClick={() => handleDeleteSubType(item.id)}>
                              <DeleteOutlineIcon />
                            </RemoveItemDiv>
                          </div>
                          <LineHorizontal />
                        </>
                      )
                    else
                      return
                  })}
                </div>
              </KanbanItemDiv>

            )
          })}
        </ContainerKanban> */}
      </>
    )
  }

  async function getStorages() {
    api
      .get('/storage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        
        setStorages(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getProductTypes() {
    api
      .get('/product-type', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        
        setProductTypeItems(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getSubTypes() {
    api
      .get('/sub-product-type', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        
        setSubTypeItems(res.data)
      })
      .catch(err => console.error(err))
  }

  async function deleteStorage() {
    setLoadingDelete(true);
    api
      .delete(
        `/storage/delete/${storageId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.reload();
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Storage deleted.',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting storage',
        });
      });
  }

  useEffect(() => {
    getStorages()
    getProductTypes()
    getSubTypes()
    getIP()
  }, []);

  const HandleViewQrCode = (qrCodeInfo) => {
    setStorageQrCodeInfo({ qrCode: qrCodeInfo[3], name: qrCodeInfo[0] })
    setOpenModal(true)

  }

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${storageQrCodeInfo.name}-qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const content = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        height: '300px', fontSize: '20px'
      }}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          width: '90%', height: '40px', fontFamily: 'roboto', marginTop: '20px'
        }}>
          QR Code: {storageQrCodeInfo.name}
        </div>
        <LineHorizontal />
        <div style={{
          width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center'
        }}>
          <QRCode id="qrcode" value={storageQrCodeInfo.qrCode} />
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button style={{ marginTop: 10 }} onClick={downloadQRCode}> Download QR Code</Button>
          </div>
          <PrintDiv>
            <ReactToPrint
              trigger={() => <PrintIcon fontSize={'40px'} style={{
                color: '#82ce5e',
                fontSize: '30px', marginLeft: 10
              }} />}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
              <ComponentToPrint content={<QRCode id="qrcode" value={storageQrCodeInfo.qrCode} />} ref={componentRef} />
            </div>
          </PrintDiv>
        </div>
      </div >
    )
  }

  function storageDetails() {
    return (
      <>
        {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal}
          content={content()} widthProp="50%" />}
        <HeaderInformations >
          <div style={{
            display: 'flex', alignItems: 'inherit',
            fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
            fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
          }}>
            <ListIcon style={{ color: "#6096BA", fontSize: '2rem', marginRight: 10 }} />
            Storage List
          </div>
          <Link to="/inventory/storageregistration">
            <button type="button">New Storage</button>
          </Link>
        </HeaderInformations>
        <MUIDataTable
          data={storages}
          columns={columns}
          options={options}

        />
      </>
    )
  }



  useEffect(() => {

  }, []);

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Storage Settings</text>
              </div>
            </ContainerHeader>
            <Formik>
              <Form >
                <NavTab>
                  <li
                    onClick={() => {
                      setTabInformation('storageDetails');
                    }}
                    style={
                      tabInformation === 'storageDetails' ? { backgroundColor: '#618095' } : null
                    }
                  >
                    <a>1. Storage Details</a>
                  </li>
                  <li
                    onClick={() => {
                      setTabInformation('productType');
                    }}
                    style={
                      tabInformation === 'productType' ? {
                        marginLeft: '10px',
                        backgroundColor: '#618095',
                        marginLeft: '10px'
                      } : { marginLeft: '10px' }
                    }
                  >
                    <a>2. Product Type</a>
                  </li>

                </NavTab>
                {tabInformation === 'storageDetails' && storageDetails()}
                {tabInformation === 'productType' && productType()}
              </Form>
            </Formik>
          </ ContainerForms>
        </ ContainerBackground>
      </ ContainerColumn>
    </Container>
  );
}

export default index;