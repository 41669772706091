import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Skeleton } from '@mui/material';

import TaskCard from './Components/TaskItem';

import {
  FilterContainer,
  ContainerColumns,
  TaskColumnStyles,
  TaskList,
  Title
} from './styles'
import { Container, ContainerBackground, ContainerColumn } from '../styles';
import SideBar from '../../../components/SideBar';
import Header from '../../../components/Header';

import api from '../../../services/api';
const Kanban = () => {
  const [employees, setEmployees] = useState([]);
  const [employeesFiltered, setEmployeesFiltered] = useState([]);
  const [activitiesPerEmployee, setActivitiesPerEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentEmployee = JSON.parse(localStorage.getItem('@Propack:employee'))

  const getEmployees = async () => {
    setLoading(true);
    await api.get(`/employee`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    })
    .then(res => {
      const employeesNames = res.data.map( employee => {
        const dataInfo = {
          name: `${employee.first_name} ${employee.last_name}`,
          id: employee.employee_id
        }

        return dataInfo;
      });
      setEmployees(employeesNames);
      console.log(currentEmployee.id)
      setEmployeesFiltered(employeesNames.filter(employee => employee.id === currentEmployee.id));
      setLoading(false);
    })
  }
  
  const getActivities = async () => {
    setLoading(true)
    await api.get(`/activity/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    })
    .then(res => {
      setActivitiesPerEmployee(res.data);
      setLoading(false);
    })
  }

  useEffect(() => {
    getEmployees();
    getActivities();
  }, [])

  const handleFilterEmployees = (employeesArray) => {
    const filteredEmployees = employees.filter(employee => {
      return employeesArray.some(id => id === employee.id)
    });

    setEmployeesFiltered(filteredEmployees)
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedEmployee(
      typeof value === 'string' ? value.split(',') : value,
    );
    if(value.length > 0){
      handleFilterEmployees(value)
    } else {
      getEmployees()
    }
  };

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <FilterContainer>
          { loading ? 
            <Skeleton variant="rectangular" width={300} height={60} /> : 
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="employees-select">Employees</InputLabel>
              <Select
                labelId="employees-select"
                id="employees-select"
                multiple
                value={selectedEmployee}
                onChange={handleChange}
                input={<OutlinedInput label="Employees" />}
              >
                {employees.map((employee) => (
                  <MenuItem
                    key={employee.id}
                    value={employee.id}
                  >
                    {employee.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </FilterContainer>
        <ContainerBackground>
          <ContainerColumns>
            { loading ? 
             <Skeleton variant="rectangular" width={341} height={600} /> :  
            <TaskColumnStyles>
              {employeesFiltered.map((employee, index) => {
                return (
                  <TaskList>
                    <Title>{employee.name}</Title>
                    {activitiesPerEmployee.map((item, index) => (
                      item.team_member === employee.id ? 
                      <TaskCard key={item.activity_id} item={item} index={index} /> :
                      null
                    ))}
                  </TaskList>
                );
              })}
            </TaskColumnStyles>}
          </ContainerColumns>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
};

export default Kanban;
