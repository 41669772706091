import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { parseISO, isWithinInterval } from 'date-fns'

import MUIDataTable from "mui-datatables";

import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid,
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import DashboardComponent from '../../components/DashboardComponent';

import { JobContext } from '../../contexts/Job'
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';


import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import ConfirmModal from '../../components/ConfirmModal';
import ToastContainer from '../../components/ToastContainer';

import Jobs from '../../assets/icons/Jobs.svg'
import Activities from '../../assets/icons/Activities.svg'
import Employees from '../../assets/icons/Employees.svg'
import Customers from '../../assets/icons/Customers.svg'

import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  ContainerInformations,
  DarkContainer,
  ContainerElements,
  NavTab,
  ContainerRow,
  ContainerPageTitle,
  ContainerFilter
} from './styles';
import { RangeDate } from '../../components/RangeDateComponent';

function Dashboard() {
  let history = useHistory();
  const { jobId, setJobId } = useContext(JobContext)

  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [showToast, setShowToast] = useState({ show: false });
  const [jobsPerDivision, setJobsPerDivision] = useState([])
  const [jobsPerStatus, setJobsPerStatus] = useState([])
  const [jobsPerEmployee, setJobsPerEmployee] = useState([])
  const [activitiesPerStatus, setActivitiesPerStatus] = useState([])
  const [activitiesPerEmployee, setActivitiesPerEmployee] = useState([])
  const [activities, setActivities] = useState([])
  const [counters, setCounters] = useState({})
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [ip, setIp] = useState([]);

  // const colors = ['#fffc8c', '#8ada7c', '#7ccdda', '#e45242', 'purple', 'brown', 'black'];
  const colors = ['#FE7096', '#9A55FF', '#3CBA9F', '#E8C3B9', '#DA8CFF', 'brown', 'black'];
  const jobsPerDivisionDataColors = ['#FE7096', '#9A55FF', '#3CBA9F', '#E8C3B9', '#DA8CFF', 'brown', 'black'];
  const jobStatusDataColors = ['#FE7096', '#9A55FF', '#3CBA9F', '#E8C3B9', '#DA8CFF', 'brown', 'black'];

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getJobsPerDivision() {
    api
      .get('/dashboard/job-per/division', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
        params: {
          startDate,
          endDate
        }
      })
      .then(res => {
        setJobsPerDivision(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getJobsPerStatus() {
    api
      .get('/dashboard/job-per/status', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
        params: {
          startDate,
          endDate
        }
      })
      .then(res => {
        setJobsPerStatus(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getJobsPerEmployee() {
    api
      .get('/dashboard/job-per/employee', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setJobsPerEmployee(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getActivitiesPerStatus() {
    api
      .get('/dashboard/activities-per/status', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
        params: {
          startDate,
          endDate
        }
      })
      .then(res => {
        setActivitiesPerStatus(res.data);
      })
      .catch(err => console.error(err))
  }

  async function getActivitiesPerEmployee() {
    api
      .get('/dashboard/activities-per/employee', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setActivitiesPerEmployee(res.data);
      })
      .catch(err => console.error(err))
  }
 
  async function getCounters() {
    api
      .get(`/dashboard/counters/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
        params: {
          startDate,
          endDate
        }
      })
      .then(res => {
        setCounters(res.data);
      })
      .catch(err => console.error(err))
  }

  async function getActivities() {
    api
      .get('/dashboard/activities', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setActivities(res.data);
      })
      .catch(err => console.error(err))
  }

  function handleToast() {
    setShowToast({ show: false });
  }


  function handlePopUp() {
    setOpenPopUp({ show: false });
  }


  const columns = [
    {
      name: "activity_type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "activity_name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "team_member_name",
      label: "Team member",
      options: {
        filter: true,
        sort: true
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        filter: false,
        viewColumns: false,
      },
    },
  ];
  const options = {
    // filter: "false",
    selectableRows: "none",
    filterType: 'checkbox'
  };

  useEffect(() => {
    getCounters();
    getJobsPerDivision();
    getJobsPerStatus();
    getJobsPerEmployee();
    getActivitiesPerStatus();
    getActivitiesPerEmployee();
    getActivities();
    getCounters();
    getIP();
  }, []);

  const CustomTooltip = ({ active, payload, labelName }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip"
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            flexDirection: 'column', backgroundColor: "white", border: '1px solid #bebebe',
            minWidth: '150px'
          }}>
          <p className="label">{`${payload[0].payload.name}`}</p>
          <p className="label" style={{ color: '#8884D8' }}>{`${labelName}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const handleFilterByDate = () => {
    getCounters()
    getActivitiesPerStatus()
    getJobsPerDivision()
    getJobsPerStatus()

    const parsedStartDate = parseISO(startDate);
    const parsedEndDate = parseISO(endDate);

    const filteredData = activities.filter(item => {
      const itemDate = parseISO(item.created_at);
    
      return isWithinInterval(itemDate, {
        start: parsedStartDate, 
        end: parsedEndDate 
      });
    });

    setActivities(filteredData)
  }


  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {showToast.show && (
            <ToastContainer
              messages={showToast}
              show={() => handleToast()}
            />
          )}
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show &&
            <ConfirmModal
              show={() => handlePopUp()}
              deleteUser={() => deleteJob(jobId)}
            />
          }
          <ContainerRow>
            <ContainerPageTitle>
              <LineVertical />
              Dashboard
            </ContainerPageTitle>
            <ContainerFilter>
              <RangeDate 
                onChangeStartDate={setStartDate}
                onChangeEndDate={setEndDate}
                onHandleFilter={handleFilterByDate}
              />
            </ContainerFilter>
          </ContainerRow>
          <div>
            <ContainerForms>
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Jobs</text>
                </div>
              </ContainerHeader>
              <div style={{
                width: '100%', height: '100%',
                fontSize: 60, display: 'flex', justifyContent: 'center',
                alignItems: 'center'
              }}>
                <img height={15} src={Jobs} style={{
                  marginRight: '10px', height: '40px'
                }}></img>
                {counters.jobs}
              </div>
            </ContainerForms>
            <ContainerForms>
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Activities</text>
                </div>
              </ContainerHeader>
              <div style={{
                width: '100%', height: '100%',
                fontSize: 60, display: 'flex', justifyContent: 'center',
                alignItems: 'center'
              }}>
                <img height={15} src={Activities} style={{
                  marginRight: '10px', height: '40px'
                }}></img>
                {counters.activities}
              </div>
            </ContainerForms>
            <ContainerForms>
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Employees</text>
                </div>
              </ContainerHeader>
              <div style={{
                width: '100%', height: '100%',
                fontSize: 60, display: 'flex', justifyContent: 'center',
                alignItems: 'center'
              }}>
                <img height={15} src={Employees} style={{
                  marginRight: '10px', height: '40px'
                }}></img>
                {counters.employees}
              </div>
            </ContainerForms>
            <ContainerForms>
              <ContainerHeader >
                <div>
                  <LineVertical />
                  <text>Customers</text>
                </div>
              </ContainerHeader>
              <div style={{
                width: '100%', height: '100%',
                fontSize: 60, display: 'flex', justifyContent: 'center',
                alignItems: 'center'
              }}>
                <img height={15} src={Customers} style={{
                  marginRight: '10px', height: '40px'
                }}></img>
                {counters.customers}
              </div>
            </ContainerForms>
          </div>
          <div>

            <ContainerForms>
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Activities</text>
                </div>
              </ContainerHeader>
              <div style={{ width: '100%', height: '100%', }}>
                <ResponsiveContainer width="100%" height="100%" >
                  <BarChart
                    width="100%"
                    height="100%"
                    data={activitiesPerStatus}
                    margin={{
                      top: 15,
                      right: 30,
                      left: 20,
                      bottom: 15,
                    }}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" fontSize="10px" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip labelName="Activities" />} />
                    <Bar dataKey="amt" fill="#618095">
                      {activitiesPerStatus.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </ContainerForms>
            <ContainerForms className="pieChart">
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Jobs per division</text>
                </div>
              </ContainerHeader>
              <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width="100%" height="100%">
                    <Pie
                      dataKey="value"
                      nameKey="name"
                      isAnimationActive={true}
                      data={jobsPerDivision}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={60}
                      paddingAngle={3}
                      label>
                      {jobsPerDivision.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={jobsPerDivisionDataColors[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </ContainerForms>
            <ContainerForms className="pieChart">
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Job status</text>
                </div>
              </ContainerHeader>
              <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width="100%" height="100%">
                    <Pie
                      dataKey="amt"
                      isAnimationActive={true}
                      data={jobsPerStatus}
                      cx="50%"
                      cy="50%"
                      outerRadius={60}
                      label>
                      {jobsPerStatus.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={jobStatusDataColors[index]} />
                      ))}
                    </Pie>
                    <Legend />
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </ContainerForms>
          </div>
          <div 
            style={{display: !activitiesPerEmployee ? 'none' : null}}
          >
            <ContainerForms >
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Activities per employee</text>
                </div>
              </ContainerHeader>
              <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveContainer width="100%" height="100%" >
                  <BarChart
                    layout="vertical"
                    width="100%"
                    height="100%"
                    data={activitiesPerEmployee}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis fontSize="10px" type="category"  
                    dataKey="name"/>
                    <Legend content={() => {
                      return (
                        <ul style={{display: 'flex', justifyContent: 'center', color: '#858FE3'}}>
                          Activities
                        </ul>
                      )
                    }}/>
                    <Tooltip content={<CustomTooltip labelName="Activities" />} />
                    <Bar dataKey="amt" fill="#858FE3" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </ContainerForms>
            <ContainerForms >
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Jobs per employee</text>
                </div>
              </ContainerHeader>
              <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveContainer width="100%" height="100%" >
                  <BarChart
                    layout="vertical"
                    width="100%"
                    height="100%"
                    data={jobsPerEmployee}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={20}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis fontSize="10px" type="category" dataKey="name" />
                    <Tooltip content={<CustomTooltip labelName="Jobs" />} />
                    <Legend content={() => {
                      return (
                        <ul style={{display: 'flex', justifyContent: 'center', color: '#C7CEFF'}}>
                          Jobs
                        </ul>
                      )
                    }}/>
                    <Bar dataKey="amt" fill="#C7CEFF" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </ContainerForms>
          </div>
          <div style={{ display: 'none' }}>
            <ContainerForms >
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Jobs per division</text>
                </div>
              </ContainerHeader>
              <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width="100%" height="100%">
                    <Pie
                      dataKey="value"
                      nameKey="name"
                      isAnimationActive={false}
                      data={jobsPerDivision}
                      cx="50%"
                      cy="50%"
                      outerRadius="40%">
                      {jobsPerDivision.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </ContainerForms>
            <ContainerForms >
              <ContainerHeader>
                <div>
                  <LineVertical />
                  <text>Job status</text>
                </div>
              </ContainerHeader>
              <div style={{ width: '100%', height: '100%' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width="100%" height="100%">
                    <Pie
                      dataKey="amt"
                      isAnimationActive={true}
                      data={jobsPerStatus}
                      cx="50%"
                      cy="50%"
                      outerRadius="40%"
                    >
                      {jobsPerStatus.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={jobStatusDataColors[index]} />
                      ))}
                    </Pie>
                    <Legend />
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </ContainerForms>
          </div>
          <div>
            <ContainerForms>
              <MUIDataTable
                title={"Activities List"}
                data={activities}
                columns={columns}
                options={options}
              />
            </ContainerForms>
            <ContainerForms>
              <MUIDataTable
                title={"Last Activities"}
                data={activities}
                columns={columns}
                options={options}
              />
            </ContainerForms>
          </div>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default Dashboard;