import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { FormControl } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }


`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  /* height: 617px; */
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  padding: 15px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;

  table {
    padding: 50px;
    thead {
      background: #e6ebee;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;

      color: #618095;

      tr {
        height: 50px;
      }
      th {
        padding: 0px 20px 0px 20px;
      }
    }
    td {
      font-size: 14px;
      line-height: 16px;

      color: #8c8c8c;
      padding: 10px 20px 10px 20px;
    }

    span {
      height: 6px;
      width: 6px;
      margin-left: 5px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
    }

    button {
      background-color: transparent;
      color: #8c8c8c;
      border-radius: 50%;
      border: none;
      font-size: 30px;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
    }
  }
`;

export const MoreHorizon = styled.div`
  &:hover {
    cursor: pointer;
  }
`
export const JobNumberDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`


export const PopoverDiv = styled.div`
  border-bottom: 1px solid #eee;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`

export const PaginationButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    background-color: transparent;
    color: #8c8c8c;
    border-radius: 50%;
    border: none;
    font-size: 12px;

    &:hover {
      cursor: pointer;
      background-color: ${darken(0.01, '#fafafa')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 30px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #333533;
  }
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;
  margin-bottom: 4 0px;

  border: 1px solid #eeeeee;
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;

  img {
    width: 17px;
    height: 19px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;

  > img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const EditButton = styled.button`
  border: none;
  color: #fff;
  padding: 8px;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;
`;

export const DarkContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;

  height: 2000px;
  width: 2500px;
`;

export const FilterContainer = styled(FormControl)`
  display: flex;
  flex-direction: column;
`

export const DateFilterDiv = styled(DemoContainer)`
  display: flex;
   && {
     flex-direction: column;
   }
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 30px;
  color: white;
  background-color: ${(props) => {
    if (props.status.toLowerCase() === "not started") {
      return "#96959C"
    } else if (props.status.toLowerCase() === "completed") {
      return "#37A820"
    } else if (props.status.toLowerCase() === "in progress") {
      return "#397AC2"
    } else if (props.status.toLowerCase() === "estimate due") {
      return "#397AC2"
    } else if (props.status.toLowerCase() === "canceled") {
      return "#B81F12"
    } else {
      return "#C26426"
    }
  }};
`
