import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;

`

export const HeaderInformations = styled.div`
  display: flex;
  width: 100%;
  margin-left: 15px;
  align-items: center;
  font-weight: 500;
  font-size: 1.25rem;
  height: 20px;
  margin-top: 20px;

  text {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 21px;
  }
`;


export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;
  margin-bottom: 4 0px;

  border: 1px solid #eeeeee;
`;

export const ContentBody = styled.div`
width: 95%;
margin: 10px auto;
display: flex;
align-items: flex-end;
flex-wrap: wrap;
text {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 21px;
  }
  select {
    margin-top: 10px;
    display: block;
    width: 100%;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Options = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 30%;

`

export const Buttons = styled.div`
  margin: 20px 20px 20px auto;
  display: flex;
  justify-content: space-between;
  width: 40%;
  button {
      width: 120px;
      height: 41px;
      justify-self: flex-end;
      color: #fff;
      border: none;

      font-weight: bold;
      font-size: 15px;
      line-height: 18px;

      background: #82ce5e;
      box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
      border-radius: 3px;

      &:hover {
        cursor: pointer;
        background-color: ${lighten(0.06, '#82ce5e')};
      }

      &:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
}`