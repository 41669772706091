import styled from 'styled-components';
import { lighten, darken } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;

  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  padding: 15px;
`;

export const NavTab = styled.ul`
  display: flex;
  /* justify-content: space-around; */
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 2px solid #618095;
  

  li {
    display: flex;
    background: #cfdbd5;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    min-width: 144px;
    max-width: 100%;
    height: 45px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;

    a {
      display: block;
      padding: 0.5rem 1rem;
      text-decoration: none;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1200px) {
    z-index: 2;
    overflow-x: scroll;
    overflow-y: clip;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 30px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333533;
  }
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;

  border: 1px solid #eeeeee;
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;

  img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 40px;

  img {
    width: 17px;
    height: 19px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  @media (min-width: 500px) {
    button {
      display: none;
    }
  }
`;

export const DivForms = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  height: 10%;
`;

export const AddButton = styled.button`
    border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 51px;
  height: 41px;
  margin-top: 24px;
  color: #ffffff;
  background-color: #618095;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  img {
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }

  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }

  &:disabled {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: not-allowed;
  }
`;

export const DeleteButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 51px;
  height: 41px;
  color: #ffffff;
  background-color: #FF5656;
  border-radius: 3px;

  img {
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }

  &:hover {
    background: ${lighten(0.15, '#FF5656')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }

  &:disabled {
    background: ${lighten(0.15, '#FF5656')};
    cursor: not-allowed;
  }
`;

export const DivInput = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;

  img {
    width: 55px;
    height: 55px;
  }

  input {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ececec;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }

    &:hover {
      cursor: ${props => (props.noEdit ? 'not-allowed' : '')};
    }
  }

  select {
    margin-top: 10px;
    display: block;
    width: 90%;
    height: 40px;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
  }

`;

export const ListSettings = styled.div`
border: 2px solid #618095;
display: flex;
flex-wrap: wrap;
width: 31%;
min-height: 150px;
margin: 10px;
background-color: whitesmoke;
border-radius: 10px;

text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #618095;
  font-size: 18px;
  height: 50px;
  width: 100%;
  color: #618095;
  font-weight: 600;

  input {
    width: 80%;
    border: none;
    border-radius: '5px';
    background-color: 'white';
    border-color: '#618095';

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

  button {
    margin: 0 0 0 5px;
  }
}

h3 {
  margin: 10px;
  font-size: 20px;
  border-radius: 40px;
  height: 45px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #ececec;
}

div {
  color: #FF5656;
  margin-left: 5px;
  /* background-color: #ececec; */
  display: flex;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }

  
}
  @media (max-width: 1200px) {
    width: 100%;
    margin: 10px 0px;
    
  }

`

export const DivInputHorizontal = styled.div`
  margin: auto;

  img {
    width: 55px;
    height: 55px;
  }
  &:hover {
    cursor: pointer;
  }
`

export const PopoverDiv = styled.div`
  border-bottom: 1px solid #eee;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const SubmitButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 94px;
  height: 41px;
  margin-top: 24px;
  color: #ffffff;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  img {
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }

  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }

  @media (max-width: 500px) {
      width: 100%;
      margin-right: 0;
  }
`;

export const ContainerModal = styled.div`
  position: fixed;
  width: 40vw;
  height: 80%;
  left: 30vw;
  top: 10vh;
  z-index: 3;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  @media(max-width: 500px) {
    width: 90%;
    left: 5vw;
  }

  @media(max-width: 1000px) {
    height: 90%;
  }
`;

export const DarkContainerModal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

export const OfficeList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  li {
    display: flex;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    width: 100%;
    color: #000;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 2px solid #eeeeee;

    & + li {
      margin-top: 16px;
    }

    strong {
      font-weight: 500;
    }
  }
`;

export const DivTable = styled.div`
  display: flex;
  max-height: 610px;
  `;

export const MoreHorizon = styled.div`
  &:hover {
    cursor: pointer;
  }
`
export const HQ1 = styled.div`
  display: flex;
  justify-content: space-between;
  div:nth-child(1) {
    width: 74%;
  }

  div:nth-child(2) {
    width: 22%;
  }

  @media (max-width: 960px) {
    flex-direction: column;

    div:nth-child(1) {
      margin: 10px 0;
    width: 100%;
  }

  div:nth-child(2) {
    margin: 10px 0;
    width: 100%;
  }
  }
`

export const HQ2 = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    margin: 10px 0;
    width: 22%;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    
      div {
      width: 100%;
    }
  }
`

export const OfficesDiv = styled.div`
  display: flex;
  width: 100%;
  font-weight: 400;
  @media (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 100px
  }
`

export const ButtonOfficesDiv = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 500px) {
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    button {
      width: 100%;
    }
  }
`

export const ListSettingsWrapper = styled.div`
  display: flex;
  /* border: 1px solid red; */
  justify-content: center;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

export const ModalDiv1 = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  div:nth-child(1),
  div:nth-child(3) {
    width: 22%
  }

  div:nth-child(2) {
    width: 48%
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    div:nth-child(1),
    div:nth-child(3) {
    width: 100%
  }

  div:nth-child(2) {
    width: 100%
  }
  }
`
export const ModalDiv2 = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  div {
    width: 22%
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    div {
      width: 100%;
    }
  }
`

export const ModalDiv3 = styled.div`
  display: flex;
  width: 100%;
  div {
    width: 100%;
  }
`

export const CloseModalDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export const DivInputModal = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid red;
  /* margin-left: 30px; */
  
  div {
    width: 500px;
  }
`