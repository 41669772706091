import React, { useState } from "react";
import {
  Container,
  ContainerButtonFilter,
  ContainerInputDate,
  InputDate
} from './styles';
import { Button, IconButton } from "@mui/material";
import { Close } from "@material-ui/icons";

export function RangeDate({onChangeStartDate, onChangeEndDate, onHandleFilter}) {
  const [visible, setVisible] = useState(false);

  const RangeDateComponent = () => {
    return(
      <>
        <ContainerInputDate>
          <span>Start Date</span>
          <InputDate onChange={e => onChangeStartDate(e.toISOString().split('T')[0])}/>
        </ContainerInputDate>
        <ContainerInputDate>
          <span>End Date</span>
          <InputDate onChange={e => onChangeEndDate(e.toISOString().split('T ')[0])}/>
        </ContainerInputDate>
        <ContainerButtonFilter>
          <Button 
            variant="outlined"
            onClick={() => onHandleFilter()}
          >
            Enter
          </Button>
        </ContainerButtonFilter>
        <ContainerButtonFilter>
          <IconButton onClick={() => setVisible(false)}>
            <Close />
          </IconButton>
        </ContainerButtonFilter>
      </>
    )
  }

  return (
    <Container>
      {
        visible ? 
        RangeDateComponent() :
        <Button 
          variant="outlined"
          onClick={() => setVisible(true)}
        >
          Filter
        </Button>
      }
    </Container>
  )
}