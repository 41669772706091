import styled from 'styled-components';
import { lighten, darken } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  min-height: 625px;
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  padding: 15px 15px 60px 15px;

  form {
    height: 100%;
  }
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  background-color: transparent;
  padding: 0px;

  img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;

  img {
    width: 23px;
    height: 23px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  
  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    height: 100px;
    align-items: center;
  }
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;

  border: 1px solid #eeeeee;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 30px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333533;
  }
`;

export const DivForms = styled.div`
  display: flex;
  flex: 1;
  margin-top: 30px;
  /* margin-bottom: 30px; */
  width: 100%;
  /* padding: 0; */

`;


export const DivInput = styled.div`
  /* width: 30%; */
  display: flex;
  flex-direction: column;

  @media(max-width: 960px) {
   width: 100%;
    
  }

  /* img {
    width: 55px;
    height: 55px;
  } */

  input {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ececec;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

  select {
    margin-top: 10px;
    display: block;
    width: 100%;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const DivInputText = styled.div`
width: 92%;
`

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const SubmitButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 197px;
  height: 41px;
  color: #ffffff;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const DarkContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;

  height: 2000px;
  width: 2500px;
`;

export const NextStepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  /* border: 1px solid red; */

  @media (max-width: 760px) {
      flex-direction: column;
        button {
          width: 100%;
          margin: 10px 0;
        } 
    }
`;

export const StepText = styled.text`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 22px;

  color: #618095;
`;

export const NavTab = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 30px;
  list-style: none;
  border-bottom: 2px solid #618095;

  li {
    display: flex;
    background: #cfdbd5;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    margin-top: 5px;
    min-width: 144px;
    max-width: 100%;
    height: 39px;
    /* margin-left: 10px; */
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;

    a {
      display: block;
      padding: 0.5rem 1rem;
      text-decoration: none;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1300px) {
    display: none
  }
`;

export const DivisionDiv = styled.div`
  width: 200px;
`

export const FullDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  div {
    width: 100%;
  }
  
`

export const Half2Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  div {
    width: 48%;
  }

  @media (max-width: 760px) {
      flex-direction: column;
        div {
          width: 100%;
        } 
    }
  
`

export const Half2Div2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;

  div:nth-child(1) {
    width: 74%;
  }

  div:nth-child(22) {
    width: 22%;
  }

  @media (max-width: 760px) {
      flex-direction: column;
      div:nth-child(1) {
        width: 100%;
      }

      div:nth-child(22) {
        width: 100%;
      }
    }
  
`
