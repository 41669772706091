import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';

import { JobContext } from '../../contexts/Job'
import { Link, useHistory } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MUIDataTable from "mui-datatables";
import { DatePicker,  } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import api from '../../services/api';

import Popover from '@material-ui/core/Popover';

import SideBar from '../../components/SideBar';
import searchByIcon from '../../assets/icons/searchBy.png';
import Header from '../../components/Header';
import ConfirmModal from '../../components/ConfirmModal';
import ToastContainer from '../../components/ToastContainer';

import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'


import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  PaginationButtonsContainer,
  TableContainer,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  ContainerInformations,
  EditButton,
  DarkContainer,
  PopoverDiv,
  MoreHorizon,
  JobNumberDiv,
  DateFilterDiv,
  FilterContainer,
  StatusContainer
} from './styles';
import { format, isValid } from 'date-fns';
import { FormControl } from '@mui/material';
import { FormLabel } from '@mui/material';

function Jobs() {
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [showToast, setShowToast] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [sortedActivities, setSortedActivities] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [ip, setIp] = useState([]);
  const openPop = Boolean(anchorEl);
  const { jobId, setJobId } = useContext(JobContext)
  const [initialStartDate, setInitialStartDate] = useState(null);
  const [finalStartDate, setFinalStartDate] = useState(null);
  let history = useHistory();

  const statusColors = [
    "#FF5733", // Red
    "#FFC300", // Yellow
    "#36A64F", // Green
    "#3498DB", // Blue
    "#8E44AD", // Purple
  ];

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function comparePerCreatedAt(a, b) {
    return a.created_at - b.created_at;
  }

  async function getJobs() {
    api
      .get('/job', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        let jobsData = res.data.map(item => {
          item.created_at = new Date(item.created_at)
          return {
            ...item,
            division: item.division?.[0]
          }
        })
        setJobs(jobsData.sort(comparePerCreatedAt).reverse())
        setFilteredJobs(jobsData.sort(comparePerCreatedAt).reverse())
        return jobsData
      })
  }

  useEffect(() => {
    getJobs();
    getIP();
  }, []);

  function goEdit(id) {
    const job = jobs.find(items => items.job_id === id);
    localStorage.setItem('@Propack:customerName', `${job.first_name} ${job.last_name}`);
    localStorage.setItem('@Propack:customerPhone', job.business_phone);
    localStorage.setItem('@Propack:job_number', job.job_number);
    localStorage.setItem('@Propack:invoice_id', job.invoice_id);
    history.push({
      pathname: `/jobs/viewer/`,
      state: job.job_id,
    });
    setSortedActivities([])
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  async function deleteJob(job_id) {
    setLoadingDelete(true);
    await api
      .delete(
        `/job/delete/${job_id}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        setOpenPopUp({ show: false })
        window.location.reload();
        setSortedActivities([])
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Job successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        setOpenPopUp({ show: false })
        setSortedActivities([])
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting job',
        });
      });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value, openJobNumber) => {
    if (event)
      handleClick(event)
    setJobId(value)
    localStorage.setItem('jobId', value);
    if (openJobNumber)
      goEdit(value)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeJob = () => {
    handleClose()
    setOpenPopUp({ show: true })
    // deleteCustomer()

  }

  const columns = [
    {
      name: "project_manager_name",
      label: "Project Manager",
      options: {
        filter: true,
        display: false
      },
    },

    {
      name: "created_at",
      label: "Start date",
      options: {
        filter: false,
        sort: true,
        display: false,
        filterType: 'custom',
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location);
            return false;
          },
          display: () => {
            return (
              <FilterContainer>
                <FormLabel>
                  Start Date
                </FormLabel>
                <FilterContainer>
                  <DatePicker 
                    value={initialStartDate} 
                    onChange={(e) => {
                      if(isValid(e)){
                        setFilteredJobs(jobs);
                        setInitialStartDate(e)
                        onChangeFilterDate(e, finalStartDate);
                      }
                    }}
                  />
                  <label>Até</label>
                  <DatePicker 
                    value={finalStartDate} 
                    onChange={(e) => {
                      if(isValid(e)){
                        setFilteredJobs(jobs);
                        setFinalStartDate(e)
                        onChangeFilterDate(initialStartDate, e)
                      }
                    }}
                    disabled={!initialStartDate ? true : false}
                  />
                </FilterContainer>
              </FilterContainer>
            );
          }
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <JobNumberDiv>
              <span>{format(new Date(value), 'MM/dd/yyyy')}</span>
            </JobNumberDiv>
          )
        }
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <StatusContainer status={value}>
              <span>{value}</span>
            </StatusContainer>
          )
        }
      },
    },
    {
      name: "job_number",
      label: "Job Number",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <JobNumberDiv onClick={(event) => openPopover(null, tableMeta.rowData[10], 'openJobNumber')}>
              <span>{value}</span>
            </JobNumberDiv>
          )
        }
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "division",
      label: "Division",
      options: {
        filter: true,
        sort: true
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "business_phone",
      label: "Customer Cell",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "claim_number",
      label: "Claim Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "received_by_name",
      label: "Sold By",
      options: {
        filter: true,
        display: false
      },
    },
    {
      name: 'job_id',
      label: "Options",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
              paddingLeft: '14px'
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value)}>
                <MoreHorizIcon  style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {/* <PopoverDiv >
                  <img height={15} src={ViewDetails} style={{ marginRight: '10px' }}></img>
                    View Details
                  </PopoverDiv> */}
                <PopoverDiv onClick={() => goEdit(jobId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                  Edit
                </PopoverDiv>
                <PopoverDiv onClick={() => removeJob()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                  Delete
                </PopoverDiv>
              </Popover>
            </div>
          )
        }
      },
    },
    
  ];
  const options = {
    // filter: "false",
    selectableRows: "none",
    filterType: 'checkbox'
  };

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  const onChangeFilterDate = (initialDate, finalDate) => {
    if(finalDate){
      const parsedInitialDate = new Date(initialDate)
      const parsedFinalDate = new Date(finalDate)
      if(parsedInitialDate < parsedFinalDate){
        const result = jobs.filter(job => {
          const dataJob = new Date(job.created_at);
          return dataJob >= parsedInitialDate && dataJob <= parsedFinalDate;
        })
        setFilteredJobs(result)
      }
  }
    }

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {showToast.show && (
            <ToastContainer
              messages={showToast}
              show={() => handleToast()}
            />
          )}
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show &&
            <ConfirmModal
              show={() => handlePopUp()}
              deleteUser={() => deleteJob(jobId)}
            />
          }
          <ContainerForms >
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Jobs</text>
              </div>
            </ContainerHeader>
            <ContainerInformations>
              <HeaderInformations>
                <div>
                  <img src={searchByIcon} alt="searchByIcon" />
                  <text>Search by:</text>
                </div>
                <Link to="/job/register">
                  <button type="button">New Job</button>
                </Link>
              </HeaderInformations>
              <LineHorizontal />
              {
                <MUIDataTable
                  title={"Jobs List"}
                  data={filteredJobs}
                  columns={columns}
                  options={options}
                />
              }
            </ContainerInformations>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default Jobs;