import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { JobContext } from '../../../../contexts/Job'

import ActivityRegistration from './ActivityRegistration'
import ActivityEdit from './ActivityEdit'

import api from '../../../../services/api';
import Axios from 'axios'


import MUIDataTable from "mui-datatables";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ListIcon from '@material-ui/icons/List';

import Popover from '@material-ui/core/Popover';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import ConfirmModal from '../../../../components/ConfirmModal';
import TransitionsModal from '../../../../components/TransitionsModal';


import EditInventory from '../../../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../../../assets/icons/DeleteInventory.svg'
import email from '../../../../assets/icons/email.svg'
import call from '../../../../assets/icons/call.svg'
import visit from '../../../../assets/icons/visit.svg'
import task from '../../../../assets/icons/task.svg'

import {
  HeaderInformations,
  ContainerForms,
  MoreHorizon,
  PopoverDiv,
  ActivityNameDiv,
  DenyButton,
  ConfirmButton,
  DarkContainer,
  LineHorizontal,
  DescriptionDiv
} from './styles'
import { format } from 'date-fns';

function Activities() {
  const location = useLocation();
  let history = useHistory();
  const newTask = location.newTask;

  const [showToast, setShowToast] = useState({ show: false });
  const [activities, setActivities] = useState([]);
  const [notes, setNotes] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [ip, setIp] = useState([]);
  const [activityId, setActivityId] = useState(null);
  const { jobId, setJobId } = useContext(JobContext)
  // const [loadingDelete, setLoadingDelete] = useState(false);
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [openModal, setOpenModal] = useState(false)
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false)
  const [description, setDescription] = useState('')
  // const [loading, setLoading] = useState(false)

  const openPop = Boolean(anchorEl);

  const setBackGroundColor = (priority) => {
    if (priority === 'Low')
      return '#9CD97F'
    else if (priority === 'Normal')
      return '#859DF0'
    else
      return '#FF7777'
  }

  function goEdit(id) {
    history.push(`/jobs/activityedit/${id}`)
    setActivityId(null)
  }

  const insertIcon = (iconType) => {
    let icon
    if (iconType === 'Call')
      icon = call
    else if (iconType === 'Visit')
      icon = visit
    else if (iconType === 'Task')
      icon = task
    else
      icon = email
    return (
      <div style={{
        border: '1px solid #618095', display: 'flex',
        justifyContent: 'center', alignItems: 'center', borderRadius: '5px',
        height: '30px', width: '40px'
      }} >
        <img height={20} src={icon} />
      </div>

    )
  }

  const handleOpenDescriptionModal = (desc) => {
    setDescription(desc)
    setOpenDescriptionModal(true)
  }

  const descriptionContent = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        minHeight: '500px', fontSize: '20px',
      }}>
        <div style={{
          display: 'flex', alignItems: 'flex-end',
          width: '100%', height: '40px', fontFamily: 'roboto', justifyContent: 'center'
        }}>
          Description
        </div>
        <LineHorizontal />
        <div style={{
          width: '100%',
          marginTop: '15px', textAlign: 'center'
        }}>
          <TextareaAutosize
            style={{
              backgroundColor: '#ececec', border: 'none',
              color: '#495057', padding: '10px', width: '80%',
              maxHeight: '400px', fontSize: '15px', overflow: 'auto',
            }}
            rows={5}
            aria-label="maximum height"
            value={description}
          />
        </div>
      </div>
    )
  }

  const columns = [
    {
      name: "team_member_name",
      label: "Team Member",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[8] === "note") {
            return (
              <div style={{
                display: 'flex',
                width: '120px', justifyContent: 'center'
              }}>
                {tableMeta.rowData[6]}
              </div>
            )
          } else {
            return (
              <ActivityNameDiv
                onClick={() => goEdit(tableMeta.rowData[7])}
                style={tableMeta?.rowData[3] !== "Completed" &&
                  typeof tableMeta.rowData[4] &&
                  new Date(tableMeta?.rowData[4].split('T')[0]) - new Date() < 0
                  ? {
                    color: 'red', display: 'flex',
                    width: '120px', justifyContent: 'center'
                  }
                  : {
                    display: 'flex',
                    width: '120px', justifyContent: 'center'
                  }}
              >
                <span>{`${tableMeta?.rowData[0]} `}</span>
              </ActivityNameDiv>
            )
          }
        }
      },
    },
    {
      name: "activity_type",
      label: "Type",
      options: {
        filter: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[8] === "note") {
            return (
              "Note"
            )
          } else {
            return (
              insertIcon(tableMeta.rowData[0])
            )
          }
        }
      },
    },
    {
      name: "date",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <span
                style={tableMeta.rowData?.[3] === 'Completed' ?
                  { textDecorationLine: 'line-through' }
                  : tableMeta?.rowData?.[3] !== "Completed" &&
                    typeof tableMeta.rowData?.[4] &&
                    new Date(tableMeta?.rowData?.[4].split('T')[0]) - new Date() < 0 ?
                    { color: 'red' } : null}
              >{`${format(new Date(tableMeta.rowData[2]), 'MM/dd/yyyy') } `}</span>
            </div>
          )
        }
      },
    },
    {
      name: "due_date",
      label: "Due Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[8] === "note") {
            return (
              ""
            )
          } else {
            return (
              <div>
                <span
                  style={tableMeta.rowData?.[3] === 'Completed' ?
                    { textDecorationLine: 'line-through' }
                    : tableMeta?.rowData?.[3] !== "Completed" &&
                      typeof tableMeta.rowData?.[4] &&
                      new Date(tableMeta?.rowData?.[4].split('T')[0]) - new Date() < 0 ?
                      { color: 'red' } : null}
                >{`${format(new Date(tableMeta.rowData[3]), 'MM/dd/yyyy') } `}</span>
              </div>
            )
          }
        }
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[8] === "note") {
            return (
              "Note"
            )
          } else {
            return (
              <div
                style={tableMeta?.rowData[3] !== "Completed" &&
                  new Date(tableMeta?.rowData[4].split('T')[0]) - new Date() < 0 ?
                  { color: 'red', width: '100%' } : { width: '100%' }}
              >
                <span>{`${tableMeta?.rowData[4]} `}</span>
              </div>
            )
          }
        }
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{marginRight: '-100px', width: '300px'}}
              onClick={() => handleOpenDescriptionModal(tableMeta.rowData[5])}>
              <DescriptionDiv
                disabled
                rows="4" cols="50"
              >{`${tableMeta.rowData[5]} `}</DescriptionDiv>
            </div>
          )
        }
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[8] === "note") {
            return (
              ""
            )
          } else {
            return (
              <div style={{
                display: 'flex',
                justifyContent: 'center', backgroundColor: setBackGroundColor(value)
              }}>
                <span>{`${value} `}</span>
              </div>
            )
          }
        }
      },
    },
    {
      name: 'activity_id',
      label: "Options",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[8] === "note") {
            return (
              ""
            )
          } else {
            return (
              <div style={{
                width: '100%', display: 'flex',
              }}>
                <MoreHorizon onClick={(event) => openPopover(event, value)}>
                  <MoreHorizIcon fontSize={'40px'} style={{
                    color: '#618095',
                    fontSize: '30px'
                  }}

                  />
                </MoreHorizon>
                <Popover
                  open={openPop}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <PopoverDiv onClick={() => goEdit(activityId)}>
                    <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                    Edit
                  </PopoverDiv>
                  <PopoverDiv onClick={() => removeActivity()}>
                    <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                    Delete
                  </PopoverDiv>
                </Popover>
              </div>
            )
          }
        }
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        viewColumns: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
            }}
              onClick={() => console.log(tableMeta.rowData)}>
              ola
            </div>
          )
        }
      },
    },
    {
      name: "note",
      label: "Note",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
  ];


  const options = {
    filter: "true",
    print: "true",
    search: "true",
    download: "true",
    viewColumns: "false",
    selectableRows: "none",
    filterType: 'checkbox'
  };

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(err);
      });
  }

  async function getActivities() {
    api
      .get(`/activity/${localStorage.getItem('jobId')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        if (res.data.length)
          setActivities(res.data)
        else
          setActivities([])
        getNotes()
      });
  }


  async function deleteActivity(activity_id) {
    setLoadingDelete(true);
    await api
      .delete(
        `/activity/${activity_id}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        setOpenPopUp({ show: false })
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Activity successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        setOpenPopUp({ show: false })
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting activity',
        });
      });
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    handleClick(event)
    setActivityId(value)

  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeActivity = () => {
    handleClose()
    setOpenPopUp({ show: true })
    // deleteCustomer()

  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  const handleCloseModal = () => {
    setOpenModal()
    if (newTask)
    {
      window.location.href = `${process.env.REACT_APP_WEB_URL}/myTasks`;
    }
  }

  async function getNotes() {
    api
      .get(`/general-note/jobnumber/${localStorage.getItem('@Propack:job_number')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        let data = res.data.map(note => {
          return {
            ...note,
            type: "note",
            team_member_name: note.employee_name
          }
        })
        setNotes(res.data)
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    if (newTask) {
      setOpenModal(true)
    }
    if (localStorage.getItem('jobId')) {
      setJobId(localStorage.getItem('jobId'))
    }
    // getNotes()
    getActivities();
    getIP();
  }, []);



  return (
    <>
      {openModal && <TransitionsModal openModal={true}
        closeModal={handleCloseModal} content={<ActivityRegistration newTask={newTask}/>} />}
      {openDescriptionModal && <TransitionsModal openModal={true}
        closeModal={setOpenDescriptionModal} content={descriptionContent()}
        widthProp="50%" />}
      {openPopUp.show && <DarkContainer />}
      {openPopUp.show &&
        <ConfirmModal
          show={() => handlePopUp()}
          deleteUser={() => deleteActivity(activityId)}
        />
      }
      <ContainerForms>
        <HeaderInformations >
          <div style={{
            display: 'flex', alignItems: 'inherit',
            fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
            fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
          }}>
            <ListIcon style={{ color: "#6096BA", fontSize: '2rem', marginRight: 10 }} />
            Activities List
          </div>
          {/* <Link to="/jobs/activityregistration"> */}
          <button
            type="button"
            onClick={() => setOpenModal(true)}
          >New Activity</button>
          {/* </Link> */}
        </HeaderInformations>
      </ContainerForms>
      <MUIDataTable
        data={activities}
        columns={columns}
        options={options}

      />
    </>
  );
}

export default Activities;