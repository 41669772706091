import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, useFormik } from 'formik';

import * as Yup from 'yup';

import Axios from 'axios'
import api from '../../../services/api';

import getValidationErros from '../../../utils/getValidationErrors';

import SideBar from '../../../components/SideBar';
import Header from '../../../components/Header';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ToastContainer from '../../../components/ToastContainer';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  NavTab,
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  ActivityContainer,
  Label,
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  Half3Div

} from './styles';
const index = () => {
  const templateTypeArray = ['Email', 'Call', 'Visit', 'Task']
  const priorityArray = ['Low', 'Normal', 'High']
  const rolesArray = ['Director', 'Project Manager', 'Lead Tech', 'Controller']

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showToast, setShowToast] = useState({ show: false });
  const [ip, setIp] = useState(null)
  const [openPopUp, setOpenPopUp] = useState({ show: false });

  const formik = useFormik({
    initialValues: {
      template_name: '',
      template_type: '',
      priority: 'Normal',
      creation_notification: '',
      due_notification: '',
      completed_notification: '',
      description: ''
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  async function handleSubmit(values, createNew) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        template_name: Yup.string().required('Template name is required'),
        template_type: Yup.string().required('Template type is required'),
        priority: Yup.string().required('Priority is required'),
        description: Yup.string().required('Description is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      createTemplate(values, createNew);

    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      console.error(errors);
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }

  const createTemplate = (values, createNew) => {
    api
      .post(
        '/template',
        {
          template_name: values.template_name,
          template_type: values.template_type,
          creation_notification: values.creation_notification,
          due_notification: values.due_notification,
          completed_notification: values.completed_notification,
          priority: values.priority,
          description: values.description,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setOpenPopUp({ show: true });
        setLoading(false);
        if (createNew) {
          formik.handleReset();
          window.location.href = "${process.env.REACT_APP_WEB_URL}/templateregistration";
        }
        else
          window.location.href = "${process.env.REACT_APP_WEB_URL}/templates";
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error creating',
          description: 'Error creating template',
        });
      });
  }


  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  const saveAndCreateNew = (formikObj) => {
    handleSubmit(formikObj.values, 'createNew');
  }

  useEffect(() => {
    getIP()
  }, [])

  return (
    <Container >
      <SideBar />
      <ContainerColumn >
        <Header />
        <ContainerBackground>
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms >
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Template Registration</text>
              </div>
            </ContainerHeader>
            <NavTab >
              <li
                style={{
                  backgroundColor: '#618095'
                }}
              >
                1. Template Details
                  </li>
            </NavTab>
            <HeaderInformations>
              <ViewCompactIcon style={{
                color: '#6096BA', fontSize: '2rem',
                marginRight: '10px'
              }} />
              Template Information
            </HeaderInformations>
            <LineHorizontal />
            <Formik >
              <Form onSubmit={formik.handleSubmit}>
                <ActivityContainer style={{ marginTop: '15px', marginBottom: 0 }}>
                  <FirstSection>
                    <div >
                      <Label
                      // style={errors.template_name ? { color: 'red' } : null}
                      >
                        Template Name
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="template_name"
                        placeholder="Enter the activity name..."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.template_name}
                        style={
                          errors.template_name ? { border: '1px solid red' } :
                            { width: '100%' }
                        }
                      />
                    </div>
                    <div>
                      <Label>
                        Template Type
                      <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="template_type"
                        as="select"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.template_type}
                        style={
                          errors.template_type ? { border: '1px solid red' } : { width: '100%' }
                        }
                      >
                        <option value="" disabled selected>
                          Choose a template type
                          </option>
                        {templateTypeArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                  </FirstSection>
                  <SecondSection style={{ marginTop: '20px' }}>
                    <div>
                      <Label>
                        Priotity
                      <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="priority"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.priority}
                        defaultValue='Normal'
                        style={
                          errors.priority ? { border: '1px solid red' } : null
                        }
                      >
                        <option value="" disabled selected>
                          Choose a priority
                          </option>
                        {priorityArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                  </SecondSection>
                  <Half3Div>
                    <div>
                      <Label>
                        Creation Notification
                      </Label>
                      <Field
                        name="creation_notification"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.creation_notification}
                      >
                        <option value="" disabled selected>
                          Choose a creation notification
                          </option>
                        {rolesArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <div>
                      <Label>
                        Due Notification
                      </Label>
                      <Field
                        name="due_notification"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.due_notification}
                      >
                        <option value="" disabled selected>
                          Choose a due notification
                          </option>
                        {rolesArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <div>
                      <Label>
                        Completed Notification
                      </Label>
                      <Field
                        name="completed_notification"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.completed_notification}
                      >
                        <option value="" disabled selected>
                          Choose a completed notification
                          </option>
                        {rolesArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>

                  </Half3Div>
                  <ThirdSection style={{ marginTop: '20px' }}>
                    <Label style={{ marginBottom: '10px' }}>
                      Description:
                    </Label>
                    <TextareaAutosize
                      style={
                        errors.template_name ? {
                          border: '1px solid red', backgroundColor: '#ececec', border: 'none',
                          color: '#495057', padding: '10px'
                        } : {
                          backgroundColor: '#ececec', border: 'none',
                          color: '#495057', padding: '10px'
                        }
                      }
                      rows={5}
                      aria-label="maximum height"
                      placeholder="Enter activity description..."
                      name="description"
                      placeholder="Enter the activity name..."
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.description}
                    />
                  </ThirdSection>
                  <LineHorizontal />
                  <FourthSection>
                    <button type="button" style={{
                      color: '#82ce5e',
                      border: '1px solid #82ce5e',
                      backgroundColor: 'white'
                    }}
                      onClick={() => saveAndCreateNew(formik)} >
                      {
                        loading ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          'Save & Create New Template'
                        )
                      }</button>
                    <button type="submit">
                      {loading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : (
                        'Save & Go to Dashboard'
                      )}
                    </button>
                  </FourthSection>
                </ActivityContainer>
              </Form>
            </Formik>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default index;
