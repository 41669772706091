import React, { createContext, useEffect, useState } from 'react';
export const JobContext = createContext();

export const JobProvider = ({ children }) => {
  let jobIdStorage = localStorage.getItem('jobId')
  const [jobId, setJobId] = useState()

  useEffect(() => {
    if (jobIdStorage)
      setJobId(jobIdStorage)
  }, [])

  return (
    <JobContext.Provider
      value={{
        jobId,
        setJobId
      }}
    >
      {children}
    </JobContext.Provider>
  );
};
