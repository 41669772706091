import React, { useState, useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import Activities from './Activities'
import Documents from './Documents'
import ControlPoints from './ControlPoints'
import Details from './Details'
import GeneralNote from './GeneralNote'
import Inventory from './InventoryItems'
import JobSettings from './JobSettings'
import JobPhotos from './JobPhotos'
import Payments from './Payments'

import SideBar from '../../../components/SideBar';
import Header from '../../../components/Header';

import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  NavTab,
  ContainerHeader,
  LineVertical,
  GanttContainer
} from './styles';

function JobViewer() {
  const location = useLocation();
  const [tabContent, setTabContent] = useState('activities')
  const [content, setContent] = useState(<Activities />)
  const customerName = localStorage.getItem('@Propack:customerName')
  const customerPhone = localStorage.getItem('@Propack:customerPhone')

  useEffect(() => {
    const hash = (location.hash).substr(1)
    if (hash)
      handleSetTab(hash)
  }, []);

  const handleSetTab = (hash) => {
    let content = ""
    if (hash == "activities")
      content = <Activities />
    else if (hash == "documents")
      content = <Documents />
    else if (hash == "control-points")
      content = <ControlPoints />
    else if (hash == "details")
      content = <Details />
    else if (hash == "general-note")
      content = <GeneralNote />
    else if (hash == "inventory-items")
      content = <Inventory />
    else if (hash == "job-photos")
      content = <JobPhotos />
    else if (hash == "workflow")
      content = <JobSettings />
    setTabContent(hash)
    setContent(content)
  }


  return (
    <Container >
      <SideBar />
      <ContainerColumn  >
        <Header />
        <ContainerBackground >
          <ContainerForms>
            <ContainerHeader >
              <div>
                <LineVertical />
                <text>Job Information - Customer name: {customerName}, Business Phone: {customerPhone}</text>
              </div>
            </ContainerHeader>
            <NavTab >
              <a href="#activities"
                style={
                  tabContent === 'activities' ? { backgroundColor: '#618095' } : null
                }
                onClick={() => {
                  setTabContent('activities')
                  setContent(<Activities />)
                }}>
                <li>
                  Activities
                </li>
              </a>
              <a href="#general-note"
                onClick={() => {
                  setTabContent('general-note')
                  setContent(<GeneralNote />)
                }}
                style={
                  tabContent === 'general-note' ? { backgroundColor: '#618095' } : null
                }>
                <li>
                  General Note
                </li>
              </a>
              <a href="#documents"
                style={
                  tabContent === 'documents' ? { backgroundColor: '#618095' } : null
                }
                onClick={() => {
                  setTabContent('documents')
                  setContent(<Documents />)
                }}>
                <li>
                  Documents
                </li>
              </a>
              <a href="#control-points"
                onClick={() => {
                  setTabContent('control-points')
                  setContent(<ControlPoints />)
                }}
                style={
                  tabContent === 'control-points' ? { backgroundColor: '#618095' } : null
                }>
                <li>
                  Control Points
                </li>
              </a>
              <a href="#details"
                onClick={() => {
                  setTabContent('details')
                  setContent(<Details />)
                }}
                style={
                  tabContent === 'details' ? { backgroundColor: '#618095' } : null
                }>
                <li >
                  Details
                </li>
              </a>
              <a href="#inventory-items"
                onClick={() => {
                  setTabContent('inventory-items')
                  setContent(<Inventory />)
                }}
                style={tabContent === 'inventory-items' ? { backgroundColor: '#618095' } : null}
              >
                <li>
                  Inventory
                </li>
              </a>
              <a href="#job-photos"
                onClick={() => {
                  setTabContent('job-photos')
                  setContent(<JobPhotos />)
                }}
                style={
                  tabContent === 'job-photos' ? { backgroundColor: '#618095' } : null
                }>
                <li>
                  Job Photos
                </li>
              </a>
              <a href="#workflow"
                onClick={() => {
                  setTabContent('workflow')
                  setContent(<JobSettings />)
                }}
                style={tabContent === 'workflow' ? { backgroundColor: '#618095' } : null}>
                <li>
                  Workflow
                </li>
              </a>
              <a href="#payments"
                onClick={() => {
                  setTabContent('payments')
                  setContent(<Payments />)
                }}
                style={tabContent === 'payments' ? { backgroundColor: '#618095' } : null}
              >
                <li>
                  Payments
                </li>
              </a>
            </NavTab>
            <GanttContainer>
              {content}
            </GanttContainer>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  )
}

export default JobViewer;