import styled, { keyframes } from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  width: 100%;
  background-color: #618095;
  position: sticky;
  top: 0;
  z-index: 999;
  // margin-left: 224px;

  @media (max-width: 960px) {
    margin-left: 0px;

    div {
      display: flex;
      width: 80%;
      justify-content: space-between;
    }
  }
`;

const DropDownSlide = keyframes`
100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
`;

export const Text = styled.span`
  color: #fff;

@media(max-width: 490px) {
  display: none;
}

`

export const DropdownContainer = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  left: auto;
  z-index: 999;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #747a80;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11);
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11);
  -webkit-animation-name: ${DropDownSlide};
  animation-name: ${DropDownSlide};
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: 0.3s;

  span {
    margin-left: 10px;
  }

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const DropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0.4rem 1.2rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover {
    background: ${darken(0.05, '#fff')};

  }
`;

export const Button = styled.button`
  background-color: transparent;
  height: 100%;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  // border: 1px solid red;
  text {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &:hover {
    cursor: pointer;
  }
  &:focus {
    background: ${lighten(0.1, '#618095')};
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }

  @media(max-width: 490px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const ArrowIcon = styled.img`
  width: 10px;
  height: 6px;
  margin-left: 8px;
`;

export const NotificationIcon = styled.img`
  width: 22px;
  height: 25px;
`;

export const SearchIcon = styled.img`
  width: 22px;
  height: 22px;
`;

export const FlagImg = styled.img`
  width: 31px;
  height: 31px;
  margin-right: 10px;
`;

export const AvatarImg = styled.img`
  width: 31px;
  height: 31px;
  margin-right: 8px;
`;
