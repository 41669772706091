import React, { Component } from 'react';

class ComponentToPrint extends Component {
  render() {
    const { content } = this.props;
    return (
      <div style={{
        display: 'flex', justifyContent: 'center',
        alignItems: 'center', width: '100%', height: '100%'
      }}>{content}</div>
    );
  }
}

export default ComponentToPrint;
