import styled from 'styled-components';
import { lighten } from 'polished';

export const ContainerBackground = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #618095;
`;

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 60%;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10vh;
  @media(max-width: 960px) {
   width: 90%;
  }
`;

export const DivIllustration = styled.div`
  display: flex;
  width: 40%;
  background: #fafafa;
  align-items: center;
  justify-content: center;
  
  @media(max-width: 960px) {
    display: none;
  }
`;

export const DivInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  @media(max-width: 960px) {
    width: 100%;
  }

  img {
    width: 50%;
  }
`;

export const DivTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const DivForms = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  input {
    border: 1px solid #afaeae;
    margin-top: 24px;
    border-radius: 3px;
    width: 87%;
    height: 36px;
    padding: 20px;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const WelcomeText = styled.text`
  margin-top: 16px;
  margin-left: 50px;
  font-weight: 500;
  font-size: 20px;
`;

export const InformationText = styled.text`
  font-weight: normal;
  margin-left: 50px;
  font-size: 14px;
  margin-top: 8px;
`;

export const ForgotPasswordText = styled.text`
  color: #8c8c8c;
  margin-top: 24px;
  font-size: 14px;
`;

export const ClickHereText = styled.a`
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
`;

export const Input = styled.input`
  border: 1px solid #afaeae;
  margin-top: 24px;
  border-radius: 3px;
  width: 87%;
  height: 36px;
  padding: 20px;

  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const ButtonLogin = styled.button`
  background-color: #82ce5e;
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  width: 87%;
  height: 36px;
  margin-top: 24px;
  color: #ffffff;
  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;
