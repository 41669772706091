import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import api from '../../services/api'

import jwt_decode from "jwt-decode";

import { CircularProgress } from '@material-ui/core';

import ToastContainer from '../../components/ToastContainer';

import { Link, useParams } from 'react-router-dom';
import {
  ContainerBackground,
  ContainerLogin,
  DivIllustration,
  DivInformation,
  DivForms,
  DivTexts,
  WelcomeText,
  InformationText,
  Input,
  ButtonLogin,
  ForgotPasswordText,
  ClickHereText,
} from './styles';

import loginIllustration from '../../assets/loginIllustration.png';
import logo from '../../assets/logo.png';

function ResetPassword() {
  let { token } = useParams();
  let decodedToken = jwt_decode(token)

  const [ip, setIp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false)
  const [showToast, setShowToast] = useState({ show: false });



  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        setIp('0.0.0.0');
      });
  }

  const handleSubmit = async () => {
    if (password !== confirmPassword || password.length < 6) {
      setShowToast({
        show: true,
        type: 'error',
        title: 'Error',
        description: 'Passwords must be the same and have at least 6 characters.',
      });
      return
    } else {
      changePassword()
    }
  }

  async function changePassword() {
    await api
      .post('/reset-password', {
        employee_id: decodedToken.employee_id,
        password,
        ip
      })
      .then(response => {
        setLoading(false)
        setShowToast({
          show: true,
          type: 'success',
          title: 'Success',
          description: 'Password changed successfully.',
        });
        // setLoading(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/`;
      })
      .catch(err => {
        setLoading(false);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: 'Error in change password.',
        });
      });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  useEffect(() => {
    getIP();
  }, []);

  return (
    <ContainerBackground>
      {showToast.show && (
        <ToastContainer messages={showToast} show={() => handleToast()} />
      )}
      <ContainerLogin>
        <DivIllustration>
          <img src={loginIllustration} alt="Illustration" />
        </DivIllustration>
        <DivInformation>
          <img src={logo} alt="Logo" />
          <DivTexts>
            <WelcomeText><a>Welcome!</a></WelcomeText>
            <InformationText>Reset password</InformationText>
            <DivForms>
              <Input
                type="password"
                placeholder="Enter the password"
                onChange={e => setPassword(e.target.value)}
              />
              <Input
                type="password"
                placeholder="Confirm the password"
                onChange={e => setConfirmPassword(e.target.value)}
              />
              <ButtonLogin
                onClick={() => handleSubmit()}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  'Send'
                )}
              </ButtonLogin>
              <div className="mt-3">
                <ForgotPasswordText>Back to login?</ForgotPasswordText>
                <Link to="/">
                  <ClickHereText> Click here</ClickHereText>
                </Link>
              </div>
            </DivForms>
          </DivTexts>
        </DivInformation>
      </ContainerLogin>
    </ContainerBackground>
  );
}

export default ResetPassword;
