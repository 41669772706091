import { DatePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerInputDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  span {
    font-size: 14px;
  }
`;

export const ContainerButtonFilter = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  align-items: end;
  justify-content: end;
`;

export const InputDate = styled(DatePicker)`

`