import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;

`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }
`;

export const ContainerRow = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const ContainerPageTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerBackground = styled.div`
  display: flex;
  
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fafafa;
  font-family: Inter,-apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif;

  > div {
    width: 100%;
    display: flex;
    margin: 10px;
    
    @media(max-width: 960px) {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  }

  > div:nth-child(1) {
    justify-content: flex-start;
    font-size: 20px;
    align-items: center;

    
  }

  > div:nth-child(2) {
    padding: 0 20px;
    height: 20%;
    justify-content: space-between;
    
    > div {
      border-radius: 0px;
      width: 22%;
      height: 100%;

      @media(max-width: 960px) {
        width: 90%;
        height: 90%;
      }
    }
    @media(max-width: 960px) {
      width: 90%;
      height: 100%;
    }
  }

  > div:nth-child(3) {
    height: 358px;
    padding: 0 20px;
    justify-content: space-between;
    
    .pieChart {
      width: 22%;

      @media(max-width: 960px) {
      width: 90%;
      height: 100%;
    }
    }
    > div {
      width: 48%;
      height: 100%;
      
      #legends{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* border: 1px solid; */
        width: 100%;

        > div {
        font-size: 10px;
        display: flex;
        align-items: center;
        /* margin: 0 5px; */
        width: max-content;
        }
        .square {
          height: 10px;
          width: 10px;
          margin-right:5px;
        }
      }

      @media(max-width: 960px) {
      width: 90%;
      height: 100%;
    }
    }
    
    @media(max-width: 960px) {
      width: 90%;
      height: 100%;
    }
  }

  > div:nth-child(4) {
    padding: 0 20px;
    height: 358px;
    justify-content: space-between;

    
    > div {
      width: 48%;
      margin: 5px 0;
      
      #legends{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* border: 1px solid; */
        width: 100%;
        
        > div {
        font-size: 10px;
        display: flex;
        align-items: center;
        margin-right:5px;
        width: max-content;
        }
        .square {
          height: 10px;
          width: 10px;
          margin: 0 5px;
        }
      }

     
      @media(max-width: 960px) {
      width: 90%;
      height: 100%;
    }
    }
    
    @media(max-width: 960px) {
      width: 90%;
      height: 100%;
    }
  }

  > div:nth-child(5) {
    padding: 0 20px;
    height: 50%;
    justify-content: space-around;
    margin-bottom: 10px;
    
    
    > div {
      width: 40%;
      height: 100%;

      @media(max-width: 960px) {
      width: 90%;
      height: 100%;
      }
    }
    
    @media(max-width: 960px) {
      height: 100%;
    }
  }

  > div:nth-child(6) {
    padding: 0 20px;
    height: 50%;
    justify-content: space-between;
    margin-bottom: 10px;
    
    > div {
      overflow-y: auto;
      width: 48%;
      margin: 5px 0;
      height: 100%;

      @media(max-width: 960px) {
      width: 90%;
      height: 100%;
      }
    }
    
    @media(max-width: 960px) {
      height: 100%;
    }
  }
  
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  /* border-radius: 5px; */
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  font-family: Inter,-apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif;
  
  > div:first-of-type {
    padding-top: 10px;
    /* border: 1px solid; */
    /* padding: 15px; */
    /* margin-top: 10px; */
  }
`;

export const ContainerElements = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05); */
  /* padding: 15px; */
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;

  table {
    padding: 50px;
    thead {
      background: #e6ebee;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;

      color: #618095;

      tr {
        height: 50px;
      }
      th {
        padding: 0px 20px 0px 20px;
      }
    }
    td {
      font-size: 14px;
      line-height: 16px;

      color: #8c8c8c;
      padding: 10px 20px 10px 20px;
    }

    span {
      height: 6px;
      width: 6px;
      margin-left: 5px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
    }

    button {
      background-color: transparent;
      color: #8c8c8c;
      border-radius: 50%;
      border: none;
      font-size: 30px;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
    }
  }
`;

export const MoreHorizon = styled.div`
  &:hover {
    cursor: pointer;
  }
`
export const JobNumberDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`


export const PopoverDiv = styled.div`
  border-bottom: 1px solid #eee;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`

export const PaginationButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    background-color: transparent;
    color: #8c8c8c;
    border-radius: 50%;
    border: none;
    font-size: 12px;

    &:hover {
      cursor: pointer;
      background-color: ${darken(0.01, '#fafafa')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #333533;
  }
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;
  margin-bottom: 4 0px;

  border: 1px solid #eeeeee;
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;

  img {
    width: 17px;
    height: 19px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;

  > img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const EditButton = styled.button`
  border: none;
  color: #fff;
  padding: 8px;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;
`;

export const DarkContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;

  height: 2000px;
  width: 2500px;
`;

export const NavTab = styled.ul`
  display: flex;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 2px solid #618095;
  /* border: 1px solid red; */

  li {
    display: flex;
    background: #cfdbd5;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    width: 134px;
    height: 39px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;

    a {
      display: block;
      /* padding: 0.5rem 1rem; */
      text-decoration: none;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
  @media (max-width: 1200px) {
    z-index: 2;
    overflow-x: scroll;
    overflow-y: clip;
  }

`;