import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/Auth';

import { StyledBurger } from './styles.js';

function HamburgerMenu() {
  const { openMenu, setOpenMenu } = useContext(AuthContext);
  return (
    <StyledBurger open={openMenu} onClick={() => setOpenMenu(!openMenu)}>
      <span />
      <span />
      <span />
    </StyledBurger>
  );
}


export default HamburgerMenu;