import react, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core'

import SideBar from '../../../../../components/SideBar';
import Header from '../../../../../components/Header';

import {
  Container,
  ContainerBackground,
  ContainerColumn,
  ContainerForms,
  Textarea,
  DivInput,
  Title,
  Label,
  BackButton,
} from './styles';
import api from '../../../../../services/api';

const index = (props) => {
  const [note, setNote] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [sendingTo, setSendingTo] = useState([]);

  const history = new useHistory()
  const { noteId } = useParams();
  async function getNotes() {
    api
      .get(`/general-note/id/${noteId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        setNote(res.data)
        getEmployee(res.data.created_by, 'create');
        if(res.data.sending_to) {
          res.data.sending_to.forEach(sendingId => {
            getEmployee(sendingId, 'sending');
          });
        }
      })
      .catch(err => console.error(err))
  }

  async function getEmployee(id, type){
    api
      .get(`/employee/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        if(type === 'create'){
          setCreatedBy(res.data[0]);
        } else {
          setSendingTo(prev => ([...prev, ` ${res.data[0].first_name} ${res.data[0].last_name}`]));
        }
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    getNotes();
  }, [])

  function handleJob() {
    history.push({
      pathname: `/jobs`,
    });
  }

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
         { 
            note && createdBy ? (
              <ContainerForms >
                <Title>Note from JOB: {note.job_number}</Title>
                <DivInput>
                  <Label>Description</Label>
                  <Textarea
                    disabled
                    value={note.note}
                  />
                </DivInput>
                <DivInput>
                  <Label>Date</Label>
                  <input value={note.date} disabled/>
                </DivInput>
                <DivInput>
                  <Label>Created by</Label>
                  <input 
                    value={`${createdBy.first_name} ${createdBy.last_name}`} 
                    disabled
                  />
                </DivInput>
                <DivInput>
                  <Label>Sending To</Label>
                  <input 
                    value={sendingTo} 
                    disabled
                  />
                </DivInput>
                {/* { sendingTo.length > 0 ?
                    <DivInput>
                      <Label>Sending To</Label>
                      {sendingTo.forEach(sendingName => (
                        <input 
                          value={sendingName} 
                          disabled
                        />
                      ))}
                    </DivInput>
                   :
                  ''
                } */}
                <DivInput>
                  <BackButton onClick={() => handleJob()}>
                    Back to jobs
                  </BackButton>
                </DivInput>
              </ContainerForms>
            ) : <CircularProgress/>
          }
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  )
}

export default index