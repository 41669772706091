import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  padding: 15px;
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;

  > img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 50px;

  > img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;

  border: 1px solid #eeeeee;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333533;
  }
`;

export const DivForms = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  height: 10%;

  @media(max-width: 960px) {
    flex-direction: column;
    
  }
`;

export const DivInput = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;

  @media(max-width: 960px) {
   width: 100%;
    /* border: 1px solid red; */
    
  }

  img {
    width: 55px;
    height: 55px;
  }

  input {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ececec;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

  select {
    margin-top: 10px;
    display: block;
    width: 100%;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const DivPicture = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const UploadButton = styled.label`
  background-color: #618095;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 3px 8px rgba(97, 128, 149, 0.3);
  border-radius: 3px;
  width: 110px;
  height: 29px;
  border: none;
  transition: 0.3s;
  color: #fff;
  margin-left: 10px;

  text {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }

  &:hover {
    background: ${lighten(0.15, '#618095')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const SubmitButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 197px;
  height: 41px;

  color: #ffffff;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const PopUpButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;

  width: 84px;
  height: 32px;
  margin-top: 24px;
  color: #ffffff;
  background: #618095;
  box-shadow: 0px 3px 8px rgba(97, 128, 149, 0.3);
  border-radius: 3px;

  &:hover {
    background: ${lighten(0.15, '#618095')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const DivPopUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  > img {
    height: 101px;
    width: 101px;
    margin-bottom: 20px;
  }
`;


export const ConfirmButton = styled.button`
    width: 100px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    
  }
`

export const DenyButton = styled.button`
    width: 100px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background-color: #FF5656;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.15, '#FF5656')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    
  }
`

export const DeleteButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-left: 20px;
  width: 197px;
  height: 41px;
  /* margin-top: 15px; */
  color: #ffffff;
  background-color: #FF5656;
  /* border-radius: 50%; */

  &:hover {
    background: ${lighten(0.15, '#FF5656')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const DarkContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;

  height: 2000px;
  width: 2500px;
`;

export const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  margin-top: 10px;

  div {
    width: 22%;
  }
  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 320px; */
      margin-top: 10px;
      div {
        width: 100%;
      }
    }
`

export const SecondSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  margin-top: 10px;

  div {
    width: 48%;
  }
  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 160px; */
      margin-top: 10px;
      div {
        width: 100%;
      }
    }
`

export const ThirdSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  align-items: flex-end;
  margin-top: 10px;
  
  div {
    width: 48%;
  }

  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 160px; */
      margin-top: 10px;
      
      button {
        width: 100%;
        margin-top: 10px;
      }
      
      div {
        width: 100%;
      }
    }
`

export const FourthSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  align-items: flex-end;
  margin-top: 10px;

  div {
    width: 48%;
  }
  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 160px; */
      margin-top: 10px;
      div {
        width: 100%;
      }
    }
`

export const FifthSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  align-items: flex-end;
  margin-top: 10px;
  
  div:nth-child(1) {
    width: 74%
  }

  div:nth-child(2) {
    width: 22%
  }
  
  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 160px; */
      margin-top: 10px;
      div:nth-child(1) {
        width: 100%
      }

    div:nth-child(2) {
      width: 100%
    }
  }
`
export const SixthSection = styled.div`
display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */

  margin-top: 10px;
  div {
    width: 22%;
  }
  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 320px; */
      margin-top: 10px;
      div {
        width: 100%;
      }
    }
`
export const SeventhSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  margin-top: 10px;

  div {
    width: 22%;
  }
  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 320px; */
      margin-top: 10px;
      div {
        width: 100%;
      }
    }
`
export const EighthSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 48%;
  margin-top: 10px;

  div {
    width: 46%;
  }
  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 160px; */
      margin-top: 10px;
      width: 100%;
      div {
        width: 100%;
      }
    }
`
export const NinthSection = styled.div`
display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  margin-top: 10px;

  div {
    width: 22%;
  }
  @media (max-width: 760px) {
      flex-direction: column;
      height: 320px;
      margin-top: 10px;
      div {
        width: 100%;
      }
    }
`
export const TenthSection = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  align-items: flex-end;
  margin-top: 10px;
  
  div {
    width: 22%;
    /* border: 1px solid red; */
  }

  div:nth-child(3) {
    width: 48%
  }
  
  @media (max-width: 760px) {
      flex-direction: column;
      height: 240px;
      margin-top: 10px;
      div {
        width: 100%
      }

      div:nth-child(3) {
      width: 100%
    }
  }
`

export const EleventhSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media (max-width: 760px) {
      flex-direction: column;
      /* height: 160px; */
      button {
        width: 100%;
        margin: 10px 0;
      }
  }
`
