import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import * as Yup from 'yup';
import { Formik, Field, Form, useFormik } from 'formik';

import { useHistory, useParams } from 'react-router-dom';

import ProductDetails from '../../../../../assets/icons/ProductDetails.svg'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from '../../../../../services/api';

import {
  Container, ContainerColumn,
  ContainerBackground, ContainerForms,
  ContainerHeader, ContainerInformations,
  HeaderInformations, LineVertical,
  LineHorizontal, DivInput,
  Label, SubmitButton,
  DarkContainer, NextStepContainer,
  NavTab, Half2Div,
  DeleteButton, BackButton

} from './styles';

import getValidationErros from '../../../../../utils/getValidationErrors';

import SideBar from '../../../../../components/SideBar';
import Header from '../../../../../components/Header';
import SuccessPopUp from '../../../../../components/SuccessPopUp';

import ToastContainer from '../../../../../components/ToastContainer';


function index() {
  let { job_id } = useParams();
  let history = useHistory();

  const tasksArray = ['Customer Contacted', 'Inspected', 'Job Started',
    'Pack Out Started', 'Pack Out Completed', 'Cleaning Started',
    'Cleaning Finished', 'Job in Storage', 'Job Packed Back']
  const delayTime = [0, 1, 2, 3, 5, 7, 10, 15, 30, 45, 60, 90]
  const [errors, setErrors] = useState({});
  const [jobs, setJobs] = useState([])
  const [storages, setStorages] = useState([])
  const [employees, setEmployees] = useState([])
  const [templates, setTemplates] = useState([])
  const [workflowEdit, setWorkflowEdit] = useState([])

  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ip, setIp] = useState();

  const formik = useFormik({
    initialValues: {
      job_id: localStorage.getItem('jobId'),
      template_id: '',
      responsible: '',
      delay: '',
      task: '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  async function handleSubmit(values) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        // job_id: Yup.string().required('Job Number is required'),
        template_id: Yup.string().required('Template ID is required'),
        responsible: Yup.string().required('Responsible is required'),
        delay: Yup.string().required('Delay is required'),
        task: Yup.string().required('Task is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });
      createWorkflow(values);
    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }

  async function createWorkflow(values) {
    setLoading(true);
    await api
      .post('/workflow-settings', {
        job_id: values.job_id,
        template_id: values.template_id,
        responsible: values.responsible,
        delay: values.delay,
        task: values.task,
        ip
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(response => {
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer/#job-setings/`;
        //window.location.href = "http://localhost:3000/jobs/viewer/#job-setings/";
        setLoading(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function deleteWorkflow() {
    setLoadingDelete(true);
    await api
      .delete(`/workflow-settings/delete/${workflowEdit.id}/${ip}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(response => {
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer/#job-setings/`;
        // window.location.href = "http://localhost:3000/jobs/viewer/#job-setings/";
        setLoading(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getJobs() {
    api
      .get('/job', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {

        setJobs(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getWorkflowById() {
    await api
      .get(`/workflow-settings/getById/${job_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(response => {
        setWorkflowEdit(response.data[0])
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function getTemplates() {
    api
      .get('/template', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setTemplates(res.data)
      })
      .catch(err => console.error(err))
  }


  async function getEmployee() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    
    setEmployees(response.data);
  }

  function handleToast() {
    setShowToast({ show: false });
  }


  const workflowInformation = () => {
    return (
      <Formik>
        <Form onSubmit={formik.handleSubmit}>
          {/* <button type="button" onClick={() => teste()}>ok</button> */}
          <ContainerInformations>
            <HeaderInformations>
              <img height={15} src={ProductDetails} style={{ marginRight: '10px' }}></img>
              <text>Workflow Registration</text>
            </HeaderInformations>
            <LineHorizontal />
            <Half2Div >
              <DivInput >
                <Label style={errors.task ? { color: 'red' } : null}>
                  Control Points (When this happen)
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="task"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.task}
                  style={
                    errors.task
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {tasksArray.map(task => {
                    return (
                      <option key={task} value={task}>
                        {task}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
              {/* <DivInput >
                <Label style={errors.job_id ? { color: 'red' } : null}>
                  Job Number
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="job_id"
                  placeholder="Job Number"
                  disabled={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.job_id}
                  style={
                    errors.job_id
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {jobs.map(job => {
                    return (
                      <option key={job.job_number} value={job.job_id}>
                        {job.job_number}
                      </option>
                    )
                  })}
                </select>
              </DivInput> */}
            </Half2Div>
            <Half2Div>
              <DivInput >
                <Label style={errors.template_id ? { color: 'red' } : null}>
                  Template ID
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="template_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.template_id}
                  style={
                    errors.template_id
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {templates.map(template => {
                    return (
                      <option key={template.id} value={template.id}>
                        {template.template_name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.responsible ? { color: 'red' } : null}>
                  Responsible
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="responsible"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.responsible}
                  style={
                    errors.responsible
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {employees.map(employee => {
                    return (
                      <option key={employee.employee_id} value={employee.employee_id}>
                        {employee.first_name} {employee.last_name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
            </Half2Div>
            <Half2Div>
              <DivInput >
                <Label
                  style={errors.delay ? { color: 'red' } : null}
                >
                  Delay Time (in days)
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="delay"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.delay}
                  style={
                    errors.delay
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {delayTime.map(item => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
              {/* <DivInput >

                <Field
                  name="delay"
                  type="number"
                  min='0'
                  onChange={e => formik.setFieldValue('delay', e.target.value.toString())}
                  onBlur={formik.handleBlur}
                  value={formik.values.delay}
                  style={
                    errors.delay ? { border: '1px solid red' } :
                      { width: '100%' }
                  }
                />
              </DivInput> */}
            </Half2Div>
          </ContainerInformations>
          <LineHorizontal style={{ marginBottom: '30px' }} />
          <NextStepContainer >
            {job_id ?
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <DeleteButton
                  type="button"
                  onClick={() => deleteWorkflow()}
                  style={{ alignSelf: 'flex-start' }}>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </DeleteButton>
                <SubmitButton type="submit">
                  {loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Edit'
                  )}
                </SubmitButton>
              </div>
              :
              <SubmitButton type="submit">
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  'Save'
                )}
              </SubmitButton>
            }
          </NextStepContainer>
        </Form>
      </Formik >
    )
  }


  useEffect(() => {
    getJobs()
    getEmployee()
    getIP()
    getTemplates()
    if (job_id) {
      getWorkflowById()
    }
  }, []);

  useEffect(() => {
    if (job_id) {
      formik.setFieldValue('job_id', workflowEdit.job_id)
      formik.setFieldValue('template_id', workflowEdit.template_id)
      formik.setFieldValue('responsible', workflowEdit.responsible)
      formik.setFieldValue('delay', workflowEdit.delay)
      formik.setFieldValue('task', workflowEdit.task)
    }
  }, [workflowEdit]);


  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Workflow Registration</text>
              </div>
              <BackButton type="button" onClick={() => {
                history.goBack()
              }}>
                <ArrowBackIcon />
                Back to Jobs
              </BackButton>
            </ContainerHeader>
            <NavTab>
              <li
                style={
                  { backgroundColor: '#618095' }
                }
              >
                <a>1. Workflow Information</a>
              </li>
            </NavTab>
            {workflowInformation()}
          </ ContainerForms>
        </ ContainerBackground>
      </ ContainerColumn>
    </Container>
  );
}

export default index;