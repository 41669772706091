import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'rgba(78, 96, 108, 0.55)',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    minWidth: '300px',


  },
}));

export default function TransitionsModal({ openModal, closeModal, content, widthProp }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  const handleClose = () => {
    closeModal(false)
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div style={{ width: widthProp ? widthProp : '90%' }} className={classes.paper}>
            {content}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
