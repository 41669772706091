import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Formik, Field, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import MUIDataTable from "mui-datatables";

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ClearIcon from '@material-ui/icons/Clear';

import getValidationErros from '../../../../utils/getValidationErrors';

import EditInventory from '../../../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../../../assets/icons/DeleteInventory.svg'


import ToastContainer from '../../../../components/ToastContainer';
import TransitionsModal from '../../../../components/TransitionsModal'
import SearchableSelect from '../../../../components/SelectSearchable'

import api from '../../../../services/api';

import {
  HeaderInformations,
  LineHorizontal,
  ContainerForms,
  MoreHorizon,
  PopoverDiv,
  AddButton,
  EmployeeNameDiv,
  DeleteButton,
  DivInput,
  Label,
  StoragesDiv
} from './styles';

const index = ({ newNote, closeModal }) => {
  const jobId = localStorage.getItem('jobId')
  const [errors, setErrors] = useState({})
  const [ip, setIp] = useState('0.0.0.0')
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(newNote || false)
  const [noteId, setNoteId] = useState(false)
  const [noteInfo, setNoteInfo] = useState({})
  const [editNote, setEditNote] = useState(false)
  const [employees, setEmployees] = useState([])
  const [employeesOptions, setEmployeesOptions] = useState([])
  const [employeeID, setEmployeeID] = useState([])

  const [notes, setNotes] = useState([])

  const compare = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  function compareDate(a, b) {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  }

  async function getNotes() {
    api
      .get(`/general-note/jobnumber/${localStorage.getItem('@Propack:job_number')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        const dataArray = res.data.sort(compareDate);
        setNotes(dataArray.reverse())
      })
      .catch(err => console.error(err))
  }

  async function getEmployees() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEmployees(response.data);

    const arrayOptions = []
    response.data.map(employee => {
      arrayOptions.push({
        id: employee.employee_id,
        name: `${employee.first_name} ${employee.last_name}`
      })
    })
    
    arrayOptions.sort(compare);

    setEmployeesOptions(arrayOptions);
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }


  const formik = useFormik({
    initialValues: {
      id: '',
      job_number: localStorage.getItem('@Propack:job_number'),
      created_by: JSON.parse(localStorage.getItem('@Propack:employee')).id,
      customer_name: localStorage.getItem('@Propack:customerName'),
      note: '',
      date: '',
      sending_to: [],
    },
    onSubmit: values => {
      let employeesEmails = employeeID.map(employee => employee.email)
      values.date = new Date().toLocaleString('en-US').split(',')[0] + "," + new Date().toLocaleString('en-US').split(',')[1].substr(0, 6) + " " + new Date().toLocaleString('en-US').split(',')[1].substr(9)
      values.emails = employeesEmails
      handleSubmit(values);
    },
  });

  async function handleSubmit(values) {
    try {
      setErrors({});
      const schema = Yup.object().shape({
        note: Yup.string().required('Note is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });
      if (editNote)
        updateNote(values)
      else
        createNote(values);
    } catch (error) {
      setErrors(getValidationErros(error));
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }

  async function deleteNote() {
    setLoadingDelete(true)
    api
      .delete(`/general-note/delete/${noteId}/${ip}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(response => {
        setLoadingDelete(false)
        let newNotes = notes.filter(note => note.id !== noteId)
        setNotes(newNotes)
        setOpenModal(false)
        setEditNote(false)
        handleClose()
        formik.setFieldValue('note', '')
        setShowToast({
          show: true,
          type: 'success',
          title: 'Success',
          description: 'Note deleted successfully.',
        });
      })
      .catch(err => {
        setLoading(false);
        setOpenModal(false)
        formik.setFieldValue('note', '')
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
      });

  }

  async function updateNote(values) {
    setLoading(true)
    api
      .patch('/general-note/update', {
        note: values.note,
        id: values.id,
        ip
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(response => {
        setLoading(false)
        let newNotes = notes.map(singleNote => {
          if (singleNote.id == values.id) {
            return {
              ...singleNote,
              note: values.note
            }
          } else
            return singleNote
        })
        setNotes(newNotes)
        setOpenModal(false)
        setEditNote(false)
        formik.setFieldValue('note', '')
        setShowToast({
          show: true,
          type: 'success',
          title: 'Success',
          description: 'Note updated successfully.',
        });
      })
      .catch(err => {
        setLoading(false);
        setOpenModal(false)
        formik.setFieldValue('note', '')
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
      });

  }


  async function createNote(values) {
    setLoading(true)
    api
      .post('/general-note', {
        job_number: values.job_number,
        created_by: values.created_by,
        customer_name: values.customer_name,
        note: values.note,
        date: values.date,
        emails: values.emails,
        ip
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(response => {
        setLoading(false)
        setNotes(prev => [response.data, ...prev])
        setShowToast({
          show: true,
          type: 'success',
          title: 'Success',
          description: 'Note created successfully.',
        });
        handleCloseModal("success", "Success", "Note created succesfully")
      })
      .catch(err => {
        setLoading(false);
        setOpenModal(false)
        formik.setFieldValue('note', '')
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        handleCloseModal("error", "Error", err.response.data.message)
      });

  }

  const openPop = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    handleClick(event)
    setNoteId(value[0])
    setNoteInfo(value);
  }


  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        display: false
      },
    },
    {
      name: "created_by",
      label: "employee_id",
      options: {
        filter: false,
        sort: false,
        display: false
      },
    },
    {
      name: "employee_name",
      label: "Sent by",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <EmployeeNameDiv
              onClick={() => handleOpenModal(tableMeta.rowData)}
            > {value} </EmployeeNameDiv>
          )
        }
      },
    },
    {
      name: "sending_to",
      label: "Sending to",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let sendingArray = []
          if(value){
            value.forEach(sending => {
              const to = employees.find(employee => employee.employee_id === sending);
              if(to){
                sendingArray.push(`${to.first_name} ${to.last_name}`);
              }
            })
          }

          const display = sendingArray.map(
            sendingTo => {
              return (
                <EmployeeNameDiv
                  onClick={() => handleOpenModal(tableMeta.rowData)}
                > {`${sendingTo}, `} </EmployeeNameDiv>
              )
            }
          );

          return display;
        }
      },
    },
    {
      name: "note",
      label: "Note",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: "200px", textOverflow: 'ellipsis',
              height: '50px',
              overflow: 'hidden', fontSize: '13px',
              display: 'block', whiteSpace: 'pre'
            }}>
              {value}
            </div>
          )
        }
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (index, nextIndex) => {
            var parseDateA = new Date(index.data);
            var parseDateB = new Date(nextIndex.data);
            
            if (order === 'asc'){
              if (parseDateA < parseDateB) {
                return -1;
              } else if (parseDateA > parseDateB) {
                return 1;
              } else {
                return 0;
              }
            } else {
              if (parseDateA < parseDateB) {
                return 1;
              } else if (parseDateA > parseDateB) {
                return -1;
              } else {
                return 0;
              }
            }
          }
        }
      },
    },
    {
      name: 'id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, tableMeta.rowData)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => handleOpenModal(noteInfo)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                  Edit
                </PopoverDiv> 
                <PopoverDiv onClick={() => deleteNote()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </PopoverDiv>
              </Popover>
            </div>
          )
        }
      },
    },
  ];

  const options = {
    filter: "false",
    print: "false",
    search: "true",
    download: "false",
    viewColumns: "false",
    selectableRows: "none",
  };

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  const handleOpenModal = (note) => {
    formik.setFieldValue('job_number', localStorage.getItem('@Propack:job_number'))
    formik.setFieldValue('id', note[0])
    formik.setFieldValue('sending_to', note[3])
    formik.setFieldValue('created_by', note[2])
    formik.setFieldValue('note', note[4])
    formik.setFieldValue('date', note[5])
    setNoteId(note[0])
    if (note[1] === JSON.parse(localStorage.getItem('@Propack:employee')).id)
      setEditNote(true)
    setOpenModal(true)
  }

  const handleEmployeeChange = (employee_id) => {
    let exist = employeeID.find(employee => employee.employee_id === employee_id)
    if (!exist) {
      let employee = employees.find(employee => employee.employee_id === employee_id)
      setEmployeeID(prev => ([...prev, {
        name: `${employee.first_name} ${employee.last_name}`,
        employee_id: employee.employee_id,
        email: employee.email
      }]))
    }
    else
      return
  }

  const handleRemoveEmployee = (employee_id) => {
    let employee = employeeID.filter(employee => employee.employee_id !== employee_id)
    setEmployeeID(employee)
  }

  useEffect(() => {
    getIP()
    getNotes()
    getEmployees()
  }, [])
  
  const handleCloseModal = (type, title, description) => {
    setEditNote(false)
    formik.setFieldValue('job_number', localStorage.getItem('@Propack:job_number'))
    formik.setFieldValue('created_by', JSON.parse(localStorage.getItem('@Propack:employee')).id)
    formik.setFieldValue('note', '')
    formik.setFieldValue('date', '')
    setEmployeeID([])
    setOpenModal(false)
    if (closeModal) {
      closeModal(type, title, description)
    }
  }

  const addNoteModal = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        minHeight: '500px', fontSize: '20px',
      }}>
        <div style={{
          display: 'flex', alignItems: 'flex-end',
          width: '100%', height: '40px', fontFamily: 'roboto', justifyContent: 'center'
        }}>
          Note
        </div>
        <LineHorizontal />
        <div style={{
          width: '100%', height: '100%',
          marginTop: '15px', textAlign: 'center'
        }}>
          <Formik>
            <Form 
              onSubmit={formik.handleSubmit}
              style={{
                height: '100%'
              }}
            >
              <TextareaAutosize
                style={
                  errors.note
                    ? {
                      border: '1px solid red',
                      backgroundColor: '#ececec',
                      color: '#495057', padding: '10px', width: '80%',
                      maxHeight: '200px', fontSize: '15px', overflow: 'auto',
                    } :
                    {
                      backgroundColor: '#ececec', border: 'none',
                      color: '#495057', padding: '10px', width: '80%',
                      maxHeight: '200px', fontSize: '15px', overflow: 'auto',
                    }

                }
                rows={5}
                aria-label="maximum height"
                placeholder="Enter the note..."
                name="note"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={formik.values.note}
              />
              <DivInput >
                <Label >
                  Notify
                </Label>
                {editNote ? '' : <SearchableSelect
                  options={employeesOptions}
                  label="Notify"
                  id="Notify"
                  selectedVal={" "}
                  handleChange={(val) => handleEmployeeChange(val)} 
                />}
              </DivInput>
              <DivInput style={{
                border: '1px solid #ececec', flexDirection: 'row',
                flexWrap: 'wrap', minHeight: 42, maxHeight: 100,
                alignItems: 'center', overflowY: 'auto',
                fontSize: '0.8125rem', borderRadius: 10
              }}>
                <Label style={{ width: '100%', marginLeft: 5 }}>
                  {editNote ? 'Sending to' : 'Selected Employees:'}
                </Label>
                { editNote && formik.values.sending_to ? 
                  employees.map(employee => {
                    if(formik.values.sending_to.includes(employee.employee_id)){
                      return (
                        <StoragesDiv style={{width: 'auto'}} key={employee.employee_id}>
                         {`${employee.first_name} ${employee.last_name}`}
                      </StoragesDiv>
                      )
                    }
                  })
                  :
                  employeeID.map(employee => {
                    return (
                      <StoragesDiv key={employee.employee_id}>
                        {employee.name}
                        <div style={{
                          width: 24, height: 24, backgroundColor: '#E0E0E0',
                          borderRadius: 24, marginLeft: '5px'
                        }}>
                          <ClearIcon
                            style={{ width: 20 }}
                            onClick={() => handleRemoveEmployee(employee.employee_id)} />
                        </div>
                      </StoragesDiv>
                    )
                  })
                }
              </DivInput>
              {editNote ? (
                <div style={{
                  display: 'flex', flexDirection: 'column',
                  margin: '10px 0', alignItems: 'center'
                }}>
                  <AddButton
                    // onClick={() => setOpenModal(true)}
                    type="submit">
                    {loading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      'Edit Note'
                    )}
                  </AddButton>
                  <DeleteButton type="button"
                    onClick={() => deleteNote()}>
                    {loadingDelete ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      'Delete'
                    )}
                  </DeleteButton>
                </div>
                ) :
                (<div style={{
                  margin: '10px 0',
                  display: formik.values.created_by == JSON.parse(localStorage.getItem('@Propack:employee')).id ? 'flow-root' : 'none'
                }}>
                  <AddButton
                    // onClick={() => setOpenModal(true)}
                    type="submit">
                    {loading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      'Add Note'
                    )}
                  </AddButton>
                </div>)
              }

            </Form>
          </Formik>
        </div>
      </div>
    )
  }

  return (
    <>
      {openModal && <TransitionsModal openModal={true} closeModal={() => handleCloseModal()}
        content={addNoteModal()} widthProp='60%' />}
      {showToast.show && (
        <ToastContainer messages={showToast} show={() => handleToast()} />
      )}
      {!newNote &&
        <>
          <ContainerForms>
            <HeaderInformations >
              <div style={{
                display: 'flex', alignItems: 'inherit',
                fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
                fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
              }}>
                {/* <ListIcon style={{ color: "#6096BA", fontSize: '2rem', marginRight: 10 }} /> */}
                Notes
              </div>

              <div>
                <button
                  onClick={() => setOpenModal(true)}
                  type="button">New Note</button>
              </div>
            </HeaderInformations>
          </ContainerForms>
          <MUIDataTable
            data={notes}
            columns={columns}
            options={options}
          />
          <LineHorizontal style={{ marginBottom: '30px' }} />
        </>
      }
    </>
  );
}

export default index;
