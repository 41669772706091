import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';

import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

import MUIDataTable from "mui-datatables";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import TransitionsModal from '../../components/TransitionsModal'

import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';

import PrintIcon from '@material-ui/icons/Print';
import ListIcon from '@material-ui/icons/List';
import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'

import SideBar from '../../components/SideBar';
import searchByIcon from '../../assets/icons/searchBy.png';
import Header from '../../components/Header';

import ComponentToPrint from '../../utils/ComponentToPrint'

import {
  HeaderInformations,
  ContainerForms,
  MoreHorizon,
  PopoverDiv,
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerHeader,
  LineHorizontal,
  LineVertical,
  InventoryNameDiv,
  StorageQrCodeDiv,
  Button,
  PrintDiv
} from './styles'

const index = () => {
  let history = useHistory();
  const componentRef = useRef();

  const [inventories, setInventories] = useState([])
  const [inventoryId, setInventoryId] = useState([])
  const [employees, setEmployees] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [storageQrCodeInfo, setStorageQrCodeInfo] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  const openPop = Boolean(anchorEl);


  const columns = [
    {
      name: "inventory_id",
      label: "Inventory ID",
      options: {
        filter: false,
        viewColumns: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <InventoryNameDiv
              onClick={() =>
                history.push({
                  pathname: `/inventory/inventoryedit/${tableMeta.rowData[0]}`,
                })
              }>
              {tableMeta.rowData[0]}
            </InventoryNameDiv >
          )
        }
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: true,
        viewColumns: false,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <InventoryNameDiv
              onClick={() =>
                history.push({
                  pathname: `/inventory/inventoryedit/${tableMeta.rowData[0]}`,
                })
              }>
              {value}
            </InventoryNameDiv >
          )
        }
      },
    },
    {
      name: "customer_phone",
      label: "Customer Phone",
      searchable: true,
      options: {
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "storage_id",
      label: "Storage",
      searchable: true,
      options: {
        filter: false,
        viewColumns: false,
        display: false,
      },
    },
    {
      name: "job_number",
      label: "Job Number",
      options: {
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "inventory_responsible",
      label: "Inventory Responsible",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            setEmployeeName(value)
          )
        }
      },
    },
    {
      name: "inventory_manager",
      label: "Inventory Manager",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            setEmployeeName(value)
          )
        }
      },
    },
    {
      name: "storage_id",
      label: "QR Code",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <StorageQrCodeDiv
              onClick={() =>
                HandleViewQrCode(tableMeta.rowData[3])
              }>
              <div style={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', height: 35, color: '#6096BA',
                border: '1px solid #6096BA', borderRadius: 5, width: 100
              }}>View QR Code</div>
            </StorageQrCodeDiv >
          )
        }
      },
    },
    {
      name: "storage_id",
      label: "Storage",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'inventory_id',
      label: "Options",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >

                <PopoverDiv onClick={() => goEdit(inventoryId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                  Edit
                </PopoverDiv>
                <PopoverDiv onClick={() => deleteInventory()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </PopoverDiv>
              </Popover>
            </div>
          )
        }
      },
    },
  ];

  const options = {
    filter: "true",
    print: "false",
    search: "true",
    download: "false",
    filterType: 'checkbox',
    viewColumns: "true",
    selectableRows: "none",
    // onRowsDelete: (rowsDeleted) => {
    //   rowsDeleted.data.map(d => {
    //     // activityId(prevState => [...prevState, customers[d.dataIndex].costumer_id])
    //     setOpenPopUp({ show: true })
    //   })
    // }
  };

  const HandleViewQrCode = (qrCodeInfo) => {
    let parsedQrCode = JSON.parse(qrCodeInfo)
    let qrCodeValue = parsedQrCode.map(qrCode => {
      return qrCode.storage_id
    })
    let qrCodeName = parsedQrCode.map(qrCode => {
      return qrCode.name
    })
    setStorageQrCodeInfo({ qrCode: qrCodeValue.toString(), name: qrCodeName.toString() })
    setOpenModal(true)

  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    handleClick(event)
    setInventoryId(value)

  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goEdit = () => {
    history.push({
      pathname: `/inventory/inventoryedit/${inventoryId}`,
    });
  }

  const setEmployeeName = (employee_id) => {
    const employee = employees.find(employee => employee.employee_id === employee_id)
    if (employee)
      return `${employee.first_name} ${employee.last_name}`
  }

  async function getInventories() {
    api
      .get('/inventory', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        let data = res.data.map(data => {
          return {
            ...data,
            storage_id: JSON.parse(data.storage_id)
          }
        })
        let dataWithStorageId = data.map(data => {
          return {
            ...data,
            storage_id: data.storage_id.map(storage => 
              `${storage.name} -> ID: ${storage.storage_id}`).join()
              // `${storage.storage_id}`)
          }
        })
        setInventories(dataWithStorageId)
      })
      .catch(err => console.error(err))
  }

  async function getEmployee() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEmployees(response.data);
  }

  async function deleteInventory() {
    setLoadingDelete(true);
    api
      .delete(
        `/inventory/delete/${inventoryId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/inventory`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Inventory successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting inventory',
        });
      });
  }

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${storageQrCodeInfo.name}-qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const content = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        height: '300px', fontSize: '20px'
      }}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          width: '90%', height: '40px', fontFamily: 'roboto', marginTop: '20px'
        }}>
          QR Code: {storageQrCodeInfo.name}
        </div>
        <LineHorizontal />
        <div style={{
          width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center'
        }}>
          <QRCode id="qrcode" value={storageQrCodeInfo.qrCode} />
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button style={{ marginTop: 10 }} onClick={downloadQRCode}> Download QR Code</Button>
          </div>
          <PrintDiv>
            <ReactToPrint
              trigger={() => <PrintIcon fontSize={'40px'} style={{
                color: '#82ce5e',
                fontSize: '30px', marginLeft: 10
              }} />}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
              <ComponentToPrint content={<QRCode id="qrcode" value={storageQrCodeInfo.qrCode} />} ref={componentRef} />
            </div>
          </PrintDiv>
        </div>
      </div >
    )
  }

  useEffect(() => {
    getInventories()
    getEmployee()
  }, []);

  return (
    <>
      <Container >
        <SideBar />
        <ContainerColumn  >
          <Header />
          <ContainerBackground >
            <ContainerForms>
              <ContainerHeader >
                <div>
                  <LineVertical />
                  <text>Inventory Information</text>
                </div>
              </ContainerHeader>
              {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} content={content()} />}
              <HeaderInformations >
                <div style={{
                  display: 'flex', alignItems: 'inherit',
                  fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
                  fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
                }}>
                  <ListIcon style={{ color: "#6096BA", fontSize: '2rem', marginRight: 10 }} />
                  Inventory List
                </div>
                <Link to="/inventoryregistration">
                  <button type="button">New Inventory</button>
                </Link>
              </HeaderInformations>
              <MUIDataTable
                data={inventories}
                columns={columns}
                options={options}

              />
            </ContainerForms>
          </ContainerBackground>
        </ContainerColumn>
      </Container>
    </>
  );
}

export default index;
