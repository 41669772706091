import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { JobContext } from '../../../../contexts/Job'

import Axios from 'axios';
import api from '../../../../services/api';

import MUIDataTable from "mui-datatables";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import TransitionsModal from '../../../../components/TransitionsModal'

import ListIcon from '@material-ui/icons/List';
import EditInventory from '../../../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../../../assets/icons/DeleteInventory.svg'

import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';

import {
  HeaderInformations,
  ContainerForms,
  MoreHorizon,
  PopoverDiv,
  JobIdDiv
} from './styles'

const index = () => {
  let history = useHistory();
  const [jobSettings, setJobSettings] = useState()
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [workflows, setWorkflows] = useState([])
  const [jobId, setJobId] = useState([])
  const [employees, setEmployees] = useState([])
  const [workflowToDelete, setWorkflowToDelete] = useState(null)
  const [showToast, setShowToast] = useState({ show: false });
  const [ĺoading, setLoading] = useState(false)

  const [ip, setIp] = useState();

  const openPop = Boolean(anchorEl);

  async function getWorkflow() {
    await api
      .get(`/workflow-settings/${localStorage.getItem('jobId')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(response => {
        setWorkflows(response.data)
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function getEmployee() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    
    setEmployees(response.data);
  }

  const columns = [
    {
      name: "task",
      label: "Task",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <JobIdDiv onClick={() => 
            goEdit(tableMeta.rowData[4])
            }>
              <span>{value}</span>
            </JobIdDiv>
          )
        }
      },
    },
    {
      name: "responsible_name",
      label: "Responsible",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div onClick={() => console.log(tableMeta.rowData)}>
              <span>{value}</span>
            </div>
          )
        }
      },
    },
    {
      name: "template_name",
      label: "Template",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "delay",
      label: "Delay",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div onClick={() => console.log(tableMeta.rowData)}>
              <span>{`${value} ${value === '1' ? 'day' : 'days'}`}</span>
            </div>
          )
        }
      },
    },
    {
      name: 'id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value, tableMeta.rowData[4])}
              >
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => goEdit(jobId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                    Edit
                  </PopoverDiv>
                <PopoverDiv onClick={() => deleteWorkflow()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </PopoverDiv>
              </Popover>
            </div>
          )
        }
      },
    },
  ];



  const options = {
    filter: "false",
    print: "false",
    search: "false",
    download: "false",
    viewColumns: "false",
    selectableRows: "none",
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(d => {
        // activityId(prevState => [...prevState, customers[d.dataIndex].costumer_id])
        setOpenPopUp({ show: true })
      })
    }
  };

  const setCustomerName = (id) => {
    let customer = employees.find(employee => employee.employee_id === id)
    if (customer)
      return `${customer.first_name} ${customer.last_name}`
  }


  const goEdit = (job_id) => {
    history.push({
      pathname: `/workflowedit/${job_id}`,
    });
  }

  async function deleteWorkflow() {
    setLoadingDelete(true);
    await api
      .delete(`/workflow-settings/delete/${workflowToDelete}/${ip}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(response => {
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer`;
        // window.location.href = "http://localhost:3000/jobs/viewer/#job-setings/";
        setLoadingDelete(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoadingDelete(false);
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value, workflowId) => {
    handleClick(event)
    setJobId(value)
    setWorkflowToDelete(workflowId)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    getWorkflow()
    getEmployee()
    getIP()
  }, []);



  return (
    <>

      <ContainerForms>
        <HeaderInformations >
          <div style={{
            display: 'flex', alignItems: 'inherit',
            fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
            fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
          }}>
            <ListIcon style={{ color: "#6096BA", fontSize: '2rem', marginRight: 10 }} />
              Workflow
          </div>

          <Link to="/workflowsettings">
            <button type="button">New Workflow</button>
          </Link>
        </HeaderInformations>
      </ContainerForms>
      <MUIDataTable
        data={workflows}
        columns={columns}
        options={options}

      />
    </>
  );
}

export default index;
