import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Field, Form, useFormik } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import * as Yup from 'yup';
import Axios from 'axios';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Select from 'react-select';

import api from '../../services/api';

import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  ContainerInformations,
  HeaderInformations,
  LineVertical,
  LineHorizontal,
  DivForms,
  DivInput,
  Label,
  SubmitButton,
  DarkContainer,
  DeleteButton,
  DivInputText,
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  FifthSection,
  SixthSection,
  SeventhSection
} from './styles';

import getValidationErros from '../../utils/getValidationErrors';
import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import ConfirmModal from '../../components/ConfirmModal';
import ToastContainer from '../../components/ToastContainer';

import customerIcon from '../../assets/icons/customerIcon.png';
import deleteIcon from '../../assets/icons/deleteIcon.png';

function CustomerEdit() {
  const location = useLocation();
  const customer = location.state;

  let autoComplete;
  const autoCompleteRef = useRef(null);

  const [errors, setErrors] = useState({});
  //const [customer, setCustomer] = useState({});
  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ip, setIp] = useState('0.0.0.0');
  const [deactivate, setDeactivate] = useState(customer.canceled_at);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [customerTitles, setCustomerTitles] = useState([]);

  const [customers, setCustomers] = useState([]);

  const referredArray = ['Carrier', 'TPA (Third Party Admin)', 'Contractor']

  const [options, setOptions] = useState([]);

  function setOptionsSearch(data) {
    let arrayOptions = []
    arrayOptions.push({
      label: 'Choose a option',
      value: 'null'
    })
    data.map(item => {
      arrayOptions.push({
        label: item.first_name + ' ' + item.last_name,
        value: item.customer_id
      })
    })
    setOptions(arrayOptions);
  }

  function listCustomerByCustomerType(value) {
    const optionsArray = customers.filter(item => {
      return item.customer_type === value
    });
    setOptionsSearch(optionsArray)
  }

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(setFormik, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(setFormik)
    );
  }

  async function handlePlaceSelect(setFormik) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    let streetNumber = '';
    addressObject.address_components.map(component => {
      if (component.types[0] === 'locality') {
        setFormik('city', component.long_name)
      }
      if (component.types[0] === 'administrative_area_level_1') {
        setFormik('state', component.long_name)
      }
      if (component.types[0] === 'country') {
        setFormik('country', component.long_name)
      }
      if (component.types[0] === 'postal_code') {
        setFormik('postal_code', component.long_name)
      }
      if (component.types[0] === 'street_number') {
        streetNumber = component.long_name
      }
      if (component.types[0] === 'route') {
        setFormik('address', `${streetNumber} ${component.long_name}`)
      }
    })
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function deleteCustomer() {
    setLoadingDelete(true);
    api
      .delete(
        `/customer/${customer.customer_id}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        setOpenPopUp({ show: false })
        window.location.href = `${process.env.REACT_APP_WEB_URL}/customer/`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Customer successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        setOpenPopUp({ show: false })
        console.error(err.name);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting customer',
        });
      });
  }


  const formik = useFormik({
    initialValues: {
      referred: customer.referred,
      referred_field: customer.referred_field,
      customer_type: customer.customer_type,
      customer_title: customer.customer_title,
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      secondary_email: customer.secondary_email,
      address: customer.address,
      postal_code: customer.postal_code,
      city: customer.city,
      region: customer.region,
      country: customer.country,
      state: customer.state,
      business_phone: customer.business_phone,
      mobile_phone: customer.mobile_phone,
      fax_phone: customer.fax_phone,
      other_phone: customer.other_phone,
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  function setValueInRerredSelect() {
    const search = formik.values.referred_field;
    const value = options.find(item => item.value === search);
    if (value)
      return value.value;
  }

  async function getCustomerTitles() {
    const response = await api.get('/customer-title', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setCustomerTitles(response.data);
  }

  async function getCustomerTypes() {
    const response = await api.get('/customer-type', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setCustomerTypes(response.data);
  }

  async function handleSubmit(values) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string()
          .required('E-mail is required')
          .email('Invalid e-mail'),
        address: Yup.string().required('Address is required'),
        postal_code: Yup.string().required('Postal code is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        state: Yup.string().required('State is required'),
        business_phone: Yup.string().required('Phone is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      // if (
      //   !values.business_phone &&
      //   !values.mobile_phone &&
      //   !values.fax_phone &&
      //   !values.other_phone
      // ) {
      //   setErrors({ phone: 'phone is required' });
      //   setShowToast({
      //     show: true,
      //     type: 'error',
      //     title: 'Required fields',
      //     description: 'Phone is required',
      //   });
      //   setLoading(false);
      // } else {
      // }
      updateCustomer(values);
    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      console.error(errors);
      if (
        errors.email === 'Invalid e-mail' ||
        errors.secondary_email === 'Invalid e-mail'
      ) {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Invalid fields',
          description: 'E-mail or Secondary e-mail is invalid',
        });
      } else {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Required fields',
          description: 'There are unfilled required fields',
        });
      }
    }
  }

  async function updateCustomer(values) {
    api
      .patch(
        '/customer/update',
        {
          referred: values.referred,
          referred_field: values.referred_field,
          customer_id: customer.customer_id,
          customer_type: values.customer_type,
          customer_title: values.customer_title,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          secondary_email: values.secondary_email,
          address: values.address,
          postal_code: values.postal_code,
          city: values.city,
          region: values.region,
          country: values.country,
          state: values.state,
          business_phone: values.business_phone,
          mobile_phone: values.mobile_phone,
          fax_phone: values.fax_phone,
          other_phone: values.other_phone,
          deactivate,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoading(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/customer/`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Updated successfully',
          description: 'Customer successfully updated',
        });
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error updating',
          description: 'Error updating customer',
        });
      });
  }

  async function getPostalCodeInformation(postalCode) {
    if (postalCode.toString().length >= 5) {
      Axios
        .get(
          `https://www.zipcodeapi.com/rest/js-If0vBk90TlD6WE5F7aCDEAJbwRLRYNWbFGYqgHyiVZxpteCFW120VS9RBER5fJVd/info.json/${postalCode}/radians`
        )
        .then(res => {
          formik.setFieldValue("city", res.data.city);
          formik.setFieldValue("state", res.data.state);
          formik.setFieldValue("country", 'US');
          if (errors.postal_code === 'invalid zip code') {
            setErrors({ postal_code: false })
          }
        }).catch(err => {
          console.error(err)
          setErrors({ postal_code: 'invalid zip code' })
        })
    }
  }

  async function getCustomers() {
    api
      .get('/customer', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        const arrayOrdenate = res.data.sort(function (a, b) {
          if (a.first_name < b.first_name) {
            return -1
          }
          if (a.first_name > b.first_name) {
            return 1
          }
          return 0
        });
        setCustomers(arrayOrdenate)
      });
  }

  useEffect(() => {
    getIP();
    getCustomers();
    getCustomerTypes();
    getCustomerTitles();
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Z-ODAYUDEjetAqP-tuuH4m1w5Xx2RgU&libraries=places`,
      () => handleScriptLoad(formik.setFieldValue, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    listCustomerByCustomerType(customer.referred)
  }, [customers]);

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {
            openPopUp.show &&
            <ConfirmModal
              show={() => handlePopUp()}
              deleteUser={() => deleteCustomer()}
            />
          }
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Customer Edit</text>
              </div>
            </ContainerHeader>
            <Formik>
              <Form onSubmit={formik.handleSubmit}>
                <ContainerInformations>
                  <HeaderInformations>
                    <img src={customerIcon} alt="detailsIcon" />
                    <text>Customer Information</text>
                  </HeaderInformations>
                  <LineHorizontal />
                  <FirstSection>
                    <DivInput >
                      <Label style={errors.customer_type ? { color: 'red' } : null}>
                        Customer Type
                          <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <select
                        name="customer_type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.customer_type}
                        style={
                          errors.customer_type
                            ? { border: '1px solid red' }
                            : null
                        }
                      >
                        <option>
                          Choose an option
                          </option>
                        {
                          customerTypes.map(item => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))
                        }
                      </select>
                    </DivInput>
                    <DivInput >
                      <Label style={errors.customer_title ? { color: 'red' } : null}>
                        Title
                          <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <select
                        name="customer_title"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.customer_title}
                        style={
                          errors.customer_title
                            ? { border: '1px solid red' }
                            : null
                        }
                      >
                        <option>
                          Choose an option
                          </option>
                        {
                          customerTitles.map(item => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))
                        }
                      </select>
                    </DivInput>
                  </FirstSection>
                  {/* </DivForms> */}
                  <SecondSection>
                    <DivInput>
                      <Label
                        style={errors.first_name ? { color: 'red' } : null}
                      >
                        First name
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="first_name"
                        placeholder="Customer's first name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name}
                        style={
                          errors.first_name ? { border: '1px solid red' } : null
                        }
                      />
                    </DivInput>
                    <DivInput>
                      <Label style={errors.last_name ? { color: 'red' } : null}>
                        Last name
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="last_name"
                        placeholder="Customer's last name"
                        onChange={formik.handleChange}
                        value={formik.values.last_name}
                        style={
                          errors.last_name ? { border: '1px solid red' } : null
                        }
                      />
                    </DivInput>
                    <DivInput>
                      <Label style={errors.email ? { color: 'red' } : null}>
                        Email
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="email"
                        placeholder="Customer's email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        style={
                          errors.email ? { border: '1px solid red' } : null
                        }
                      />
                    </DivInput>
                    <DivInput>
                      <Label>
                        Sencodary E-mail
                      </Label>
                      <Field
                        name="secondary_email"
                        placeholder="Customer's sencodary e-mail"
                        onChange={formik.handleChange}
                        value={formik.values.secondary_email}
                      />
                    </DivInput>
                  </SecondSection>
                  <ThirdSection>
                    <DivInput >
                      <Label style={errors.address ? { color: 'red' } : null}>
                        Address
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <input
                        name="address"
                        ref={autoCompleteRef}
                        placeholder="Customer's address"
                        style={{
                          border: `${errors.address ? '1px solid red' : 'none'
                            }`,
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.address}
                      />
                    </DivInput>
                    <DivInput >
                      <Label
                        style={errors.postal_code ? { color: 'red' } : null}
                      >
                        ZIP/Postal Code
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="postal_code"
                        placeholder="e.g 'xxxxx-xxx'"
                        onChange={(event) => {
                          formik.setFieldValue("postal_code", event.currentTarget.value);
                          // getPostalCodeInformation(event.currentTarget.value)
                        }}
                        value={formik.values.postal_code}
                        style={
                          errors.postal_code
                            ? { border: '1px solid red' }
                            : null
                        }
                      />
                    </DivInput>
                  </ThirdSection>
                  <FourthSection>
                    <DivInput>
                      <Label style={errors.city ? { color: 'red' } : null}>
                        City
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="city"
                        placeholder="Customer's city"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                        style={errors.city ? { border: '1px solid red' } : null}
                      />
                    </DivInput>
                    <DivInput>
                      <Label >
                        Region
                      </Label>
                      <Field
                        name="region"
                        placeholder="Customer's region"
                        onChange={formik.handleChange}
                        value={formik.values.region}
                      />
                    </DivInput>
                    <DivInput>
                      <Label style={errors.Country ? { color: 'red' } : null}>
                        Country
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="country"
                        placeholder="Customer's country"
                        onChange={formik.handleChange}
                        value={formik.values.country}
                        style={
                          errors.country ? { border: '1px solid red' } : null
                        }
                      />
                    </DivInput>
                    <DivInput>
                      <Label style={errors.state ? { color: 'red' } : null}>
                        State/Province
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="state"
                        placeholder="Customer's state/province"
                        onChange={formik.handleChange}
                        value={formik.values.state}
                        style={
                          errors.state ? { border: '1px solid red' } : null
                        }
                      />
                    </DivInput>
                  </FourthSection>
                  <FifthSection>
                    <DivInput>
                      <Label style={errors.phone ? { color: 'red' } : null}>
                        Phone
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="business_phone"
                        placeholder="Business phone number"
                        onChange={formik.handleChange}
                        value={formik.values.business_phone}
                        style={{
                          border: `${errors.business_phone ? '1px solid red' : 'none'}`,
                        }}
                      />
                    </DivInput>
                    <DivInput>
                      <Field
                        name="mobile_phone"
                        placeholder="Mobile phone number"
                        onChange={formik.handleChange}
                        value={formik.values.mobile_phone}
                        style={{
                          marginTop: '30px',
                          // border: `${errors.phone ? '1px solid red' : 'none'}`,
                        }}
                      />
                    </DivInput>
                    <DivInput>
                      <Field
                        name="fax_phone"
                        placeholder="FAX phone number"
                        onChange={formik.handleChange}
                        value={formik.values.fax_phone}
                        style={{
                          marginTop: '30px',
                          // border: `${errors.phone ? '1px solid red' : 'none'}`,
                        }}
                      />
                    </DivInput>
                    <DivInput>
                      <Field
                        name="other_phone"
                        placeholder="Other phone number"
                        onChange={formik.handleChange}
                        value={formik.values.other_phone}
                        style={{
                          marginTop: '30px',
                          // border: `${errors.phone ? '1px solid red' : 'none'}`,
                        }}
                      />
                    </DivInput>
                  </FifthSection>
                  <SixthSection>

                    <DivInput >
                      <Label style={errors.referred ? { color: 'red' } : null}>
                        Referred
                          {/* <span style={{ color: 'red' }}> *</span> */}
                      </Label>
                      <select
                        name="referred"
                        onChange={e => {
                          formik.setFieldValue('referred', e.target.value);
                          formik.setFieldValue('referred_field', 'null');
                          listCustomerByCustomerType(e.target.value)
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.referred}
                        style={
                          errors.referred
                            ? { border: '1px solid red' }
                            : null
                        }
                      >
                        <option value="">
                          Choose an option
                          </option>
                        {
                          referredArray.map(item => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))
                        }
                      </select>
                    </DivInput>
                    <DivInput >
                      <select
                        // options={options}
                        disabled={
                          formik.values.referred === 'null' ||
                            !formik.values.referred
                            ? true
                            : false
                        }
                        placeholder='choose a option'
                        name='referred_field'
                        onChange={e => formik.setFieldValue('referred_field', e.target.value)}
                        value={setValueInRerredSelect()}
                      >
                        {
                          options.map(item => (

                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))
                        }
                      </select>
                    </DivInput>
                  </SixthSection>
                  <SeventhSection>
                    <DeleteButton
                      type="button"
                      onClick={() => setOpenPopUp({ show: true })}
                    // style={{backgroundColor: '#FF7777'}}
                    >
                      Delete Customer
                    </DeleteButton>
                    <SubmitButton type="submit">
                      {loading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : (
                        'Update Customer'
                      )}
                    </SubmitButton>
                  </SeventhSection>
                </ContainerInformations>
              </Form>
            </Formik>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default CustomerEdit;
