import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';

import * as Yup from 'yup';
import { Formik, Field, Form, useFormik } from 'formik';

import getValidationErros from '../../../../utils/getValidationErrors';

import { JobContext } from '../../../../contexts/Job'
import { Link, useHistory } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MUIDataTable from "mui-datatables";

import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import api from '../../../../services/api';

import Popover from '@material-ui/core/Popover';

import TransitionsModal from '../../../../components/TransitionsModal'

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import searchByIcon from '../../../../assets/icons/searchBy.png';
import ConfirmModal from '../../../../components/ConfirmModal';
import ToastContainer from '../../../../components/ToastContainer';
import templatesIcon from '../../../../assets/icons/templatesIcon.png';


import EditInventory from '../../../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../../../assets/icons/DeleteInventory.svg'
import ViewDetails from '../../../../assets/icons/ViewDetails.svg'


import {
  ContainerForms,
  LineHorizontal,
  HeaderInformations,
  DarkContainer,
  PopoverDiv,
  MoreHorizon,
  JobNumberDiv,
  Task,
  ModalDiv,
  Label,
  ActivityContainer,
  SaveButtonDiv,
  SwitchTableButton,
  NavTab
} from './styles';

function Documents() {
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [showToast, setShowToast] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [openNewDocumentModal, setOpenNewDocumentModal] = useState(false)
  const [openUploadDocumentModal, setOpenUploadDocumentModal] = useState(false)
  const [contracts, setContracts] = useState([]);
  const [document, setDocument] = useState('');
  const [file, setFile] = useState([]);
  const [tabInformation, setTabInformation] = useState('documentsList');
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState({});
  const [ip, setIp] = useState([]);
  const openPop = Boolean(anchorEl);
  const { jobId, setJobId } = useContext(JobContext)
  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      document_name: '',
      document_description: '',
      status: 'Upload',

    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  async function handleSubmit(values) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        document_name: Yup.string().required('Document name is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      createContract(values);

    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',

      });
    }
  }

  const createContract = (values) => {
    const job_number = localStorage.getItem('@Propack:job_number');
    const employee_id = JSON.parse(localStorage.getItem('@Propack:employee')).id;
    const date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`

    const data = new FormData();
    data.append('job_number', job_number)
    data.append('employee_id', employee_id)
    data.append('sign_date', date)
    data.append('document_url', file)
    data.append('document_name', values.document_name)
    data.append('document_description', values.document_description)
    data.append('status', values.status)
    api
      .post(
        '/contracts',
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
            'content-type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        // setOpenPopUp({ show: true });
        setLoading(false);
        window.location.reload();
      })
      .catch(err => {
        setLoading(false);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error creating',
          description: 'Error creating contract',
        });
      });
  }

  const deleteContract = () => {
    setLoadingDelete(true)
    api
      .delete(
        `/contracts/${document.id}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
            'content-type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.reload();
      })
      .catch(err => {
        setLoadingDelete(false);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting contract',
        });
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getContracts() {
    api
      .get(`/contracts/${localStorage.getItem('@Propack:job_number')}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setContracts(res.data)
      });
  }

  useEffect(() => {
    getIP();
    getContracts()
  }, []);


  function handleToast() {
    setShowToast({ show: false });
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    const document = {
      id: value[1],
      name: value[0],
      url: value[5],
    }
    if (event)
      handleClick(event)
    setDocument(document)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };



  const columns = [
    {
      name: "document_name",
      label: "Document Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              style={{ textDecoration: 'none', color: 'black' }}
              href={tableMeta.rowData?.[5]} target="_blank">
              <span>{`${tableMeta.rowData?.[0]}`}</span>
            </a>
          )
        }
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        display: false
      },
    },
    {
      name: "document_description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                maxWidth: "550px", wordWrap: "break-word",
                maxHeight: "100px", overflowY: "auto", fontSize: '13px',
                whiteSpace: 'pre-wrap'
              }}>
              <span>{`${tableMeta.rowData?.[2]}`}</span>
            </div>
          )
        }
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "sign_date",
      label: "Sign Date",
      options: {
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: 'document_url',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
              paddingLeft: '14px'
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, tableMeta.rowData)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv>
                  <a
                    style={{ textDecoration: 'none', color: 'black' }}
                    href={document.url} target="_blank">
                    <img height={18} src={ViewDetails} style={{ marginRight: '14px' }}></img>
                    View
                  </a>
                </PopoverDiv>
                <PopoverDiv onClick={() => deleteContract()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </PopoverDiv>
              </Popover>
            </div>
            // <EditButton onClick={() => goEdit(value)} type="button">
            //   <MoreHorizIcon />
            // </EditButton>
          )
        }
      },
    },
  ];

  const options = {
    filter: "false",
    selectableRows: "none",
  };

  function handlePopUp() {
    setOpenPopUp({ show: false });

  }

  const newDocument = () => {
    return (
      <ModalDiv>
        <Task onClick={() => {
          setOpenNewDocumentModal(false),
            setOpenUploadDocumentModal(true)
        }}>
          Upload Document
        </Task>
        OR
        <Task onClick={() => history.push({
          pathname: "/new-contract",
        })}>
          Contracts Template
        </Task>
      </ModalDiv >
    )
  }

  const onFileChange = (event) => {
    setFile(event.target.files[0])
  }

  const uploadDocument = () => {
    return (
      <Formik >
        <Form onSubmit={formik.handleSubmit}>
          <ActivityContainer>
            <div style={{
              display: 'flex', flexDirection: 'column',
              width: '100%'
            }}>
              <div style={{
                marginBottom: '5px'
              }}>
                <Label
                  style={errors.document_name ? { color: 'red' } : null}
                >
                  Document Name
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <Field
                  placeholder="Enter the document name..."
                  name="document_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.document_name}
                  style={errors.document_name ? { border: '1px solid red' } : null}
                />
              </div>
              <Label style={{ marginBottom: '10px' }}>
                Description:
              </Label>
              <TextareaAutosize
                style={{
                  backgroundColor: '#ececec', border: 'none',
                  color: '#495057', padding: '10px',
                }}
                rows={5}
                aria-label="maximum height"
                placeholder="Enter document description..."
                name="document_description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
              <input style={{
                backgroundColor: '#618095', color: '#fff',
                height: '41px', width: '200px', marginTop: '5px'
              }} type="file" onChange={(e) => onFileChange(e)} />
            </div>
            <SaveButtonDiv>
              <button
                type="submit">
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  'Save'
                )}
              </button>
            </SaveButtonDiv>
          </ActivityContainer >
        </Form>
      </Formik>
    )
  }

  const documentsContents = () => {
    return (
      <>
        <HeaderInformations>
          <div>
          </div>
          <button
            onClick={() => setOpenNewDocumentModal(true)}
            type="button">New Document</button>
        </HeaderInformations>
        {
          <MUIDataTable
            title={"Documents List"}
            data={contracts}
            columns={columns}
            options={options}
          />
        }
      </>
    )
  }

  const docusignContents = () => {
    return (
      <>
        <HeaderInformations>
          <div>
          </div>
          <button
            onClick={() => setOpenNewDocumentModal(true)}
            type="button">New Document</button>
        </HeaderInformations>
        {<MUIDataTable
          title={"Docusign Documents"}
          data={[]}
          columns={columns}
          options={options}
        />}
      </>
    )
  }

  return (
    <>
      {showToast.show && (
        <ToastContainer
          messages={showToast}
          show={() => handleToast()}
        />
      )}
      {/* {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} content={viewContract()} />} */}
      {openNewDocumentModal && <TransitionsModal openModal={true} closeModal={setOpenNewDocumentModal}
        content={newDocument()} widthProp="50%" />}
      {openUploadDocumentModal && <TransitionsModal openModal={true} closeModal={setOpenUploadDocumentModal}
        content={uploadDocument()} widthProp="50%" />}
      {openPopUp.show && <DarkContainer />}
      {openPopUp.show &&
        <ConfirmModal
          show={() => handlePopUp()}
          deleteUser={() => deleteJob(jobId)}
        />
      }
      <ContainerForms >
        <NavTab>
          <li
            onClick={() => {
              setTabInformation('documentsList');
            }}
            style={
              tabInformation === 'documentsList' ? { backgroundColor: '#618095' } : null
            }
          >
            <a>1. Documents List</a>
          </li>
          <li
            onClick={() => {
              setTabInformation('docusignDocuments');
            }}
            style={
              tabInformation === 'docusignDocuments' ? {
                marginLeft: '10px',
                backgroundColor: '#618095',
                marginLeft: '10px'
              } : { marginLeft: '10px' }
            }
          >
            <a>2. Docusign Documents</a>
          </li>

        </NavTab>
        {tabInformation === 'documentsList' ?
          documentsContents() :
          docusignContents()}
      </ContainerForms>
    </>
  )
}

export default Documents;