import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: 20px;
  border-radius: 5px;
  /* padding: 15px; */
`;

export const MoreHorizon = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export const ActivityNameDiv = styled.div`
  display: flex;
  width: 150px;
  &:hover {
    cursor: pointer;
  }
`

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;

  border: 1px solid #eeeeee;
`;

export const PopoverDiv = styled.div`
  border-bottom: 1px solid #eee;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`

export const HeaderInformations = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;


  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const DarkContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  left: 0;
  top: 0;
  height: 2000px;
  width: 2500px;
`;


export const ConfirmButton = styled.button`
    width: 100px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    
  }
`

export const DenyButton = styled.button`
    width: 100px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background-color: #FF5656;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.15, '#FF5656')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    
  }
`
export const DescriptionDiv = styled.div`
  display: flex;
  word-break: break-all;
  max-width: 300px;
  margin: 0;
  &:hover {
    cursor: pointer;
  }
`