import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const NavTab = styled.ul`
  display: flex;
  width: 100%;
  list-style: none;
  padding-left: 0;
  border-bottom: 2px solid #618095;
  li {
    display: flex;
    background: #cfdbd5;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    min-width: 144px;
    max-width: 100%;
    height: 39px;
    align-items: center;
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }


`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 900px;
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  padding: 15px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 30px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #333533;
  }
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;
  margin-bottom: 4 0px;

  border: 1px solid #eeeeee;
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.25rem;
  height: 20px;
  margin-top: 20px;

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const ActivityIcons = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  
  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`
export const Email = styled.div`
  width: 100px;
  height: 76px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: 5px 5px 5px #eee;
  &:hover {
    cursor: pointer
  }
`

export const Call = styled.div`
  width: 100px;
  height: 76px;
  margin-left: 10px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: 5px 5px 5px #eee;
&:hover {
  cursor: pointer
}
`

export const Visit = styled.div`
  width: 100px;
  height: 76px;
  margin-left: 10px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: 5px 5px 5px #eee;
&:hover {
  cursor: pointer
}
`
export const Task = styled.div`
  width: 100px;
  height: 76px;
  margin-left: 10px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: 5px 5px 5px #eee;
&:hover {
  cursor: pointer
}
`

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;

  > img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;


export const ActivityContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 300px;

  input {
    margin-top: 10px;
    display: block;
    width: 90%;
    height: calc(1.5em + 0.75rem + 13px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ececec;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
    > select {
    margin-top: 10px;
    display: block;
    padding: 0.65rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

      &:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
    }
`;

export const FirstSection = styled.div`
display: flex;
justify-content: space-between;
div {
  width: 48%;
}
select {
    margin-top: 10px;
    display: block;
    width: 100%;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

@media (max-width: 960px) {
  flex-direction: column;
  div {
    width: 100%;
  }
}
`

export const SecondSection = styled.div`
  display: flex;
  /* justify-content: space-between; */
  div {
    width: 48%;
  }
  select {
    margin-top: 10px;
    display: block;
    width: 100%;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
  @media (max-width: 960px) {
  flex-direction: column;

  div {
    width: 100%;
  }
  }

`
export const ThirdSection = styled.div`

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const TagsDiv = styled.div`
  width: 16%;
  text-align: center;
  font-weight: bold;

  div:nth-child(1) {
    text-align: end;
    margin-right: 10px;
    color: gray;
  }
  span:nth-child(1) {
    &:hover {
      cursor: pointer;
    }
  }
  
  li {
    &:hover {
      cursor: pointer;
    }
  }
`

export const FourthSectionEdit = styled.div`
margin: 20px 0 0 auto;
display: flex;
justify-content: space-between;
width: 100%;

button {
    width: 200px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;
    margin-right: 5px;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
  button:nth-child(1) {
    background-color: #FF5656;
    
    &:hover {
      background-color: ${lighten(0.06, '#FF5656')};
    }
  }

  @media (max-width: 960px) {
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 90px;
}

@media (max-width: 500px) {
  
  button {
    width: 100%;

  }
}
`

export const FourthSection = styled.div`
margin: 20px 0 0 auto;
display: flex;
justify-content: flex-end;
width: 60%;

button {
    width: 200px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;
    margin-right: 5px;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
  @media (max-width: 960px) {
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 90px;
}
@media (max-width: 500px) {
  
  button {
    width: 100%;
  }
}
`

export const Half3Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  div {
    width: 30%;
  }

  select {
    margin-top: 10px;
    display: block;
    width: 100%;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
  @media (max-width: 760px) {
      flex-direction: column;
        div {
          width: 100%;
        } 
    }
`