import styled from 'styled-components';
import { lighten, darken } from 'polished';


export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const GanttContainer = styled.div`
  width: 80vw;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-top: 20px;
  border-radius: 5px;
  /* padding: 15px; */
`;

export const NavTab = styled.ul`
  display: flex;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 2px solid #618095;
  /* border: 1px solid red; */
  text-decoration: none;
  a {
    display: flex;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    width: 134px;
    height: 39px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    text-decoration: none;
      /* display: block; */
      background: #cfdbd5;

      /* padding: 0.5rem 1rem; */
      color: #fff;
      text-decoration: none;


      &:hover {
        color: #fff;

      }
    

  li {
    


    

    &:hover {
      cursor: pointer;
    }
  }
}
  @media (max-width: 1200px) {
    z-index: 2;
    overflow-x: scroll;
    overflow-y: clip;
  }

`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 30px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333533;
  }
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;

  border: 1px solid #eeeeee;
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;

  img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 40px;

  img {
    width: 17px;
    height: 19px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const DivForms = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  height: 10%;
`;

export const AddButton = styled.button`
    border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 51px;
  height: 41px;
  margin-top: 24px;
  color: #ffffff;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  img {
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }

  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }

  &:disabled {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: not-allowed;
  }
`;

export const DeleteButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 51px;
  height: 41px;
  color: #ffffff;
  background-color: #FF5656;
  border-radius: 3px;

  img {
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }

  &:hover {
    background: ${lighten(0.15, '#FF5656')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }

  &:disabled {
    background: ${lighten(0.15, '#FF5656')};
    cursor: not-allowed;
  }
`;

export const DivInput = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;

  img {
    width: 55px;
    height: 55px;
  }

  input {
    margin-top: 10px;
    display: block;
    width: 90%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #f9f9f9;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }

    &:hover {
      cursor: ${props => (props.noEdit ? 'not-allowed' : '')};
    }
  }

  select {
    margin-top: 10px;
    display: block;
    width: 90%;
    height: 40px;
    padding: 0.8rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const SubmitButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 94px;
  height: 41px;
  margin-top: 24px;
  color: #ffffff;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  img {
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }

  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const ConatinerModal = styled.div`
  position: fixed;
  width: 1300px;
  height: 500px;
  left: calc(50% - 1300px / 2);
  top: 155px;
  z-index: 3;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

export const DarkContainerModal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

export const OfficeList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;

  li {
    display: flex;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    width: 100%;
    color: #000;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 2px solid #eeeeee;

    & + li {
      margin-top: 16px;
    }

    strong {
      font-weight: 500;
    }
  }
`;

export const DivTable = styled.div`
  display: flex;
  max-height: 610px;
  `;


