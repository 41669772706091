import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import MUIDataTable from "mui-datatables";
import Axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { Formik, Field, Form, useFormik } from 'formik';
import Popover from '@material-ui/core/Popover';


import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  NavTab,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  ContainerInformations,
  HeaderInformations,
  Label,
  DivForms,
  DivInput,
  SubmitButton,
  ContainerModal,
  DarkContainerModal,
  OfficeList,
  AddButton,
  DivTable,
  DeleteButton,
  DivInputHorizontal,
  PopoverDiv,
  ListSettings,
  MoreHorizon,
  HQ1, HQ2,
  OfficesDiv,
  ButtonOfficesDiv,
  ListSettingsWrapper,
  ModalDiv1, ModalDiv2, ModalDiv3, CloseModalDiv
} from './styles';

import api from '../../services/api';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';

import headQuarterIcon from '../../assets/icons/headQuarterIcon.png';
import officesIcon from '../../assets/icons/officesIcon.png';
import editIcon from '../../assets/icons/editIcon.png';
import deleteIcon from '../../assets/icons/deleteIcon.png';
import saveIcon from '../../assets/icons/saveIcon.png';
import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ToastContainer from '../../components/ToastContainer';
import ClearIcon from '@material-ui/icons/Clear';

function Settings() {
  let autoComplete;
  const autoCompleteRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElOffice, setAnchorElOffice] = useState(null);
  const [companyProfileShow, setCompanyProfileShow] = useState(true);
  const [permissionGroupShow, setPermissionGroupShow] = useState(false);
  const [logsShow, setLogsShow] = useState(false);
  const [jobShow, setJobShow] = useState(false);
  const [customerShow, setCustomerShow] = useState(false);
  const [jobTitleShow, setJobTitleShow] = useState(false);
  const [logs, setLogs] = useState([]);
  const [headQuarter, setHeadQuarter] = useState([]);
  const [edit, setEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editOffice, setEditOffice] = useState();
  const [offices, setOffices] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const [loadingInformations, setLoadingInformations] = useState(false);
  const [showToast, setShowToast] = useState({ show: false });
  const [ip, setIp] = useState('0.0.0.0');

  const [pagination, setPagination] = useState(1);
  const paginationLength = Math.ceil(logs.length / 5);

  const [address, setAddress] = useState();
  const [postalCode, setPostalCode] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [phone, setPhone] = useState();
  const [mobilePhone, setMobilePhone] = useState();
  const [website, setWebsite] = useState();

  const [nameOffice, setNameOffice] = useState();
  const [addressOffice, setAddressOffice] = useState();
  const [postalCodeOffice, setPostalCodeOffice] = useState();
  const [cityOffice, setCityOffice] = useState();
  const [stateOffice, setStateOffice] = useState();
  const [phoneOffice, setPhoneOffice] = useState();
  const [mobilePhoneOffice, setMobilePhoneOffice] = useState('');
  const [websiteOffice, setWebsiteOffice] = useState('');

  const [readOnlyEmployee, setReadOnlyEmployee] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const [fullControlEmployee, setFullControlEmployee] = useState(false);
  const [readOnlyCustomer, setReadOnlyCustomer] = useState(false);
  const [editCustomer, setEditCustomer] = useState(false);
  const [fullControlCustomer, setFullControlCustomer] = useState(false);
  const [readOnlyOffice, setReadOnlyOffice] = useState(false);
  const [editOfficeBox, setEditOfficeBox] = useState(false);
  const [fullControlOffice, setFullControlOffice] = useState(false);
  const [permissionsGroup, setPermissionsGroup] = useState([]);
  const [permissionTitle, setPermissionTitle] = useState();
  const [readOnlyActivity, setReadOnlyActivity] = useState(false);
  const [editActivity, setEditActivity] = useState(false);
  const [fullControlActivity, setFullControlActivity] = useState(false);
  const [readOnlyControlPoints, setReadOnlyControlPoints] = useState(false);
  const [editControlPoints, setEditControlPoints] = useState(false);
  const [fullControlControlPoints, setFullControlControlPoints] = useState(false);
  const [readOnlyGeneralNotes, setReadOnlyGeneralNotes] = useState(false);
  const [editGeneralNotes, setEditGeneralNotes] = useState(false);
  const [fullControlGeneralNotes, setFullControlGeneralNotes] = useState(false);
  const [readOnlyInventory, setReadOnlyInventory] = useState(false);
  const [editInventory, setEditInventory] = useState(false);
  const [fullControlInventory, setFullControlInventory] = useState(false);
  const [readOnlyJobPhotos, setReadOnlyJobPhotos] = useState(false);
  const [editJobPhotos, setEditJobPhotos] = useState(false);
  const [fullControlJobPhotos, setFullControlJobPhotos] = useState(false);
  const [readOnlyWorkflow, setReadOnlyWorkflow] = useState(false);
  const [editWorkflow, setEditWorkflow] = useState(false);
  const [fullControlWorkflow, setFullControlWorkflow] = useState(false);
  const [readOnlyDocument, setReadOnlyDocument] = useState(false);
  const [editDocument, setEditDocument] = useState(false);
  const [fullControlDocument, setFullControlDocument] = useState(false);
  const [readOnlyJob, setReadOnlyJob] = useState(false);
  const [editJob, setEditJob] = useState(false);
  const [fullControlJob, setFullControlJob] = useState(false);
  const [readOnlyDashboard, setReadOnlyDashboard] = useState(false);
  const [editDashboard, setEditDashboard] = useState(false);
  const [fullControlDashboard, setFullControlDashboard] = useState(false);
  const [companyDashboard, setCompanyDashboard] = useState(false);
  const [personalDashboard, setPersonalDashboard] = useState(false);

  const [permissionTitleEdit, setPermissionTitleEdit] = useState();
  const [permissionId, setPermissionId] = useState();
  const [createEmployeeEdit, setCreateEmployeeEdit] = useState(false);
  const [createOfficeEdit, setCreateOfficeEdit] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [editablePermission, setEditablePermission] = useState({});

  const [lossCategoryInput, setLossCategoryInput] = useState('');
  const [lossTypeInput, setLossTypeInput] = useState('');
  const [sourceOfLossInput, setSourceOfLossInput] = useState('');
  const [jobSizeInput, setJobSizeInput] = useState('');
  const [roomsAffectedInput, setRoomsAffectedInput] = useState('');
  const [environmentalCodeInput, setEnvironmentalCodeInput] = useState('');
  const [divisionInput, setDivisionInput] = useState('');
  const [jobStatusInput, setJobStatusInput] = useState('');
  const [lossCategories, setLossCategories] = useState([]);
  const [lossTypes, setLossTypes] = useState([]);
  const [sourcesOfLoss, setSourcesOfLoss] = useState([]);
  const [jobSizes, setJobSizes] = useState([]);
  const [roomsAffected, setRoomsAffected] = useState([]);
  const [environmentalCode, setEnvironmentalCode] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [jobStatus, setJobStatus] = useState([]);

  const [customerTypeInput, setCustomerTypeInput] = useState('');
  const [titleInput, setTitleInput] = useState('');
  const [customerTypes, setCustomerTypes] = useState([]);
  const [customerTitles, setCustomerTitles] = useState([]);

  const [jobTitles, setJobTitles] = useState([]);
  const [jobTitleInput, setJobTitleInput] = useState('');

  const handleClick = (event, permission) => {
    setAnchorEl(event.currentTarget);
    setEditablePermission(permission);
    setPermissionTitleEdit(permission.name);
    setCreateOfficeEdit(permission.create_office);
    setCreateEmployeeEdit(permission.create_employee);
    setPermissionId(permission.permission_groups_id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElOffice(null);
  };
  const openPop = Boolean(anchorEl);
  const openPopOffice = Boolean(anchorElOffice);

  const openPopover = (event) => {
    setAnchorElOffice(event.currentTarget);
  }

  const handleEditOffice = (office) => {
    handleClose()
    setEditOffice(office);
    setOpenModal(true);
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function handleButton() {
    if (edit) {
      setLoading(true);
      await api
        .patch(
          'office/update',
          {
            client_id: clients[0].client_id,
            lastName: headQuarter.name,
            address,
            postal_code: postalCode,
            city,
            state,
            phone,
            mobile_phone: mobilePhone,
            website,
            head_quarter: true,
            ip,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
            },
          },
        )
        .then(() => {
          setLoading(false);
          setShowToast({
            show: true,
            type: 'success',
            title: 'Updated successfully',
            description: 'Office successfully updated',
          });
        })
        .catch(err => {
          console.error(err);
          setShowToast({
            show: true,
            type: 'error',
            title: 'Updated error',
            description: 'error updating office',
          });
          setLoading(false);
        });

      setEdit(false);
    } else {
      setEdit(true);
    }
  }

  function handleCreateJobTitle() {
    setShowToast({
      show: true,
      type: 'success',
      title: 'Updating',
      description: 'Updating data...',
    });
    api.post(
      'job-title/',
      {
        value: jobTitleInput,
        ip,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      },
    )
      .then((res) => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Job title successfully created',
        });
        setJobTitles(prev => [...prev, { value: res.data.value, job_title_id: res.data.job_title_id }])
        setJobTitleInput("")
        // location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created job title',
        });
        // setLoading(false);
      });
  }

  async function handleUpdatedOffice(lastName) {
    setLoading(true);
    await api
      .patch(
        'office/update',
        {
          client_id: clients[0].client_id,
          name: nameOffice,
          address: addressOffice,
          postal_code: postalCodeOffice,
          city: cityOffice,
          state: stateOffice,
          phone: phoneOffice,
          mobile_phone: mobilePhoneOffice,
          website: websiteOffice,
          lastName,
          head_quarter: false,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Updated successfully',
          description: 'Office successfully updated',
        });
        location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Updated error',
          description: 'error updating office',
        });
        setLoading(false);
      });

    setEdit(false);
  }

  async function handleCreateCustomerType() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Updating',
      description: 'Updating data...',
    });
    await api
      .post(
        'customer-type/',
        {
          name: customerTypeInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Customer type successfully created',
        });
        setCustomerTypes(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setCustomerTypeInput("")
        // location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created customer type',
        });
        // setLoading(false);
      });
  }

  async function handleCreateCustomerTitle() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Updating',
      description: 'Updating data...',
    });
    await api
      .post(
        'customer-title/',
        {
          name: titleInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Title successfully created',
        });
        setCustomerTitles(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setTitleInput("")
        // location.reload();
      })
      .catch(err => {
        // console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created title',
        });
        // setLoading(false);
      });
  }

  async function handleCreateLossCategory() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Updating',
      description: 'Updating data...',
    });
    await api
      .post(
        'loss-category/',
        {
          name: lossCategoryInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Loss category successfully created',
        });
        setLossCategories(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setLossCategoryInput("")
        // location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created Loss category',
        });
        setLoading(false);
      });
  }

  async function handleCreateLossType() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Updating',
      description: 'Updating data...',
    });
    await api
      .post(
        'loss-type/',
        {
          name: lossTypeInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Loss type successfully created',
        });
        setLossTypes(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setLossTypeInput("")
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created Loss type',
        });
        // setLoading(false);
      });
  }

  async function handleCreateSourceOfLoss() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Uploading',
      description: 'Uploading data...',
    });
    await api
      .post(
        'source-of-loss/',
        {
          name: sourceOfLossInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Source Of Loss successfully created',
        });
        setSourcesOfLoss(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setSourceOfLossInput("")
        // location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created Source Of Loss',
        });
        // setLoading(false);
      });
  }

  async function handleCreateJobSize() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Uploading',
      description: 'Uploading data...',
    });
    await api
      .post(
        'job-size/',
        {
          name: jobSizeInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Job size successfully created',
        });
        setJobSizes(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setJobSizeInput("")
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created job size',
        });
        setLoading(false);
      });
  }

  async function handleCreateRoomAffected() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Uploading',
      description: 'Uploading data...',
    });
    await api
      .post(
        'room-affected/',
        {
          name: roomsAffectedInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(res => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Room affected successfully created',
        });
        setRoomsAffected(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setRoomsAffectedInput("")
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created room affected',
        });
        // setLoading(false);
      });
  }

  async function handleEnvironmentalCode() {
    // // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Uploading',
      description: 'Uploading data...',
    });
    await api
      .post(
        'environmental-code/',
        {
          name: environmentalCodeInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'created enviromental code',
        });
        setEnvironmentalCode(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setEnvironmentalCodeInput("")
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created enviromental code',
        });
        // // setLoading(false);
      });
  }

  async function handleDivision() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Uploading',
      description: 'Uploading data...',
    });
    await api
      .post(
        'division',
        {
          name: divisionInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'created division',
        });
        setDivisions(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setDivisionInput("")
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created division',
        });
        // setLoading(false);
      });
  }

  async function handleJobStatus() {
    // setLoading(true);
    setShowToast({
      show: true,
      type: 'success',
      title: 'Uploading',
      description: 'Uploading data...',
    });
    await api
      .post(
        'job-status',
        {
          name: jobStatusInput,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        // setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'created job status',
        });
        setJobStatus(prev => [...prev, { name: res.data.name, id: res.data.id }])
        setJobStatusInput("")
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created job status',
        });
        // setLoading(false);
      });
  }

  async function handleCreateOffice() {
    await api
      .post(
        'office/',
        {
          client_id: clients[0].client_id,
          name: nameOffice,
          address: addressOffice,
          postal_code: postalCodeOffice,
          city: cityOffice,
          state: stateOffice,
          phone: phoneOffice,
          mobile_phone: mobilePhoneOffice,
          website: websiteOffice,
          head_quarter: false,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoading(false);
        setOpenModal(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Office successfully created',
        });
        location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created office',
        });
        setLoading(false);
      });
  }

  async function handleCreatePermission(permission) {
    await api
      .post(
        'permissionGroup/',
        {
          name: permissionTitle,
          full_employee: fullControlEmployee, // ? fullControlEmployee : permission.full_employee,
          edit_employee: editEmployee, // ? editEmployee: permission.edit_employee,
          read_employee: readOnlyEmployee, // ? readOnlyEmployee: permission.read_employee,
          full_customer: fullControlCustomer, // ? fullControlCustomer: permission.full_customer,
          edit_customer: editCustomer, // ? editCustomer: permission.edit_customer,
          read_customer: readOnlyCustomer, // ? readOnlyCustomer: permission.read_customer,
          full_office: fullControlOffice, // ? fullControlOffice: permission.full_office,
          edit_office: editOfficeBox, // ? editOfficeBox: permission.edit_office,
          read_office: readOnlyOffice, // ? readOnlyOffice: permission.read_office,
          full_activity: fullControlActivity, // ? fullControlActivity: permission.full_activity,
          edit_activity: editActivity, // ? editActivity: permission.edit_activity,
          read_activity: readOnlyActivity, // ? readOnlyActivity: permission.read_activity,
          full_job: fullControlJob, // ? fullControlJob: permission.full_job,
          edit_job: editJob, // ? editJob: permission.edit_job,
          read_job: readOnlyJob, // ? readOnlyJob: permission.read_job,
          full_control_points: fullControlControlPoints, // ? fullControlControlPoints: permission.full_control_points,
          edit_control_points: editControlPoints, // ? editControlPoints: permission.edit_control_points,
          read_control_points: readOnlyControlPoints, // ? readOnlyControlPoints: permission.read_control_points,
          full_general_note: fullControlGeneralNotes, // ? fullControlGeneralNotes: permission.full_general_note,
          edit_general_note: editGeneralNotes, // ? editGeneralNotes: permission.edit_general_note,
          read_general_note: readOnlyGeneralNotes, // ? readOnlyGeneralNotes: permission.read_general_note,
          full_inventory: fullControlInventory, // ? fullControlInventory: permission.full_inventory,
          edit_inventory: editInventory, // ? editInventory: permission.edit_inventory,
          read_inventory: readOnlyInventory, // ? readOnlyInventory: permission.read_inventory,
          full_job_photos: fullControlJobPhotos, // ? fullControlJobPhotos: permission.full_job_photos,
          edit_job_photos: editJobPhotos, // ? editJobPhotos: permission.edit_job_photos,
          read_job_photos: readOnlyJobPhotos, // ? readOnlyJobPhotos: permission.read_job_photos,
          full_workflow: fullControlWorkflow, // ? fullControlWorkflow: permission.full_workflow,
          edit_workflow: editWorkflow, // ? editWorkflow: permission.edit_workflow,
          read_workflow: readOnlyWorkflow, // ? readOnlyWorkflow: permission.read_workflow,
          full_document: fullControlDocument, // ? fullControlDocument: permission.full_document,
          edit_document: editDocument, // ? editDocument: permission.edit_document, 
          read_document: readOnlyDocument, // ? readOnlyDocument: permission.read_document,
          full_document: fullControlDashboard, // ? fullControlDocument: permission.full_document,
          edit_document: editDashboard, // ? editDocument: permission.edit_document, 
          read_document: readOnlyDashboard, // ? readOnlyDocument: permission.read_document,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        
        setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Created successfully',
          description: 'Permission successfully created',
        });
        location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Created error',
          description: 'error created permission',
        });
        setLoading(false);
      });
  }

  async function handleUpdatePermission(permission) {
    await api
      .patch(
        'permissionGroup/update',
        {
          permission_groups_id: permissionId,
          full_employee: permission.full_employee,
          edit_employee: permission.edit_employee,
          read_employee: permission.read_employee,
          full_customer: permission.full_customer,
          edit_customer: permission.edit_customer,
          read_customer: permission.read_customer,
          full_office: permission.full_office,
          edit_office: permission.edit_office,
          read_office: permission.read_office,
          full_activity: permission.full_activity,
          edit_activity: permission.edit_activity,
          read_activity: permission.read_activity,
          full_job: permission.full_job,
          edit_job: permission.edit_job,
          read_job: permission.read_job,
          full_control_points: permission.full_control_points,
          edit_control_points: permission.edit_control_points,
          read_control_points: permission.read_control_points,
          full_general_note: permission.full_general_note,
          edit_general_note: permission.edit_general_note,
          read_general_note: permission.read_general_note,
          full_inventory: permission.full_inventory,
          edit_inventory: permission.edit_inventory,
          read_inventory: permission.read_inventory,
          full_job_photos: permission.full_job_photos,
          edit_job_photos: permission.edit_job_photos,
          read_job_photos: permission.read_job_photos,
          full_workflow: permission.full_workflow,
          edit_workflow: permission.edit_workflow,
          read_workflow: permission.read_workflow,
          full_document: permission.full_document,
          edit_document: permission.edit_document,
          read_document: permission.read_document,
          full_dashboard: permission.full_dashboard,
          edit_dashboard: permission.edit_dashboard,
          read_dashboard: permission.read_dashboard,
          company_dashboard: permission.company_dashboard,
          personal_dashboard: permission.personal_dashboard,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingPermission(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Updated successfully',
          description: 'Permission successfully updated',
        });
        location.reload();
      })
      .catch(err => {
        console.error(err);

        setShowToast({
          show: true,
          type: 'error',
          title: 'Updated error',
          description: 'error updated permission',
        });
        setLoadingPermission(false);
      });
  }

  async function handleDeletePermission(permissionGroupsId) {
    await api
      .delete(`permissionGroup/delete/${permissionGroupsId}/:${ip}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(() => {
        setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Deleted successfully',
          description: 'Permission successfully deleted.',
        });
        location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Deleted error',
          description: 'error deleted permission',
        });
        setLoading(false);
      });
  }

  async function handleDeleteOffice(id) {
    await api
      .delete(`office/delete/${id}/:${ip}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(() => {
        setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Deleted successfully',
          description: 'Permission successfully deleted.',
        });
        location.reload();
      })
      .catch(err => {
        console.error(err);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Deleted error',
          description: 'error deleted permission',
        });
        setLoading(false);
      });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect()
    );
  }

  async function handlePlaceSelect() {
    const addressObject = autoComplete.getPlace();
    let streetNumber = ''
    addressObject.address_components.map(component => {
      if (component.types[0] === 'locality') {
        setCityOffice(component.long_name)
        //setFormik('city', component.long_name.split('County')[0])
      }
      if (component.types[0] === 'administrative_area_level_1') {
        setStateOffice(component.long_name)
        //setFormik('state', component.long_name)
      }
      if (component.types[0] === 'country') {
        //setFormik('country', component.long_name)
      }
      if (component.types[0] === 'postal_code') {
        setPostalCodeOffice(component.long_name)
        //setFormik('postal_code', component.long_name)
      }
      if (component.types[0] === 'street_number') {
        streetNumber = component.long_name
      }
      if (component.types[0] === 'route') {
        setAddressOffice(`${streetNumber} ${component.long_name}`)
        //setFormik('address', `${streetNumber} ${component.long_name}`)
      }
    })
  }

  function permissionModal(permission) {
    return (
      <ContainerModal style={{ overflow: 'auto' }}>
        <HeaderInformations style={{
          marginTop: '30px',
          justifyContent: 'space-between'
        }}>
          <text>Permission Group</text>
          <CloseModalDiv
            onClick={() => setEditPermission(false)}>
            <ClearIcon />
          </CloseModalDiv>
        </HeaderInformations>
        <LineHorizontal />
        <DivForms>
          <DivInput>
            <Label>
              Title
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              defaultValue={permission.name}
              onChange={e => setPermissionTitle(e.target.value)}
              placeholder="Pemission's group title"
            />
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Employee</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                defaultChecked={permission.read_employee}
                style={{ marginRight: '30px' }}
                onChange={e => {
                  permission.read_employee = e.target.checked;
                  setReadOnlyEmployee(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_employee}
                onChange={e => {
                  permission.edit_employee = e.target.checked;
                  setEditEmployee(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_employee}
                onChange={e => {
                  permission.full_employee = e.target.checked;
                  setFullControlEmployee(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Customer</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_customer}
                onChange={e => {
                  permission.read_customer = e.target.checked;
                  setReadOnlyCustomer(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_customer}
                onChange={e => {
                  permission.edit_customer = e.target.checked;
                  setEditCustomer(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_customer}
                onChange={e => {
                  permission.full_customer = e.target.checked;
                  setFullControlCustomer(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Office</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_office}
                onChange={e => {
                  permission.read_office = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_office}
                onChange={e => {
                  permission.edit_office = e.target.checked;
                  setEditOfficeBox(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_office}
                onChange={e => {
                  permission.full_office = e.target.checked;
                  setFullControlOffice(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Activity</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_activity}
                onChange={e => {
                  permission.read_activity = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_activity}
                onChange={e => {
                  permission.edit_activity = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_activity}
                onChange={e => {
                  permission.full_activity = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Job</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_job}
                onChange={e => {
                  permission.read_job = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_job}
                onChange={e => {
                  permission.edit_job = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_job}
                onChange={e => {
                  permission.full_job = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Control Points</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_control_points}
                onChange={e => {
                  permission.read_control_points = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_control_points}
                onChange={e => {
                  permission.edit_control_points = e.target.checked
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_control_points}
                onChange={e => {
                  permission.full_control_points = e.target.checked
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>General Notes</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_general_note}
                onChange={e => {
                  permission.read_general_note = e.target.checked
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_general_note}
                onChange={e => {
                  permission.edit_general_note = e.target.checked
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_general_note}
                onChange={e => {
                  permission.full_general_note = e.target.checked
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Inventory</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_inventory}
                onChange={e => {
                  permission.read_inventory = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_inventory}
                onChange={e => {
                  permission.edit_inventory = e.target.checked
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_inventory}
                onChange={e => {
                  permission.full_inventory = e.target.checked
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Job Photos</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_job_photos}
                onChange={e => {
                  permission.read_job_photos = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_job_photos}
                onChange={e => {
                  permission.edit_job_photos = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_job_photos}
                onChange={e => {
                  permission.full_job_photos = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Workflow</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_workflow}
                onChange={e => {
                  permission.read_workflow = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_workflow}
                onChange={e => {
                  permission.edit_workflow = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_workflow}
                onChange={e => {
                  permission.full_workflow = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Document</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.read_document}
                onChange={e => {
                  permission.read_document = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.edit_document}
                onChange={e => {
                  permission.edit_document = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.full_document}
                onChange={e => {
                  permission.full_document = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput>
            <text>Dashboard</text>
            <div style={{ marginLeft: '30px', width: '500px' }}>
              <Label>Personal Dashboard</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.personal_dashboard}
                onChange={e => {
                  permission.personal_dashboard = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Company Dashboard</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                defaultChecked={permission.company_dashboard}
                onChange={e => {
                  permission.company_dashboard = e.target.checked;
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms style={{ justifyContent: 'flex-end' }}>
          <SubmitButton
            style={{ backgroundColor: '#618095' }}
            onClick={() => {
              setLoadingPermission(true);
              handleUpdatePermission(permission);
            }}
          >
            {loadingPermission ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              'Save'
            )}
            {!loadingPermission && <img src={saveIcon} alt="saveIcon" />}
          </SubmitButton>
        </DivForms>
      </ContainerModal>
    );
  }

  function officeModal(office) {
    return (
      <ContainerModal>
        <HeaderInformations style={{
          marginTop: '30px',
          justifyContent: 'space-between'
        }}>
          <div>
            <img src={officesIcon} alt="officesIcon" />
            <text>Offices</text>
          </div>
          <CloseModalDiv onClick={() => setOpenModal(false)}>
            <ClearIcon />
          </CloseModalDiv>
        </HeaderInformations>
        <LineHorizontal />
        <ModalDiv1 >
          <DivInput >
            <Label>
              Office name
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              placeholder="office's name"
              defaultValue={office && office.name}
              onChange={e => setNameOffice(e.target.value)}
            />
          </DivInput>
          <DivInput >
            <Label>
              Address
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              placeholder="office's address"
              value={addressOffice}
              ref={autoCompleteRef}
              defaultValue={office && office.address}
              onChange={e => setAddressOffice(e.target.value)}
            />
          </DivInput>
          <DivInput >
            <Label>
              ZIP
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              edit={edit}
              placeholder="office's ZIP"
              value={postalCodeOffice}
              defaultValue={office && office.postal_code}
              onChange={e => setPostalCodeOffice(e.target.value)}
            />
          </DivInput>
        </ModalDiv1>
        <ModalDiv2 >
          <DivInput >
            <Label>
              City
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              placeholder="office's city"
              value={cityOffice}
              defaultValue={office && office.city}
              onChange={e => setCityOffice(e.target.value)}
            />
          </DivInput>
          <DivInput >
            <Label>
              State
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              value={stateOffice}
              placeholder="office's state"
              defaultValue={office && office.state}
              onChange={e => setStateOffice(e.target.value)}
            />
          </DivInput>
          <DivInput >
            <Label>
              Phone
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              placeholder="office's phone"
              defaultValue={office && office.phone}
              onChange={e => setPhoneOffice(e.target.value)}
            />
          </DivInput>
          <DivInput >
            <Label>
              Cell
            </Label>
            <input
              placeholder="office's Cell"
              defaultValue={office && office.mobile_phone}
              onChange={e => setMobilePhoneOffice(e.target.value)}
            />
          </DivInput>
        </ModalDiv2>
        <ModalDiv3>
          <DivInput >
            <Label>
              Website
            </Label>
            <input
              placeholder="office's website"
              defaultValue={office && office.website}
              onChange={e => setWebsiteOffice(e.target.value)}
            />
          </DivInput>
        </ModalDiv3>
        <DivForms style={{ justifyContent: 'flex-end', marginTop: 0 }}>
          <SubmitButton
            style={{ backgroundColor: '#618095' }}
            onClick={() => {
              setLoading(true);

              if (office) {
                handleUpdatedOffice(office.name);
              } else {
                handleCreateOffice();
              }
            }}
          >
            {loading ? <CircularProgress color="inherit" size={25} /> : 'Save'}
            {!loading && <img src={saveIcon} alt="editIcon" />}
          </SubmitButton>
        </DivForms>
      </ContainerModal>
    );
  }

  function companyProfileForm() {
    return (
      <ContainerInformations
        style={!companyProfileShow ? { display: 'none' } : null}
      >
        <HeaderInformations>
          <img src={headQuarterIcon} alt="headQuarterIcon" />
          <text>Headquarter</text>
        </HeaderInformations>
        <LineHorizontal />
        <HQ1>
          <DivInput noEdit={!edit}>
            <Label>
              Address
              {edit && <span style={{ color: 'red' }}> *</span>}
            </Label>
            <input
              style={{ width: '100%' }}
              defaultValue={headQuarter.address}
              readOnly={edit ? false : true}
              onChange={e => setAddress(e.target.value)}
            />
          </DivInput>
          <DivInput noEdit={!edit}>
            <Label>
              ZIP
              {edit && <span style={{ color: 'red' }}> *</span>}
            </Label>
            <input
              defaultValue={headQuarter.postal_code}
              readOnly={edit ? false : true}
              onChange={e => setPostalCode(e.target.value)}
            />
          </DivInput>
        </HQ1>
        <HQ2>
          <DivInput noEdit={!edit}>
            <Label>
              City
              {edit && <span style={{ color: 'red' }}> *</span>}
            </Label>
            <input
              defaultValue={headQuarter.city}
              readOnly={edit ? false : true}
              onChange={e => setCity(e.target.value)}
            />
          </DivInput>
          <DivInput noEdit={!edit}>
            <Label>
              State
              {edit && <span style={{ color: 'red' }}> *</span>}
            </Label>
            <input
              defaultValue={headQuarter.state}
              readOnly={edit ? false : true}
              onChange={e => setState(e.target.value)}
            />
          </DivInput>
          <DivInput noEdit={!edit}>
            <Label>
              Phone
              {edit && <span style={{ color: 'red' }}> *</span>}
            </Label>
            <input
              defaultValue={headQuarter.phone}
              readOnly={edit ? false : true}
              onChange={e => setPhone(e.target.value)}
            />
          </DivInput>
          <DivInput noEdit={!edit}>
            <Label>
              Cell
              {edit && <span style={{ color: 'red' }}> *</span>}
            </Label>
            <input
              defaultValue={headQuarter.mobile_phone}
              readOnly={edit ? false : true}
              onChange={e => setMobilePhone(e.target.value)}
            />
          </DivInput>
        </HQ2>
        <DivForms>
          <DivInput style={{ width: '100%' }} noEdit={!edit}>
            <Label>
              Website
              {edit && <span style={{ color: 'red' }}> *</span>}
            </Label>
            <input
              style={{ width: '100%' }}
              defaultValue={headQuarter.website}
              readOnly={edit ? false : true}
              onChange={e => setWebsite(e.target.value)}
            />
          </DivInput>
        </DivForms>
        <DivForms style={{ justifyContent: 'flex-end' }}>
          <SubmitButton
            onClick={() => handleButton()}
            disabled={loadingInformations ? true : false}
            style={edit ? { backgroundColor: '#618095' } : null}
          >
            {edit && loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : edit ? (
              'Save'
            ) : (
              'Edit'
            )}
            {edit && loading ? (
              ''
            ) : (
              <img src={edit ? saveIcon : editIcon} alt="editIcon" />
            )}
          </SubmitButton>
        </DivForms>
        <HeaderInformations style={{ marginTop: '60px' }}>
          <img src={officesIcon} alt="officesIcon" />
          <text>Offices</text>
        </HeaderInformations>
        <LineHorizontal />
        <OfficeList>
          {offices.map(office => {
            return (
              <OfficesDiv >
                <DivInput>
                  <strong>Office Name: </strong>
                  <p>{office.name}</p>
                </DivInput>
                <DivInput>
                  <strong>City: </strong>
                  <p>{office.city}</p>
                </DivInput>
                <DivInput>
                  <strong>State: </strong>
                  <p>{office.state}</p>
                </DivInput>
                <DivInput>
                  <strong>ZIP: </strong>
                  <p>{office.postal_code}</p>
                </DivInput>
                <DivInput>
                  <strong>Phone: </strong>
                  <p>{office.phone}</p>
                </DivInput>
                <DivInput>
                  <strong>Cell: </strong>
                  <p>{office.mobile_phone}</p>
                </DivInput>
                <DivInput>
                  <div style={{
                    width: '100%', display: 'flex',
                    paddingLeft: '14px'
                  }}>
                    <MoreHorizon onClick={(event) => openPopover(event)}>
                      <MoreHorizIcon fontSize={'40px'} style={{
                        color: '#618095',
                        fontSize: '30px'
                      }}

                      />
                    </MoreHorizon>
                    <Popover
                      open={openPopOffice}
                      anchorEl={anchorElOffice}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <PopoverDiv onClick={() => handleEditOffice(office)}>
                        <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                        Edit
                      </PopoverDiv>
                      <PopoverDiv onClick={() => handleDeleteOffice(office.office_id)}>
                        <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                        Delete
                      </PopoverDiv>
                    </Popover>
                  </div>
                </DivInput>
              </OfficesDiv>
            );
          })}
        </OfficeList>
        <ButtonOfficesDiv>
          <SubmitButton onClick={() => setOpenModal(true)}>
            Add Office
          </SubmitButton>
        </ButtonOfficesDiv>
      </ContainerInformations >
    );
  }

  function permissionGroupForm() {
    return (
      <ContainerInformations
        style={!permissionGroupShow ? { display: 'none' } : null}
      >
        <HeaderInformations>
          <text>Permission Group</text>
        </HeaderInformations>
        <LineHorizontal />
        <DivForms>
          <DivInput>
            <Label>
              Title
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              onChange={e => setPermissionTitle(e.target.value)}
              placeholder="Pemission's group title"
            />
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput style={{ width: '100%' }}>
            <text>Employee</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyEmployee(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditEmployee(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlEmployee(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput style={{ width: '100%' }}>
            <text>Customer</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyCustomer(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditCustomer(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlCustomer(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          <DivInput style={{ width: '100%' }}>
            <text>Office</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyOffice(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditOffice(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlOffice(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Activities */}
          <DivInput style={{ width: '100%' }}>
            <text>Activity</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyActivity(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditActivity(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlActivity(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Activities */}
          <DivInput style={{ width: '100%' }}>
            <text>Activity</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyActivity(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditActivity(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlActivity(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Activities */}
          <DivInput style={{ width: '100%' }}>
            <text>Job</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyJob(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditJob(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlJob(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Control Points */}
          <DivInput style={{ width: '100%' }}>
            <text>Control Points</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyControlPoints(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditControlPoints(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlControlPoints(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**General Notes */}
          <DivInput style={{ width: '100%' }}>
            <text>General Notes</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyGeneralNotes(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditGeneralNotes(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlGeneralNotes(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Inventory */}
          <DivInput style={{ width: '100%' }}>
            <text>Inventory</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyInventory(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditInventory(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlInventory(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Job Photos */}
          <DivInput style={{ width: '100%' }}>
            <text>Job Photos</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyJobPhotos(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditJobPhotos(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlJobPhotos(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Workflow*/}
          <DivInput style={{ width: '100%' }}>
            <text>Workflow</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyWorkflow(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditWorkflow(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlWorkflow(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Document */}
          <DivInput style={{ width: '100%' }}>
            <text>Document</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Read Only</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setReadOnlyDocument(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Edit</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setEditDocument(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Full Control</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setFullControlDocument(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms>
          {/**Document */}
          <DivInput style={{ width: '100%' }}>
            <text>Dashboard</text>
            <div style={{ marginLeft: '30px' }}>
              <Label>Personal Dashboard</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setPersonalDashboard(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Company Dashboard</Label>
              <Checkbox
                style={{ marginRight: '30px' }}
                onChange={e => {
                  setCompanyDashboard(e.target.checked);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </DivInput>
        </DivForms>
        <DivForms style={{ justifyContent: 'flex-end' }}>
          <SubmitButton
            style={{ backgroundColor: '#618095' }}
            onClick={() => {
              setLoading(true);
              handleCreatePermission();
            }}
          >
            {loading ? <CircularProgress color="inherit" size={25} /> : 'Save'}
            {!loading && <img src={saveIcon} alt="saveIcon" />}
          </SubmitButton>
        </DivForms>
        <HeaderInformations style={{ marginTop: '60px' }}>
          <img src={officesIcon} alt="officesIcon" />
          <text>Permissions</text>
        </HeaderInformations>
        <LineHorizontal />
        <OfficeList>
          {permissionsGroup.map(permission => {
            return (
              <OfficesDiv>
                <DivInput>
                  <strong>Permission Name: </strong>
                  <p>{permission.name}</p>
                </DivInput>
                <DivInput>
                  <strong>Edit Employee: </strong>
                  <Checkbox
                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                    checked={permission.edit_employee}
                    disabled
                    style={{ width: '10px', marginLeft: '50px' }}
                  />
                </DivInput>
                <DivInput>
                  <strong>Full Control Employee: </strong>
                  <Checkbox
                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                    checked={permission.full_employee}
                    disabled
                    style={{ width: '10px', marginLeft: '40px' }}
                  />
                </DivInput>
                <DivInput>
                  <strong>Edit Office: </strong>
                  <Checkbox
                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                    checked={permission.edit_office}
                    disabled
                    style={{ width: '10px', marginLeft: '40px' }}
                  />
                </DivInput>
                <DivInput>
                  <strong>Full Control Office: </strong>
                  <Checkbox
                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                    checked={permission.full_office}
                    disabled
                    style={{ width: '10px', marginLeft: '40px' }}
                  />
                </DivInput>
                <DivInput>
                  <strong>Edit Customer: </strong>
                  <Checkbox
                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                    checked={permission.edit_customer}
                    disabled
                    style={{ width: '10px', marginLeft: '40px' }}
                  />
                </DivInput>
                <DivInput>
                  <strong>Full Control Customer: </strong>
                  <Checkbox
                    inputProps={{ 'aria-label': 'disabled checkbox' }}
                    checked={permission.full_customer}
                    disabled
                    style={{ width: '10px', marginLeft: '40px' }}
                  />
                </DivInput>
                <DivInputHorizontal>
                  <MoreHorizIcon onClick={(event) => handleClick(event, permission)}
                    style={{ color: '#618095' }} />
                  <Popover
                    open={openPop}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <PopoverDiv
                      onClick={() => {
                        setEditPermission(true);
                        // setEditablePermission(permission);
                        // setPermissionTitleEdit(permission.name);
                        // setCreateOfficeEdit(permission.create_office);
                        // setCreateEmployeeEdit(permission.create_employee);
                        // setPermissionId(permission.permission_groups_id);
                        setAnchorEl(null);
                      }}>
                      <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                      Edit
                    </PopoverDiv>
                    <PopoverDiv
                      onClick={() => {
                        handleDeletePermission(permission.permission_groups_id);
                      }}>
                      <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                      Delete
                    </PopoverDiv>
                  </Popover>
                </DivInputHorizontal>
              </OfficesDiv>
            );
          })}
        </OfficeList>
      </ContainerInformations>
    );
  }

  function logsForm() {
    let rows = [];
    for (let i = 1; i <= paginationLength; i++) {
      rows.push(i);
    }

    const columns = [
      {
        name: "employee_id",
        label: "Employee name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span onClick={() => console.log(tableMeta)}>
                {value.split('T')[0]}
              </span>
            )
          }
        },
      },
      {
        name: "ip",
        label: "IP Address",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "type",
        label: "Type",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "action",
        label: "Action",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'created_at',
        label: "Date",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span>
                {value.split('T')[0]}
              </span>
            )
          }
        },
      },
      {
        name: 'created_at',
        label: "Hour",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span>
                {value.split('T')[1].split(':')[0]
                  + ':' +
                  value.split('T')[1].split(':')[1].split(':')[0]
                }
              </span>
            )
          }
        },
      },
    ];

    const options = {
      filter: "false",
      selectableRows: "none",

    };

    return (
      <ContainerInformations style={!logsShow ? { display: 'none' } : null}>
        <HeaderInformations>
          {/* <img src={headQuarterIcon} alt="headQuarterIcon" /> */}
          <text>Logs</text>
        </HeaderInformations>
        <LineHorizontal />
        <MUIDataTable
          title={"Logs List"}
          data={logs}
          columns={columns}
          options={options}
        />
      </ContainerInformations>
    );
  }

  function jobsForm() {
    async function handleDeleteJobsSettings(id, route) {
      // setLoading(true);
      setShowToast({
        show: true,
        type: 'success',
        title: 'Deleting',
        description: 'Deleting data...',
      });
      await api
        .delete(`${route}/delete/${id}/:${ip}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        })
        .then(() => {
          // setLoading(false);
          setShowToast({
            show: true,
            type: 'success',
            title: 'Deleted successfully',
            description: `${route.replace('-', ' ')} successfully deleted.`,
          });
          if (route === 'loss-category') {
            let newLossCategories = lossCategories.filter(category => category.id !== id)
            setLossCategories(newLossCategories)
          }
          else if (route === 'loss-type') {
            let newLossTypes = lossTypes.filter(type => type.id !== id)
            setLossTypes(newLossTypes)
          }
          else if (route === 'source-of-loss') {
            let newSourcesofLoss = sourcesOfLoss.filter(source => source.id !== id)
            setSourcesOfLoss(newSourcesofLoss)
          }
          else if (route === 'job-size') {
            let newJobSizes = jobSizes.filter(jobSize => jobSize.id !== id)
            setJobSizes(newJobSizes)
          }
          else if (route === 'room-affected') {
            let newRoomsAffected = roomsAffected.filter(room => room.id !== id)
            setRoomsAffected(newRoomsAffected)
          }
          else if (route === 'environmental-code') {
            let newEnvironmentalCodes = environmentalCode.filter(environm => environm.id !== id)
            setEnvironmentalCode(newEnvironmentalCodes)
          }
          else if (route === 'division') {
            let newDivisions = divisions.filter(division => division.id !== id)
            setDivisions(newDivisions)
          }
          else if (route === 'job-status') {
            let newJobStatus = jobStatus.filter(job => job.id !== id)
            setJobStatus(newJobStatus)
          }
        })
        .catch(err => {
          console.error(err);
          setShowToast({
            show: true,
            type: 'error',
            title: 'Deleted error',
            description: 'error deleted loss category',
          });
        });
    }

    return (
      <ContainerInformations style={!jobShow ? { display: 'none' } : null}>
        <HeaderInformations>
          {/* <img src={headQuarterIcon} alt="headQuarterIcon" /> */}
          <text>Job Settings</text>
        </HeaderInformations>
        <LineHorizontal />
        <div className='row mt-5 ml-2 col-12'>
          <ListSettings>
            <text>
              <input
                onChange={e => setLossCategoryInput(e.target.value)}
                placeholder="Enter the loss category"
                value={lossCategoryInput}
              />
              <AddButton
                type='button'
                onClick={() => handleCreateLossCategory()}

              >
                Add
              </AddButton>
            </text>
            {lossCategories?.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    <div onClick={
                      () => handleDeleteJobsSettings(item.id, 'loss-category')
                    }>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
          <ListSettings>
            <text>
              <input
                onChange={e => setLossTypeInput(e.target.value)}
                placeholder="Enter the loss type"
                value={lossTypeInput}
              />
              <AddButton
                type='button'
                onClick={() => handleCreateLossType()}

              >
                Add
              </AddButton>
            </text>
            {lossTypes?.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    <div onClick={
                      () => handleDeleteJobsSettings(item.id, 'loss-type')
                    }>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
          <ListSettings>
            <text>
              <input
                onChange={e => setSourceOfLossInput(e.target.value)}
                placeholder="Enter the source of loss"
                value={sourceOfLossInput}
              />
              <AddButton
                type='button'
                onClick={() => handleCreateSourceOfLoss()}

              >
                Add
              </AddButton>
            </text>
            {sourcesOfLoss?.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    <div onClick={
                      () => handleDeleteJobsSettings(item.id, 'source-of-loss')
                    }>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
        </div>
        <div className='row mt-5 ml-2 col-12'>
          <ListSettings>
            <text>
              <input
                onChange={e => setJobSizeInput(e.target.value)}
                placeholder="Enter the job size"
                value={jobSizeInput}
              />
              <AddButton
                type='button'
                onClick={() => handleCreateJobSize()}

              >
                Add
              </AddButton>
            </text>
            {jobSizes?.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    <div onClick={
                      () => handleDeleteJobsSettings(item.id, 'job-size')
                    }>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
          <ListSettings>
            <text>
              <input
                onChange={e => setRoomsAffectedInput(e.target.value)}
                placeholder="Enter the rooms affected"
                value={roomsAffectedInput}
              />
              <AddButton
                type='button'
                onClick={() => handleCreateRoomAffected()}

              >
                Add
              </AddButton>
            </text>
            {roomsAffected?.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    <div onClick={
                      () => handleDeleteJobsSettings(item.id, 'room-affected')
                    }>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
          <ListSettings>
            <text>
              <input
                onChange={e => setEnvironmentalCodeInput(e.target.value)}
                placeholder="Enter the environmental code"
                value={environmentalCodeInput}
              />
              <AddButton
                type='button'
                onClick={() => handleEnvironmentalCode()}

              >
                Add
              </AddButton>
            </text>
            {environmentalCode?.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    <div onClick={
                      () => handleDeleteJobsSettings(item.id, 'environmental-code')
                    }>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
          <ListSettings>
            <text>
              <input
                onChange={e => setDivisionInput(e.target.value)}
                placeholder="Enter the division"
                value={divisionInput}
              />
              <AddButton
                type='button'
                onClick={() => handleDivision()}

              >
                Add
              </AddButton>
            </text>
            {divisions.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    <div onClick={
                      () => handleDeleteJobsSettings(item.id, 'division')
                    }>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
          <ListSettings>
            <text>
              <input
                onChange={e => setJobStatusInput(e.target.value)}
                placeholder="Enter the job status"
                value={jobStatusInput}
              />
              <AddButton
                type='button'
                onClick={() => handleJobStatus()}

              >
                Add
              </AddButton>
            </text>
            {jobStatus.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    {item.id !== 2 &&
                      item.id !== 4 &&
                      item.id !== 5 &&
                      item.id !== 6 &&
                      <div onClick={
                        () => handleDeleteJobsSettings(item.id, 'job-status')
                      }>
                        <ClearIcon />
                      </div>
                    }
                  </h3>
                </>
              )
            })}
          </ListSettings>
          {/* </DivTable> */}
          {/* </DivInput> */}
        </div>
      </ContainerInformations>
    )
  }

  function CustomerForm() {
    const columnsCustomerType = [
      {
        name: "name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'id',
        label: "Options",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <DeleteButton
                type="button"
                onClick={() => handleDeleteJobsSettings(value, 'customer-type')}
                disabled={loading}
              >
                delete
              </DeleteButton>
            )
          }
        },
      },
    ];

    const columnsCustomerTitle = [
      {
        name: "name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'id',
        label: "Options",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <DeleteButton
                type="button"
                onClick={
                  () => handleDeleteCustomerSettings(value, 'customer-title')
                }
                disabled={loading}
              >
                delete
              </DeleteButton>
            )
          }
        },
      },
    ];

    const options = {
      filter: "false",
      selectableRows: "none"
    };

    async function handleDeleteCustomerSettings(id, route) {
      // setLoading(true);
      setShowToast({
        show: true,
        type: 'success',
        title: 'Deleting',
        description: 'deleting data...',
      });
      await api
        .delete(`${route}/delete/${id}/:${ip}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        })
        .then(() => {
          // setLoading(false);
          if (route === "customer-type") {
            let newCustomerTypes = customerTypes.filter(type => type.id !== id)
            setCustomerTypes(newCustomerTypes)
          }
          else if (route === "customer-title") {
            let newCustomerTitle = customerTitles.filter(title => title.id !== id)
            setCustomerTitles(newCustomerTitle)
          }
          setShowToast({
            show: true,
            type: 'success',
            title: 'Deleted successfully',
            description: 'Customer type successfully deleted',
          });
          // location.reload();
        })
        .catch(err => {
          setShowToast({
            show: true,
            type: 'error',
            title: 'Deleted error',
            description: err.response.data.message,
          });
          // setLoading(false);
        });
    }

    return (
      <ContainerInformations style={!customerShow ? { display: 'none' } : null}>
        <HeaderInformations>
          {/* <img src={headQuarterIcon} alt="headQuarterIcon" /> */}
          <text>Customer Settings</text>
        </HeaderInformations>
        <LineHorizontal />
        <ListSettingsWrapper >
          <ListSettings >
            <text>
              <input
                onChange={e => setCustomerTypeInput(e.target.value)}
                placeholder="Enter the customer type"
                value={customerTypeInput}
              />
              <AddButton
                type='button'
                onClick={() => handleCreateCustomerType()}
              // disabled={loading}
              >
                Add
              </AddButton>
            </text>
            {/* </div> */}
            {customerTypes?.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    {item.name !== "Carrier" &
                      item.name !== "Contractor" &
                      item.name !== "TPA (Third Party Admin)" ?
                      <div onClick={() => handleDeleteCustomerSettings(item.id, 'customer-type')}>
                        <ClearIcon />
                      </div>
                      : null
                    }
                  </h3>
                </>
              )
            })}
          </ListSettings>
          <ListSettings>
            <text>
              <input
                onChange={e => setTitleInput(e.target.value)}
                placeholder="Enter the customer title"
                value={titleInput}
              />
              <AddButton
                type='button'
                onClick={() => handleCreateCustomerTitle()}
              // disabled={loading}
              >
                Add
              </AddButton>
            </text>
            {customerTitles?.map((item) => {
              return (
                <>
                  <h3 key={item.id}>
                    {item.name}
                    <div onClick={() => handleDeleteCustomerSettings(item.id, 'customer-title')}>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
        </ListSettingsWrapper>
      </ContainerInformations >
    )
  }

  function jobTitleForm() {
    // const columnsJobTitle = [
    //   {
    //     name: "name",
    //     label: "Job Title",
    //     options: {
    //       filter: true,
    //       sort: true,
    //     },
    //   },
    //   {
    //     name: 'id',
    //     label: "Options",
    //     options: {
    //       filter: true,
    //       sort: false,
    //       customBodyRender: (value, tableMeta, updateValue) => {
    //         return (
    //           <DeleteButton
    //             type="button"
    //             onClick={() => handleDeleteJobTitle(value, 'customer-type')}
    //             disabled={loading}
    //           >
    //             delete
    //           </DeleteButton>
    //         )
    //       }
    //     },
    //   },
    // ];


    // const options = {
    //   filter: "false",
    //   selectableRows: "none"
    // };

    async function handleDeleteJobTitle(id, route) {
      // setLoading(true);
      setShowToast({
        show: true,
        type: 'success',
        title: 'Deleting',
        description: 'Deleting data...',
      });
      await api
        .delete(`${route}/delete/${id}/${ip}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        })
        .then(() => {
          // setLoading(false);
          setShowToast({
            show: true,
            type: 'success',
            title: 'Deleted successfully',
            description: 'Job title successfully deleted.',
          });
          let newJobTitles = jobTitles.filter(title => title.job_title_id !== id)
          setJobTitles(newJobTitles)
          // location.reload();
        })
        .catch(err => {
          console.error(err);
          setShowToast({
            show: true,
            type: 'error',
            title: 'Deleted error',
            description: 'error deleted loss category',
          });
          // setLoading(false);
        });
    }

    return (
      <ContainerInformations style={!jobTitleShow ? { display: 'none' } : null}>
        <HeaderInformations>
          {/* <img src={headQuarterIcon} alt="headQuarterIcon" /> */}
          <text>Job Title</text>
        </HeaderInformations>
        <LineHorizontal />
        {/* <DivForms>
          <DivInput>
            <Label>
              Job Title
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <input
              onChange={e => setJobTitleInput(e.target.value)}
              placeholder="Enter the job title"
              value={jobTitleInput}
            />
          </DivInput>
          <AddButton
            type='button'
            onClick={() => handleCreateJobTitle()}
            disabled={loading}
          >
            Add
          </AddButton>
        </DivForms> */}
        <div className='row mt-5 ml-2 col-12'>
          <ListSettings>
            <text>
              <input
                onChange={e => setJobTitleInput(e.target.value)}
                placeholder="Enter the job title"
                value={jobTitleInput}
              />
              <AddButton
                type='button'
                onClick={() => handleCreateJobTitle()}
              // disabled={loading}
              >
                Add
              </AddButton>
            </text>
            {jobTitles?.map((item) => {
              return (
                <>
                  <h3 key={item.job_title_id}>
                    {item.value}
                    <div onClick={
                      () => handleDeleteJobTitle(item.job_title_id, 'job-title')
                    }>
                      <ClearIcon />
                    </div>
                  </h3>
                </>
              )
            })}
          </ListSettings>
        </div>
      </ContainerInformations>
    )
  }

  async function getLogs() {
    setLoadingInformations(true);
    const response = await api.get('/logs', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setLogs(response.data.reverse());
    setLoadingInformations(false);
  }

  async function getOffices() {
    setLoadingInformations(true);
    const response = await api.get('/office', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setOffices([]);
    response.data.map(office => {
      if (office.head_quarter) {
        return setHeadQuarter(office);
      }
      return setOffices(prevState => [...prevState, office]);
    });
    setLoadingInformations(false);
  }

  async function getClients() {
    setLoadingInformations(true);
    const response = await api.get('/client', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setClients(response.data);
    setLoadingInformations(false);
  }

  async function getJobStatus() {
    setLoadingInformations(true);
    const response = await api.get('/job-status', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    ;
    setJobStatus(response.data);
    setLoadingInformations(false);
  }

  async function getPermissonsGroup() {
    setLoadingInformations(true);
    const response = await api.get('/permissionGroup', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setPermissionsGroup(response.data);
    setLoadingInformations(false);
  }

  async function getCustomerTitles() {
    setLoadingInformations(true);
    const response = await api.get('/customer-title', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setCustomerTitles(response.data);
    setLoadingInformations(false);
  }

  async function getCustomerTypes() {
    setLoadingInformations(true);
    const response = await api.get('/customer-type', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setCustomerTypes(response.data);
    setLoadingInformations(false);
  }

  async function getLossCategories() {
    setLoadingInformations(true);
    const response = await api.get('/loss-category', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setLossCategories(response.data);
    setLoadingInformations(false);
  }

  async function getLossTypes() {
    setLoadingInformations(true);
    const response = await api.get('/loss-type', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setLossTypes(response.data);
    setLoadingInformations(false);
  }

  async function getSourcesOfLoss() {
    setLoadingInformations(true);
    const response = await api.get('/source-of-loss', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setSourcesOfLoss(response.data);
    setLoadingInformations(false);
  }

  async function getJobSizes() {
    setLoadingInformations(true);
    const response = await api.get('/job-size', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setJobSizes(response.data);
    setLoadingInformations(false);
  }

  async function getJobTitle() {
    setLoadingInformations(true);
    const response = await api.get('/job-title', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setJobTitles(response.data);
    setLoadingInformations(false);
  }

  async function getRoomsAffected() {
    setLoadingInformations(true);
    const response = await api.get('/room-affected', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setRoomsAffected(response.data);
    setLoadingInformations(false);
  }

  async function getEnvironmentalCode() {
    setLoadingInformations(true);
    const response = await api.get('/environmental-code', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEnvironmentalCode(response.data);
    setLoadingInformations(false);
  }

  async function getDivisions() {
    setLoadingInformations(true);
    const response = await api.get('/division', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setDivisions(response.data);
    setLoadingInformations(false);
  }

  useEffect(() => {
    getOffices();
    getClients();
    getJobStatus();
    getPermissonsGroup();
    getIP();
    getLogs();
    getLossCategories();
    getLossTypes();
    getSourcesOfLoss();
    getJobSizes();
    getRoomsAffected();
    getEnvironmentalCode();
    getCustomerTitles();
    getCustomerTypes();
    getDivisions();
    getJobTitle();
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Z-ODAYUDEjetAqP-tuuH4m1w5Xx2RgU&libraries=places`,
      () => handleScriptLoad(autoCompleteRef)
    );
  }, [openModal]);

  return (
    <>
      <Container >
        {openModal || editPermission ? (
          <DarkContainerModal
            onClick={() => {
              setOpenModal(false);
              setEditPermission(false);
              setEditOffice(null);
            }}
          />
        ) : (
          ''
        )}
        <SideBar />
        <ContainerColumn >
          <Header />
          <ContainerBackground >
            {showToast.show && (
              <ToastContainer messages={showToast} show={() => handleToast()} />
            )}
            <ContainerForms >
              <ContainerHeader >
                <div>
                  <LineVertical />
                  <text>General Settings</text>
                </div>
              </ContainerHeader>
              <NavTab >
                <li
                  onClick={() => {
                    setCompanyProfileShow(true);
                    setPermissionGroupShow(false);
                    setLogsShow(false);
                    setCustomerShow(false);
                    setJobShow(false);
                    setJobTitleShow(false);
                    setJobShow(false);
                  }}
                  style={
                    companyProfileShow ? { backgroundColor: '#618095' } : null
                  }
                >
                  <a href="#companyProfile">Company Profile</a>
                </li>
                <li
                  onClick={() => {
                    setCompanyProfileShow(false);
                    setPermissionGroupShow(true);
                    setLogsShow(false);
                    setCustomerShow(false);
                    setJobShow(false);
                    setJobTitleShow(false);
                    setJobShow(false);
                  }}
                  style={
                    permissionGroupShow ? { backgroundColor: '#618095' } : null
                  }
                >
                  <a href="#pemissionGroup">Permission Group</a>
                </li>
                <li
                  onClick={() => {
                    setCompanyProfileShow(false);
                    setPermissionGroupShow(false);
                    setLogsShow(true);
                    setJobTitleShow(false);
                    setCustomerShow(false);
                    setJobShow(false);
                  }}
                  style={logsShow ? { backgroundColor: '#618095' } : null}
                >
                  <a href="#logs">Logs</a>
                </li>
                <li
                  onClick={() => {
                    setCompanyProfileShow(false);
                    setPermissionGroupShow(false);
                    setLogsShow(false);
                    setCustomerShow(true);
                    setJobTitleShow(false);
                    setJobShow(false);
                  }}
                  style={customerShow ? { backgroundColor: '#618095' } : null}
                >
                  <a href="#customer">Customer</a>
                </li>
                <li
                  onClick={() => {
                    setCompanyProfileShow(false);
                    setPermissionGroupShow(false);
                    setLogsShow(false);
                    setCustomerShow(false);
                    setJobTitleShow(true);
                    setJobShow(false);
                  }}
                  style={jobTitleShow ? { backgroundColor: '#618095' } : null}
                >
                  <a href="#employees">Employees</a>
                </li>
                <li
                  onClick={() => {
                    setCompanyProfileShow(false);
                    setPermissionGroupShow(false);
                    setLogsShow(false);
                    setCustomerShow(false);
                    setJobTitleShow(false);
                    setJobShow(true);
                  }}
                  style={jobShow ? { backgroundColor: '#618095' } : null}
                >
                  <a href="#jobssettings">Jobs</a>
                </li>
              </NavTab>
              {companyProfileForm()}
              {permissionGroupForm()}
              {logsForm()}
              {CustomerForm()}
              {jobTitleForm()}
              {jobsForm()}
            </ContainerForms>
          </ContainerBackground>
        </ContainerColumn>
        {editPermission ? permissionModal(editablePermission) : ''}
        {openModal ? officeModal(editOffice && editOffice) : ''}
      </Container>
    </>
  );
}

export default Settings;
