import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import MUIDataTable from "mui-datatables";

import Axios from 'axios';
import * as Yup from 'yup';
import { Formik, Field, Form, useFormik } from 'formik';

import ProductDetails from '../../assets/icons/ProductDetails.svg'

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import ListIcon from '@material-ui/icons/List';
import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'

import api from '../../services/api';

import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  ContainerInformations,
  HeaderInformations,
  LineVertical,
  LineHorizontal,
  DivInput,
  Label,
  SubmitButton,
  DarkContainer,
  StepText,
  NextStepContainer,
  NavTab,
  FullDiv,
  Half2Div,
  Half2Div2
} from './styles';

import getValidationErros from '../../utils/getValidationErrors';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import SuccessPopUp from '../../components/SuccessPopUp';

import ToastContainer from '../../components/ToastContainer';

function index() {
  const [tabInformation, setTabInformation] = useState('inventoryDetails')
  const [inventoriesItems, setInventoriesItems] = useState([])

  const [errors, setErrors] = useState({});

  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [loading, setLoading] = useState(false)

  const inventoryResponsibleArray = []
  const inventoryManagerArray = []
  const primaryOfficeArray = []



  const columns = [
    {
      name: "inventory_name",
      label: "Inventory Name",
      options: {
        filter: false,
        viewColumns: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     insertIcon(tableMeta.rowData[0])
        //     // <span>{`${tableMeta.rowData[1]} `}</span>
        //   )
        // }
      },
    },
    {
      name: "room",
      label: "Room",
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <ActivityNameDiv onClick={() => goEdit(tableMeta.rowData[7])}>
        //       <span>{`${tableMeta.rowData[1]} `}</span>
        //     </ActivityNameDiv>
        //   )
        // }
      },
    },
    {
      name: "item_name",
      label: "Item Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <div style={{ width: '100px' }}>
        //       <span>{`${tableMeta?.rowData[3]} `}</span>
        //     </div>
        //   )
        // }
      },
    },
    {
      name: "condition",
      label: "Condition",
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <span>{`${tableMeta?.rowData[4] && tableMeta?.rowData[4].split('T')[0]} `}</span>
        //   )
        // }
      },
    },
    {
      name: "building",
      label: "Building",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <div style={{
        //       display: 'flex',
        //       justifyContent: 'center', backgroundColor: setBackGroundColor(value)
        //     }}>
        //       <span>{`${value} `}</span>
        //     </div>
        //   )
        // }
      },
    },
    {
      name: "photos",
      label: "Photos",
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <div style={{
        //       display: 'flex',
        //       width: '120px', justifyContent: 'center'
        //     }}>
        //       <span>{`${tableMeta?.rowData[6]} `}</span>
        //     </div>
        //   )
        // }
      },
    },
    {
      name: 'inventory_id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {/* <PopoverDiv >
                  <img height={15} src={ViewDetails} style={{ marginRight: '10px' }}></img>
                    View Details
                  </PopoverDiv> */}
                <PopoverDiv onClick={() => goEdit(activityId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                    Edit
                  </PopoverDiv>
                <PopoverDiv onClick={() => removeActivity()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                    Delete
                  </PopoverDiv>
              </Popover>
            </div>
            // <EditButton onClick={() => goEdit(value)} type="button">
            // </EditButton>
          )
        }
      },
    },
  ];

  const options = {
    filter: "false",
    print: "false",
    search: "false",
    download: "false",
    viewColumns: "false",
    selectableRows: "none",
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(d => {
        // activityId(prevState => [...prevState, customers[d.dataIndex].costumer_id])
        setOpenPopUp({ show: true })
      })
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    handleClick(event)
    setActivityId(value)

  }

  const handleClose = () => {
    setAnchorEl(null);
  };



  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  const formik = useFormik({
    initialValues: {
      job_number: '',
      product_type: '',
      sub_type: '',
      product_name: '',
      value: '',
      condition: '',
      sameAsCustomerAddress: false,
      product_address: '',
      pickUp_address: '',
      room: '',
      location: '',
      description: '',
    },
    onSubmit: values => {
      // handleCreateJob(values);
    },
  });

  function inventoryDetails() {
    return (
      <>

        <ContainerInformations>
          <HeaderInformations>
            <div>
              <img height={15} src={ProductDetails} style={{ marginRight: '10px' }}></img>
              <text>Inventory Details</text>
            </div>
          </HeaderInformations>
          <LineHorizontal />
          <form onSubmit={formik.handleSubmit}>
            <FullDiv >
              <DivInput >
                <Label style={errors.job_number ? { color: 'red' } : null}>
                  Job Number
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_number"
                  placeholder="Job Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.job_number}
                  style={
                    errors.job_number
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </FullDiv>
            <Half2Div>
              <DivInput >
                <Label style={errors.inventory_responsible ? { color: 'red' } : null}>
                  Inventory Responsible
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="inventory_responsible"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.inventory_responsible}
                  style={
                    errors.inventory_responsible
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {inventoryResponsibleArray.map(item => {
                    return (
                      <option key={item.office_id} value={item.office_id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.inventory_manager ? { color: 'red' } : null}>
                  Inventory Manager
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="inventory_manager"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.inventory_manager}
                  style={
                    errors.inventory_manager
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {inventoryManagerArray.map(item => {
                    return (
                      <option key={item.office_id} value={item.office_id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
            </Half2Div>
            <Half2Div >
              <DivInput >
                <Label style={errors.product_name ? { color: 'red' } : null}>
                  Product Name
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="product_name"
                  placeholder="Product Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.product_name}
                  style={
                    errors.product_name
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </Half2Div>
            <FullDiv style={{ flexDirection: 'column' }}>
              <Label style={{ marginBottom: '10px' }}>
                Description:
                    </Label>
              <TextareaAutosize
                style={errors.description ?
                  {
                    backgroundColor: '#ececec', border: 'none',
                    padding: '10px', color: 'red'
                  } :
                  {
                    backgroundColor: '#ececec', border: 'none',
                    color: '#495057', padding: '10px',
                  }
                }
                rows={5}
                aria-label="maximum height"
                placeholder="Enter item description..."
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
            </FullDiv>
            <Half2Div>
              <DivInput >
                <Label style={errors.primary_office ? { color: 'red' } : null}>
                  Primary Office Storage
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="primary_office"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.primary_office}
                  style={
                    errors.primary_office
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {primaryOfficeArray.map(item => {
                    return (
                      <option key={item.office_id} value={item.office_id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
            </Half2Div>
          </form>
        </ContainerInformations>

      </>
    )
  }


  function inventoryItems() {
    return (
      <>
        <HeaderInformations >
          <div style={{
            display: 'flex', alignItems: 'inherit',
            fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
            fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
          }}>
            <ListIcon style={{ color: "#6096BA", fontSize: '2rem', marginRight: 10 }} />
              Items List
          </div>
          <Link to="/itemregistration">
            <button type="button">New Item</button>
          </Link>
        </HeaderInformations>
        <MUIDataTable
          data={inventoriesItems}
          columns={columns}
          options={options}

        />
      </>
    )
  }



  useEffect(() => {

  }, []);

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Inventory Viewer</text>
              </div>
            </ContainerHeader>
            <Formik>
              <Form >
                <NavTab>
                  <li
                    onClick={() => {
                      setTabInformation('inventoryDetails');
                    }}
                    style={
                      tabInformation === 'inventoryDetails' ? { backgroundColor: '#618095' } : null
                    }
                  >
                    <a>1. Inventory Details</a>
                  </li>
                  <li
                    onClick={() => {
                      setTabInformation('inventoryItems');
                    }}
                    style={
                      tabInformation === 'inventoryItems' ? {
                        marginLeft: '10px',
                        backgroundColor: '#618095',
                        marginLeft: '10px'
                      } : { marginLeft: '10px' }
                    }
                  >
                    <a>2. Inventory Items</a>
                  </li>

                </NavTab>
                {tabInformation === 'inventoryDetails' && inventoryDetails()}
                {tabInformation === 'inventoryItems' && inventoryItems()}
              </Form>
            </Formik>
          </ ContainerForms>
        </ ContainerBackground>
      </ ContainerColumn>
    </Container>
  );
}

export default index;