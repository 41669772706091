import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Formik, Field, Form, useFormik } from 'formik';
import * as Yup from 'yup';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PermMediaIcon from '@material-ui/icons/PermMedia';

import ToastContainer from '../../../../components/ToastContainer';
import SuccessPopUp from '../../../../components/SuccessPopUp';
import TransitionsModal from '../../../../components/TransitionsModal'


import getValidationErros from '../../../../utils/getValidationErrors';

import ClearIcon from '@material-ui/icons/Clear';

import api from '../../../../services/api';

import AddPhotos from '../../../../assets/icons/AddPhotos.svg'

import {
  ContainerInformations,
  HeaderInformations,
  LineHorizontal,
  DivInput,
  Label,
  Half3Div,
  Half2Div,
  SubmitButton,
  FullDiv,
  StoragesDiv,
  DivPicture,
  UploadButton,
  DarkContainer,
  GalleryDiv,
  DeleteButton,
  DivButtons,
  DeleteImageButton,
  ImageFolderDiv,
  ImageDiv
} from './styles';

const index = () => {
  const jobId = localStorage.getItem('jobId')
  const [errors, setErrors] = useState({})
  const [jobPhotos, setJobPhotos] = useState({})
  const [ip, setIp] = useState('0.0.0.0')
  const [roomsAffected, setRoomsAffected] = useState([])
  const [roomName, setRoomName] = useState('')
  const [rooms, setRooms] = useState([])
  const [employees, setEmployees] = useState([])
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [photoTitle, setPhotoTitle] = useState('')
  const [photoDescription, setPhotoDescription] = useState('')
  const [photoInfo, setPhotoInfo] = useState({})

  const [openModal, setOpenModal] = useState(false)
  const [openModalDescription, setOpenModalDescription] = useState(false)
  const [photo, setPhoto] = useState({})
  const [editPhoto, setEditPhoto] = useState(false)
  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });

  async function getRooms() {
    const response = await api.get('/room-affected', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    // 
    setRoomsAffected(response.data);
  }

  async function getJobPhotosInformation() {
    try {
      const response = await api.get(`/job-photos/${jobId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      setJobPhotos(response.data);
      setRooms(JSON.parse(response.data.rooms));

    }
    catch {
      return
    }
  }

  async function getEmployees() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });

    setEmployees(response.data);
  }

  async function getPhotos() {
    const response = await api.get(`/storage-job-photos/${jobId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    let filteredPhotos = response.data.map(photo => {
      return {
        ...photo,
        photo: photo.photos
      }

    })
    setImages(filteredPhotos)
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    getIP()
    getRooms()
    getEmployees()
    getJobPhotosInformation()
    getPhotos()
  }, [])

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  async function handleSubmit(values) {
    values.rooms = rooms.length == 0 ? '' : rooms
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        date: Yup.date().required('date is required'),
        employee: Yup.string().required('Employee is required'),
        rooms: Yup.array().required('rooms is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });
      if (jobPhotos && jobPhotos.employee) {
        submitUpdateJobPhoto(values);
      } else {
        submitCreateJobPhoto(values)
      }
    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }

  async function submitCreateJobPhoto(values) {
    setLoading(true);
    const data = new FormData();
    data.append('job_id', jobId)
    data.append('date', values.date)
    data.append('hour', values.hour)
    data.append('rooms', JSON.stringify(values.rooms))
    data.append('employee', values.employee)
    data.append('description', values.description)
    data.append('ip', ip)

    api
      .post(
        '/job-photos', data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        setJobPhotos(res.data)
        setShowToast({
          show: true,
          type: 'success',
          title: 'Success creating',
          description: 'Success creating job photos',
        });
        setLoading(false);
        // window.location.href = "${process.env.REACT_APP_WEB_URL}/jobs/viewer/";
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error creating',
          description: 'Error creating customer',
        });
      });
  }

  async function submitUpdateJobPhoto(values) {
    setLoading(true);
    const data = new FormData();
    data.append('id', jobPhotos.id)
    data.append('ip', ip)
    data.append('date', values.date)
    data.append('hour', values.hour)
    data.append('rooms', JSON.stringify(values.rooms))
    data.append('employee', values.employee)
    data.append('description', values.description)

    api
      .patch(
        '/job-photos', data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        //setOpenPopUp({ show: true });
        setLoading(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Succes updating',
          description: 'Success updating job photos.',
        });
        // window.location.href = "${process.env.REACT_APP_WEB_URL}/jobs/viewer/";
      })
      .catch(err => {
        setLoading(false);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error updating',
          description: 'Error updating job photos.',
        });
      });
  }

  async function deleteJobPhotos() {
    setLoadingDelete(true);
    api
      .delete(
        `/job-photos/delete/${jobPhotos.id}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        // window.location.reload()
        setJobPhotos([])
        setRooms([])
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Job photos successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting job photos',
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      date: jobPhotos.date || '',
      hour: jobPhotos.hour || '',
      rooms: jobPhotos.rooms || '',
      description: jobPhotos.description || '',
      employee: jobPhotos.employee || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const handleRoomChange = (room_id) => {
    let exist = rooms.find(room => room.room_id == room_id)
    if (!exist) {
      let room = roomsAffected.find(room => room.id == room_id)
      setRooms(prev => ([...prev, { name: room.name, room_id: room.id }]))
    }
    else
      return
  }

  const removeRoom = (room_id) => {
    let room = rooms.filter((room, index) => room.room_id !== room_id)
    setRooms(room)
  }

  const deleteImages = (photoId) => {
    setLoadingDelete(true)
    api
      .delete(
        `/storage-job-photos/delete/${photoId}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Photo successfully deleted',
        });
        let newImages = images.filter(image => image.photo_id !== photoId)
        setImages(newImages)
        setOpenModalDescription(false)
      })
      .catch(err => {
        // setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting job photo',
        });
      });

  }


  const contentPhotos = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        height: '500px', fontSize: '20px',
      }}>
        <div style={{ width: '90%', overflowY: 'auto' }}>
          <div style={{
            color: '#618095', backgroundColor: '#E6EBEE',
            height: '50px', marginTop: '20px', display: 'flex',
            justifyContent: 'center', alignItems: 'center'
          }}>
            {loadingDelete ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              `Job Photos Gallery: ${roomName}`
            )}
          </div>
          <div style={{
            display: 'flex', flexWrap: 'wrap',
            justifyContent: 'flex-start', alignItems: 'center', marginTop: 5
          }}>
            {
              // images.length > 0 ?
              images.map((image) => {
                if (image.room == roomName) {
                  return (
                    <div
                      style={{
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      key={image.photo_id}
                    >
                      <ImageDiv
                        key={image.photo_id}
                      >
                        {image.photo && Array.from(image.photo).map((item, index) => {
                          return (
                            <img
                              style={{
                                margin: 5,
                                border: '1px solid #618095',
                                borderRadius: '10px', boxShadow: '3px 3px 5px rgb(97, 128, 149)'
                              }}
                              onClick={() => {
                                setPhoto(item)
                                setPhotoInfo({
                                  title: image.title,
                                  description: image.description,
                                  id: image.photo_id
                                })
                                setEditPhoto(true)
                                setOpenModalDescription(true)
                              }}
                              src={typeof item === 'object' ? URL.createObjectURL(item) : item}
                              alt="photo"
                              width={200}
                              height={200}
                              key={image.photo_id}
                            />
                          );
                        })}
                      </ImageDiv>
                    </div>
                  )
                }
              })
              // :
              // <div style={{ width: '100%', textAlign: 'center' }}>There are no photos yet.</div>
            }


            <UploadButton htmlFor="upload-photo">
              <img src={AddPhotos} />
              Add photos
            </UploadButton>
            <Field
              multiple
              name="avatar"
              type="file"
              className="overflow-hidden"
              id="upload-photo"
              style={{ opacity: 0, zIndex: -1 }}
              onChange={event => {
                const photos = event.currentTarget.files
                setPhoto(photos)
                setOpenModalDescription(true)
              }}
              onBlur={formik.handleBlur}
            />

          </div>
        </div>
      </div>
    )
  }

  const handleOpenModalDescription = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        fontSize: '20px', padding: 10
      }}>
        {showToast.show && (
          <ToastContainer messages={showToast} show={() => handleToast()} />
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div></div>
          <h3>Photo details</h3>
          <DeleteImageButton
            onClick={() =>
            (
              setOpenModalDescription(false),
              setPhotoTitle(''),
              setPhotoDescription(''),
              setEditPhoto(false)
            )}
          >
            <ClearIcon
              style={{ width: 20 }} />
          </DeleteImageButton>
        </div>
        <img
          style={{
            margin: 5, border: '1px solid #618095',
            borderRadius: '10px', boxShadow: '3px 3px 5px rgb(97, 128, 149)'
          }}
          src={typeof photo === 'object' ? URL.createObjectURL(photo[0]) : photo}
          alt="photo"
          width={256}
          height={256}
        />
        <FullDiv>
          <DivInput style={{ marginTop: '10px' }}>
            <Label style={{ marginBottom: '5px' }}>
              Title:
            </Label>
            <input
              style={{
                backgroundColor: '#ececec', border: 'none',
                color: '#495057', padding: '10px'
              }}
              aria-label="maximum height"
              placeholder="Enter the title..."
              name="description"
              onChange={(e) => setPhotoTitle(e.target.value)}
              onBlur={formik.handleBlur}
              value={photoInfo.title}
            />
          </DivInput>
          <DivInput>
            <Label style={{ marginBottom: '10px' }}>
              Description:
            </Label>
            <TextareaAutosize
              style={{
                backgroundColor: '#ececec', border: 'none',
              }
              }
              rows={4}
              aria-label="maximum height"
              placeholder="Enter the description..."
              name="description"
              onChange={(e) => setPhotoDescription(e.target.value)}
              onBlur={formik.handleBlur}
              value={photoInfo.description}
            />
          </DivInput>
        </FullDiv>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          {editPhoto ?
            <DeleteButton
              type="button"
              onClick={() => deleteImages(photoInfo.id)}
              style={{ alignSelf: 'flex-start' }}>
              {loadingDelete ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                'Delete'
              )}
            </DeleteButton>
            :
            <SubmitButton
              onClick={() => handleSavePhoto()}
              type="button">
              {
                loading ?
                  (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Save'
                  )
              }
            </SubmitButton>
          }
        </div>
      </div>
    )

  }

  const handleSavePhoto = () => {
    setLoading(true);
    const data = new FormData();
    data.append('job_id', jobId)
    data.append('title', photoTitle)
    data.append('room', roomName)
    data.append('description', photoDescription)
    data.append('ip', ip)

    for (let i = 0; i < photo.length; i++) {
      data.append('photos[]', photo[i]);
    }
    api
      .post(
        '/storage-job-photos', data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        setLoading(false);
        setImages(prevState => [...prevState,
        { photo_id: res.data.photo_id, room: roomName, title: photoTitle, description: photoDescription, photo: photo }])
        setShowToast({
          show: true,
          type: 'success',
          title: 'Success saving',
          description: 'Success saving photo.',
        })
        setOpenModalDescription(false)
        setPhotoTitle('')
        setPhotoDescription('')
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error creating',
          description: 'Error saving photo.',
        });
      });
  }

  return (
    <Formik>
      <Form onSubmit={formik.handleSubmit}>
        <ContainerInformations>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} content={contentPhotos()} />}
          {openModalDescription && <TransitionsModal openModal={true}
            closeModal={setOpenModalDescription} content={handleOpenModalDescription()}
            widthProp="50%" />}

          <HeaderInformations style={{ marginTop: '20px' }}>
            <div style={{
              display: 'flex', alignItems: 'inherit',
              fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
              fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
            }}>
              Job Photos Information
            </div>
          </HeaderInformations>
          <div style={{
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
            borderRadius: '4px', padding: '5px', marginTop: '20px'
          }}>
            <Half3Div style={{ marginTop: '20px' }}>
              <DivInput >
                <Label >
                  Date
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date.split('T')[0]}
                  style={
                    errors.date
                      ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                      : { textAlign: 'center', padding: 'inherit' }
                  }
                />
              </DivInput>
              <DivInput >
                <Label >
                  Hour
                </Label>
                <input
                  name="hour"
                  type="time"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.hour}
                  style={{ textAlign: 'center', padding: 'inherit' }}
                />
              </DivInput>
              <DivInput >
                <Label style={errors.employee ? { color: 'red' } : null}>
                  Employee
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="employee"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employee}
                  style={
                    errors.employee
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {employees.map(employee => {
                    return (
                      <option key={employee.employee_id} value={employee.employee_id}>
                        {`${employee.first_name} ${employee.last_name}`}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
            </Half3Div>
            <Half2Div>
              <DivInput >
                <Label style={errors.rooms ? { color: 'red' } : null}>
                  Rooms Affected
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="rooms"
                  onChange={(e) => handleRoomChange(e.target.value)}
                  style={
                    errors.rooms
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {
                    roomsAffected.map(item => {
                      return (
                        <option
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      )
                    })
                  }
                </select>
              </DivInput>
              <DivInput style={{
                border: '1px solid #ececec', flexDirection: 'row',
                flexWrap: 'wrap', minHeight: 42, maxHeight: 84,
                alignItems: 'center', overflowY: 'auto',
                fontSize: '0.8125rem', borderRadius: 10
              }}>
                <Label style={{ width: '100%', marginLeft: 5 }}>
                  Selected Rooms:
                </Label>
                {rooms.map((room, i) => {
                  return (
                    <StoragesDiv key={room.room_id}>
                      {room.name}
                      <div style={{
                        width: 20, backgroundColor: '#E0E0E0',
                        borderRadius: 10
                      }}>
                        <ClearIcon
                          style={{ width: 20 }}
                          onClick={() => removeRoom(room.room_id)} />
                      </div>
                    </StoragesDiv>
                  )
                })}
              </DivInput>
            </Half2Div>
            <FullDiv>
              <DivInput>
                <Label style={{ marginBottom: '10px' }}>
                  Description:
                </Label>
                <TextareaAutosize
                  style={
                    errors.template_name ? {
                      border: '1px solid red', backgroundColor: '#ececec', border: 'none',
                      color: '#495057', padding: '10px'
                    } : {
                      backgroundColor: '#ececec', border: 'none',
                      color: '#495057', padding: '10px'
                    }
                  }
                  rows={5}
                  aria-label="maximum height"
                  placeholder="Enter the description..."
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                />
              </DivInput>
            </FullDiv>
            <ImageFolderDiv>
              {rooms.map(room => {
                return (
                  <GalleryDiv
                    onClick={() => (setOpenModal(true),
                      setRoomName(room.name))
                    }
                  >
                    <PermMediaIcon style={{ width: "50px" }} />
                    {room.name}
                  </GalleryDiv>
                )
              })}
            </ImageFolderDiv>
          </div>
        </ContainerInformations>
        <LineHorizontal style={{ marginBottom: '30px' }} />
        <DivButtons>
          {jobPhotos.id && (
            <DeleteButton
              type="button"
              onClick={() => deleteJobPhotos()}
              style={{ alignSelf: 'flex-start' }}>
              {loadingDelete ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                'Delete'
              )}
            </DeleteButton>
          )}
          {jobPhotos.id ?
            <SubmitButton type="submit">
              {
                loading ?
                  (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Edit'
                  )
              }
            </SubmitButton>
            :
            <SubmitButton type="submit">
              {
                loading ?
                  (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Save'
                  )
              }
            </SubmitButton>
          }

        </DivButtons>
      </Form>
    </Formik >
  );
}

export default index;
