import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';

import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import QRCode from 'qrcode.react';

import { useParams } from "react-router-dom";

import { Formik, Field, Form, useFormik } from 'formik';

import { JobContext } from '../../contexts/Job'

import { moneyMask } from '../../utils/Masks'

import ProductDetails from '../../assets/icons/ProductDetails.svg'
import SectionsIcon from '../../assets/icons/SectionsIcon.svg'
// import QRCodeIcon from '../../assets/icons/QRCode.svg'

import Checkbox from '@material-ui/core/Checkbox';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';

import AddPhotos from '../../assets/icons/AddPhotos.svg'

import api from '../../services/api';

import {
  Container, ContainerColumn,
  ContainerBackground, ContainerForms,
  ContainerHeader, ContainerInformations,
  HeaderInformations, LineVertical,
  LineHorizontal, DivInput,
  Label, SubmitButton,
  DarkContainer, StepText,
  NextStepContainer, NavTab,
  FullDiv, Half2Div,
  Half2Div2, AutoCompleteDiv,
  DivPicture, UploadButton,
  DeleteButton, ButtonsDiv,
  DeleteImageButton, BackButton,

} from './styles';

import getValidationErros from '../../utils/getValidationErrors';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import SuccessPopUp from '../../components/SuccessPopUp';

import ToastContainer from '../../components/ToastContainer';

function index() {
  let { item_id } = useParams();
  let history = useHistory();

  const [tabInformation, setTabInformation] = useState('itemInformation')
  const [inventories, setInventories] = useState([])
  const [itemEdit, setItemEdit] = useState({})
  const [customerInfo, setCustomerInfo] = useState({})
  const [customerId, setCustomerId] = useState([])
  const [sameAsCustomerAddress, setSameAsCustomerAddress] = useState(true)
  const [ip, setIp] = useState([]);
  const [errors, setErrors] = useState({});
  const [images, setImages] = useState([]);
  const [productTypeItems, setProductTypeItems] = useState([])
  const [subTypeItems, setSubTypeItems] = useState([])
  const [storages, setStorages] = useState([])
  const [rooms, setRooms] = useState([])

  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { jobId } = useContext(JobContext)

  const conditionArray = [{ name: 'good' }, { name: 'intermediary' }, { name: 'bad' }]

  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  const formik = useFormik({
    initialValues: {
      job_number: '',
      product_type: '',
      sub_type: '',
      product_name: '',
      value: 0,
      condition: '',
      product_address: '',
      pick_up_address: '',
      room: '',
      location: '',
      description: '',
      storage_id: '',
      photos_url: ''
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });


  async function handleSubmit(values) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        job_number: Yup.string().required('Job Number is required'),
        product_type: Yup.string().required('Product Type is required'),
        sub_type: Yup.string().required('Sub Type is required'),
        product_name: Yup.string().required('Product Name is required'),
        condition: Yup.string().required('Condition is required'),
        product_address: Yup.string().required('Product Address is required'),
        pick_up_address: Yup.string().required('Pick Up Address is required'),
        room: Yup.string().required('Room is required'),
        location: Yup.string().required('Location is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      if (item_id) {
        updateItem(values)
      }
      else {
        createItem(values);
      }
    } catch (error) {
      console.error(error)
      setLoading(false);
      setErrors(getValidationErros(error));
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }

  async function updateItem(values) {
    const data = new FormData();
    data.append('item_id', item_id)
    data.append('job_number', values.job_number)
    data.append('product_type', values.product_type)
    data.append('sub_type', values.sub_type)
    data.append('product_name', values.product_name)
    data.append('value', moneyMask(values.value))
    data.append('condition', values.condition)
    data.append('product_address', values.product_address)
    data.append('pick_up_address', values.pick_up_address)
    data.append('room', values.room)
    data.append('location', values.location)
    data.append('description', values.description)
    data.append('storage_id', values.storage_id)
    data.append('qr_code', values.storage_id)
    data.append('ip', ip)
    images.forEach((item, i) => {
      if (typeof (item) == "object") {
        data.append("photos_url[]",
          item
        );
      }
      else if (typeof (item) == "string") {
        data.append("photos_db",
          item
        );
      }
    });
    await api
      .patch('/item/update', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then(response => {
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer`;
        setLoading(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function createItem(values) {
    setLoading(true);
    const data = new FormData();
    data.append('job_number', values.job_number)
    data.append('product_type', values.product_type)
    data.append('sub_type', values.sub_type)
    data.append('product_name', values.product_name)
    data.append('value', moneyMask(values.value))
    data.append('condition', values.condition)
    data.append('product_address', values.product_address)
    data.append('pick_up_address', values.pick_up_address)
    data.append('room', values.room)
    data.append('location', values.location)
    data.append('description', values.description)
    data.append('storage_id', values.storage_id)
    data.append('qrcode', values.storage_id)
    data.append('ip', ip)
    images.forEach((item, i) => {
      data.append("photos_url[]",
        item
      );
    });
    await api
      .post('/item', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then(response => {
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer`;
        setLoading(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function deleteItem() {
    setLoadingDelete(true);
    api
      .delete(
        `/item/delete/${itemEdit.item_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Item successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting item',
        });
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getInventories() {
    api
      .get('/inventory', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        setInventories(res.data.map(inventory => {
          return { inventory_id: inventory.inventory_id, storage_id: inventory.storage_id }
        }))
      })
      .catch(err => console.error(err))
  }

  async function getRooms() {
    api
      .get('/room-affected', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        setRooms(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getStorages() {
    api
      .get('/storage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        let aux = res.data.map(storage => {
          return {
            value: `${storage.name} - ${storage.storage_id}`
          }
        })
        setStorages(aux)
      })
      .catch(err => console.error(err))
  }

  async function getJobById() {
    api
      .get(`/job/${jobId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setCustomerId(res.data.customer_id)
        formik.setFieldValue('job_number', res.data.job_number)
      })
  }

  async function getCustomers() {
    api
      .get(`/customer`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        let aux = res.data.filter(customer => customer.customer_id === customerId)[0]
        setCustomerInfo(aux)
        formik.setFieldValue('product_address', aux?.address);
        formik.setFieldValue('pick_up_address', aux?.address);
        formik.setFieldValue('location', aux?.address);
      })
  }

  async function getInventoriesItems() {
    api
      .get('/item', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        let itemToEdit = res.data.find(item => item.item_id === item_id)
        setImages(itemToEdit.photos_url)
        setItemEdit(itemToEdit)
      })
      .catch(err => console.error(err))
  }

  async function getProductTypes() {
    api
      .get('/product-type', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        setProductTypeItems(res.data)

      })
      .catch(err => console.error(err))
  }

  async function getSubTypes() {
    api
      .get('/sub-product-type', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        setSubTypeItems(res.data)

      })
      .catch(err => console.error(err))
  }

  const handleCheckBox = (isChecked) => {
    if (isChecked) {
      formik.setFieldValue('product_address', '');
      formik.setFieldValue('pick_up_address', '');
      formik.setFieldValue('location', '');
    } else {
      formik.setFieldValue('product_address', customerInfo.address);
      formik.setFieldValue('pick_up_address', customerInfo.address);
      formik.setFieldValue('location', customerInfo.address);
    }
    setSameAsCustomerAddress(!isChecked)
  }

  useEffect(() => {
    getInventories()
    getIP()
    getProductTypes()
    getSubTypes()
    getStorages()
    getRooms()
    if (item_id) {
      getInventoriesItems()
    }
  }, []);

  useEffect(() => {
    getCustomers()
  }, [customerId]);

  useEffect(() => {
    if (jobId)
      getJobById()
  }, [jobId]);



  useEffect(() => {
    if (item_id) {
      formik.setFieldValue('job_number', itemEdit.job_number)
      formik.setFieldValue('product_type', itemEdit.product_type)
      formik.setFieldValue('sub_type', itemEdit.sub_type)
      formik.setFieldValue('product_name', itemEdit.product_name)
      formik.setFieldValue('value', itemEdit.value)
      formik.setFieldValue('condition', itemEdit.condition)
      formik.setFieldValue('product_address', itemEdit.product_address)
      formik.setFieldValue('pick_up_address', itemEdit.pick_up_address)
      formik.setFieldValue('room', itemEdit.room)
      formik.setFieldValue('location', itemEdit.location)
      formik.setFieldValue('description', itemEdit.description)
      formik.setFieldValue('storage_id', itemEdit.storage_id)
      formik.setFieldValue('photos_url', itemEdit.photos_url)
    }
  }, [itemEdit]);

  function itemInformationForm() {
    return (
      <>
        <ContainerInformations>
          <HeaderInformations>
            <img height={15} src={ProductDetails} style={{ marginRight: '10px' }}></img>
            <text>Item Details</text>
          </HeaderInformations>
          <LineHorizontal />
          <form onSubmit={formik.handleSubmit}>
            {/* <Half2Div >
              <DivInput >
                <Label style={errors.job_number ? { color: 'red' } : null}>
                  Job Number
                    <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_number"
                  disabled={true}
                  placeholder="Job Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.job_number}
                  style={
                    errors.job_number
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </Half2Div> */}
            <Half2Div>
              <DivInput >
                <Label style={errors.product_type ? { color: 'red' } : null}>
                  Product Type
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="product_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.product_type}
                  style={
                    errors.product_type
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {productTypeItems.map(productType => {
                    return (
                      <option key={productType.id} value={productType.id}>
                        {productType.name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.sub_type ? { color: 'red' } : null}>
                  Sub Type
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="sub_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sub_type}
                  style={
                    errors.sub_type
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {subTypeItems.map(subType => {
                    if (subType.product_type_id == formik.values.product_type) {
                      return (
                        <option key={subType.id} value={subType.id}>
                          {subType.name}
                        </option>
                      )
                    }
                  })}
                </select>
              </DivInput>
            </Half2Div>
            <FullDiv >
              <DivInput >
                <Label style={errors.product_name ? { color: 'red' } : null}>
                  Product Name
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="product_name"
                  placeholder="Product Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.product_name}
                  style={
                    errors.product_name
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </FullDiv>
            <Half2Div>
              <DivInput >
                <Label>
                  Value

                </Label>
                <input
                  name="value"
                  type="number"
                  placeholder="Item Value"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={moneyMask(formik.values.value)}
                />
              </DivInput>
              <DivInput >
                <Label style={errors.condition ? { color: 'red' } : null}>
                  Condition
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="condition"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.condition}
                  style={
                    errors.condition
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {conditionArray.map(item => {
                    return (
                      <option key={item.office_id} value={item.office_id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
            </Half2Div>
            <HeaderInformations style={{ marginTop: '20px' }}>
              <img height={15} src={ProductDetails} style={{ marginRight: '10px' }}></img>
              <text>Item Location</text>
            </HeaderInformations>
            <LineHorizontal />
            {!item_id &&
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <DivInput>
                  <div>
                    <Checkbox
                      name="sameAsCustomerAddress"
                      // onClick={() => {
                      //   setSameAsCustomerAddress(!sameAsCustomerAddress);
                      //   formik.setFieldValue('product_address', customerInfo[0].address);
                      //   formik.setFieldValue('pick_up_address', customerInfo[0].address);
                      //   formik.setFieldValue('location', customerInfo[0].address);
                      // }}
                      onClick={() => handleCheckBox(sameAsCustomerAddress)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      checked={sameAsCustomerAddress}
                      color="primary"
                    />
                    <Label>Same as Customer Address</Label>
                  </div>
                </DivInput>
              </div>
            }
            <Half2Div>
              <DivInput >
                <Label style={errors.product_address ? { color: 'red' } : null}>
                  Product Address
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="product_address"
                  placeholder="Product Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.product_address}
                  style={
                    errors.product_address
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label style={errors.pick_up_address ? { color: 'red' } : null}>
                  Pick Up Address
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="pick_up_address"
                  placeholder="Pick Up Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pick_up_address}
                  style={
                    errors.pick_up_address
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </Half2Div>
            <Half2Div>
              <DivInput >
                <Label style={errors.room ? { color: 'red' } : null}>
                  Room
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="room"
                  placeholder="Room"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.room}
                  style={
                    errors.room
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {rooms.map(room => {
                    return <option key={room.id} value={room.name}>
                      {room.name}
                    </option>
                  })}
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.location ? { color: 'red' } : null}>
                  Location
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="location"
                  placeholder="Location"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                  style={
                    errors.location
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </Half2Div>
            <FullDiv style={{ flexDirection: 'column' }}>
              <Label style={{ marginBottom: '10px' }}>
                Description:
              </Label>
              <TextareaAutosize
                style={errors.description ?
                  {
                    backgroundColor: '#ececec', border: 'none',
                    padding: '10px', color: 'red'
                  } :
                  {
                    backgroundColor: '#ececec', border: 'none',
                    color: '#495057', padding: '10px',
                  }
                }
                rows={5}
                aria-label="maximum height"
                placeholder="Enter item description..."
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
            </FullDiv>

            <LineHorizontal />
          </form>
        </ContainerInformations>
        <LineHorizontal style={{ marginBottom: '30px' }} />
        <NextStepContainer>
          <StepText>Step 1: Item Information</StepText>
          <SubmitButton type="button"
            onClick={() => setTabInformation('storageInformation')}>
            Next Step
          </SubmitButton>
        </NextStepContainer>
      </>
    )
  }

  const setQrCodeValue = () => {
    let storagesID = inventories.filter(inventory => inventory.inventory_id === itemEdit.inventory_id)
    let storages = storagesID.map(storage => {
      return storage.storage_id
    })
    return storages.toString()
  }

  const deleteImages = (imageIndex) => {
    let newImages = images.filter((image, index) => index !== imageIndex)
    setImages(newImages)
  }

  const handleChangeAutoComplete = (storageInfo) => {
    if (storageInfo) {
      formik.setFieldValue('storage_id', `${storageInfo.value}`)
    }
  }

  function storageInformationForm() {
    return (
      <>
        <ContainerInformations>
          <Form onSubmit={formik.handleSubmit}>
            <HeaderInformations style={{ marginTop: '20px' }}>
              <img height={15} src={SectionsIcon} style={{ marginRight: '10px' }}></img>
              <text>Section Information</text>
            </HeaderInformations>
            <LineHorizontal />
            <Label style={{ marginTop: 10 }}>
              Storage ID
            </Label>
            <AutoCompleteDiv>
              <Autocomplete
                name="storage_id"
                id="combo-box-demo"
                size="small"
                options={storages}
                value={formik.values.storage_id}
                getOptionLabel={(option) => option.value ? option.value : formik.values.storage_id}
                getOptionSelected={(option) => option.value == formik.values.storage_id}
                onChange={(event, value) => handleChangeAutoComplete(value)}
                onBlur={formik.handleBlur}
                style={{ width: '100%', marginTop: 0 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </AutoCompleteDiv>
            {/* <Half2Div> */}
            {/* <DivInput >
                <Label style={errors.storage_id ? { color: 'red' } : null}>
                  Storage ID
                    <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="storage_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.storage_id}
                  style={
                    errors.storage_id
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                    </option>
                  {storages.map(storage => {
                    return (
                      <option key={storage.storage_id} value={storage.storage_id}>
                        {storage.name}
                      </option>
                    )
                  })}
                </select>
              </DivInput> */}
            {/* </Half2Div> */}
            <Half2Div>
              {itemEdit.job_number &&
                <Half2Div>
                  <DivInput>
                    <Label style={{ marginBottom: 10 }}>
                      QR Code
                    </Label>
                    {/* value={setQrCodeValue()} */}
                    <QRCode id="qrcode" value={itemEdit.storage_id} />
                  </DivInput>
                </Half2Div>
              }
            </Half2Div>
            <FullDiv >
              <DivInput>
                <Label>Add photo</Label>
                <DivPicture>
                  {images.length > 0 &&
                    images.map((image, id) => {
                      if (image !== "undefined") {
                        return (
                          <div style={{
                            display: 'flex', flexDirection: 'column',
                            width: '200px', alignItems: 'center'
                          }}>
                            <DeleteImageButton
                              style={{ width: 30, height: 30, marginBottom: '5px' }}
                              onClick={() => deleteImages(id)}>
                              <ClearIcon
                                style={{ width: 20 }} />
                            </DeleteImageButton>
                            <img
                              style={{ width: '100%', marginRight: 0 }}
                              src={typeof image === 'object' ? URL.createObjectURL(image) : image}
                            // alt="photo"
                            />
                          </div>
                        )
                      }
                    })
                  }
                  <UploadButton for="upload-photo" style={{ marginBottom: 0 }}>
                    <img src={AddPhotos} />
                    Add photo
                  </UploadButton>
                  <Field
                    name="avatar"
                    type="file"
                    className="overflow-hidden"
                    id="upload-photo"
                    style={{ opacity: 0, zIndex: -1 }}
                    onChange={event => {
                      const photo = event.currentTarget.files[0]
                      setImages(prevState => [...prevState, photo])
                    }}
                    onBlur={formik.handleBlur}
                  />
                </DivPicture>
              </DivInput>
            </FullDiv>
            <NextStepContainer style={{ justifyContent: 'flex-end' }}>
              {itemEdit.storage_id ?
                <ButtonsDiv >
                  <DeleteButton
                    type="button"
                    onClick={() => deleteItem()}
                    style={{ alignSelf: 'flex-start' }}>
                    {loadingDelete ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      'Delete'
                    )}
                  </DeleteButton>
                  <SubmitButton
                    type="button"
                    onClick={() => handleSubmit(formik.values)}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      'Edit'
                    )}
                  </SubmitButton>
                </ButtonsDiv>
                :
                <SubmitButton type="button" onClick={() => handleSubmit(formik.values)}>
                  {loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Save'
                  )}
                </SubmitButton>
              }
            </NextStepContainer>
          </Form>
        </ContainerInformations>
        <LineHorizontal style={{ marginBottom: '30px' }} />
        <NextStepContainer>
        </NextStepContainer>
      </>
    )
  }



  useEffect(() => {

  }, []);

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Item Registration</text>
              </div>
              {/* <Link to="/jobs/viewer/" style={{textDecoration: 'none'}}> */}
              <BackButton type="button" onClick={() => {
                history.goBack()
              }}>
                <ArrowBackIcon />
                Back to Jobs
              </BackButton>
              {/* </Link> */}
            </ContainerHeader>
            <Formik>
              <Form >
                <NavTab>
                  <li
                    onClick={() => {
                      setTabInformation('itemInformation');
                    }}
                    style={
                      tabInformation === 'itemInformation' ? { backgroundColor: '#618095' } : null
                    }
                  >
                    <a>1. Item Information</a>
                  </li>
                  <li
                    onClick={() => {
                      setTabInformation('storageInformation');
                    }}
                    style={
                      tabInformation === 'storageInformation' ? {
                        marginLeft: '10px',
                        backgroundColor: '#618095',
                        marginLeft: '10px'
                      } : { marginLeft: '10px' }
                    }
                  >
                    <a>2. Storage Information</a>
                  </li>

                </NavTab>
                {tabInformation === 'itemInformation' && itemInformationForm()}
                {tabInformation === 'storageInformation' && storageInformationForm()}
              </Form>
            </Formik>
          </ ContainerForms>
        </ ContainerBackground>
      </ ContainerColumn>
    </Container>
  );
}

export default index;