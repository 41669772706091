import styled from 'styled-components';

export const Dropdown = styled.div`
  position: relative;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
`;

export const Arrow = styled.div.attrs(props => ({
  classname: props.classname,
}))`
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.8rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;

  & .ArrowOpen{
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }
`;

export const SelectedValue = styled.div`
  input {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    minWidth: 100%;
  }
`;

export const Option = styled.div.attrs(props => ({
  classname: props.classname,
}))`
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  font-size: 14px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: -1px;
  max-height: 200px;

  &:hover {
    background-color: #f2f9fc;
    color: #333;
  }
`;

export const OptionContainer = styled.div.attrs(props => ({
  classname: props.classname,
}))`
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
`;


