import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Formik, Field, Form, useFormik } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';

import api from '../../../../services/api';

import {
  ContainerInformations,
  HeaderInformations,
  LineHorizontal,
  DivInput,
  Label,
  Half2Div,
  Half3Div,
  SubmitButton,
  DivInspected,
  FormControlRadio,
  DivSkeleton,
} from './styles';
import { CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Skeleton } from '@mui/material';
import ToastContainer from '../../../../components/ToastContainer';

const index = () => {
  const [errors, setErrors] = useState({})
  const [controlPoints, setControlPoints] = useState({})
  const jobId = localStorage.getItem('jobId')
  const [ip, setIp] = useState('0.0.0.0')
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState({ show: false })
  const [loadingGet, setLoadingGet] = useState(false);
  const [inspectType, setInspectType] = React.useState(null);

  const handleChangeRadio = (event) => {
    setInspectType((event.target).value);
  };

  function handleToast() {
    setShowToast({ show: false });
  }

  async function getControlPoints() {
    setLoadingGet(true);
    try {
      const response = await api.get(`/control-points/${jobId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      });
      
      setControlPoints(response.data);
      setInspectType(response.data.inspect_type);
      setLoadingGet(false);
    } catch (error) {
      setLoadingGet(false);
      return
    }
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    getControlPoints(),
      getIP()
  }, [])

  function handleSubmit(values) {
    if (controlPoints && controlPoints.customer_contacted) {
      submitUpdateControlPoint(values);
    } else {
      submitCreateControlPoint(values)
    }
  }

  async function submitCreateControlPoint(values) {
    setLoading(true);
    api
      .post(
        '/control-points/',
        {
          customer_contacted: values.customer_contacted !== '' ? values.customer_contacted : null,
          inspected: values.inspected !== '' ? values.inspected : null,
          inspect_type: inspectType,
          job_started: values.job_started !== '' ? values.job_started : null,
          pack_out_started: values.pack_out_started !== '' ? values.pack_out_started : null,
          pack_out_completed: values.pack_out_completed !== '' ? values.pack_out_completed : null,
          cleaning_started: values.cleaning_started !== '' ? values.cleaning_started : null,
          cleaning_finished: values.cleaning_finished !== '' ? values.cleaning_finished : null,
          job_in_storage: values.job_in_storage !== '' ? values.job_in_storage : null,
          job_packed_back: values.job_packed_back !== '' ? values.job_packed_back : null,
          job_completed: values.job_completed !== '' ? values.job_completed : null,
          job_id: jobId,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setOpenPopUp({ show: true });
        setLoading(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer/#control-points`;
      })
      .catch(err => {
        setLoading(false);
        console.error(err.name);
        // return setShowToast({
        //   show: true,
        //   type: 'error',
        //   title: 'Error creating',
        //   description: 'Error creating customer',
        // });
      });
  }

  async function submitUpdateControlPoint(values) {
    setLoading(true);
    api
      .patch(
        '/control-points/',
        {
          customer_contacted: values.customer_contacted !== '' ? values.customer_contacted : null,
          inspected: values.inspected !== '' ? values.inspected : null,
          inspect_type: inspectType,
          virtual_inspection: values.virtual_inspection !== '' ? values.virtual_inspection : null,
          onsite_inspection: values.onsite_inspection !== '' ? values.onsite_inspection : null,
          job_started: values.job_started !== '' ? values.job_started : null,
          pack_out_started: values.pack_out_started !== '' ? values.pack_out_started : null,
          pack_out_completed: values.pack_out_completed !== '' ? values.pack_out_completed : null,
          cleaning_started: values.cleaning_started !== '' ? values.cleaning_started : null,
          cleaning_finished: values.cleaning_finished !== '' ? values.cleaning_finished : null,
          job_in_storage: values.job_in_storage !== '' ? values.job_in_storage : null,
          job_packed_back: values.job_packed_back !== '' ? values.job_packed_back : null,
          job_completed: values.job_completed !== '' ? values.job_completed : null,
          job_id: jobId,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setShowToast({ 
          show: true,
          type: 'success',
          title: 'Success',
          description: 'Updated Job successfully.', 
        });
        setLoading(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer/#control-points`;
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: 'Error updating job',
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      customer_contacted: controlPoints.customer_contacted || '',
      inspected: controlPoints.inspected || '',
      virtual_inspection: controlPoints.virtual_inspection || '',
      onsite_inspection: controlPoints.onsite_inspection || '',
      job_started: controlPoints.job_started || '',
      pack_out_started: controlPoints.pack_out_started || '',
      pack_out_completed: controlPoints.pack_out_completed || '',
      cleaning_started: controlPoints.cleaning_started || '',
      cleaning_finished: controlPoints.cleaning_finished || '',
      job_in_storage: controlPoints.job_in_storage || '',
      job_packed_back: controlPoints.job_packed_back || '',
      job_completed: controlPoints.job_completed || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  return (

    <ContainerInformations>
    {showToast.show && (
      <ToastContainer messages={showToast} show={() => handleToast()} />
    )}
      <HeaderInformations style={{ marginTop: '20px' }}>
        <div style={{
          display: 'flex', alignItems: 'inherit',
          fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
          fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
        }}>
          Control Points Information
        </div>
      </HeaderInformations>
      <div style={{
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        borderRadius: '4px', padding: '5px', marginTop: '20px',
        backgroundColor: "#fff"
      }}>
        <Formik>
          <Form onSubmit={formik.handleSubmit}>
            { loadingGet ? (
              <>
                <DivSkeleton>
                  <Skeleton variant="rectangular" width={'45%'} height={60} />
                  <Skeleton variant="rectangular" width={'45%'} height={60} />
                </DivSkeleton>
                <DivSkeleton>
                  <Skeleton variant="rectangular" width={'45%'} height={60} />
                  <Skeleton variant="rectangular" width={'45%'} height={60} />
                </DivSkeleton>
              </>
            ) :
              (
              <>
              <Half2Div style={{ marginTop: '20px' }}>
                <DivInput >
                  <Label >
                    Customer Contracted
                  </Label>
                  <input
                    name="customer_contacted"
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer_contacted.split('T')[0]}
                    style={
                      errors.customer_contacted
                        ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                        : { textAlign: 'center', padding: 'inherit' }
                    }
                  />
                </DivInput>
                <DivInput style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                  <DivInspected>
                    <Label >
                      Inspected
                    </Label>
                    <input
                      name="inspected"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.inspected.split('T')[0]}
                      style={
                        errors.inspected
                          ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                          : { textAlign: 'center', padding: 'inherit' }
                      }
                    />
                  </DivInspected>
                  <DivInspected style={{justifyContent: 'center'}}>
                    <FormControl style={{width: '100%'}}>
                      <RadioGroup
                        style={{width: '100%', marginLeft: '10px'}}
                        row
                        name="row-radio-buttons-group"
                        value={inspectType}
                        onChange={handleChangeRadio}
                      >
                        <FormControlRadio
                          style={{margin: '5px'}} 
                          value="virtual_inspection"
                          control={<Radio />} 
                          label="Virtual Inspection"
                          disabled={formik.values.inspected ? false : true}
                        />
                        <FormControlRadio
                          style={{margin: '5px'}} 
                          value="onsite_inspection" 
                          control={<Radio />} 
                          label="On-site inspection"
                          disabled={formik.values.inspected ? false : true}
                        />
                      </RadioGroup>
                    </FormControl>
                  </DivInspected>
                </DivInput>
              </Half2Div>
              <Half2Div>
                <DivInput >
                  <Label >
                    Job Started
                  </Label>
                  <input
                    name="job_started"
                    type="date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.job_started.split('T')[0]}
                    style={
                      errors.job_started
                        ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                        : { textAlign: 'center', padding: 'inherit' }
                    }
                  />
                </DivInput>
                <DivInput style={{ alignItems: 'center' }}>
                  <Label>
                    Job Completed
                  </Label>
                  <Checkbox
                    name="job_completed"
                    checked={formik.values.job_completed}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    color='primary'
                  />
                </DivInput>
              </Half2Div>
            </>
            )}

            {formik.values.job_started &&
              <>
                <Half3Div>
                  <DivInput >
                    <Label >
                      Pack Out Started
                    </Label>
                    <input
                      name="pack_out_started"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pack_out_started.split('T')[0]}
                      style={
                        errors.pack_out_started
                          ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                          : { textAlign: 'center', padding: 'inherit' }
                      }
                    />
                  </DivInput>
                  <DivInput >
                    <Label >
                      Pack Out Completed
                    </Label>
                    <input
                      name="pack_out_completed"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pack_out_completed.split('T')[0]}
                      style={
                        errors.pack_out_completed
                          ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                          : { textAlign: 'center', padding: 'inherit' }
                      }
                    />
                  </DivInput>
                  <DivInput >
                    <Label >
                      Cleaning Started
                    </Label>
                    <input
                      name="cleaning_started"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cleaning_started.split('T')[0]}
                      style={
                        errors.cleaning_started
                          ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                          : { textAlign: 'center', padding: 'inherit' }
                      }
                    />
                  </DivInput>
                </Half3Div>
                <Half3Div>
                  <DivInput >
                    <Label >
                      Cleaning Finished
                    </Label>
                    <input
                      name="cleaning_finished"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cleaning_finished.split('T')[0]}
                      style={
                        errors.cleaning_finished
                          ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                          : { textAlign: 'center', padding: 'inherit' }
                      }
                    />
                  </DivInput>
                  <DivInput >
                    <Label >
                      Job in Storage
                    </Label>
                    <input
                      name="job_in_storage"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.job_in_storage.split('T')[0]}
                      style={
                        errors.job_in_storage
                          ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                          : { textAlign: 'center', padding: 'inherit' }
                      }
                    />
                  </DivInput>
                  <DivInput >
                    <Label >
                      Job Packed Back
                    </Label>
                    <input
                      name="job_packed_back"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.job_packed_back.split('T')[0]}
                      style={
                        errors.job_packed_back
                          ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                          : { textAlign: 'center', padding: 'inherit' }
                      }
                    />
                  </DivInput>
                </Half3Div>
              </>
            }
            <LineHorizontal style={{ marginBottom: '30px' }} />
            <SubmitButton type="submit">
              {loading ? <CircularProgress size={20}/> : 'Save'}
            </SubmitButton>
          </Form>
        </Formik>
      </div>
    </ContainerInformations>
  );
}

export default index;
