import { useEffect, useRef, useState } from "react";

import {
  Arrow,
  Dropdown,
  Option,
  SelectedValue,
  OptionContainer
} from './styles';

const SearchableSelect = ({
  options,
  id,
  handleChange
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option.id);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const filter = (options) => {
    return options.filter(
      (option) => option.name.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <Dropdown>
      <div className="control">
        <SelectedValue>
          <input
            ref={inputRef}
            autocomplete="off"
            type="text"
            placeholder="Select a option ..."
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onClick={toggle}
          />
        </SelectedValue>
        <Arrow className="ArrowOpen"></Arrow>
      </div>

      <OptionContainer 
        className="optionOpen"
        style={{display: `${isOpen ? 'block' : 'none'}`}}
      >
        {filter(options).map((option, index) => {
          return (
            <Option
              onClick={() => selectOption(option)}
              className="OptionSelected"
              key={`${id}-${index}`}
            >
              {option.name}
            </Option>
          );
        })}
      </OptionContainer>
    </Dropdown>
  );
};

export default SearchableSelect;
