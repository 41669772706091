import React, { useState, useRef, useEffect } from 'react';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, useFormik } from 'formik';
import { format } from 'date-fns';
import {
  CircularProgress,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  ContainerInformations,
  HeaderInformations,
  LineVertical,
  LineHorizontal,
  DivForms,
  DivInput,
  Label,
  SubmitButton,
  DarkContainer,
  StepText,
  NextStepContainer,
  NavTab,
  AddButton,
  PreviousButton,
  TransferListDiv,
  TransferInput,
  TransferItem,
  TransferButton,
  DivInputText,
  DivisionDiv,
  GI1, GI2,
  GI5, CI1,
  CI2, CI3,
  JA1, JA2,
  JA3, IT1, StoragesDiv, IT2
} from './styles';

import api from '../../services/api';
import { moneyMask } from '../../utils/Masks';
import getValidationErros from '../../utils/getValidationErrors';
import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import SuccessPopUp from '../../components/SuccessPopUp';
import ToastContainer from '../../components/ToastContainer';
import generalInfo from '../../assets/icons/generalInfo.png';
import arrow from '../../assets/icons/arrow2.png';
import { MountEmployeeOptions } from '../../utils/EmployeeMappers';
import SearchableSelect from '../../components/SelectSearchable';

function JobRegister() {

  let autoComplete;
  let autoComplete2;
  const autoCompleteRef = useRef(null);
  const autoCompleteRef2 = useRef(null);

  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [loading, setLoading] = useState(false)

  const [employees, setEmployees] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([
    { id: 1, name: 'Residential' },
    { id: 2, name: 'Commercial' }

  ]);
  const [customerTitles, setCustomerTitles] = useState([]);

  const [checked, setChecked] = useState([]);

  const [lossCategories, setLossCategories] = useState([]);
  const [lossTypes, setLossTypes] = useState([]);
  const [sourcesOfLoss, setSourcesOfLoss] = useState([]);
  const [jobSizes, setJobSizes] = useState([]);
  const [environmentalCode, setEnvironmentalCode] = useState([]);
  const [roomsAffected, setRoomsAffected] = useState([]);
  const [roomsAffectedSelect, setRoomsAffectedSelect] = useState([]);

  const [generalInformation, setGeneralInformation] = useState(true);
  const [teams, setTeams] = useState(false);
  const [policyAndDivision, setPolicyAndDivision] = useState(false);
  const [payment, setPayment] = useState(false);
  const [lossDescription, setLossDescription] = useState(false);
  const [offices, setOffices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [ip, setIp] = useState('0.0.0.0');
  const [errors, setErrors] = useState({});
  const [jobStatus, setJobStatus] = useState([])

  const referredArray = ['Carrier', 'TPA (Third Party Admin)', 'Contractor']
  const priorityArray = ['Low', 'Normal', 'High']
  const [years, setYears] = useState([]);

  const [divisions, setDivisions] = useState([]);
  const [divisionsArray, setDivisionsArray] = useState([]);

  const [options, setOptions] = useState([]);
  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [selectedTecnicians, setSelectedTecnicians] = useState([]);
  const [selectedTecniciansID, setSelectedTecniciansID] = useState([]);

  function setOptionsSearch(data) {
    let arrayOptions = []
    arrayOptions.push({
      label: 'Choose a option',
      value: 'null'
    })
    data.map(item => {
      arrayOptions.push({
        label: item.first_name + ' ' + item.last_name,
        value: item.customer_id
      })
    })
    setOptions(arrayOptions);
  }

  function listCustomerByCustomerType(value) {
    const optionsArray = customers.filter(item => {
      return item.customer_type === value
    });
    setOptionsSearch(optionsArray)
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  const formik = useFormik({
    initialValues: {
      job_name: '',
      customer_id: '',
      office_id: '',
      job_size: '',
      received_by: '',
      assigned_to: '',
      referred_field: 'null',
      status: '',
      referred_by: '',
      date_of_loss: '',
      loss_category: '',
      loss_type: '',
      secondary_loss_type: '',
      source_of_loss: '',
      environmental_code: '',
      priority: 'Normal',
      customer: '',
      customer_type: '',
      customer_title: '',
      customer_first_name: '',
      customer_last_name: '',
      customer_email: '',
      customer_secondary_email: '',
      customer_address: '',
      customer_street_number: '',
      customer_postal_code: '',
      customer_city: '',
      customer_region: '',
      customer_country: '',
      customer_state: '',
      customer_business_phone: '',
      customer_mobile_phone: '',
      customer_fax_phone: '',
      customer_other_phone: '',
      street_number: '',
      job_first_name: '',
      job_last_name: '',
      job_address: '',
      job_street_number: '',
      job_postal_code: '',
      job_city: '',
      job_region: '',
      job_country: '',
      job_state: '',
      business_phone: '',
      mobile_phone: '',
      fax_phone: '',
      other_phone: '',
      director: '',
      project_manager: '',
      lead_tech: '',
      controller: '',
      division: [],
      job_number: '',
      policy_number: '',
      claim_number: '',
      year_built: '',
      policy_expiration_date: null,
      policy_start_date: null,
      collect_when: null,
      dwelling_limits: '',
      contents_limits: '',
      other_structures_limits: '',
      deductible_required: '',
      amount: '',
      self_pay: false,
      account_record_no: '',
      loss_description: '',
      special_instruction: '',
      sameAsCustomerAddress: false,
      name: '',
      phone: '',
      email: '',
      tecnicians: [],
    },
    onSubmit: values => {
      handleCreateJob(values);
    },
  });

  async function createCustomer(values) {
    setLoading(true)
    api
      .post(
        '/customer/',
        {
          referred: '',
          referred_field: '',
          customer_title: values.customer_title,
          customer_type: values.customer_type,
          first_name: values.customer_first_name,
          last_name: values.customer_last_name,
          email: values.customer_email,
          secondary_email: values.customer_secondary_email,
          address: values.customer_address,
          postal_code: values.customer_postal_code,
          city: values.customer_city,
          region: values.customer_region,
          country: values.customer_country,
          state: values.customer_state,
          business_phone: values.customer_business_phone,
          mobile_phone: values.customer_mobile_phone,
          other_phone: values.customer_other_phone,
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then((res) => {
        let customer = res.data
        values.customer_id = customer.customer_id
        handleCreateJob(values)
      })
      .catch(err => {
        setLoading(false);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error creating',
          description: 'Error creating customer',
        });
      });
  }

  function handleCreateJob(values) {
    setLoading(true)
    if (values.customer_id == "")
      createCustomer(values)
    else
      api
        .post(
          '/job',
          {
            job_name: 'n/a',
            created_by: JSON.parse(localStorage.getItem('@Propack:employee')).id,
            office_id: values.office_id,
            job_size: values.job_size,
            received_by: values.received_by,
            assigned_to: values.assigned_to,
            referred_by: values.referred_by,
            referred_field: values.referred_field,
            status: values.status,
            date_of_loss: values.date_of_loss,
            loss_category: values.loss_category,
            loss_type: values.loss_type,
            secondary_loss_type: values.secondary_loss_type,
            source_of_loss: values.source_of_loss,
            environmental_code: values.environmental_code,
            priority: values.priority,
            customer: values.customer,
            customer_type: values.customer_type,
            customer_title: values.customer_title,
            customer_id: values.customer_id,
            customer_first_name: values.customer_first_name,
            customer_last_name: values.customer_last_name,
            customer_email: values.customer_email,
            customer_secondary_email: values.customer_secondary_email,
            customer_address: values.customer_address,
            customer_street_number: values.customer_street_number,
            customer_postal_code: values.customer_postal_code,
            customer_city: values.customer_city,
            customer_region: values.customer_region,
            customer_country: values.customer_country,
            customer_state: values.customer_state,
            customer_business_phone: values.customer_business_phone,
            customer_mobile_phone: values.customer_mobile_phone,
            customer_fax_phone: values.customer_fax_phone,
            customer_other_phone: values.customer_other_phone,
            street_number: values.street_number,
            job_first_name: values.job_first_name || values.first_name,
            job_last_name: values.job_last_name || values.last_name,
            job_address: values.job_address || values.address,
            job_street_number: values.job_street_number || values.street_number,
            job_postal_code: values.job_postal_code || values.postal_code,
            job_city: values.job_city || values.city,
            job_region: values.job_region || values.region,
            job_country: values.job_country || values.country,
            job_state: values.job_state || values.state,
            business_phone: values.business_phone,
            mobile_phone: values.mobile_phone,
            fax_phone: values.fax_phone,
            other_phone: values.other_phone,
            director: values.director,
            project_manager: values.project_manager ? values.project_manager : values.assigned_to,
            lead_tech: values.lead_tech,
            controller: values.controller,
            division: divisionsArray,
            job_number: values.job_number,
            policy_number: values.policy_number,
            claim_number: values.claim_number,
            year_built: values.year_built,
            policy_expiration_date: values.policy_expiration_date,
            policy_start_date: values.policy_start_date,
            collect_when: values.collect_when,
            dwelling_limits: moneyMask(values.dwelling_limits),
            contents_limits: moneyMask(values.contents_limits),
            other_structures_limits: moneyMask(values.other_structures_limits),
            amount: moneyMask(values.amount),
            deductible_required: values.deductible_required,
            self_pay: values.self_pay,
            account_record_no: values.account_record_no,
            loss_description: values.loss_description,
            special_instruction: values.special_instruction,
            rooms_affected: roomsAffectedSelect,
            same_as_customer_address: values.sameAsCustomerAddress,
            name: values.name,
            phone: values.phone,
            email: values.email,
            ip,
            tecnicians: selectedTecniciansID,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
            },
          },
        )
        .then((res) => {
          setOpenPopUp({ show: true });
          setLoading(false);
          window.location.href = "/jobs";
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
          return setShowToast({
            show: true,
            type: 'error',
            title: 'Error creating',
            description: 'Error creating job',
          });
        })
  }

  function setValueInRerredSelect() {
    const search = formik.values.referred_field;
    const value = options.find(item => item.value === search);
    if (value)
      return value.value;
  }

  const setDigitSequence = (name) => {
    let sequence
    if (name == 'Contents')
      sequence = 'CON-' + Math.floor(Math.random() * 10000).toString().padStart(4, '0')
    else if (name == 'Textiles')
      sequence = 'TXT-' + Math.floor(Math.random() * 10000).toString().padStart(4, '0')
    else if (name == 'Electronics')
      sequence = 'ELC-' + Math.floor(Math.random() * 10000).toString().padStart(4, '0')
    else if (name == 'Mitigation')
      sequence = 'MIT-' + Math.floor(Math.random() * 10000).toString().padStart(4, '0')
    else if (name == 'Reconstruction')
      sequence = 'REC-' + Math.floor(Math.random() * 10000).toString().padStart(4, '0')
    else if (name == 'Flooring')
      sequence = 'FLR-' + Math.floor(Math.random() * 10000).toString().padStart(4, '0')
    else
      sequence = 'MOV-' + Math.floor(Math.random() * 10000).toString().padStart(4, '0')
    return sequence
  }

  const isChecked = (name) => {
    let checked = divisionsArray.find(division => division == name)
    if (checked)
      return true
    else
      return false
  }

  function handleDivisionCheckbox(name, state) {
    if (divisionsArray.length == 0) {
      let date = new Date().getFullYear().toString().substr(2)
      let characters = setDigitSequence(name)
      formik.setFieldValue('job_number', `${date}${characters}`)
    }
    if (state) {
      setDivisionsArray(prevState => [...prevState, name])
    } else {
      setDivisionsArray(divisionsArray.filter(item => item !== name))
    }
  }

  async function getOffices() {
    const response = await api.get('/office', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setOffices(response.data);
  }

  async function getJobStatus() {
    const response = await api.get(`/job-status`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setJobStatus(response.data);
  }

  async function getCustomers() {
    api
      .get('/customer', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        const arrayOrdenate = res.data.sort(function (a, b) {
          if (a.first_name < b.first_name) {
            return -1
          }
          if (a.first_name > b.first_name) {
            return 1
          }
          return 0
        });
        // arrayOrdenate.unshift({first_name: 'New', last_name: 'Customer', customer_id: 'New Customer'})
        setCustomers(arrayOrdenate);
      });
  }


  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const loadScript2 = (url, callback) => {
    let script2 = document.createElement("script");
    script2.type = "text/javascript";

    if (script2.readyState) {
      script2.onreadystatechange = function () {
        if (script2.readyState === "loaded" || script2.readyState === "complete") {
          script2.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script2.onload = () => callback();
    }

    script2.src = url;
    document.getElementsByTagName("head")[0].appendChild(script2);
  };

  function handleScriptLoad(setFormik, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(setFormik)
    );
  }

  async function handlePlaceSelect(setFormik) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    let streetNumber = '';
    addressObject.address_components.map(async component => {
      if (component.types[0] === 'locality') {
        setFormik('customer_city', component.long_name)
      }
      if (component.types[0] === 'administrative_area_level_1') {
        setFormik('customer_state', component.long_name)
      }
      if (component.types[0] === 'country') {
        setFormik('customer_country', component.long_name)
      }
      if (component.types[0] === 'postal_code') {
        setFormik('customer_postal_code', component.long_name)
      }
      if (component.types[0] === 'street_number') {
        streetNumber = component.long_name
      }
      if (component.types[0] === 'route') {
        setFormik('customer_address', `${streetNumber} ${component.long_name}`)
      }
    })
  }

  function handleScriptLoad2(setFormik, autoCompleteRef2) {
    autoComplete2 = new window.google.maps.places.Autocomplete(
      autoCompleteRef2.current
    );
    autoComplete2.setFields(["address_components", "formatted_address"]);
    autoComplete2.addListener("place_changed", () =>
      handlePlaceSelect2(setFormik)
    );
  }

  async function handlePlaceSelect2(setFormik) {
    const addressObject = autoComplete2.getPlace();
    let streetNumber = ''
    addressObject.address_components.map(component => {
      if (component.types[0] === 'locality') {
        setFormik('job_city', component.long_name)
      }
      if (component.types[0] === 'administrative_area_level_1') {
        setFormik('job_state', component.long_name)
      }
      if (component.types[0] === 'country') {
        setFormik('job_country', component.long_name)
      }
      if (component.types[0] === 'postal_code') {
        setFormik('job_postal_code', component.long_name)
      }
      if (component.types[0] === 'street_number') {
        streetNumber = component.long_name
      }
      if (component.types[0] === 'route') {
        setFormik('job_address', `${streetNumber} ${component.long_name}`)
      }
    })
  }

  const handleTecniciansChange = (employee_id) => {
    let exist = selectedTecnicians.find(employee => employee.employee_id === employee_id)
    if (!exist) {
      let employee = employees.find(employee => employee.employee_id === employee_id)
      setSelectedTecnicians(prev => ([...prev, {
        name: `${employee.first_name} ${employee.last_name}`,
        employee_id: employee.employee_id,
        email: employee.email
      }]))
      setSelectedTecniciansID(prev => ([...prev, employee_id]));
    }
    else
      return
  }

  const handleRemoveTecnicians = (employee_id) => {
    let tecnician = selectedTecnicians.filter(employee => employee.employee_id !== employee_id);
    console.log(tecnician);
    setSelectedTecnicians(tecnician)
  }

  function setYearsArray() {
    var startYear = 1900
    var yearArray = []
    var currentYear = new Date().getFullYear();
    for (var i = startYear; i <= currentYear; i++) {
      yearArray.push(i);
    }
    setYears(yearArray.reverse());
  }

  function lossDescriptionForm() {

    function not(a, b) {
      return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
      return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a, b) {
      return [...a, ...not(b, a)];
    }

    const leftChecked = intersection(checked, roomsAffected);
    const rightChecked = intersection(checked, roomsAffectedSelect);

    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    };

    const handleCheckedRight = () => {
      setRoomsAffectedSelect(roomsAffectedSelect.concat(leftChecked));
      setRoomsAffected(not(roomsAffected, leftChecked));
      setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
      setRoomsAffected(roomsAffected.concat(rightChecked));
      setRoomsAffectedSelect(not(roomsAffectedSelect, rightChecked));
      setChecked(not(checked, rightChecked));
    };

    const customList = (title, items) => (
      <Card>
        <CardHeader
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        {/* <LineHorizontal /> */}
        <List
          dense
          component="div"
          role="list"
          style={{
            height: '200px',
            width: '490px',
            overflow: 'hidden',
            overflowY: 'scroll'
          }}
        >
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );

    return (
      <>
        <ContainerInformations>
          <HeaderInformations>
            <img src={generalInfo} alt="generalInfo" />
            <text>{'Loss Description & Special Instruction'}</text>
          </HeaderInformations>
          <LineHorizontal />
          <GI1 >
            <DivInput >
              <Label>
                Loss Description
              </Label>
              <textarea
                name="loss_description"
                rows="5" cols="30"
                placeholder="Enter special instruction"
                style={{ backgroundColor: '#ececec' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.loss_description}
              />
            </DivInput>
            <DivInput >
              <Label>
                Special Instruction
              </Label>
              <textarea
                name="special_instruction"
                rows="5" cols="30"
                placeholder="Enter special instruction"
                style={{ backgroundColor: '#ececec' }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.special_instruction}
              />
            </DivInput>
          </GI1>
          <DivForms className="col-12">
            <div className="col-12  row">
              <DivInput className="col-sm-12 col-md-6 col-xl-12">
                <Label>
                  Rooms Affected
                </Label>
                <TransferListDiv>
                  {customList('', roomsAffected)}
                  <div style={{ width: '90px' }}>
                    <TransferButton type="button" onClick={handleCheckedRight}>
                      <img
                        src={arrow}
                        style={{ transform: "none" }}
                      />
                    </TransferButton>
                    <TransferButton type="button" onClick={handleCheckedLeft}>
                      <img
                        src={arrow}
                      />
                    </TransferButton>
                  </div>
                  {customList('', roomsAffectedSelect)}
                </TransferListDiv>
              </DivInput>
            </div>
          </DivForms>
        </ContainerInformations>
        <LineHorizontal style={{ marginBottom: '30px' }} />
        <NextStepContainer>
          <StepText>Step 5: {'Loss Description'}</StepText>
          <div>
            <PreviousButton
              type="button"
              onClick={() => {
                setGeneralInformation(false);
                setTeams(false);
                setPolicyAndDivision(false);
                setPayment(true);
                setLossDescription(false);
              }}
            >
              Previous Step
            </PreviousButton>
            <SubmitButton
              type="button"
              onClick={() => handleCreateJob(formik.values)}
            >
              {loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                'Create Job'
              )}
            </SubmitButton>
          </div>
        </NextStepContainer>
      </>
    )
  }

  function paymentForm() {
    async function checkPaymentInfo(values) {
      try {
        setErrors({});
        const schema = Yup.object().shape({
          // collect_when: Yup.date().required(),
          // dwelling_limits: Yup.string().required(),
          // contents_limits: Yup.string().required(),
          // other_structures_limits: Yup.string().required(),
          // deductible_required: Yup.string().required(),
          // amount: Yup.string().required(),
          // account_record_no: Yup.string().required(),
        });

        await schema.validate(values, {
          abortEarly: false,
        });
        setGeneralInformation(false);
        setTeams(false);
        setPolicyAndDivision(false);
        setPayment(false);
        setLossDescription(true);
      }
      catch (error) {
        console.error(error)
        setErrors(getValidationErros(error));
      }
    }
    return (
      <>
        <ContainerInformations>
          <HeaderInformations>
            <img src={generalInfo} alt="generalInfo" />
            <text>Payment Services</text>
          </HeaderInformations>
          <LineHorizontal />
          <IT1 >
            <DivInput >
              <Label style={errors.collect_when ? { color: 'red' } : null}>
                Collect When
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="collect_when"
                type="date"
                placeholder="Collect When"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.collect_when}
                style={
                  errors.collect_when
                    ? { border: '1px solid red' }
                    : { textAlign: 'center', padding: 'inherit' }
                }
              />
            </DivInput>
            <DivInput >
              <Label style={errors.dwelling_limits ? { color: 'red' } : null}>
                Dwelling Limits
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="dwelling_limits"
                placeholder="Dwelling Limits"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={moneyMask(formik.values.dwelling_limits)}
                style={
                  errors.dwelling_limits
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label style={errors.contents_limits ? { color: 'red' } : null}>
                Contents Limits
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="contents_limits"
                placeholder="Contents limits"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={moneyMask(formik.values.contents_limits)}
                style={
                  errors.contents_limits
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label
                style={
                  errors.other_structures_limits
                    ? { color: 'red' }
                    : null
                }
              >
                Other Structures Limits
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="other_structures_limits"
                placeholder="Other structures limits"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={moneyMask(formik.values.other_structures_limits)}
                style={
                  errors.other_structures_limits
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
          </IT1>
          <GI2 >
            <DivInput>
              <Label
                style={errors.deductible_required ? { color: 'red' } : null}
              >
                Deductible Required
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <select
                name="deductible_required"
                placeholder="Choose an option"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.deductible_required}
                style={
                  errors.deductible_required
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                <option value="yes">
                  Yes
                </option>
                <option value="no">
                  No
                </option>
              </select>
            </DivInput>
            <DivInput>
              <Label style={errors.amount ? { color: 'red' } : null}>
                Amount
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="amount"
                placeholder="e.g '$00,00'"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={moneyMask(formik.values.amount)}
                style={
                  errors.amount
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput>
              <Label
                style={errors.account_record_no ? { color: 'red' } : null}
              >
                Accounting Record No
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="account_record_no"
                placeholder="e.g 'xxx.xxx.xxx.xxx'"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_record_no}
                style={
                  errors.account_record_no
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
          </GI2>
          <DivInput style={{ marginTop: '10px' }}>
            <div>
              <Checkbox
                name="self_pay"
                onChange={e => {
                  formik.setFieldValue("self_pay", e.target.checked)
                }}
                checked={formik.values.self_pay}
                value={formik.values.self_pay}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Label>Self Pay?</Label>
            </div>
          </DivInput>
        </ContainerInformations>
        <LineHorizontal style={{ marginBottom: '30px' }} />
        <NextStepContainer>
          <StepText>Step 4: {'Payment'}</StepText>
          <div>
            <PreviousButton
              type="button"
              onClick={() => {
                setGeneralInformation(false);
                setTeams(false);
                setPolicyAndDivision(true);
                setPayment(false);
                setLossDescription(false);
              }}
            >
              Previous Step
            </PreviousButton>
            <SubmitButton
              type="button"
              onClick={() => checkPaymentInfo(formik.values)}
            >
              Next Step
            </SubmitButton>
          </div>
        </NextStepContainer>
      </>
    )
  }

  function policyAndDivisionForm() {
    async function checkPolicyInfo(values) {
      try {
        setErrors({});
        const schema = Yup.object().shape({
          job_number: Yup.string().required(),
          // policy_number: Yup.string().required(),
          claim_number: Yup.string().required(),
          // year_built: Yup.string().required(),
          // policy_start_date: Yup.date().required(),
          // policy_expiration_date: Yup.date().required(),
        });

        await schema.validate(values, {
          abortEarly: false,
        });
        setGeneralInformation(false);
        setTeams(false);
        setPolicyAndDivision(false);
        setPayment(true);
        setLossDescription(false);
      }
      catch (error) {
        console.error(error)
        setErrors(getValidationErros(error));
      }
    }
    return (
      <>
        <ContainerInformations>
          <HeaderInformations>
            <img src={generalInfo} alt="generalInfo" />
            <text>Policy Information</text>
          </HeaderInformations>
          <LineHorizontal />
          <IT1 >
            <DivInput >
              <Label style={errors.job_number ? { color: 'red' } : null}>
                Job Number
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                disabled
                name="job_number"
                placeholder="Job number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.job_number}
                style={
                  errors.job_number
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label style={errors.policy_number ? { color: 'red' } : null}>
                Policy Number
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <input
                name="policy_number"
                placeholder="Policy number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.policy_number}
                style={
                  errors.policy_number
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label style={errors.claim_number ? { color: 'red' } : null}>
                Claim Number
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <input
                name="claim_number"
                placeholder="Claim number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.claim_number}
                style={
                  errors.claim_number
                    ? { border: '1px solid red' }
                    : null
                }
              />
            </DivInput>
            <DivInput >
              <Label >
                Year Built
                {/* <span style={{ color: 'red' }}> *</span> */}
              </Label>
              <select
                name="year_built"
                placeholder="Year built"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.year_built}
              // style={
              //   errors.year_built
              //     ? { border: '1px solid red' }
              //     : null
              // }
              >
                <option>
                  Choose a year built
                </option>
                {
                  years && years.map(item => {
                    return (
                      <option value={item}>
                        {item}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
          </IT1>
          <GI1 >
            <DivInput>
              <Label
                style={errors.policy_start_date ? { color: 'red' } : null}
              >
                Policy Start Date
              </Label>
              <input
                name="policy_start_date"
                placeholder="Job number"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.policy_start_date}
                style={
                  errors.policy_start_date
                    ? { border: '1px solid red' }
                    : { textAlign: 'center', padding: 'inherit' }
                }
              />
            </DivInput>
            <DivInput>
              <Label
                style={errors.policy_expiration_date ? { color: 'red' } : null}
              >
                Policy Expiration Date
              </Label>
              <input
                name="policy_expiration_date"
                placeholder="Policy number"
                type="date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.policy_expiration_date}
                style={
                  errors.policy_expiration_date
                    ? { border: '1px solid red' }
                    : { textAlign: 'center', padding: 'inherit' }
                }
              />
            </DivInput>
          </GI1>
          <HeaderInformations style={{ marginTop: '10px' }}>
            <img src={generalInfo} alt="generalInfo" />
            <text>Division</text>
          </HeaderInformations>
          <LineHorizontal />
          <DivForms style={{ flexWrap: 'wrap' }}>
            {
              divisions.map((item, i) =>
              (
                <DivisionDiv>
                  <Checkbox
                    color={'primary'}
                    name={item.name}
                    onChange={e => {
                      handleDivisionCheckbox(e.target.name, e.target.checked)
                    }}
                    checked={isChecked(item.name)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  <Label>{item.name}</Label>
                </DivisionDiv>
              )
              )
            }
          </DivForms>

        </ContainerInformations>
        <LineHorizontal style={{ marginBottom: '30px' }} />
        <NextStepContainer>
          <StepText>Step 3: {'Policy & Division'}</StepText>
          <div>
            <PreviousButton
              type="button"
              onClick={() => {
                setGeneralInformation(false);
                setTeams(true);
                setPolicyAndDivision(false);
                setPayment(false);
                setLossDescription(false);
              }}
            >
              Previous Step
            </PreviousButton>
            <SubmitButton
              type="button"
              onClick={() => checkPolicyInfo(formik.values)}
            >
              Next Step
            </SubmitButton>
          </div>
        </NextStepContainer>
      </>
    )
  }

  function generalInformationForm() {
    async function checkFormGeneralInfo(values) {
      try {
        setErrors({});
        const schema = Yup.object().shape({
          // job_name: Yup.string().required(),
          office_id: Yup.string().required(),
          customer_type: Yup.string().required(),
          received_by: Yup.string().required(),
          assigned_to: Yup.string().required(),
          referred_by: Yup.string().required(),
          status: Yup.string().required(),
          date_of_loss: Yup.date().required(),
          // loss_category: Yup.string().required(),
          loss_type: Yup.string().required(),
          // environmental_code: Yup.string().required(),
          priority: Yup.string().required(),
          customer_first_name: Yup.string().required(),
          customer_last_name: Yup.string().required(),
          customer_email: Yup.string().required(),
          customer_address: Yup.string().required(),
          customer_city: Yup.string().required(),
          customer_country: Yup.string().required(),
          customer_state: Yup.string().required(),
          customer_postal_code: Yup.string().required(),
          sameAsCustomerAddress: Yup.boolean(),
          job_first_name: Yup.string().when("sameAsCustomerAddress", {
            is: false,
            then: Yup.string().required()
          }),
          job_last_name: Yup.string().when("sameAsCustomerAddress", {
            is: false,
            then: Yup.string().required()
          }),
          job_address: Yup.string().when("sameAsCustomerAddress", {
            is: false,
            then: Yup.string().required()
          }),
          job_city: Yup.string().when("sameAsCustomerAddress", {
            is: false,
            then: Yup.string().required()
          }),
          job_country: Yup.string().when("sameAsCustomerAddress", {
            is: false,
            then: Yup.string().required()
          }),
          job_state: Yup.string().when("sameAsCustomerAddress", {
            is: false,
            then: Yup.string().required()
          }),
          job_postal_code: Yup.string().when("sameAsCustomerAddress", {
            is: false,
            then: Yup.string().required()
          }),
        });

        await schema.validate(values, {
          abortEarly: false,
        });
        setGeneralInformation(false);
        setTeams(true);
        setPolicyAndDivision(false);
        setPayment(false);
        setLossDescription(false);
      } catch (error) {
        console.error(error)
        setErrors(getValidationErros(error));
      }
    }

    function setCustomerInfo(id) {
      const customer = customers.find(
        element => element.customer_id && element.customer_id === id
      );
      if (customer) {
        formik.setFieldValue('customer_id', customer.customer_id)
        formik.setFieldValue('customer_type', customer.customer_type)
        formik.setFieldValue('customer_title', customer.customer_title)
        formik.setFieldValue('customer_first_name', customer.first_name)
        formik.setFieldValue('customer_last_name', customer.last_name)
        formik.setFieldValue('customer_email', customer.email)
        formik.setFieldValue('customer_secondary_email', customer.secondary_email)
        formik.setFieldValue('customer_address', customer.address)
        formik.setFieldValue('customer_postal_code', customer.postal_code)
        formik.setFieldValue('customer_city', customer.city)
        formik.setFieldValue('customer_region', customer.region)
        formik.setFieldValue('customer_country', customer.country)
        formik.setFieldValue('customer_state', customer.state)
        formik.setFieldValue('customer_business_phone', customer.business_phone)
        formik.setFieldValue('customer_mobile_phone', customer.mobile_phone)
        formik.setFieldValue('customer_fax_phone', customer.fax_phone)
        formik.setFieldValue('customer_other_phone', customer.other_phone)
        formik.setFieldValue('customer_type', customer.customer_type)
        formik.setFieldValue('customer_title', customer.customer_title)
      } else {
        formik.setFieldValue('customer', '')
        formik.setFieldValue('customer_first_name', '')
        formik.setFieldValue('customer_last_name', '')
        formik.setFieldValue('customer_email', '')
        formik.setFieldValue('customer_secondary_email', '')
        formik.setFieldValue('customer_address', '')
        formik.setFieldValue('customer_postal_code', '')
        formik.setFieldValue('customer_city', '')
        formik.setFieldValue('customer_region', '')
        formik.setFieldValue('customer_country', '')
        formik.setFieldValue('customer_state', '')
        formik.setFieldValue('customer_business_phone', '')
        formik.setFieldValue('customer_mobile_phone', '')
        formik.setFieldValue('customer_fax_phone', '')
        formik.setFieldValue('customer_other_phone', '')
        formik.setFieldValue('customer_type', '')
        formik.setFieldValue('customer_title', '')
      }
    }

    return (
      <>
        <ContainerInformations>
          <HeaderInformations>
            <img src={generalInfo} alt="generalInfo" />
            <text>General Information</text>
          </HeaderInformations>
          <LineHorizontal />
          <form onSubmit={formik.handleSubmit}>
            <GI1 >
              <DivInput >
                <Label style={errors.office_id ? { color: 'red' } : null}>
                  Branch
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="office_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.office_id}
                  style={
                    errors.office_id
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {offices.map(item => {
                    return (
                      <option key={item.office_id} value={item.office_id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
            </GI1>
            <GI1>
              <DivInput >
                <Label style={errors.loss_type ? { color: 'red' } : null}>
                  Loss Type
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="loss_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.loss_type}
                  style={
                    errors.loss_type
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    lossTypes.map(item => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
            </GI1>
            <GI2 >
              <DivInput >
                <Label >
                  Job Size
                </Label>
                <select
                  name="job_size"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.job_size}

                >
                  <option>
                    Choose an option
                  </option>
                  {jobSizes.map(item => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  )
                  )}
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.received_by ? { color: 'red' } : null}>
                  Sold By
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="received_by"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.received_by}
                  style={
                    errors.received_by
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    employees.map(item => {
                      return (
                        <option
                          key={item.employee_id}
                          value={item.employee_id}
                        >
                          {item.first_name + ' ' + item.last_name}
                        </option>
                      )
                    })
                  }
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.assigned_to ? { color: 'red' } : null}>
                  Project Manager
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="assigned_to"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.assigned_to}
                  style={
                    errors.assigned_to
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    employees.map(item => {
                      return (
                        <option
                          key={item.employee_id}
                          value={item.employee_id}
                        >
                          {item.first_name + ' ' + item.last_name}
                        </option>
                      )
                    })
                  }
                </select>
              </DivInput>
            </GI2>
            <GI1 style={{ marginBottom: 20 }}>
              <DivInput >
                <Label style={errors.referred_by ? { color: 'red' } : null}>
                  Referred By
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="referred_by"
                  onChange={e => {
                    formik.setFieldValue('referred_by', e.target.value);
                    formik.setFieldValue('referred_field', 'null');
                    listCustomerByCustomerType(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.referred_by}
                  style={
                    errors.referred_by
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    referredArray.map(item => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
              <DivInput>
                <select
                  // options={options}
                  disabled={
                    formik.values.referred_by === 'null' ||
                      !formik.values.referred_by ||
                      formik.values.referred_by === "Choose an option"
                      ? true
                      : false
                  }
                  placeholder='choose a option'
                  name='referred_field'
                  onChange={e => formik.setFieldValue('referred_field', e.target.value)}
                  value={setValueInRerredSelect()}
                >
                  {
                    options.map(item => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
            </GI1>
            {formik.values.referred_field != 'null' &&
              (
                <>
                  <Label>
                    Adjuster Information
                  </Label>
                  <CI2 >
                    <DivInput >
                      <Label
                      >
                        Name
                      </Label>
                      <input
                        name="name"
                        placeholder="Adjuster name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                    </DivInput>
                    <DivInput >
                      <Label>
                        Phone
                      </Label>
                      <input
                        name="phone"
                        placeholder="Adjuster phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />
                    </DivInput>
                    <DivInput >
                      <Label>
                        E-mail
                      </Label>
                      <input
                        name="email"
                        type="email"
                        placeholder="Adjuster email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </DivInput>
                  </CI2>
                </>
              )
            }
            <GI1 >
              <DivInput >
                <Label style={errors.date_of_loss ? { color: 'red' } : null}>
                  Date of loss
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="date_of_loss"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date_of_loss}
                  style={
                    errors.date_of_loss
                      ? { border: '1px solid red', textAlign: 'center', padding: 'inherit' }
                      : { textAlign: 'center', padding: 'inherit' }
                  }
                />
              </DivInput>
              <DivInput >
                <Label style={errors.status ? { color: 'red' } : null}>
                  Status
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.status}
                  style={
                    errors.status
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    jobStatus.map(item => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
            </GI1>
            <GI5 >
              <DivInput >
                <Label
                  style={errors.secondary_loss_type ? { color: 'red' } : null}
                >
                  Secondary Loss Type
                  {/* <span style={{ color: 'red' }}> *</span> */}
                </Label>
                <select
                  name="secondary_loss_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondary_loss_type}
                  style={
                    errors.secondary_loss_type
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    lossTypes.map(item => (
                      <option
                        key={item.id}
                        value={item.name}
                        style={
                          item.name === formik.values.loss_type
                            ? { display: 'none' }
                            : null}
                      >
                        {item.name}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
              <DivInput>
                <Label  >
                  Source of Loss
                </Label>
                <select
                  name="source_of_loss"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.source_of_loss}

                >
                  <option>
                    Choose an option
                  </option>
                  {
                    sourcesOfLoss.map(item => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
              <DivInput >
                <Label >
                  Loss Category
                  {/* <span style={{ color: 'red' }}> *</span> */}
                </Label>
                <select
                  name="loss_category"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.loss_category}
                // style={
                //   errors.loss_category
                //     ? { border: '1px solid red' }
                //     : null
                // }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    lossCategories.map(item => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
            </GI5>
            <GI1 >
              <DivInput >
                <Label
                  style={errors.environmental_code ? { color: 'red' } : null}
                >
                  Environmental Code
                  {/* <span style={{ color: 'red' }}> *</span> */}
                </Label>
                <select
                  name="environmental_code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.environmental_code}
                  style={
                    errors.environmental_code
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    environmentalCode.map(item => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.priority ? { color: 'red' } : null}>
                  Priority
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="priority"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.priority}
                  defaultValue={"Normal"}
                  style={
                    errors.priority
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    priorityArray.map(item => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))
                  }
                </select>
              </DivInput>
            </GI1>
            <HeaderInformations style={{ marginTop: '10px' }}>
              <img src={generalInfo} alt="generalInfo" />
              <text>Customer Information</text>
            </HeaderInformations>
            <LineHorizontal />
            <CI1 >
              <DivInput >
                <Label>
                  Customer
                </Label>
                <select
                  name="customer_id"
                  onChange={event => setCustomerInfo(event.currentTarget.value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_id}
                >)
                  <option key="" name="customer_id"
                    onClick={() => formik.setFieldValue('customer_id', "")}>
                    New Customer
                  </option>
                  {
                    customers.map((customer, i) => {
                      return (
                        <option
                          key={customer.customer_id}
                          value={customer.customer_id}
                        >
                          {customer.first_name + ' ' + customer.last_name}
                        </option>
                      )
                    })
                  }
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.customer_type ? { color: 'red' } : null}>
                  Customer Type
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="customer_type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_type}
                  style={
                    errors.customer_type
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option>
                    Choose an option
                  </option>
                  {
                    customerTypes.map(item => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )
                    })
                  }
                </select>
              </DivInput>
              <DivInput >
                <Label >
                  Title
                </Label>
                <select
                  name="customer_title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_title}

                >
                  <option>
                    Choose an option
                  </option>
                  {
                    customerTitles.map(item => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )
                    })
                  }
                </select>
              </DivInput>
            </CI1>
            <CI2 >
              <DivInput >
                <Label
                  style={errors.customer_first_name ? { color: 'red' } : null}
                >
                  First Name
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_first_name"
                  placeholder="Customer's first name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_first_name}
                  style={
                    errors.customer_first_name
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label
                  style={errors.customer_last_name ? { color: 'red' } : null}
                >
                  Last Name
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_last_name"
                  placeholder="Customer's last name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_last_name}
                  style={
                    errors.customer_last_name
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label
                  style={errors.customer_email ? { color: 'red' } : null}
                >
                  E-mail
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_email"
                  placeholder="Customer's email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_email}
                  style={
                    errors.customer_email
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label>
                  Secondary E-mail
                </Label>
                <input
                  name="customer_secondary_email"
                  placeholder="Customer's secondary e-mail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_secondary_email}
                />
              </DivInput>
            </CI2>
            <CI3 >
              <DivInput >
                <Label
                  style={errors.customer_address ? { color: 'red' } : null}
                >
                  Address
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_address"
                  ref={autoCompleteRef}
                  placeholder="Job's address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFocus={() => loadScript(
                    `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Z-ODAYUDEjetAqP-tuuH4m1w5Xx2RgU&libraries=places`,
                    () => handleScriptLoad(formik.setFieldValue, autoCompleteRef)
                  )}
                  value={formik.values.customer_address}
                  style={
                    errors.customer_address
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label
                  style={errors.customer_postal_code ? { color: 'red' } : null}
                >
                  Postal Code
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_postal_code"
                  placeholder="e.g 'xxxxx-xxx'"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_postal_code}
                  style={
                    errors.customer_postal_code
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </CI3>
            <CI2 >
              <DivInput >
                <Label
                  style={errors.customer_city ? { color: 'red' } : null}
                >
                  City
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_city"
                  placeholder="Job's address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_city}
                  style={
                    errors.customer_city
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label>
                  Region
                </Label>
                <input
                  name="customer_region"
                  placeholder="e.g 'xxxxx-xxx'"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_region}
                />
              </DivInput>
              <DivInput >
                <Label
                  style={errors.customer_country ? { color: 'red' } : null}
                >
                  Country
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_country"
                  placeholder="e.g 'xxxxx-xxx'"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_country}
                  style={
                    errors.customer_country
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label
                  style={errors.customer_state ? { color: 'red' } : null}
                >
                  State/Province
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_state"
                  placeholder="e.g 'xxxxx-xxx'"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_state}
                  style={
                    errors.customer_state
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </CI2>
            <CI2 >
              <DivInput >
                <Label>
                  Telephone Numbers
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="customer_business_phone"
                  placeholder="Business phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.customer_business_phone
                  }
                />
              </DivInput>
              <DivInput >
                <input
                  name="customer_mobile_phone"
                  placeholder="Mobile phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_mobile_phone

                  }
                  style={{ marginTop: '30px' }}
                />
              </DivInput>
              <DivInput >
                <input
                  name="customer_fax_phone"
                  placeholder="FAX phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_fax_phone

                  }
                  style={{ marginTop: '30px' }}
                />
              </DivInput>
              <DivInput >
                <input
                  name="customer_other_phone"
                  placeholder="Other phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customer_other_phone

                  }
                  style={{ marginTop: '30px' }}
                />
              </DivInput>
            </CI2>
            <HeaderInformations style={{ marginTop: '10px' }}>
              <img src={generalInfo} alt="generalInfo" />
              <text>Job Address</text>
            </HeaderInformations>
            <LineHorizontal />
            <DivForms>
              <DivInput>
                <div>
                  <Checkbox
                    name="sameAsCustomerAddress"
                    onChange={formik.handleChange}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={formik.values.sameAsCustomerAddress}
                    color="primary"
                  />
                  <Label>Same as Customer Address</Label>
                </div>
              </DivInput>
            </DivForms>
            <JA1 >
              <DivInput>
                <Label
                  style={errors.job_first_name ? { color: 'red' } : null}
                >
                  First Name
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_first_name"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="Customer's first name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_first_name
                      : formik.values.job_first_name
                  }
                  style={
                    errors.job_first_name
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput>
                <Label
                  style={errors.job_last_name ? { color: 'red' } : null}
                >
                  Last Name
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_last_name"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="Customer's last name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_last_name
                      : formik.values.job_last_name
                  }
                  style={
                    errors.job_last_name
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </JA1>
            <JA2 >
              <DivInput >
                <Label
                  style={errors.job_address ? { color: 'red' } : null}
                >
                  Address
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_address"
                  ref={autoCompleteRef2}
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="Job's address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFocus={() => loadScript2(
                    `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Z-ODAYUDEjetAqP-tuuH4m1w5Xx2RgU&libraries=places`,
                    () => handleScriptLoad2(formik.setFieldValue, autoCompleteRef2)
                  )}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_address
                      : formik.values.job_address
                  }
                  style={
                    errors.job_address
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label
                  style={errors.job_postal_code ? { color: 'red' } : null}
                >
                  Postal Code
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_postal_code"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="e.g 'xxxxx-xxx'"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_postal_code
                      : formik.values.job_postal_code
                  }
                  style={
                    errors.job_postal_code
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </JA2>
            <JA3 >
              <DivInput >
                <Label
                  style={errors.job_city ? { color: 'red' } : null}
                >
                  City
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_city"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="Job's address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_city
                      : formik.values.job_city
                  }
                  style={
                    errors.job_city
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label>
                  Region
                </Label>
                <input
                  name="job_region"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="e.g 'xxxxx-xxx'"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_region
                      : formik.values.job_region
                  }
                />
              </DivInput>
              <DivInput >
                <Label
                  style={errors.job_country ? { color: 'red' } : null}
                >
                  Country
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_country"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="e.g 'xxxxx-xxx'"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_country
                      : formik.values.job_country
                  }
                  style={
                    errors.job_country
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
              <DivInput >
                <Label
                  style={errors.job_state ? { color: 'red' } : null}
                >
                  State/Province
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="job_state"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="e.g 'xxxxx-xxx'"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_state
                      : formik.values.job_state
                  }
                  style={
                    errors.job_state
                      ? { border: '1px solid red' }
                      : null
                  }
                />
              </DivInput>
            </JA3>

            <JA3 >
              <DivInput >
                <Label>
                  Telephone Numbers
                  <span style={{ color: 'red' }}> *</span>
                </Label>
                <input
                  name="business_phone"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="Business phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_business_phone
                      : formik.values.business_phone
                  }
                />
              </DivInput>
              <DivInput >
                <input
                  name="mobile_phone"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="Mobile phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_mobile_phone
                      : formik.values.mobile_phone
                  }
                  style={{ marginTop: '30px' }}
                />
              </DivInput>
              <DivInput >
                <input
                  name="fax_phone"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="FAX phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_fax_phone
                      : formik.values.fax_phone
                  }
                  style={{ marginTop: '30px' }}
                />
              </DivInput>
              <DivInput >
                <input
                  name="other_phone"
                  disabled={formik.values.sameAsCustomerAddress}
                  placeholder="Other phone number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.sameAsCustomerAddress
                      ? formik.values.customer_other_phone
                      : formik.values.other_phone
                  }
                  style={{ marginTop: '30px' }}
                />
              </DivInput>
            </JA3>
          </form>
        </ContainerInformations>
        <LineHorizontal style={{ marginBottom: '30px' }} />
        <NextStepContainer>
          <StepText>Step 1: General Information</StepText>
          <SubmitButton type="button" onClick={() => checkFormGeneralInfo(formik.values)}>Next Step</SubmitButton>
        </NextStepContainer>
      </>
    )
  }

  function teamsForm() {
    async function checkTeamsInfo(values) {
      try {
        setErrors({});
        const schema = Yup.object().shape({
          director: Yup.string().required(),
          // project_manager: Yup.string().required(),
          lead_tech: Yup.string().required(),
          controller: Yup.string().required(),
        });

        await schema.validate(values, {
          abortEarly: false,
        });
        setGeneralInformation(false);
        setTeams(false);
        setPolicyAndDivision(true);
        setPayment(false);
        setLossDescription(false);
      }
      catch (error) {
        console.error(error)
        setErrors(getValidationErros(error));
      }
    }

    return (
      <>
        <ContainerInformations>
          <HeaderInformations>
            <img src={generalInfo} alt="generalInfo" />
            <text>Internal Teams</text>
          </HeaderInformations>
          <LineHorizontal />
          <IT1>
            <DivInput >
              <Label style={errors.director ? { color: 'red' } : null}>
                Director
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="director"
                placeholder="Customer's first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.director}
                style={
                  errors.director
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option
                        key={item.employee_id}
                        value={item.employee_id}
                      >
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
            <DivInput >
              <Label style={errors.project_manager ? { color: 'red' } : null}>
                Supervisor
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="project_manager"
                placeholder="Customer's first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.project_manager ? formik.values.project_manager : formik.values.assigned_to}
                style={
                  errors.project_manager
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option
                        key={item.employee_id}
                        value={item.employee_id}
                      >
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
            <DivInput >
              <Label style={errors.lead_tech ? { color: 'red' } : null}>
                Lead Tech
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="lead_tech"
                placeholder="Customer's first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lead_tech}
                style={
                  errors.lead_tech
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option
                        key={item.employee_id}
                        value={item.employee_id}
                      >
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
            <DivInput >
              <Label style={errors.controller ? { color: 'red' } : null}>
                Controller
                <span style={{ color: 'red' }}> *</span>
              </Label>
              <select
                name="controller"
                placeholder="Customer's first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.controller}
                style={
                  errors.controller
                    ? { border: '1px solid red' }
                    : null
                }
              >
                <option>
                  Choose an option
                </option>
                {
                  employees.map(item => {
                    return (
                      <option
                        key={item.employee_id}
                        value={item.employee_id}
                      >
                        {item.first_name + ' ' + item.last_name}
                      </option>
                    )
                  })
                }
              </select>
            </DivInput>
          </IT1>
          <IT2>
            <DivInput>
              <Label >
                Tecnicians
              </Label>
              <SearchableSelect
                options={employeesOptions}
                label="Tecnicians"
                id="Tecnicians"
                selectedVal={" "}
                handleChange={(val) => handleTecniciansChange(val)} 
              />
            </DivInput>
            <DivInput style={{
              border: '1px solid #ececec', flexDirection: 'row',
              flexWrap: 'wrap', minHeight: 42, maxHeight: 100,
              alignItems: 'center', overflowY: 'auto',
              fontSize: '0.8125rem', borderRadius: 10,
              marginTop: '5px'
            }}>
              <Label style={{ width: '100%', marginLeft: 5 }}>
                Selected Tecnicians:
              </Label>
              {selectedTecnicians.map(employee => {
                return (
                  <StoragesDiv key={employee.employee_id}>
                    {employee.name}
                    <div style={{
                      width: 24, height: 24, backgroundColor: '#E0E0E0',
                      borderRadius: 24, marginLeft: '5px'
                    }}>
                      <Clear
                        style={{ width: 20 }}
                        onClick={() => handleRemoveTecnicians(employee.employee_id)} />
                    </div>
                  </StoragesDiv>
                )
              })}
            </DivInput>
          </IT2>
        </ContainerInformations>
        <LineHorizontal style={{ marginBottom: '30px' }} />
        <NextStepContainer>
          <StepText>Step 2: Teams</StepText>
          <div>
            <PreviousButton
              type="button"
              onClick={() => {
                setGeneralInformation(true);
                setTeams(false);
                setPolicyAndDivision(false);
                setPayment(false);
                setLossDescription(false);
              }}
            >
              Previous Step
            </PreviousButton>
            <SubmitButton
              type="button"
              onClick={() => checkTeamsInfo(formik.values)}
            >
              Next Step
            </SubmitButton>
          </div>
        </NextStepContainer>
      </>
    )
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getLossCategories() {

    const response = await api.get('/loss-category', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setLossCategories(response.data);

  }

  async function getLossTypes() {

    const response = await api.get('/loss-type', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setLossTypes(response.data);

  }

  async function getSourcesOfLoss() {

    const response = await api.get('/source-of-loss', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setSourcesOfLoss(response.data);

  }

  async function getJobSizes() {

    const response = await api.get('/job-size', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setJobSizes(response.data);

  }

  async function getRoomsAffected() {

    const response = await api.get('/room-affected', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    const array = response.data.map(room => room.name)

    setRoomsAffected(array);

  }

  async function getEnvironmentalCode() {
    // setLoadingInformations(true);
    const response = await api.get('/environmental-code', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEnvironmentalCode(response.data);
    // setLoadingInformations(false);
  }

  async function getEmployees() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEmployees(response.data);

    setEmployeesOptions(MountEmployeeOptions(response.data)) 
  }

  async function getCustomerTitles() {
    const response = await api.get('/customer-title', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setCustomerTitles(response.data);
  }

  async function getDivisions() {
    const response = await api.get('/division', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setDivisions(response.data);
  }

  useEffect(() => {
    getIP();
    getOffices();
    getJobStatus();
    getCustomers();
    getLossCategories();
    getLossTypes();
    getSourcesOfLoss();
    getJobSizes();
    getRoomsAffected();
    getEnvironmentalCode();
    getEmployees();
    getDivisions();
    getCustomerTitles();
    setYearsArray();
    // loadScript(
    //   `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Z-ODAYUDEjetAqP-tuuH4m1w5Xx2RgU&libraries=places`,
    //   () => handleScriptLoad(formik.setFieldValue, autoCompleteRef)
    // );
    // loadScript2(
    //   `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Z-ODAYUDEjetAqP-tuuH4m1w5Xx2RgU&libraries=places`,
    //   () => handleScriptLoad2(formik.setFieldValue, autoCompleteRef2)
    // );
  }, []);

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Job Register</text>
              </div>
            </ContainerHeader>
            <Formik>
              <Form >
                <NavTab>
                  <li
                    onClick={() => {
                      setGeneralInformation(true);
                      setTeams(false);
                      setPolicyAndDivision(false);
                      setPayment(false);
                      setLossDescription(false);
                    }}
                    style={
                      generalInformation ? { backgroundColor: '#618095' } : null
                    }
                  >
                    <a href="#generalinfo">1. General Information</a>
                  </li>
                  <li
                    onClick={() => {
                      setGeneralInformation(false);
                      setTeams(true);
                      setPolicyAndDivision(false);
                      setPayment(false);
                      setLossDescription(false);
                    }}
                    style={
                      teams ? { backgroundColor: '#618095' } : null
                    }
                  >
                    <a href="#teams">2. Teams</a>
                  </li>
                  <li
                    onClick={() => {
                      setGeneralInformation(false);
                      setTeams(false);
                      setPolicyAndDivision(true);
                      setPayment(false);
                      setLossDescription(false);
                    }}
                    style={policyAndDivision ? { backgroundColor: '#618095' } : null}
                  >
                    <a href="#policy">{'3. Policy & Division'}</a>
                  </li>
                  <li
                    onClick={() => {
                      setGeneralInformation(false);
                      setTeams(false);
                      setPolicyAndDivision(false);
                      setPayment(true);
                      setLossDescription(false);
                    }}
                    style={payment ? { backgroundColor: '#618095' } : null}
                  >
                    <a href="#payment">4. Payment</a>
                  </li>
                  <li
                    onClick={() => {
                      setGeneralInformation(false);
                      setTeams(false);
                      setPolicyAndDivision(false);
                      setPayment(false);
                      setLossDescription(true);
                    }}
                    style={lossDescription ? { backgroundColor: '#618095' } : null}
                  >
                    <a href="#lossDescription">5. Loss Description</a>
                  </li>
                </NavTab>
                {generalInformation && generalInformationForm()}
                {teams && teamsForm()}
                {policyAndDivision && policyAndDivisionForm()}
                {payment && paymentForm()}
                {lossDescription && lossDescriptionForm()}
              </Form>
            </Formik>
          </ ContainerForms>
        </ ContainerBackground>
      </ ContainerColumn>
    </Container>
  );
}

export default JobRegister;