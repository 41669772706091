import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik, Field, Form, useFormik } from 'formik';
import { useParams } from "react-router-dom";

import QRCode from 'qrcode.react';

import ProductDetails from '../../assets/icons/ProductDetails.svg'
import AddPhotos from '../../assets/icons/AddPhotos.svg'
import CircularProgress from '@material-ui/core/CircularProgress';


import api from '../../services/api';

import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  ContainerInformations,
  HeaderInformations,
  LineVertical,
  LineHorizontal,
  DivInput,
  Label,
  SubmitButton,
  DarkContainer,
  StepText,
  NextStepContainer,
  NavTab,
  FullDiv,
  Half2Div,
  Half3Div,
  DivPicture,
  UploadButton,
  DeleteButton
} from './styles';

import getValidationErros from '../../utils/getValidationErrors';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import SuccessPopUp from '../../components/SuccessPopUp';

import ToastContainer from '../../components/ToastContainer';

function index() {
  let { storage_id } = useParams();

  const [errors, setErrors] = useState({});
  const [offices, setOffices] = useState([])
  const [sections, setSections] = useState([])
  const [ailes, setAiles] = useState([])
  const [pallets, setPallets] = useState([])
  const [vaults, setVaults] = useState([])
  const [ip, setIp] = useState();
  const [storageEdit, setStorageEdit] = useState({})

  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [images, setImages] = useState([])


  const typeArray = ['Section', 'Aile', 'Pallet', 'Vault', 'Box']
  const primaryOfficeArray = []

  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }


  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      office_id: '',
      section: '',
      aile: '',
      pallets: '',
      vaults: '',
      photo_url: '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  async function handleSubmit(values) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        type: Yup.string().required('Type is required'),
        office_id: Yup.string().required('Office is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      if (storage_id) {
        updateStorage(values)
      }
      else {
        createStorage(values);
      }
    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }

  async function createStorage(values) {
    setLoading(true);
    const data = new FormData();
    data.append('name', values.name)
    data.append('type', values.type)
    data.append('photo_url', values.photo_url)
    data.append('office_id', values.office_id)
    data.append('section', values.section)
    data.append('aile', values.aile)
    data.append('pallets', values.pallets)
    data.append('vaults', values.vaults)
    data.append('ip', ip)
    await api
      .post('/storage', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then(response => {
        // handleClickOpen();
        window.location.href = `${process.env.REACT_APP_WEB_URL}/inventory/storagesettings`;
        // window.location.href = "http://localhost:3000/inventory/storagesettings";
        setLoading(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function updateStorage(values) {
    setLoading(true);
    const dataEdit = new FormData()
    dataEdit.append('storage_id', storage_id)
    dataEdit.append('name', values.name)
    dataEdit.append('type', values.type)
    dataEdit.append('photo_url', values.photo_url)
    dataEdit.append('office_id', values.office_id)
    dataEdit.append('section', values.section)
    dataEdit.append('aile', values.aile)
    dataEdit.append('pallets', values.pallets)
    dataEdit.append('vaults', values.vaults)
    dataEdit.append('ip', ip)
    await api
      .patch('/storage/update', dataEdit, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then(response => {
        // handleClickOpen();
        window.location.href = `${process.env.REACT_APP_WEB_URL}/inventory/storagesettings`;
        setLoading(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function deleteStorage() {
    setLoadingDelete(true);
    api
      .delete(
        `/storage/delete/${storageEdit.storage_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/inventory/storagesettings`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Storage successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting storage',
        });
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getOffices() {
    api
      .get('/office', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        setOffices(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getStorages() {
    let sectionsArray = []
    let ailesArray = []
    let palletsArray = []
    let vaultsArray = []
    api
      .get('/storage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        
        if (storage_id) {
          let storageToEdit = res.data.find(storage => storage.storage_id === storage_id)
          setStorageEdit(storageToEdit)
        }
        res.data.forEach(element => {
          if (element.type === 'Section')
            sectionsArray.push(element)
          else if (element.type === 'Aile')
            ailesArray.push(element)
          else if (element.type === 'Pallet')
            palletsArray.push(element)
          else if (element.type === 'Vault')
            vaultsArray.push(element)
        });
        setSections(sectionsArray)
        setAiles(ailesArray)
        setPallets(palletsArray)
        setVaults(vaultsArray)
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    getIP()
    getOffices()
    getStorages()
  }, []);

  useEffect(() => {
    if (storage_id) {
      formik.setFieldValue('name', storageEdit.name)
      formik.setFieldValue('type', storageEdit.type)
      formik.setFieldValue('office_id', storageEdit.office_id)
      formik.setFieldValue('section', storageEdit.section)
      formik.setFieldValue('aile', storageEdit.aile)
      formik.setFieldValue('pallets', storageEdit.pallets)
      formik.setFieldValue('vaults', storageEdit.vaults)
      formik.setFieldValue('photo_url', storageEdit.photo_url)
    }
  }, [storageEdit]);

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Storage Registration</text>
              </div>
            </ContainerHeader>
            <Formik>
              <Form onSubmit={formik.handleSubmit}>
                <NavTab>
                  <li
                    style={
                      { backgroundColor: '#618095' }
                    }
                  >
                    <a>1. Storage Information</a>
                  </li>
                </NavTab>

                <ContainerInformations>
                  <HeaderInformations>
                    <img height={15} src={ProductDetails} style={{ marginRight: '10px' }}></img>
                    <text>Storage Details</text>
                  </HeaderInformations>
                  <LineHorizontal />
                  <Half2Div >
                    <DivInput >
                      <Label style={errors.name ? { color: 'red' } : null}>
                        Name
                          <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <input
                        name="name"
                        placeholder="Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        style={
                          errors.name
                            ? { border: '1px solid red' }
                            : null
                        }
                      />
                    </DivInput>
                    <DivInput >
                      <Label style={errors.type ? { color: 'red' } : null}>
                        Type
                          <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <select
                        name="type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.type}
                        style={
                          errors.type
                            ? { border: '1px solid red' }
                            : null
                        }
                      >
                        <option value="" disabled selected>
                          Choose an option
                          </option>
                        {typeArray.map(item => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          )
                        })}
                      </select>
                    </DivInput >
                  </Half2Div>
                  <Half3Div>
                    <DivInput >
                      <Label style={errors.office_id ? { color: 'red' } : null}>
                        Office ID
                          <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <select
                        name="office_id"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.office_id}
                        style={
                          errors.office_id
                            ? { border: '1px solid red' }
                            : null
                        }
                      >
                        <option value="" disabled selected>
                          Choose an option
                          </option>
                        {offices.map(item => {
                          return (
                            <option key={item.office_id} value={item.office_id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </DivInput>
                    {formik.values.type !== 'Section' && (
                      <DivInput >
                        <Label >
                          Section
                      </Label>
                        <select
                          // disabled={formik.values.type === 'Section'}
                          name="section"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.section}
                        >
                          <option value="" disabled selected>
                            Choose an option
                          </option>
                          {sections.map(item => {
                            if (item.office_id === formik.values.office_id) {
                              return (
                                <option key={item.storage_id} value={item.storage_id}>
                                  {item.storage_id}
                                </option>
                              )
                            }
                          })}
                        </select>
                      </DivInput>
                    )}
                    {formik.values.type !== 'Section' &&
                      formik.values.type !== 'Aile' && (
                        <DivInput >
                          <Label >
                            Aile
                          </Label>
                          <select
                            // disabled={formik.values.type === 'Section' ||
                            //   formik.values.type === 'Aile'
                            // }
                            name="aile"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.aile}
                          >
                            <option value="" disabled selected>
                              Choose an option
                          </option>
                            {ailes.map(item => {
                              if (item.section === formik.values.section) {
                                return (
                                  <option key={item.storage_id} value={item.storage_id}>
                                    {item.storage_id}
                                  </option>
                                )
                              }
                            })}
                          </select>
                        </DivInput>
                      )}
                  </Half3Div>
                  <Half3Div >
                    {formik.values.type !== 'Section' &&
                      formik.values.type !== 'Aile' &&
                      formik.values.type !== 'Pallet' && (
                        <DivInput >
                          <Label >
                            Pallets
                          </Label>
                          <select
                            // disabled={formik.values.type === 'Section' ||
                            //   formik.values.type === 'Aile' ||
                            //   formik.values.type === 'Pallet'}
                            name="pallets"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pallets}
                          >
                            <option value="" disabled selected>
                              Choose an option
                          </option>
                            {pallets.map(item => {
                              if (item.aile === formik.values.aile) {
                                return (
                                  <option key={item.storage_id} value={item.storage_id}>
                                    {item.storage_id}
                                  </option>
                                )
                              }
                            })}
                          </select>
                        </DivInput>
                      )}
                    {formik.values.type !== 'Section' &&
                      formik.values.type !== 'Aile' &&
                      formik.values.type !== 'Pallet' &&
                      formik.values.type !== 'Vault' && (

                        <DivInput >
                          <Label >
                            Vaults
                      </Label>
                          <select
                            // disabled={formik.values.type === 'Section' ||
                            //   formik.values.type === 'Aile' ||
                            //   formik.values.type === 'Pallet' ||
                            //   formik.values.type === 'Vault'}
                            name="vaults"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.vaults}
                          >
                            <option value="" disabled selected>
                              Choose an option
                          </option>
                            {vaults.map(item => {
                              if (item.pallets === formik.values.pallets) {
                                return (
                                  <option key={item.storage_id} value={item.storage_id}>
                                    {item.storage_id}
                                  </option>
                                )
                              }
                            })}
                          </select>
                        </DivInput>
                      )}
                  </Half3Div>
                  {storage_id &&
                    <Half2Div>
                      <DivInput>
                        <Label style={{ marginBottom: 10 }}>
                          QR Code
                        </Label>
                        <QRCode id="qrcode" value={storage_id} />
                      </DivInput>
                    </Half2Div>
                  }
                  <FullDiv >
                    <DivInput>
                      <Label>Add photo</Label>
                      <DivPicture>
                        {formik.values.photo_url && !storageEdit.name &&
                          <img
                            src={URL.createObjectURL(formik.values.photo_url)}
                            alt="photo"
                          />
                        }
                        {formik.values.photo_url && storageEdit.name &&
                          <img
                            src={formik.values.photo_url}
                            alt="photo"
                          />
                        }
                        <UploadButton for="upload-photo" style={{ marginBottom: 0 }}>
                          <img src={AddPhotos} ></img>
                            Add photo
                            </UploadButton>
                        <Field
                          name="avatar"
                          type="file"
                          className="overflow-hidden"
                          id="upload-photo"
                          style={{ opacity: 0, zIndex: -1 }}
                          onChange={event => {
                            formik.setFieldValue(
                              'photo_url',
                              event.currentTarget.files[0],
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {!storageEdit.name &&
                          <>
                          </>
                        }
                      </DivPicture>
                    </DivInput>
                  </FullDiv>
                  <LineHorizontal style={{ marginBottom: '30px' }} />
                  <NextStepContainer >
                    {storageEdit.name ?
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <DeleteButton
                          type="button"
                          onClick={() => deleteStorage()}
                          style={{ alignSelf: 'flex-start' }}>
                          {loadingDelete ? (
                            <CircularProgress color="inherit" size={25} />
                          ) : (
                            'Delete'
                          )}
                        </DeleteButton>
                        <SubmitButton type="submit">
                          {loading ? (
                            <CircularProgress color="inherit" size={25} />
                          ) : (
                            'Edit'
                          )}
                        </SubmitButton>
                      </div>
                      :
                      <SubmitButton type="submit">
                        {loading ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          'Save'
                        )}
                      </SubmitButton>
                    }
                  </NextStepContainer>
                </ContainerInformations>
              </Form>
            </Formik>
          </ ContainerForms>
        </ ContainerBackground>
      </ ContainerColumn>
    </Container >
  );
}

export default index;