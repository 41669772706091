import React, { useEffect, useState } from 'react';

import api from '../../../../../../services/api';

import { Formik, Field, Form, useFormik } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';

import Group from '../../../../../../assets/icons/Group.svg'
import {
  Container,
  LineHorizontal,
  HeaderInformations,
  ContentBody,
  Label,
  Options,
  OptionsContainer,
  Buttons
} from './styles'

function AddTemplate({ closeModal, setFieldValue, setActivityType }) {
  const [templates, setTemplates] = useState([])
  const templateTypeArray = ['Email', 'Call', 'Visit', 'Task']
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const formik = useFormik({
    initialValues: {
      template_type: '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  async function getTemplateById(templateType) {
    api
      .get(`template/list-by-activity-type/${templateType}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        // 
        setTemplates(res.data)
      });
  }

  const handleChange = (event,template) => {
    setSelectedValue(event.target.value);
    setSelectedTemplate(template)
  };

  const setValues = (setFieldValue) => {
    setFieldValue('activity_name', selectedTemplate.template_name)
    setFieldValue('activity_type', selectedTemplate.template_type)
    setFieldValue('priority', selectedTemplate.priority)
    setFieldValue('description', selectedTemplate.description)
    setActivityType(selectedTemplate.template_type)
    closeModal(false)
  }

  useEffect(() => {
    if (formik.values.template_type.length > 0)
      getTemplateById(formik.values.template_type)
  }, [formik.values.template_type])
  return (
    <Container>
      <HeaderInformations>
        <text>
          Choose an Activity Template
        </text>
      </HeaderInformations>
      <LineHorizontal />
      <ContentBody>
        <img height={20} src={Group} style={{ marginRight: '10px' }}></img>
        <text>Template Options</text>
        <LineHorizontal style={{ marginTop: '10px' }} />
        <Formik >
          <div style={{ width: '100%', marginTop: '20px' }}>
            <div >
              <Label>
                Template Type
                      <span style={{ color: 'red' }}> *</span>
              </Label>
              <Field
                name="template_type"
                as="select"
                onChange={formik.handleChange}
                value={formik.values.template_type}
              >
                <option value="" disabled selected>
                  Choose an option
                </option>
                {templateTypeArray.map(name => {
                  return (
                    <option
                      key={name}
                      value={name}
                    >
                      {name}
                    </option>
                  );
                })}
              </Field>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Label>
                Choose the options
                      <span style={{ color: 'red' }}> *</span>
              </Label>
              <OptionsContainer >
                {templates.length > 0 ? templates.map(template => {
                  return (
                    <Options>
                      <Radio
                        checked={selectedValue === template.template_name}
                        onChange={(event) =>handleChange(event, template)}
                        value={template.template_name}
                        name="radio-button-demo"
                        color={'primary'}
                      // inputProps={{ 'aria-label': 'B' }}
                      />
                      <text>
                        {template.template_name}
                      </text>
                    </Options>
                  )
                }) : `To see the ${formik.values.template_type} 
                  templates, please create one in Templates section.`}
              </OptionsContainer>
            </div>
          </div>
        </Formik>
      </ContentBody>
      <LineHorizontal />
      <Buttons >
        <button onClick={() => closeModal(false)} type="button" style={{
          color: '#82ce5e',
          border: '1px solid #82ce5e',
          backgroundColor: 'white'
        }}>Cancel</button>
        <button onClick={() => setValues(setFieldValue)} type="button">OK</button>
      </Buttons>
    </Container>
  );
}

export default AddTemplate;
