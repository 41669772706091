import styled from 'styled-components';

export const ContainerColumns = styled.div`
  display: flex;
  width: 85vw;
`;

export const TaskList = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  overflow-x: scroll;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
`;

export const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 93vw;
  min-height: 93vh;
  overflow-y: auto;
`;

export const Title = styled.span`
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
`;

export const FilterContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
`;