import React, { useState, useEffect, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Field, Form, useFormik } from 'formik';
import { Link, useLocation } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';

import {
  ContainerBackground,
  ContainerLogin,
  DivIllustration,
  DivInformation,
  DivForms,
  DivTexts,
  WelcomeText,
  InformationText,
  Input,
  ButtonLogin,
  ForgotPasswordText,
  ClickHereText,
} from './styles';

import getValidationErros from '../../utils/getValidationErrors';

import ToastContainer from '../../components/ToastContainer';

import loginIllustration from '../../assets/loginIllustration.png';
import logo from '../../assets/logo.png';

import { AuthContext } from '../../contexts/Auth';

function Login() {
  const { signIn, loadingAuth } = useContext(AuthContext);
  const [ip, setIp] = useState('0.0.0.0');

  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState({ show: false });
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const emailNotification = location.emailNotification || false;

  function handleToast() {
    setShowToast({ show: false });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error('error', error);
        setIp('0.0.0.0');
      });
  }

  async function handleSubmit(values) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail is required')
          .email('Invalid e-mail'),
        password: Yup.string().min(6),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      signIn({ email: values.email, password: values.password, ip });
    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      console.error(errors);
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const showEmailNotification = () => {
    setShowToast({
      show: true,
      type: 'success',
      title: 'Email Sent.',
      description: 'An email has been sent to your inbox.',
    });
  }

  useEffect(() => {
    getIP();
    if (emailNotification)
      showEmailNotification()
  }, []);

  return (
    <ContainerBackground>
      {showToast.show && (
        <ToastContainer messages={showToast} show={() => handleToast()} />
      )}
      <ContainerLogin>
        <DivIllustration>
          <img src={loginIllustration} alt="Illustration" />
        </DivIllustration>
        <DivInformation >
          <img src={process.env.REACT_APP_LOGO_URL} alt="Logo" />
          <DivTexts>
            <WelcomeText>Welcome!</WelcomeText>
            <InformationText>
              Enter your email address and password to access system
            </InformationText>
            <Formik>
              <Form onSubmit={formik.handleSubmit}>
                <DivForms>
                  <Field
                    name="email"
                    type="email"
                    placeholder="E-mail Address"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    style={errors.email ? { border: '1px solid red' } : null}
                  />
                  <Field
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    style={errors.password ? { border: '1px solid red' } : null}
                  />
                  <ButtonLogin type="submit">
                    {loadingAuth ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      'Log In'
                    )}
                  </ButtonLogin>
                  <div className="mt-3">
                    <ForgotPasswordText>
                      Forgot your password?
                    </ForgotPasswordText>
                    <Link to="/forgotPassword">
                      <ClickHereText> Click here</ClickHereText>
                    </Link>
                  </div>
                </DivForms>
              </Form>
            </Formik>
          </DivTexts>
        </DivInformation>
      </ContainerLogin>
    </ContainerBackground>
  );
}

export default Login;
