export function MountEmployeeOptions(data) {
  const arrayOptions = []
  data.map(employee => {
    arrayOptions.push({
      id: employee.employee_id,
      name: `${employee.first_name} ${employee.last_name}`
    });
  });

  arrayOptions.sort(CompareEmployeesOptions)

  return arrayOptions;
}

const CompareEmployeesOptions = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};