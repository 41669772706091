import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import { AuthContext } from '../contexts/Auth';

import Router from './Router';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import EmployeeRegister from '../pages/EmployeeRegister';
import Employee from '../pages/Employee';
import Settings from '../pages/Settings';
import EmployeeEdit from '../pages/EmployeeEdit';
import CustomerRegister from '../pages/CustomerRegister';
import Customer from '../pages/Customer';
import Jobs from '../pages/Jobs';
import JobViewer from '../pages/Jobs/JobViewer';
import ActivityRegistration from '../pages/Jobs/JobViewer/Activities/ActivityRegistration';
import ActivityEdit from '../pages/Jobs/JobViewer/Activities/ActivityEdit';
import CustomerEdit from '../pages/CustomerEdit';
import ForgotPassword from '../pages/ForgotPassword';
import JobRegister from '../pages/JobRegister'
import TemplateRegistration from '../pages/Templates/TemplateRegistration'
import TemplateEdit from '../pages/Templates/TemplateEdit'
import Templates from '../pages/Templates'
import ItemRegistration from '../pages/ItemRegistration'
import Inventory from '../pages/Inventory'
import InventoryRegistration from '../pages/InventoryRegistration'
import InventoryViewer from '../pages/InventoryViewer'
import StorageViewer from '../pages/StorageViewer'
import StorageRegistration from '../pages/StorageRegistration'
import Workflow from '../pages/Jobs/JobViewer/JobSettings/Workflow'
import NewContract from '../pages/NewContract'
import ContractsTemplatesER from '../pages/Templates/ContractsTemplatesER'
import Payments from '../pages/Jobs/JobViewer/Payments'
import Profile from '../pages/Profile'
import ResetPassword from '../pages/ResetPassword'
import MyTasks from '../pages/MyTasks';
import KanbanTasks from '../pages/MyTasks/KanbanView'
import NoteViewer from '../pages/Jobs/JobViewer/GeneralNote/NoteViewer'

export default function Routes() {
  return (
    <Switch>
      <Router path="/" exact component={Login} />
      <Router path="/forgotPassword" exact component={ForgotPassword} />
      <Router path="/forgotpassword/:token" exact component={ResetPassword} />
      <Router path="/dashboard" exact component={Dashboard} isPrivate />
      <Router path="/mytasks" exact component={MyTasks} isPrivate />
      <Router path="/mytasks/kanban" exact component={KanbanTasks} isPrivate />
      <Router path="/employee" exact component={Employee} isPrivate />
      <Router path="/profile/:name" exact component={Profile} isPrivate />
      <Router
        path="/employee/register"
        exact
        component={EmployeeRegister}
        isPrivate
      />
      <Router path="/employee/edit" exact component={EmployeeEdit} isPrivate />
      <Router path="/customer" exact component={Customer} isPrivate />
      <Router path="/jobs" exact component={Jobs} isPrivate />
      <Router path="/jobs/viewer/" exact component={JobViewer} isPrivate />
      <Router path="/jobs/viewer/NoteViewer/:noteId" exact component={NoteViewer} isPrivate />
      <Router path="/jobs/activityregistration" exact component={ActivityRegistration} isPrivate />
      <Router path="/jobs/activityedit/:activityId" exact component={ActivityEdit} isPrivate />
      <Router path="/templates" exact component={Templates} isPrivate />
      <Router path="/inventory" exact component={Inventory} isPrivate />
      <Router path="/inventoryregistration" exact component={InventoryRegistration} isPrivate />
      <Router path="/inventory/inventoryedit/:inventory_id" exact component={InventoryRegistration} isPrivate />
      <Router path="/inventoryviewer" exact component={InventoryViewer} isPrivate />
      <Router path="/inventory/storagesettings" exact component={StorageViewer} isPrivate />
      <Router path="/inventory/storageregistration" exact component={StorageRegistration} isPrivate />
      <Router path="/inventory/storageedit/:storage_id" exact component={StorageRegistration} isPrivate />
      <Router path="/itemregistration" exact component={ItemRegistration} isPrivate />
      <Router path="/itemedit/:item_id" exact component={ItemRegistration} isPrivate />
      <Router path="/templateregistration" exact component={TemplateRegistration} isPrivate />
      <Router path="/templateedit" exact component={TemplateEdit} isPrivate />
      <Router path="/customer/register" exact component={CustomerRegister} isPrivate />
      <Router path="/customer/edit" exact component={CustomerEdit} isPrivate />
      <Router path="/job/register" exact component={JobRegister} isPrivate />
      <Router path="/workflowsettings" exact component={Workflow} isPrivate />
      <Router path="/workflowedit/:job_id" exact component={Workflow} isPrivate />
      <Router path="/new-contract" exact component={NewContract} isPrivate />
      <Router path="/contract-templateregistration" exact component={ContractsTemplatesER} isPrivate />
      <Router path="/payments" exact component={Payments} isPrivate />
      <Router path="/contract-template/:template_id" exact component={ContractsTemplatesER} isPrivate />
      
      <Router path="/settings" exact component={Settings} isPrivate />
    </Switch>
  );
}
