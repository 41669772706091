import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 1216px;
  max-height: 3635px;
  width: 224px;
  background-color: #fff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.08);
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1000; /* Stay on top */
  /* border: 1px solid red; */

  text {
    font-weight: 500;
    font-size: 13px;
    color: #8c8c8c;
    margin-left: 30px;
  }

  @media(max-width: 960px) {
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    margin-top: 60px;
  }
`;

export const Logo = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 180px;
  height: 35px;
`;

export const Line = styled.div`
  width: 60%;
  height: 0px;
  border: 1px solid #f5f5f5;
  margin-bottom: 30px;
`;

export const MenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
`;

export const OptionButton = styled.button`
  background-color: transparent;
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 3px;
  padding-left: 30px;
  margin-top: 16px;

  &:hover {
    background: ${darken(0.05, '#ffffff')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }

  div {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    text {
      margin-left: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
  }
`;
