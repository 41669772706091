import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;

  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  padding: 15px;
`;


export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 30px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #333533;
  }
`;

export const MoreHorizon = styled.div`
  &:hover {
    cursor: pointer;
  }
`


export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;
  margin-bottom: 4 0px;

  border: 1px solid #eeeeee;
`;

export const ActivityNameDiv = styled.div`
  display: flex;
  width: 150px;
  &:hover {
    cursor: pointer;
  }
`

export const PopoverDiv = styled.div`
  border-bottom: 1px solid #eee;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`

export const HeaderInformations = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;

  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    height: 100px;
    align-items: center;
  }
`;

export const DarkContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  left: 0;
  top: 0;
  height: 2000px;
  width: 2500px;
`;

export const InventoryNameDiv = styled.div`
  &:hover {
    cursor: pointer
  }
`

export const StorageQrCodeDiv = styled.div`
    &:hover{
      cursor:pointer
    }
`

export const Button = styled.button`
  width: 156px;
  height: 41px;
  justify-self: flex-end;
  color: #fff;
  border: none;

  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  background: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: ${lighten(0.06, '#82ce5e')};
  }

  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
  `

export const PrintDiv = styled.div`
display: flex;
justify-content: center;
margin-top: 5px;

&:hover {
  cursor: pointer;
}
`