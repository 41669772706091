import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import Routes from './routes/index';

import { AuthProvider } from './contexts/Auth';
import { ToastProvider } from './contexts/Toast';
import { JobProvider } from './contexts/Job';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <JobProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </JobProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
