import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, useFormik } from 'formik';

import { useLocation, useParams } from 'react-router-dom'

import * as Yup from 'yup';

import Axios from 'axios'
import api from '../../../services/api';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertFromRaw, convertToRaw, Modifier } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import getValidationErros from '../../../utils/getValidationErrors';

import TransitionsModal from '../../../components/TransitionsModal'

import SideBar from '../../../components/SideBar';
import Header from '../../../components/Header';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ToastContainer from '../../../components/ToastContainer';

import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  NavTab,
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  ActivityContainer,
  Label,
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  FourthSectionEdit,
  Half3Div,
  TagsDiv

} from './styles';

const index = () => {
  const params = useParams();
  const templateContractId = params.template_id;

  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [errors, setErrors] = useState({});
  const [showToast, setShowToast] = useState({ show: false });
  const [ip, setIp] = useState(null)
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [templateContract, setTemplateContract] = useState(null);
  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );
  const [data, setData] = useState('')

  const tagsArray = ['@amount', '@customer_name', '@customer_email',
    '@employee_name', '@employee_email', '@job_number',
  ]

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const formik = useFormik({
    initialValues: {
      template_name: '',
    },
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  async function handleSubmit(values, createNew) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        template_name: Yup.string().required('Template name is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });
      if (templateContractId)
        updateTemplate(values)
      else
        createTemplate(values);

    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      console.error(errors);
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }

  const updateTemplate = (values) => {
    api
      .patch(
        '/contract-template',
        {
          id: templateContract.id,
          template_name: values.template_name,
          template_content: convertToRaw(editorState.getCurrentContent()),
          job_number: 'X2OGV-20212503',
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setOpenPopUp({ show: true });
        setLoading(false);
        window.history.back()
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error creating',
          description: 'Error creating template',
        });
      });
  }

  const createTemplate = (values) => {
    api
      .post(
        '/contract-template',
        {
          template_name: values.template_name,
          template_content: convertToRaw(editorState.getCurrentContent()),
          job_number: 'X2OGV-20212503',
          ip,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setOpenPopUp({ show: true });
        setLoading(false);
        window.history.back()
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error creating',
          description: 'Error creating template',
        });
      });
  }

  async function handleDeleteContractTemplate() {
    setLoadingDelete(true)
    api
      .delete(`/contract-template/${templateContract.id}/${ip}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setLoadingDelete(false)
        window.history.back();
      });
  }


  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  async function getTemplatesContracts() {
    api
      .get('/contract-template/all', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        let data = res.data.contractTemplates
        setData(data[0].template_content)
      });
  }

  async function getTemplateContractById() {
    api
      .get(`/contract-template/${templateContractId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setTemplateContract(res.data.contractTemplate)
        formik.setFieldValue('template_name', res.data.contractTemplate.template_name)
        setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(res.data.contractTemplate.template_content)))
        )
      })
  }

  const handleInsertText = (tag) => {
    let selectionState = editorState.getSelection();
    let currentContent = editorState.getCurrentContent();
    let tagToInsert = tag
    let newContent = Modifier.insertText(
      currentContent,
      selectionState,
      tagToInsert
    )
    let editorState2 = EditorState.push(
      editorState,
      newContent
    )
    setEditorState(editorState2)
  }


  useEffect(() => {
    getIP()
    getTemplatesContracts()
    if (templateContractId)
      getTemplateContractById()
  }, [])



  const content = () => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <body style={{
            fontFamily: 'sans-serif',
            height: '90vh',
            width: '80%',
            margin: '10px 0 0 7%',
            border: '2px solid #618095',
            borderRadius: '5px',
            padding: '5px',
          }
          }>
            <div
              style={{
                border: "1px solid black", minHeight: "6em", cursor: "text",
                width: '100%', height: '100%', overflowY: 'auto'
              }}
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
          </body >
          <TagsDiv>
            <div onClick={() => setOpenModal(false)}>
              <span>
                <CloseIcon fontSize="medium" />
              </span>
            </div>
            TAGS
            <ul style={{
              textAlign: 'center', listStyleType: 'none',
              paddingLeft: 0, margin: '0 auto', width: '90%'
            }}>
              {tagsArray.map((tag, key) => {
                return (
                  <li
                    key={key}
                    onClick={() => handleInsertText(tag)}
                    style={{
                      border: '1px solid #618095', backgroundColor: '#618095',
                      borderRadius: '10px', margin: '5px 0', fontWeight: 'bold',
                      color: '#fff'
                    }}>{tag}</li>
                )
              })}
            </ul>

          </TagsDiv>
        </div>
        <FourthSection style={{ width: '100%', justifyContent: 'center', marginBottom: '5px' }}>

          <button
            style={{ width: '200px' }}
            type="button"
            onClick={() => setOpenModal(false)}>
            {templateContractId ? 'Edit' : 'Create'}
          </button>
        </FourthSection>
      </>
    )
  }

  const onFileChange = (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0])
    api
      .post('/docxToHtml', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then(res => {
        let html = res.data
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
        setOpenModal(true)
      })
      .catch(err => console.error('error in docxToHtml function'))
  }


  return (
    <Container >
      {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} content={content()} />}
      <SideBar />
      <ContainerColumn >
        <Header />
        <ContainerBackground>
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms >
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>{templateContractId ? 'Template Edit' : 'Template Registration'}</text>
              </div>
            </ContainerHeader>
            <NavTab >
              <li
                style={{
                  backgroundColor: '#618095'
                }}
              >
                1. Template Details
              </li>
            </NavTab>
            <HeaderInformations>
              <ViewCompactIcon style={{
                color: '#6096BA', fontSize: '2rem',
                marginRight: '10px'
              }} />
              Template Information
            </HeaderInformations>
            <LineHorizontal />
            <Formik >
              <Form onSubmit={formik.handleSubmit}>
                <ActivityContainer style={{ marginTop: '15px', marginBottom: 0 }}>
                  <FirstSection>
                    <div >
                      <Label
                        style={errors.template_name ? { color: 'red' } : null}
                      >
                        Template Name
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="template_name"
                        placeholder="Enter the template name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.template_name}
                        style={
                          errors.template_name ? { border: '1px solid red' } :
                            { width: '100%' }
                        }
                      />
                    </div>
                    <FourthSection style={{ alignItems: 'flex-end' }}>
                      {templateContractId ? (
                        <button
                          type="button"
                          onClick={() => setOpenModal(true)}
                          style={{ backgroundColor: '#618095' }}
                        >
                          Edit content
                        </button>)
                        :
                        (
                          <>
                            <button
                              type="button"
                              onClick={() => setOpenModal(true)}
                              style={{ backgroundColor: '#618095' }}
                            >
                              Create content
                            </button>
                            OR
                            <input style={{
                              backgroundColor: '#618095', color: '#fff',
                              height: '41px', width: '200px', margin: '0 5px'
                            }} type="file" onChange={(e) => onFileChange(e)} />
                          </>
                        )}
                    </FourthSection>
                  </FirstSection>
                  <LineHorizontal />
                  {templateContractId ?
                    <FourthSectionEdit>
                      <button
                        type="button"
                        onClick={() => handleDeleteContractTemplate()}
                      >
                        {loadingDelete ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          'Delete'
                        )}
                      </button>
                      <button type="submit">
                        {loading ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          'Save'
                        )}
                      </button>
                    </FourthSectionEdit>
                    :
                    <FourthSection>
                      <button type="submit">
                        {loading ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          'Save'
                        )}
                      </button>
                    </FourthSection>
                  }

                </ActivityContainer>
              </Form>
            </Formik>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default index;
