import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';

import { JobContext } from '../../contexts/Job'
import { Link, useHistory, useLocation } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MUIDataTable from "mui-datatables";

import TransitionsModal from '../../components/TransitionsModal';

import api from '../../services/api';

import Popover from '@material-ui/core/Popover';
import CheckIcon from '@mui/icons-material/Check';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import ConfirmModal from '../../components/ConfirmModal';
import ToastContainer from '../../components/ToastContainer';

import email from '../../assets/icons/email.svg'
import call from '../../assets/icons/call.svg'
import visit from '../../assets/icons/visit.svg'
import task from '../../assets/icons/task.svg'

import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useN01SwitchStyles } from '@mui-treasury/styles/switch/n01';


import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  PaginationButtonsContainer,
  TableContainer,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  ContainerInformations,
  EditButton,
  DarkContainer,
  PopoverDiv,
  MoreHorizon,
  JobNumberDiv,
  ActivityNameDiv,
  JobsNumberDivs,
  Input
} from './styles';
import KanbanView from './KanbanView';

function MyTasks() {
  let history = useHistory();

  const location = useLocation();
  const rowIndex = location.rowIndex;

  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [showToast, setShowToast] = useState({ show: false });
  const [openModal, setOpenModal] = useState(false)
  const [jobs, setJobs] = useState([]);
  const [jobsForNewTask, setJobsForNewTask] = useState([]);
  const [jobsForNewTaskFiltered, setJobsForNewTaskFiltered] = useState(jobsForNewTask);
  const [input, setInput] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ip, setIp] = useState([]);
  const openPop = Boolean(anchorEl);
  const { jobId, setJobId } = useContext(JobContext)

  const [rowData, setRowData] = useState([])
  const [rowMeta, setRowMeta] = useState({})

  const [checked, setChecked] = useState(false)
  const switchStyles = useN01SwitchStyles();


  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleCheckChange = () => {
    setChecked(event.target.checked);
  };

  const TableTitle = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h6 style={{ marginRight: '20px', fontSize: '20px' }}>
          My Tasks
        </h6>
        {/* <Switch
          classes={switchStyles}
          checked={checked}
          onChange={e => setChecked(e.target.checked)}
          
        />
        Do not show completed tasks */}
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={handleCheckChange}
                name="checked"
                color="primary"
              />
            }
            label="Do not show completed tasks"
          />
        </FormGroup>
      </div>
    )
  }

  const insertIcon = (iconType) => {
    let icon
    if (iconType === 'Call')
      icon = call
    else if (iconType === 'Visit')
      icon = visit
    else if (iconType === 'Task')
      icon = task
    else
      icon = email
    return (
      <div style={{
        border: '1px solid #618095', display: 'flex',
        justifyContent: 'center', alignItems: 'center', borderRadius: '5px',
        height: '30px', width: '40px'
      }} >
        <img height={20} src={icon} />
      </div>

    )
  }

  async function getActivitiesByEmployee() {
    api
      .get(`/activity/list/employee-id`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        let arrayOfJobs = []
        res.data.map(data => {
          if (!arrayOfJobs.find(job => job.job_number === data.job_number)) {
            arrayOfJobs.push({
              job_number: data.job_number,
              customer_name: data.customer_name,
              business_phone: data.business_phone,
              address: data.address,
              invoice_id: data.invoice_id,
              division: data.division,
              createdAt: data.created_at.split('T')[0].split('-').reverse().join('-'),
              tasks: [{
                job_id: data.job_id,
                customer_name: data.customer_name,
                business_phone: data.business_phone,
                invoice_id: data.invoice_id,
                job_number: data.job_number,
                date: data.date,
                description: data.description,
                priority: data.priority,
                status: data.status, name: data.activity_name,
                activity_id: data.activity_id,
                activity_type: data.activity_type,
                activity_name: data.activity_name,
                hour: data.hour,
                team_member: data.team_member,
              }]
            })
          }
          else {
            let index = arrayOfJobs.findIndex(job => job.job_number === data.job_number)
            arrayOfJobs[index].tasks.push({
              job_id: data.job_id,
              customer_name: data.customer_name,
              business_phone: data.business_phone,
              invoice_id: data.invoice_id,
              job_number: data.job_number,
              date: data.date,
              description: data.description,
              priority: data.priority,
              status: data.status, name: data.activity_name,
              activity_id: data.activity_id,
              activity_type: data.activity_type,
              activity_name: data.activity_name,
              hour: data.hour,
              team_member: data.team_member,
              team_member_name: data.team_member_name,
              createdAt: data.created_at.split('T')[0].split('-').reverse().join('-')
            })
          }
        })
        setJobs(arrayOfJobs)
      })
      .catch(err => console.error(err))
  }

  async function getJobs() {
    api
      .get('/job', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        let data = res.data.map(data => {
          return {
            ...data,
            division: data.division?.[0]
          }
        })
        setJobsForNewTask(data)
      })
  }

  useEffect(() => {
    getIP();
    getActivitiesByEmployee();
    getJobs()
  }, []);

  useEffect(() => {
    setJobsForNewTaskFiltered(jobsForNewTask)
  }, [jobsForNewTask]);



  function handleToast() {
    setShowToast({ show: false });
  }

  const setBackGroundColor = (priority) => {
    if (priority === 'Low')
      return { padding: '0 20px', color: '#FFF', backgroundColor: '#9CD97F' }
    else if (priority === 'Normal')
      return { padding: '0 10px', color: '#FFF', backgroundColor: '#859DF0' }
    else
      return { padding: '0 20px', color: '#FFF', backgroundColor: '#FF7777' }
  }

  const goEdit = (task, rowMeta) => {
    task.returnToMyTasks = true
    history.push({
      pathname: `/jobs/activityedit/${task.activity_id}`,
      state: task,
      newTask: true,
      rowIndex: rowMeta.dataIndex
    });
  }

  const createNewTask = (job) => {
    let rowIndex = jobs.findIndex(singleJob => singleJob.job_number === job.job_number)
    localStorage.setItem('@Propack:customerName', `${job.first_name} ${job.last_name}`);
    localStorage.setItem('@Propack:customerPhone', job.business_phone);
    localStorage.setItem('@Propack:job_number', job.job_number);
    localStorage.setItem('@Propack:invoice_id', job.invoice_id);
    localStorage.setItem('jobId', job.job_id);
    history.push({
      pathname: `/jobs/viewer/`,
      newTask: true,
      rowIndex: rowIndex
    });
  }

  const columns = [
    {
      name: "invoice_id",
      label: "Invoice Id",
      options: {
        viewColumns: false,
        display: false,
        filter: false,
      },
    },
    {
      name: "job_number",
      label: "Job Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "business_phone",
      label: "Customer Phone",
      options: {
        filter: true,
        sort: true
      },
    },
    {
      name: "address",
      label: "Customer Address",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span style={{ display: 'block', maxWidth: '265px' }}>{value}</span>
          )
        }
      },
    },
    {
      name: "createdAt",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (index, nextIndex) => {
            var parseDateA = index.data.split('-');
            var parseDateB = nextIndex.data.split('-');
            var dateA = new Date(parseDateA[2], parseDateA[1] - 1, parseDateA[0]);
            var dateB = new Date(parseDateB[2], parseDateB[1] - 1, parseDateB[0]);
            
            if (order === 'asc'){
              if (dateA < dateB) {
                return -1;
              } else if (dateA > dateB) {
                return 1;
              } else {
                return 0;
              }
            } else {
              if (dateA < dateB) {
                return 1;
              } else if (dateA > dateB) {
                return -1;
              } else {
                return 0;
              }
            }
          }
        }
      },
    },
    {
      name: "division",
      label: "Division",
      options: {
        filter: true,
        sort: true
      },
    },
  ];


  const options = {
    selectableRows: "none",
    filterType: 'checkbox',
    expandableRows: true,
    expandableRowsOnClick: true,
    rowsExpanded: [rowIndex],
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer >
                <table>
                  <tr>
                    <th>Status</th>
                    <th>Type</th>
                    <th style={{ padding: 0, textAlign: 'start' }}>Name</th>
                    <th style={{ padding: 0, textAlign: 'start' }}>Responsible</th>
                    <th style={{ padding: 0, textAlign: 'start' }}>Start Date</th>
                    <th style={{ padding: 0, textAlign: 'start' }}>Final Date</th>
                    <th style={{ padding: 0, textAlign: 'start' }}>Description</th>
                    <th>Priority</th>
                  </tr>
                  {jobs.map(job => {
                    if (job.job_number === rowData[1]) {
                      console.log(job);
                      return (
                        job.tasks.map(task => {
                          return (
                            <tr key={task.activity_id} className={checked && task.status === 'Completed' ? 'd-none' : 'contents'}>
                              <td >
                                {task.status === 'Completed' ? <CheckIcon style={{ color: 'green' }} /> : task.status}
                              </td>
                              <td style={{ display: 'flex', justifyContent: 'center' }}>
                                {insertIcon(task.activity_type)}
                              </td>
                              <td>
                                <ActivityNameDiv onClick={() => goEdit(task, rowMeta)}>
                                  <span>{task.name}</span>
                                </ActivityNameDiv>
                              </td>
                              <td>
                                <ActivityNameDiv onClick={() => goEdit(task, rowMeta)}>
                                  <span>{task.team_member_name}</span>
                                </ActivityNameDiv>
                              </td>
                              <td>
                                <ActivityNameDiv onClick={() => goEdit(task, rowMeta)}>
                                  {task.date.split('T')[0].split('-').reverse().join('-')}
                                </ActivityNameDiv>
                              </td>
                              <td>
                                <ActivityNameDiv onClick={() => goEdit(task, rowMeta)}>
                                  {
                                    (task.final_date &&
                                    task.final_date.split('T')[0].split('-').reverse().join('-')) ||
                                    '-'
                                  }
                                </ActivityNameDiv>
                              </td>
                              <td
                                style={{
                                  width: "250px",
                                  fontSize: '13px',
                                }}>
                                <ActivityNameDiv style={{ maxHeight: 50 }} onClick={() => goEdit(task, rowMeta)}>
                                  <span style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'pre',
                                    overflow: 'hidden',
                                  }}>
                                    {task.description}
                                  </span>
                                </ActivityNameDiv>
                              </td>
                              <td>
                                <span style={setBackGroundColor(task.priority)}>{task.priority}</span>
                              </td>
                            </tr>
                          )
                        })
                      )
                    }
                  })}
                </table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment >
      );
    }
  };

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  const handleChange = (value) => {
    setInput(value)
    let filteredArray = jobsForNewTask.filter(job => job.job_number.toLowerCase().includes(value.toLowerCase())
      || job.customer_name.toLowerCase().includes(value.toLowerCase()))
    setJobsForNewTaskFiltered(filteredArray)
  }

  const descriptionContent = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        minHeight: '500px', fontSize: '20px',
      }}>
        <div style={{
          display: 'flex', alignItems: 'flex-end',
          width: '100%', height: '40px', fontFamily: 'roboto', justifyContent: 'center'
        }}>
          Select a job
        </div>
        <Input
          style={{ fontSize: 15, width: '40%' }}
          type="text"
          placeholder="Type the job number or customer name"
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
        <LineHorizontal />
        <div style={{
          width: '100%', margin: '15px', display: 'flex',
          justifyContent: 'flex-start', flexWrap: 'wrap',
          maxHeight: '400px', overflowY: 'auto'
        }}>
          {jobsForNewTaskFiltered.map(job => {
            return (
              <JobsNumberDivs
                onClick={() => createNewTask(job)}
                key={job.job_id}>
                <span>
                  {job.job_number}
                </span>
                <span>
                  {job.customer_name}
                </span>
              </JobsNumberDivs>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
        {openModal && <TransitionsModal openModal={true}
            closeModal={setOpenModal} content={descriptionContent()}
            widthProp="50%" />}
          {showToast.show && (
            <ToastContainer
              messages={showToast}
              show={() => handleToast()}
            />
          )}
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show &&
            <ConfirmModal
              show={() => handlePopUp()}
              deleteUser={() => deleteJob(jobId)}
            />
          }
          <ContainerForms >
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>My Tasks List</text>
              </div>
            </ContainerHeader>
            <ContainerInformations>
              <HeaderInformations>
                <button onClick={() => setOpenModal(true)} type="button">New Task</button>
              </HeaderInformations>
              <LineHorizontal />
              {
                <MUIDataTable
                  title={<TableTitle />}
                  data={jobs}
                  columns={columns}
                  options={options}
                />
              }
            </ContainerInformations>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default MyTasks;