import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import api from '../../services/api'

import { CircularProgress } from '@material-ui/core';

import ToastContainer from '../../components/ToastContainer';


import { Link, useHistory } from 'react-router-dom';
import {
  ContainerBackground,
  ContainerLogin,
  DivIllustration,
  DivInformation,
  DivForms,
  DivTexts,
  WelcomeText,
  InformationText,
  Input,
  ButtonLogin,
  ForgotPasswordText,
  ClickHereText,
} from './styles';

import loginIllustration from '../../assets/loginIllustration.png';
import logo from '../../assets/logo.png';

function ForgotPassword() {
  let history = useHistory();

  const [ip, setIp] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false)
  const [showToast, setShowToast] = useState({ show: false });

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error('error', error);
        setIp('0.0.0.0');
      });
  }

  async function resetPassword() {
    setLoading(true);
    await api
      .post('/reset-password/send-email', {
        email,
        ip
      })
      .then(response => {
        setShowToast({
          show: true,
          type: 'success',
          title: 'Success',
          description: 'Email sended successfully.',
        });
        setLoading(false);
        // window.history.back()
        history.push({
          pathname: "/",
          emailNotification: true
        });
      })
      .catch(err => {
        setLoading(false);
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: 'Error in send email.',
        });
      });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  useEffect(() => {
    getIP();
  }, []);

  return (
    <ContainerBackground>
      {showToast.show && (
        <ToastContainer messages={showToast} show={() => handleToast()} />
      )}
      <ContainerLogin>
        <DivIllustration>
          <img src={loginIllustration} alt="Illustration" />
        </DivIllustration>
        <DivInformation>
          <img src={logo} alt="Logo" />
          <DivTexts>
            <WelcomeText>Welcome!</WelcomeText>
            <InformationText>Forgot password</InformationText>
            <DivForms>
              <Input
                type="email"
                placeholder="E-mail Address"
                onChange={e => setEmail(e.target.value)}
              />
              <ButtonLogin
                onClick={() => resetPassword()}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  'Send'
                )}
              </ButtonLogin>
              <div className="mt-3">
                <ForgotPasswordText>Back to login?</ForgotPasswordText>
                <Link to="/">
                  <ClickHereText> Click here</ClickHereText>
                </Link>
              </div>
            </DivForms>
          </DivTexts>
        </DivInformation>
      </ContainerLogin>
    </ContainerBackground>
  );
}

export default ForgotPassword;
