import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }


`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;


export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  /* padding: 15px; */
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;

  table {
    padding: 50px;
    thead {
      background: #e6ebee;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;

      color: #618095;

      tr {
        height: 50px;
      }
      th {
        padding: 0px 20px 0px 20px;
      }
    }
    td {
      font-size: 14px;
      line-height: 16px;

      color: #8c8c8c;
      padding: 10px 20px 10px 20px;
    }

    span {
      height: 6px;
      width: 6px;
      margin-left: 5px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
    }

    button {
      background-color: transparent;
      color: #8c8c8c;
      border-radius: 50%;
      border: none;
      font-size: 30px;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
    }
  }
`;

export const MoreHorizon = styled.div`
  &:hover {
    cursor: pointer;
  }
`
export const JobNumberDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`


export const PopoverDiv = styled.div`
  border-bottom: 1px solid #eee;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`

export const PaginationButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    background-color: transparent;
    color: #8c8c8c;
    border-radius: 50%;
    border: none;
    font-size: 12px;

    &:hover {
      cursor: pointer;
      background-color: ${darken(0.01, '#fafafa')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 30px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #333533;
  }
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;
  margin-bottom: 4 0px;

  border: 1px solid #eeeeee;
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 20px;
  width: 95%;

  img {
    width: 17px;
    height: 19px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;

  > img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const EditButton = styled.button`
  border: none;
  color: #fff;
  padding: 8px;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;
`;

export const DarkContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;

  height: 2000px;
  width: 2500px;
`;

export const Task = styled.div`
  width: 25%;
  height: 76px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #618095;
  border: 1px solid #618095;
  box-shadow: 5px 5px 5px #eee;
&:hover {
  cursor: pointer;
  background-color: #618095;
  color: #fff;
}

@media (max-width: 800px) {
      width: 80%;
      margin: 10px 0;
    }
`

export const ModalDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;

@media (max-width: 800px) {
  flex-direction: column;
  }
`
export const ActivityContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  /* border: 1px solid; */
  /* margin-bottom: 300px; */
  input {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 13px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ececec;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
    > select {
    margin-top: 10px;
    display: block;
    padding: 0.65rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

      &:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
    }

  @media(max-width: 500px) {
    width: 90%;
    div:nth-child(2) {
      input {
        width: 100%;
      }
    }
  }
`;


export const SaveButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  margin: 20px 0;
  width: 100%;

  img {
    width: 17px;
    height: 19px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  button {
    width: 156px;
    height: 41px;
    justify-self: flex-end;
    color: #fff;
    border: none;

    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    background: #82ce5e;
    box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
    border-radius: 3px;

    &:hover {
      cursor: pointer;
      background-color: ${lighten(0.06, '#82ce5e')};
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }

    @media(max-width: 500px) {
      width: 100%;
    }
  }
`;

export const SwitchTableButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; 
  color: #6096BA;
  border: 1px solid #6096BA; 
  border-radius: 5px;
  width: 157px;
  margin-top: 10px;
  background-color: #fff;
  &:hover {
      cursor: pointer;
      color: white;
      background-color: #6096BA;
  }

  @media(max-width: 500px) {
    width: 100%;
  }
`;

export const NavTab = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 30px;
  list-style: none;
  border-bottom: 2px solid #618095;

  li {
    display: flex;
    background: #cfdbd5;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    margin-top: 5px;
    min-width: 144px;
    max-width: 100%;
    height: 39px;
    /* margin-left: 10px; */
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;

    a {
      display: block;
      padding: 0.5rem 1rem;
      text-decoration: none;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1300px) {
    display: none
  }
`;