import React, { useEffect } from 'react';

import { FiAlertCircle, FiXCircle } from 'react-icons/fi';

import { Container, Toast } from './styles';

function ToastContainer({ messages, show }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      show();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container>
      <Toast
        key={messages.id}
        type={messages.type}
        hasDescription={!!messages.description}
      >
        <FiAlertCircle size={20} />

        <div>
          <strong>{messages.title}</strong>
          {messages.description && <p>{messages.description}</p>}
        </div>

        <button type="button" onClick={() => show()}>
          <FiXCircle size={18} />
        </button>
      </Toast>
    </Container>
  );
}

export default ToastContainer;
