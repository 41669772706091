import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { JobContext } from '../../../../contexts/Job'

import api from '../../../../services/api';

import ReactToPrint from 'react-to-print';

import QRCode from 'qrcode.react';

import MUIDataTable from "mui-datatables";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import TransitionsModal from '../../../../components/TransitionsModal'

import PrintIcon from '@material-ui/icons/Print';
import ListIcon from '@material-ui/icons/List';
import EditInventory from '../../../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../../../assets/icons/DeleteInventory.svg'

import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';

import ComponentToPrint from '../../../../utils/ComponentToPrint'

import {
  HeaderInformations,
  ContainerForms,
  MoreHorizon,
  PopoverDiv,
  InventoryIdDiv,
  LineHorizontal,
  Button,
  PrintDiv,
  CenterDiv,
} from './styles'

const index = () => {
  let history = useHistory();
  const { jobId, setJobId } = useContext(JobContext)
  const componentRef = useRef();

  const [customerInfo, setCustomerInfo] = useState([])
  const [inventoriesItems, setInventoriesItems] = useState([])
  const [itemId, setItemId] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [itemDetails, setItemDetails] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [openQrCodeModal, setOpenQrCodeModal] = useState(false)
  const [inventories, setInventories] = useState([])
  const [storageQrCodeInfo, setStorageQrCodeInfo] = useState([])
  const [goToCreateInventory, setGoToCreateInventory] = useState(true)
  const [showButton, setShowButton] = useState(false)
  const [productTypeItems, setProductTypeItems] = useState([])
  const [subTypeItems, setSubTypeItems] = useState([])

  const openPop = Boolean(anchorEl);

  const columns = [
    {
      name: "storage_id",
      label: "Storage ID",
      options: {
        filter: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <InventoryIdDiv
              onClick={() =>
                history.push({
                  pathname: `/itemedit/${tableMeta.rowData[8]}`,
                })
              }>
              {tableMeta.rowData[0]}
            </InventoryIdDiv >
          )
        }
      },
    },
    {
      name: "room",
      label: "Room",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "product_name",
      label: "Item Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type_name",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sub_type_name",
      label: "Sub Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "condition",
      label: "Condition",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "storage_id",
      label: "QR Code",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <InventoryIdDiv
              onClick={() => HandleViewQrCode(value)}>
              <div style={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', height: 35, color: '#6096BA',
                border: '1px solid #6096BA', borderRadius: 5, width: '100px'
              }}>View QR Code</div>
            </InventoryIdDiv >
          )
        }
      },
    },
    {
      name: "photos_url",
      label: "Photos",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <InventoryIdDiv onClick={() => handleViewPhotos(tableMeta.rowData)}>
              <div style={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', height: 35, color: '#6096BA',
                border: '1px solid #6096BA', borderRadius: 5, width: '100px'
              }}>View Photos</div>
            </InventoryIdDiv>
          )
        }
      },
    },
    {
      name: 'item_id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => goEdit(itemId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                  Edit
                </PopoverDiv>
                <PopoverDiv onClick={() => deleteItem()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </PopoverDiv>
              </Popover>
            </div>
          )
        }
      },
    },
  ];

  const HandleViewQrCode = (storage_id) => {
    let qrCodeValue = storage_id
    let qrCodeName = ''
    setStorageQrCodeInfo({ qrCode: qrCodeValue, name: qrCodeName })
    setOpenQrCodeModal(true)

  }

  const handleViewPhotos = (itemDetails) => {
    setItemDetails(itemDetails)
    setOpenModal(true)
  }

  const options = {
    filter: "false",
    print: "false",
    search: "true",
    download: "false",
    viewColumns: "false",
    selectableRows: "none",
    // onRowsDelete: (rowsDeleted) => {
    //   rowsDeleted.data.map(d => {
    //     // activityId(prevState => [...prevState, customers[d.dataIndex].costumer_id])
    //     setOpenPopUp({ show: true })
    //   })
    // }
  };


  const goEdit = () => {
    history.push({
      pathname: `/itemedit/${itemId}`,
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    handleClick(event)
    setItemId(value)

  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  // async function getProductTypes() {
  //   api
  //     .get('/product-type', {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
  //       }
  //     })
  //     .then(res => {
  //       setProductTypeItems(res.data)
  //       // getSubTypes()

  //     })
  //     .catch(err => console.error(err))
  // }

  // async function getSubTypes() {
  //   api
  //     .get('/sub-product-type', {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
  //       }
  //     })
  //     .then(res => {
  //       // 
  //       setSubTypeItems(res.data)

  //     })
  //     .catch(err => console.error(err))
  // }

  async function getInventoriesItems() {
    api
      .get('/item', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        

        let aux = res.data.map(data => {
          return {
            ...data,
            // product_type: '...',
            // sub_type: '...'
          }
        })
        setInventoriesItems(aux.filter(inventoryItem => inventoryItem.job_number === customerInfo.job_number))

      })
      .catch(err => console.error(err))
  }

  async function getInventories() {
    api
      .get('/inventory', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        let aux = res.data.find(inventory => inventory.job_number == customerInfo.job_number)
        if (aux)
          setGoToCreateInventory(false)
        setShowButton(true)
      })
      .catch(err => console.error(err))
  }

  async function getJobById() {
    api
      .get(`/job/${jobId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setCustomerInfo(res.data)
      })
  }

  async function deleteItem() {
    setLoadingDelete(true);
    api
      .delete(
        `/item/delete/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Item successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting item',
        });
      });
  }

  useEffect(() => {
    getJobById()
  }, []);

  useEffect(() => {
    getInventories()
    getInventoriesItems()
  }, [customerInfo]);

  const contentPhotos = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        height: '500px', fontSize: '20px',
      }}>
        <div style={{
          display: 'flex', alignItems: 'flex-end',
          width: '95%', height: '40px', fontFamily: 'roboto'
        }}>
          Inventory Details
        </div>
        <LineHorizontal />
        <div style={{ width: '90%', overflowY: 'auto' }}>
          <table style={{
            width: '100%', textAlign: 'center',
            marginTop: '20px', overflowY: 'auto'
          }}>
            <tr style={{ color: '#8C8C8C' }}>
              <th style={{ color: '#618095', backgroundColor: '#E6EBEE', height: '50px' }}>Item Name</th>
              <th style={{ color: '#618095', backgroundColor: '#E6EBEE', height: '50px' }}>Type</th>
              <th style={{ color: '#618095', backgroundColor: '#E6EBEE', height: '50px' }}>Condition</th>
              <th style={{ color: '#618095', backgroundColor: '#E6EBEE', height: '50px' }}>Room</th>
            </tr>
            <tr style={{ color: '#8C8C8C' }}>
              <td style={{ textAlign: 'center', height: '40px', borderBottom: '1px solid #E9E9E9' }}>{itemDetails[2]}</td>
              <td style={{ textAlign: 'center', height: '40px', borderBottom: '1px solid #E9E9E9' }}>{itemDetails[3]}</td>
              <td style={{ textAlign: 'center', height: '40px', borderBottom: '1px solid #E9E9E9' }}>{itemDetails[4]}</td>
              <td style={{ textAlign: 'center', height: '40px', borderBottom: '1px solid #E9E9E9' }}>{itemDetails[1]}</td>
            </tr>
          </table>
          <div style={{
            color: '#618095', backgroundColor: '#E6EBEE',
            height: '50px', marginTop: '20px', display: 'flex',
            justifyContent: 'center', alignItems: 'center'
          }}>
            Product Photos
          </div>
          <div style={{
            display: 'flex', flexWrap: 'wrap',
            justifyContent: 'center',
          }}>
            {
              itemDetails[7].length >= 1 && itemDetails[7][0] !== "undefined" ?
                itemDetails[7].map(photo => {
                  return (
                    <img src={photo} height="150" width="150" alt="photos" />
                  )
                })
                :
                <div>There are no photos yet.</div>
            }
          </div>
        </div>
      </div>
    )
  }

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${storageQrCodeInfo.qrCode}-qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const contentQrCode = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        height: '300px', fontSize: '20px'
      }}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          width: '90%', height: '40px', fontFamily: 'roboto', marginTop: '20px'
        }}>
          QR Code {storageQrCodeInfo.name}
        </div>
        <LineHorizontal />
        <div style={{
          width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center'
        }}>
          <QRCode id="qrcode" value={storageQrCodeInfo.qrCode} />
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button style={{ marginTop: 10 }} onClick={downloadQRCode}> Download QR Code</Button>
          </div>
          <PrintDiv>
            <ReactToPrint
              trigger={() => <PrintIcon fontSize={'40px'} style={{
                color: '#82ce5e',
                fontSize: '30px', marginLeft: 10
              }} />}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
              <ComponentToPrint content={<QRCode id="qrcode" value={storageQrCodeInfo.qrCode} />} ref={componentRef} />
            </div>
          </PrintDiv>
        </div>
      </div >
    )
  }

  return (
    <>
      {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} content={contentPhotos()} />}
      {openQrCodeModal && <TransitionsModal openModal={true} closeModal={setOpenQrCodeModal}
        content={contentQrCode()} widthProp="50%" />}


      <ContainerForms>
        <HeaderInformations >
          <div style={{
            display: 'flex', alignItems: 'inherit',
            fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
            fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
          }}>
            <ListIcon style={{ color: "#6096BA", fontSize: '2rem', marginRight: 10 }} />
            Items List
          </div>
          <div style={{ display: showButton ? 'inline' : 'none' }}>
            {!goToCreateInventory &&
              (<Link to="/itemregistration">
                <button type="button">New Item</button>
              </Link>)
            }
          </div>
        </HeaderInformations>
      </ContainerForms>
      {goToCreateInventory ?
        (
          <CenterDiv>
            <text style={{ marginBottom: '25px' }}>
              No inventory linked to this job
            </text>
            <Button
              type="button"
              onClick={() =>
                history.push({
                  pathname: "/inventoryregistration",
                  state: customerInfo
                })
              }
              style={{ marginBottom: '25px' }}
            >
              New Inventory
            </Button>
          </CenterDiv>
        )
        :
        (
          <MUIDataTable
            data={inventoriesItems}
            columns={columns}
            options={options}
          />
        )
      }
    </>
  );
}

export default index;
