import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;

  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  padding: 15px;

  form {
    height: 100%;
  }
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;

  img {
    width: 18px;
    height: 14px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const HeaderInformations = styled.div`
  display: flex;
  align-items: center;
  height: 20px;

  img {
    width: 23px;
    height: 23px;
    margin-right: 10px;
  }

  text {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 23px;
  margin-right: 25px;

  background: #6096ba;
`;

export const LineHorizontal = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 20px;

  border: 1px solid #eeeeee;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 30px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333533;
  }
`;

export const DivForms = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  height: 60px;

  &:before,
    &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
    }
`;

export const Row = styled.div`
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
`;

export const DivInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 300px; */
  img {
    width: 55px;
    height: 55px;
  }

  input {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ececec;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
    > select {
    margin-top: 10px;
    display: block;
    padding: 0.65rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

      &:focus {
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
    }
`;

export const DivInputText = styled.div`
width: 49%;

`

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #8c8c8c;
`;

export const SubmitButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-right: 40px;
  width: 197px;
  height: 41px;
  margin-top: 24px;
  color: #ffffff;
  background-color: #82ce5e;
  box-shadow: 0px 3px 8px rgba(130, 206, 94, 0.4);
  border-radius: 3px;

  &:hover {
    background: ${lighten(0.15, '#82CE5E')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;

export const DarkContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;

  height: 2000px;
  width: 2500px;
`;

export const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 10px;
  div {
    width: 48%;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    height: 160px;
    
    div {
      width: 100%;
    }
  }
`
export const SecondSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;

  div {
    width: 22%;
  }

  @media (max-width: 760px) {
      flex-direction: column;
      height: 320px;
      
      div {
        width: 100%;
      }
    }
`
export const ThirdSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  /* border: 1px solid red; */
 
  div:nth-child(1){
  width: 74%;
  /* border: 1px solid red; */
  }

  div:nth-child(2){
  width: 22%;
  /* border: 1px solid red; */
  }
  @media (max-width: 760px) {
    flex-direction: column;
    height: 160px;
    
    div:nth-child(1){
    width: 100%;
    /* border: 1px solid red; */
    }

    div:nth-child(2){
    width: 100%;
    /* border: 1px solid red; */
    }
  }
  
`
export const FourthSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;

  div {
  width: 22%;
  }

  @media (max-width: 760px) {
      flex-direction: column;
      height: 320px;
      
      div {
        width: 100%;
      }
    }
  
`
export const FifthSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;

  /* border: 1px solid red; */
  
  div {
  width: 22%;
  }

  @media (max-width: 760px) {
      flex-direction: column;
      height: 320px;
      margin-top: 10px;
      div {
        width: 100%;
      }
    }
  
`
export const SixthSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: flex-end;
  
  div {
    width: 48%;
  }

  div:nth-child(2) {
   margin-top: 1.9%;
  }

  @media (max-width: 760px) {
      flex-direction: column;
      height: 160px;
      margin-top: 10px;
      div {
        width: 100%;
      }
    }
  
`