import styled from 'styled-components';

export const Container = styled.div`
  background-color: #F1F3F5;
  height: 85%;
  width: 25%;
  margin: 5px 5px;
  overflow-y: auto;

`
export const Title = styled.div`
  color: #9099AB;
  font-weight: 500;
  margin: 5px 0 10px 10px;
  font-family: 'Roboto';

`

export const ComponentItems = styled.div`
  background-color: #fff;
  color: #9099AB;
  font-weight: 500;
  font-family: 'Roboto';
  width: 95%;
  height: 110px;
  margin: 5px auto;
  padding: 5px;
  border-radius: 5px;
  /* padding: 10px; */

`