import React, { useState, useEffect, useContext } from 'react';
import { Formik, Field, Form, useFormik } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import Axios from 'axios';
import api from '../../../../../services/api';
import getValidationErros from '../../../../../utils/getValidationErrors';

import GeneralNote from '../../GeneralNote'

import { JobContext } from '../../../../../contexts/Job'

import TransitionsModal from '../../../../../components/TransitionsModal'
import AddTemplate from './AddTemplate'

import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import CircularProgress from '@material-ui/core/CircularProgress'
import ToastContainer from '../../../../../components/ToastContainer'
import email from '../../../../../assets/icons/email.svg'
import call from '../../../../../assets/icons/call.svg'
import visit from '../../../../../assets/icons/visit.svg'
import task from '../../../../../assets/icons/task.svg'
import notes from '../../../../../assets/icons/notes.png'

import {
  NavTab,
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  ActivityIcons,
  Email,
  Call,
  Visit,
  Task,
  ActivityContainer,
  Label,
  FirstSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  FifthSection,
  Half3Div,
  DateField,
  TextAreaField,
  NameField

} from './styles';

function ActivityRegistration({newTask}) {
  let history = useHistory();
  const location = useLocation();

  const rowIndex = location.rowIndex;

  const { jobId } = useContext(JobContext)
  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [open, setOpen] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [activityType, setActivityType] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState('0.0.0.0');
  const [employees, setEmployees] = useState([]);
  const [status, setStatus] = useState([]);

  const rolesArray = ['Director', 'Project Manager', 'Lead Tech', 'Controller']

  const priorityArray = ['Low', 'Normal', 'High']
  const hourArray = ['08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '13:00 PM', '14:00 PM', '15:00 PM', '16:00 PM', '17:00 PM', '18:00 PM']
  const formik = useFormik({
    initialValues: {
      activity_type: '',
      activity_name: '',
      date: '',
      due_date: '',
      team_member: '',
      priority: 'Normal',
      hour: '',
      status: 'Not Started',
      description: '',
      creation_notification: '',
      due_notification: '',
      completed_notification: ''
    },
    onSubmit: values => {
      values.activity_type = activityType
      handleSubmit(values);
    },
  });

  const saveAndCreateNew = (formikObj) => {
    formikObj.values.activity_type = activityType
    handleSubmit(formikObj.values, 'createNew');
  }

  async function handleSubmit(values, createNew) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        activity_type: Yup.string().required('Activity type is required'),
        activity_name: Yup.string().required('Activity name is required'),
        date: Yup.string().required('Date is required'),
        due_date: Yup.string().required('Due Date is required'),
        team_member: Yup.string().required('Team member is required'),
        priority: Yup.string().required('Priority is required'),
        status: Yup.string().required('Status is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      createActivity(values, createNew);

    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      console.error(errors);
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',

      });
    }
  }

  async function createActivity(values, createNew) {
    api
      .post(
        '/activity',
        {
          activity_type: values.activity_type,
          activity_name: values.activity_name,
          date: values.date,
          due_date: values.due_date,
          team_member: values.team_member,
          priority: values.priority,
          description: values.description,
          status: values.status,
          hour: values.hour,
          ip,
          job_id: jobId
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setOpenPopUp({ show: true });
        setLoading(false);
        if (createNew) {
          formik.handleReset();
          window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/activityregistration`;
        }
        else if (newTask) {
          history.push({
            pathname: `/myTasks`,
            rowIndex: rowIndex
          });
        }
        else
          window.location.href = `${process.env.REACT_APP_WEB_URL}/jobs/viewer/`;
      })
      .catch(err => {
        setLoading(false);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error creating',
          description: 'Error creating activity',
        });
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const openModal = () => {
    setOpen(true)
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  async function getEmployees() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEmployees(response.data);
  }

  const getStatus = async () => {
    const response = await api.get(`job-status/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setStatus(response.data);
  }

  useEffect(() => {
    getIP();
    getEmployees();
    getStatus();
  }, []);

  const handleCloseNoteModal = (type, title, description) => {
    setOpenNoteModal(false)
    if (type && title && description) {
      setShowToast({
        show: true,
        type: type,
        title: title,
        description: description
      });
    }
  }

  return (
    <Container >
      {open && <TransitionsModal openModal={true} closeModal={setOpen}
        content={<AddTemplate setFieldValue={formik.setFieldValue}
          setActivityType={setActivityType} closeModal={setOpen} />} />}
      {openNoteModal && <TransitionsModal openModal={true} closeModal={handleCloseNoteModal}
        content={<GeneralNote newNote closeModal={handleCloseNoteModal} />} />}
      {/* <SideBar /> */}
      <ContainerColumn >
        {/* <Header /> */}
        <ContainerBackground>
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          <ContainerForms >
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Activity Registration</text>
              </div>
            </ContainerHeader>
            <NavTab >
              <li
                style={{
                  backgroundColor: '#618095'
                }}
              >
                1. Activity Details
              </li>
            </NavTab>
            <HeaderInformations>
              <ViewCompactIcon style={{
                color: '#6096BA', fontSize: '2rem',
                marginRight: '10px'
              }} />
              Activity Type
            </HeaderInformations>
            <LineHorizontal />
            <ActivityIcons>
              <Task onClick={() => setOpenNoteModal(true)}
                style={{ backgroundColor: 'white' }}>
                <div style={{
                  border: '1px solid #618095', display: 'flex',
                  justifyContent: 'center', alignItems: 'center', borderRadius: '5px',
                  height: '30px', width: '40px'
                }}>
                  <img height={20} src={notes}  />
                </div>
                NOTE
              </Task>
              <Task onClick={() => setActivityType('Task')}
                style={activityType === 'Task' ? { backgroundColor: '#a8d7f7' } :
                  { backgroundColor: 'white' }}>
                <div style={{
                  border: '1px solid #618095', display: 'flex',
                  justifyContent: 'center', alignItems: 'center', borderRadius: '5px',
                  height: '30px', width: '40px'
                }}>
                  <img height={20} src={task} />
                </div>
                TASK
              </Task>
              <Email onClick={() => setActivityType('Email')}
                style={activityType === 'Email' ? { backgroundColor: '#a8d7f7' } :
                  { backgroundColor: 'white' }}>
                <div style={{
                  border: '1px solid #618095', display: 'flex',
                  justifyContent: 'center', alignItems: 'center', borderRadius: '5px',
                  height: '30px', width: '40px'
                }}>
                  <img height={20} src={email} />
                </div>
                EMAIL
              </Email>
              <Call onClick={() => setActivityType('Call')}
                style={activityType === 'Call' ? { backgroundColor: '#a8d7f7' } :
                  { backgroundColor: 'white' }}>
                <div style={{
                  border: '1px solid #618095', display: 'flex',
                  justifyContent: 'center', alignItems: 'center', borderRadius: '5px',
                  height: '30px', width: '40px'
                }}>
                  <img height={20} src={call} />
                </div>
                CALL
              </Call>
              <Visit onClick={() => setActivityType('Visit')}
                style={activityType === 'Visit' ? { backgroundColor: '#a8d7f7' } :
                  { backgroundColor: 'white' }}>
                <div style={{
                  border: '1px solid #618095', display: 'flex',
                  justifyContent: 'center', alignItems: 'center', borderRadius: '5px',
                  height: '30px', width: '40px'
                }}>
                  <img height={20} src={visit} />
                </div>
                VISIT
              </Visit>
              <button
                type="button"
                onClick={() => openModal()}
              >Load Template</button>
            </ActivityIcons>
            <HeaderInformations style={{ marginTop: '40px' }}>
              <ViewCompactIcon style={{
                color: '#6096BA', fontSize: '2rem',
                marginRight: '10px'
              }} />
              Activity Information
            </HeaderInformations>
            <LineHorizontal />
            <Formik >
              <Form onSubmit={formik.handleSubmit}>
                <ActivityContainer style={{ marginTop: '15px', marginBottom: 0 }}>
                  <FirstSection>
                    <div style={{ width: '100%' }}>
                      <Label
                      // style={errors.first_name ? { color: 'red' } : null}
                      >
                        Activity Name
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <NameField
                        placeholder="Enter the activity name..."
                        name="activity_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.activity_name}
                        style={ 
                          errors.activity_name ? 
                          { border: '1px solid red' } : 
                          null
                        }
                      />
                    </div>
                  </FirstSection>
                  <SecondSection>
                    <div>
                      <Label>
                        Status
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="status"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.values.status}
                        defaultValue="Not Started"
                        style={errors.status ? { color: 'red' } : null}
                      >
                        <option value="" disabled selected>
                          Choose a status
                        </option>
                        {status.map(statusData => {
                          return (
                            <option
                              key={statusData.name}
                              value={statusData.name}
                            >
                              {statusData.name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    {activityType === 'Visit' && (
                      <div >
                        <Label>
                          Visit Hour
                        </Label>
                        <Field
                          name="hour"
                          as="select"
                          onChange={formik.handleChange}
                          value={formik.values.hour}
                          defaultValue="Normal"
                          style={errors.hour ? { color: 'red' } : null}
                        >
                          <option value="" disabled selected>
                            Choose a visit hour
                          </option>
                          {hourArray.map(name => {
                            return (
                              <option
                                key={name}
                                value={name}
                              >
                                {name}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                    )}
                    <div >
                      <Label>
                        Start Date
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <DateField
                        name="date"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.date}
                        style={ errors.date ? { color: 'red' } : null }
                      />
                    </div>
                    <div >
                      <Label>
                        Due Date
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <DateField
                        name="due_date"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.due_date}
                        style={
                          errors.due_date ? { color: 'red' } : null
                        }
                      />
                    </div>
                  </SecondSection>
                  <ThirdSection>
                    <div >
                      <Label>
                        Team Member
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="team_member"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.values.team_member}
                        style={errors.team_member ? { color: 'red' } : null}
                      >
                        <option value="" disabled selected>
                          Choose a Team Member name
                        </option>
                        {
                          employees.map(item => {
                            return (
                              <option
                                key={item.employee_id}
                                value={item.employee_id}
                              >
                                {item.first_name + ' ' + item.last_name}
                              </option>
                            )
                          })
                        }
                      </Field>
                    </div>
                    <div>
                      <Label>
                        Priority
                        <span style={{ color: 'red' }}> *</span>
                      </Label>
                      <Field
                        name="priority"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.values.priority}
                        defaultValue="Normal"
                        style={errors.priority ? { color: 'red' } : null}
                      >
                        <option value="" disabled selected>
                          Choose a priority
                        </option>
                        {priorityArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                  </ThirdSection>
                  <Half3Div>
                    <div>
                      <Label>
                        Creation Notification
                      </Label>
                      <Field
                        name="creation_notification"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.creation_notification}
                      >
                        <option value="" disabled selected>
                          Choose a creation notification
                        </option>
                        {rolesArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <div>
                      <Label>
                        Due Notification
                      </Label>
                      <Field
                        name="due_notification"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.due_notification}
                      >
                        <option value="" disabled selected>
                          Choose a due notification
                        </option>
                        {rolesArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <div>
                      <Label>
                        Completed Notification
                      </Label>
                      <Field
                        name="completed_notification"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.completed_notification}
                      >
                        <option value="" disabled selected>
                          Choose a completed notification
                        </option>
                        {rolesArray.map(name => {
                          return (
                            <option
                              key={name}
                              value={name}
                            >
                              {name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>

                  </Half3Div>
                  <FourthSection style={{ marginTop: '20px' }}>
                    <Label style={{ marginBottom: '10px' }}>
                      Description:
                    </Label>
                    <TextAreaField
                      style={ errors.description ? { color: 'red' } : null }
                      rows={5}
                      aria-label="maximum height"
                      placeholder="Enter activity description..."
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    />
                  </FourthSection>
                  <LineHorizontal />
                  <FifthSection>
                    <button type="button" style={{
                      color: '#82ce5e',
                      border: '1px solid #82ce5e',
                      backgroundColor: 'white'
                    }}
                      onClick={() => saveAndCreateNew(formik)}
                    >
                      {loading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : (
                        'Save & Create New Activity'
                      )}
                    </button>
                    <button type="submit">
                      {loading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : (
                        'Save & Go to Dashboard'
                      )}</button>
                  </FifthSection>
                </ActivityContainer>
              </Form>
            </Formik>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default ActivityRegistration;