import React, { createContext, useCallback, useState } from 'react';

// import useLocalStorage from '../hooks/useLocalStorage';

import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@Propack:token');
    const employee = localStorage.getItem('@Propack:employee');

    if (token || employee) {
      return { token, employee: JSON.parse(employee) };
    }

    return {};
  });

  const checkLogin = useCallback(async () => {
    if (!!data)
      await api.get(`/check-token/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        }
      })
        .then(res => {
          if (res.data === false) {
            signOut()
          }
        })
        .catch(err => console.error(err))
  })

  const signIn = useCallback(async ({ email, password, ip }) => {
    setLoadingAuth(true);
    await api
      .post('session', { email, password, ip })
      .then(response => {
        const { token, employeeObject } = response.data;

        localStorage.setItem('@Propack:token', token);
        localStorage.setItem(
          '@Propack:employee',
          JSON.stringify(employeeObject),
        );

        setData({ token, employeeObject });
        setLoadingAuth(false);
      })
      .catch(err => {
        console.error(err);
        setLoadingAuth(false);
      });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Propack:token');
    localStorage.removeItem('@Propack:employee');

    setData({});
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!data.token,
        signIn,
        signOut,
        loadingAuth,
        employee: data.employee,
        openMenu,
        setOpenMenu
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
