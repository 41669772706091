import styled from 'styled-components';

import { TextareaAutosize } from '@material-ui/core'
import { darken, lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  margin-left: 224px;

  @media(max-width: 960px) {
    margin-left: 0px;
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`;

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  /* height: 617px; */
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  padding: 15px;
`;

export const Textarea = styled(TextareaAutosize)`
  background-color: '#ececec';
  border: 'none';
  color: '#495057'; 
  padding: '10px';
  width: '80%';
  max-height: '200px'; 
  font-size: '15px'; 
  overflow: 'auto';
`;

export const Title = styled.text`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  color: #8c8c8c;
`;

export const Label = styled.text`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #8c8c8c;
`;

export const DivInput = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  @media(max-width: 960px) {
   width: 100%;
    
  }

  input {
    margin-top: 10px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ececec;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: #afaeae;
    }

    &:focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
`;

export const BackButton = styled.button`
  border: none;
  text-align: center;
  align-self: flex-end;
  border-radius: 3px;
  transition-duration: 0.4s;
  margin-top: 5px;
  width: 197px;
  height: 41px;
  color: #ffffff;
  background-color: #618095;
  box-shadow: 0px 3px 8px rgba(97, 128, 149, 0.4);
  border-radius: 3px;

  &:hover {
    background: ${lighten(0.15, '#618095')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;