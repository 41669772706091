import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/Auth';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
// import DeleteIcon from '@material-ui/icons/Delete';
// import DeleteIcon from '@mui/icons-material/Delete';

import { Container, Logo, Line, MenuDiv, OptionButton } from './styles';

import logo from '../../assets/logo.png';
import dashboard from '../../assets/icons/dashboard.png';
import jobIcon from '../../assets/icons/jobIcon.png';
import costumerIcon from '../../assets/icons/costumerIcon.png';
import contractsIcon from '../../assets/icons/contractsIcon.png';
import inventoryIcon from '../../assets/icons/invetoryIcon.png';
import teamIcon from '../../assets/icons/teamIcon.png';
import reportsIcon from '../../assets/icons/reportsIcon.png';
import settingsIcon from '../../assets/icons/settingsIcon.png';
import templatesIcon from '../../assets/icons/templatesIcon.png';

function SideBar() {
  const { openMenu, setOpenMenu } = useContext(AuthContext);
  const [pageOpen, setPageOpen] = useState(window.location.pathname);

  const setSelectedLink = (link, style) => {
    // if (pageOpen === link)
    //   return { ...style, color: '#6096BA'  }
    // else
    //   return { ...style }

  }
  return (
    <Container open={openMenu}  >
      <Logo src={process.env.REACT_APP_LOGO_URL} alt="logo" />
      <Line />
      <MenuDiv>
        <Link to="/dashboard">
          <OptionButton onClick={() => setOpenMenu(false)}>
            <div>
              <img src={dashboard} alt="Dashboard" />
              <text style={setSelectedLink('/dashboard')}>Dashboard</text>
            </div>
          </OptionButton>
        </Link>
        <OptionButton
          type="button"
          data-toggle="collapse"
          data-target="#collapseTasks"
          aria-expanded="false"
          aria-controls="collapseTasks"
        >
          <div>
            <img src={teamIcon} alt="Team" />
            <text>
              Tasks
            </text>
          </div>
        </OptionButton>
        <div className="collapse" id="collapseTasks">
          <Link to="/myTasks/kanban" onClick={() => setOpenMenu(false)}>
            <OptionButton >
              <div>
                <text>Kanban</text>
              </div>
            </OptionButton>
          </Link>
          <Link to="/myTasks" onClick={() => setOpenMenu(false)}>
            <OptionButton>
              <div>
                <text>List</text>
              </div>
            </OptionButton>
          </Link>
        </div>
        {/* <Link to="/myTasks">
          <OptionButton onClick={() => setOpenMenu(false)}>
            <div>
              <TaskAltIcon style={{ color: '#8c8c8c', marginLeft: '-5px', fontSize: 22 }} />
              <text style={setSelectedLink('/myTasks')}>My Tasks</text>
            </div>
          </OptionButton>
        </Link> */}
        <Link to="/jobs">
          <OptionButton onClick={() => setOpenMenu(false)}>
            <div>
              <img style={setSelectedLink('/jobs')} src={jobIcon} alt="Job" />
              <text style={setSelectedLink('/jobs')}>Job</text>
            </div>
          </OptionButton>
        </Link>
        <Link to="/customer">
          <OptionButton onClick={() => setOpenMenu(false)}>
            <div>
              <img src={costumerIcon} alt="customer" />
              <text
                style={
                  pageOpen === '/customer' ||
                    pageOpen === '/customer/register' ||
                    pageOpen === '/customer/edit'
                    ? { color: '#6096BA' }
                    : null
                }
              >
                Customers
              </text>
            </div>
          </OptionButton>
        </Link>
        <OptionButton
          type="button"
          data-toggle="collapse"
          data-target="#collapseTeam"
          aria-expanded="false"
          aria-controls="collapseTeam"
        >
          <div>
            <img src={inventoryIcon} alt="Inventory" />
            <text
              style={
                pageOpen === '/inventory' ||
                  pageOpen === '/inventory/storagesettings'
                  ? { color: '#6096BA' }
                  : null
              }
            >
              Inventory
            </text>
          </div>
        </OptionButton>
        <div className="collapse" id="collapseTeam">
          <Link to="/inventory" onClick={() => setOpenMenu(false)}>
            <OptionButton >
              <div>
                <text>Inventory</text>
              </div>
            </OptionButton>
          </Link>
          <Link to="/inventory/storagesettings" onClick={() => setOpenMenu(false)}>
            <OptionButton >
              <div>
                <text>Storage Settings</text>
              </div>
            </OptionButton>
          </Link>
        </div>
        <OptionButton
          type="button"
          data-toggle="collapse"
          data-target="#collapseTeam2"
          aria-expanded="false"
          aria-controls="collapseTeam2"
        >
          <div>
            <img src={teamIcon} alt="Team" />
            <text
              style={
                pageOpen === '/employee' ||
                  pageOpen === '/employee/register' ||
                  pageOpen === '/employee/edit'
                  ? { color: '#6096BA' }
                  : null
              }
            >
              Team
            </text>
          </div>
        </OptionButton>
        <div className="collapse" id="collapseTeam2">
          <Link to="/employee" onClick={() => setOpenMenu(false)}>
            <OptionButton >
              <div>
                <text>Employees</text>
              </div>
            </OptionButton>
          </Link>
          <OptionButton onClick={() => setOpenMenu(false)}>
            <div>
              <text>Clock</text>
            </div>
          </OptionButton>
        </div>
        <OptionButton onClick={() => setOpenMenu(false)}>
          <div>
            <img src={reportsIcon} alt="Reports" />
            <text>Reports</text>
          </div>
        </OptionButton>
        <Link to="/templates">
          <OptionButton onClick={() => setOpenMenu(false)}>
            <div>
              <img src={templatesIcon} alt="Templates" />
              <text>Templates</text>
            </div>
          </OptionButton>
        </Link>
        <Link to="/settings">
          <OptionButton onClick={() => setOpenMenu(false)}>
            <div>
              <img src={settingsIcon} alt="Settings" />
              <text
                style={pageOpen === '/settings' ? { color: '#6096BA' } : null}
              >
                Settings
              </text>
            </div>
          </OptionButton>
        </Link>
      </MenuDiv>
    </Container>
  );
}

export default SideBar;
