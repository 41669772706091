import React, { useState, useEffect, useRef, useContext } from 'react';
import * as Yup from 'yup';

import { Formik, Field, Form, useFormik } from 'formik';

import { useHistory, useParams, useLocation, Link } from 'react-router-dom';

import Axios from 'axios';

import { JobContext } from '../../contexts/Job'

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ReactToPrint from 'react-to-print';

import ComponentToPrint from '../../utils/ComponentToPrint'

import QRCode from 'qrcode.react';

import ProductDetails from '../../assets/icons/ProductDetails.svg'
import AddPhotos from '../../assets/icons/AddPhotos.svg'

import MUIDataTable from "mui-datatables";

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import TransitionsModal from '../../components/TransitionsModal'

import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';

import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'

import api from '../../services/api';

import {
  Container, ContainerColumn,
  ContainerBackground, ContainerForms,
  ContainerHeader, ContainerInformations,
  HeaderInformations, LineVertical,
  LineHorizontal, DivInput,
  Label, SubmitButton,
  DarkContainer, NextStepContainer,
  NavTab, FullDiv,
  Half2Div, DivPicture,
  UploadButton, DeleteButton,
  Half3Div, MoreHorizon,
  PopoverDiv, InventoryIdDiv,
  StoragesDiv, ButtonsDiv,
  AutoCompleteDiv, SearchDiv,
  ListSettings, ListSettingsWrapper,
  SearchContainer, DeleteImageButton,
  PrintDiv, Button,

} from './styles';

import getValidationErros from '../../utils/getValidationErrors';

import SideBar from '../../components/SideBar';
import Header from '../../components/Header';
import SuccessPopUp from '../../components/SuccessPopUp';

import ToastContainer from '../../components/ToastContainer';
import { borderRadius } from 'polished';


function index() {
  const location = useLocation();
  let { inventory_id } = useParams();
  let history = useHistory();
  const customerInfo = location.state;
  const { setJobId } = useContext(JobContext)

  const [errors, setErrors] = useState({});
  const [jobs, setJobs] = useState([])
  const [storages, setStorages] = useState([])
  const [employees, setEmployees] = useState([])
  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState(null)
  const [customerJobs, setCustomerJobs] = useState([])
  const [tabInformation, setTabInformation] = useState('inventoryInformation')
  const [inventoriesItems, setInventoriesItems] = useState([])
  const [itemId, setItemId] = useState(null)
  const [itemDetails, setItemDetails] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [openSearchModal, setOpenSearchModal] = useState(false)
  const [storageID, setStorageID] = useState([])
  const [images, setImages] = useState([])
  const [storageQrCodeInfo, setStorageQrCodeInfo] = useState([])
  const [openQrCodeModal, setOpenQrCodeModal] = useState(false)

  const componentRef = useRef();

  const openPop = Boolean(anchorEl);


  const [inventoryEdit, setInventoryEdit] = useState({})


  const [showToast, setShowToast] = useState({ show: false });
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ip, setIp] = useState();

  const formik = useFormik({
    initialValues: {
      job_number: '',
      inventory_responsible: '',
      inventory_manager: '',
      employee: '',
      customer_id: '',
      photos_url: '',
    },
    onSubmit: values => {
      values.storage_id = storageID
      handleSubmit(values);
    },
  });

  function handleToast() {
    setShowToast({ show: false });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value) => {
    handleClick(event)
    setItemId(value)
  }

  const goEdit = () => {
    history.push({
      pathname: `/itemedit/${itemId}`,
    });
  }

  async function deleteItem() {
    setLoadingDelete(true);
    api
      .delete(
        `/item/delete/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.reload()
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Item successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting item',
        });
      });
  }


  const columns = [
    {
      name: "storage_id",
      label: "Storage ID",
      options: {
        filter: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <InventoryIdDiv
              onClick={() =>
                history.push({
                  pathname: `/itemedit/${tableMeta.rowData[8]}`,
                })
              }>
              { tableMeta.rowData[0]}
            </InventoryIdDiv >
          )
        }
      },
    },
    {
      name: "room",
      label: "Room",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "product_name",
      label: "Item Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type_name",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sub_type_name",
      label: "Sub Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "condition",
      label: "Condition",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "storage_id",
      label: "QR Code",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <InventoryIdDiv
              onClick={() => HandleViewQrCode(value)}>
              <div style={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', height: 35, color: '#6096BA',
                border: '1px solid #6096BA', borderRadius: 5, width: '100px'
              }}>View QR Code</div>
            </InventoryIdDiv >
          )
        }
      },
    },
    {
      name: "photos_url",
      label: "Photos",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <InventoryIdDiv onClick={() => handleViewPhotos(tableMeta.rowData)}>
              <div style={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', height: 35, color: '#6096BA',
                border: '1px solid #6096BA', borderRadius: 5, width: '100px'
              }}>View Photos</div>
            </InventoryIdDiv>
          )
        }
      },
    },
    {
      name: 'item_id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => goEdit(itemId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                    Edit
                  </PopoverDiv>
                <PopoverDiv onClick={() => deleteItem()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </PopoverDiv>
              </Popover>
            </div>
          )
        }
      },
    },
  ];

  const options = {
    filter: "false",
    print: "false",
    search: "true",
    download: "false",
    viewColumns: "false",
    selectableRows: "none",
    // onRowsDelete: (rowsDeleted) => {
    //   rowsDeleted.data.map(d => {
    //     // activityId(prevState => [...prevState, customers[d.dataIndex].costumer_id])
    //     setOpenPopUp({ show: true })
    //   })
    // }
  };
  // const options = {
  //   filter: "false",
  //   print: "false",
  //   search: "false",
  //   download: "false",
  //   viewColumns: "false",
  //   selectableRows: "none",
  //   onRowsDelete: (rowsDeleted) => {
  //     rowsDeleted.data.map(d => {
  //       // activityId(prevState => [...prevState, customers[d.dataIndex].costumer_id])
  //       setOpenPopUp({ show: true })
  //     })
  //   }
  // };

  const HandleViewQrCode = (storage_id) => {
    let qrCodeValue = storage_id
    let qrCodeName = ''
    setStorageQrCodeInfo({ qrCode: qrCodeValue, name: qrCodeName })
    setOpenQrCodeModal(true)
  }

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${storageQrCodeInfo.qrCode}-qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const contentQrCode = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        height: '300px', fontSize: '20px'
      }}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          width: '90%', height: '40px', fontFamily: 'roboto', marginTop: '20px'
        }}>
          QR Code {storageQrCodeInfo.name}
        </div>
        <LineHorizontal />
        <div style={{
          width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center'
        }}>
          <QRCode id="qrcode" value={storageQrCodeInfo.qrCode} />
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button style={{ marginTop: 10 }} onClick={downloadQRCode}> Download QR Code</Button>
          </div>
          <PrintDiv>
            <ReactToPrint
              trigger={() => <PrintIcon fontSize={'40px'} style={{
                color: '#82ce5e',
                fontSize: '30px', marginLeft: 10
              }} />}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
              <ComponentToPrint content={<QRCode id="qrcode" value={storageQrCodeInfo.qrCode} />} ref={componentRef} />
            </div>
          </PrintDiv>
        </div>
      </div >
    )
  }


  async function handleSubmit(values) {
    setLoading(true);
    try {
      setErrors({});
      const schema = Yup.object().shape({
        job_number: Yup.string().required('Job Number is required'),
        storage_id: Yup.array().required('Storage ID is required'),
        inventory_responsible: Yup.string().required('Inventory Responsible is required'),
        inventory_manager: Yup.string().required('Inventory Manager is required'),
        employee: Yup.string().required('Employee is required'),
        customer_id: Yup.string().required('Customer ID is required'),
      });

      await schema.validate(values, {
        abortEarly: false,
      });

      if (inventory_id) {
        updateInventory(values)
      }
      else {
        createInventory(values);
      }
    } catch (error) {
      setLoading(false);
      setErrors(getValidationErros(error));
      setShowToast({
        show: true,
        type: 'error',
        title: 'Required fields',
        description: 'There are unfilled required fields',
      });
    }
  }


  async function updateInventory(values) {
    setLoading(true);
    const data = new FormData();
    data.append('inventory_id', inventory_id)
    data.append('job_number', values.job_number)
    data.append('storage_id', JSON.stringify(values.storage_id))
    data.append('inventory_responsible', values.inventory_responsible)
    data.append('inventory_manager', values.inventory_manager)
    data.append('employee', values.employee)
    data.append('customer_id', values.customer_id)
    data.append('ip', ip)
    images.forEach((item, i) => {
      if (typeof (item) == "object") {
        data.append("photos_url[]",
          item
        );
      }
      else if (typeof (item) == "string") {
        data.append("photos_db",
          item
        );
      }
    });
    await api
      .patch('/inventory/update', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then(response => {
        // handleClickOpen();
        window.location.href = `${process.env.REACT_APP_WEB_URL}/inventory`;
        // window.location.href = "http://localhost:3000/inventory/storagesettings";
        setLoading(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function createInventory(values) {
    setLoading(true);
    const data = new FormData();
    data.append('job_number', values.job_number)
    data.append('storage_id', JSON.stringify(values.storage_id))
    data.append('inventory_responsible', values.inventory_responsible)
    data.append('inventory_manager', values.inventory_manager)
    data.append('employee', values.employee)
    data.append('customer_id', values.customer_id)
    data.append('ip', ip)
    images.forEach((item, i) => {
      data.append("photos_url[]",
        item
      );
    });
    await api
      .post('/inventory', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then(response => {
        if (customerInfo) {
          history.goBack()
        } else {
          window.location.href = `${process.env.REACT_APP_WEB_URL}/inventory`;
        }
        setLoading(false);
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error',
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function getJobs() {
    api
      .get('/job', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setJobs(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getStorages() {
    api
      .get('/storage', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        setStorages(res.data)
      })
      .catch(err => console.error(err))
  }

  async function getEmployee() {
    const response = await api.get('/employee', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
      },
    });
    setEmployees(response.data);
  }

  async function getCustomers() {
    api
      .get('/customer', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setCustomers(res.data)
      });
  }

  async function getInventories() {
    api
      .get('/inventory', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        if (inventory_id) {
          let inventoryToEdit = res.data.find(inventory => inventory.inventory_id === inventory_id)
          setImages(inventoryToEdit.photos_url)
          setStorageID(JSON.parse(inventoryToEdit.storage_id))
          setInventoryEdit(inventoryToEdit)
        }
      })
      .catch(err => console.error(err))
  }

  async function getInventoriesItems() {
    api
      .get('/item', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        }
      })
      .then(res => {
        setInventoriesItems(res.data.filter(inventoryItem => inventoryItem.job_number === inventoryEdit.job_number))
      })
      .catch(err => console.error(err))
  }

  async function deleteInventory() {
    setLoadingDelete(true);
    api
      .delete(
        `/inventory/delete/${inventoryEdit.inventory_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoadingDelete(false);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/inventory`;
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Inventory successfully deleted',
        });
      })
      .catch(err => {
        setLoadingDelete(false);
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting inventory',
        });
      });
  }

  const handleViewPhotos = (itemDetails) => {
    setItemDetails(itemDetails)
    setOpenModal(true)
  }

  const handleStorageChange = (storage_id) => {
    let exist = storageID.find(storage => storage.storage_id === storage_id)
    if (!exist) {
      let storage = storages.find(storage => storage.storage_id === storage_id)
      setStorageID(prev => ([...prev, { name: storage.name, storage_id: storage.storage_id }]))
    }
    else
      return
  }

  const removeStorage = (storage_id) => {
    let storage = storageID.filter(storage => storage.storage_id !== storage_id)
    setStorageID(storage)
  }

  const setQrCodeValue = () => {
    let storagesID = storageID.map(storage => {
      return storage.storage_id
    })
    return storagesID.toString()
  }

  const handleChangeAutoComplete = (jobInfo) => {
    if (jobInfo) {
      formik.setFieldValue('job_number', jobInfo.job_number)
      formik.setFieldValue('customer_id', jobInfo.customer_id)
    }
  }

  const deleteImages = (imageIndex) => {
    let newImages = images.filter((image, index) => index !== imageIndex)
    setImages(newImages)
  }

  const inventoryInformation = () => {
    return (
      <Formik>
        <Form onSubmit={formik.handleSubmit}>
          <ContainerInformations>
            <HeaderInformations>
              <div>
                <svg height={15} src={ProductDetails} style={{ marginRight: '10px' }}></svg>
                <text>Inventory Details</text>
              </div>
            </HeaderInformations>
            <LineHorizontal />
            <Label style={errors.job_number ? { color: 'red', marginTop: 10 } : { marginTop: 10 }}>
              Job Number
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <AutoCompleteDiv>
              <Autocomplete
                name="job_number"
                id="combo-box-demo"
                size="small"
                options={jobs}
                value={formik.values.job_number}
                getOptionLabel={(option) => option.job_number ? option.job_number : formik.values.job_number}
                getOptionSelected={(option) => option.job_number == formik.values.job_number}
                onChange={(event, value) => handleChangeAutoComplete(value)}
                onBlur={formik.handleBlur}
                style={{ width: '100%', marginTop: 0 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
              <SearchDiv>
                <SearchIcon
                  style={
                    {
                      color: "#618095",
                    }
                  }
                  onClick={() => setOpenSearchModal(true)}
                />
              </SearchDiv>
            </AutoCompleteDiv>
            <Half2Div>
              <DivInput >
                <Label style={errors.storage_id ? { color: 'red' } : null}>
                  Storage ID
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="storage_id"
                  onChange={(e) => handleStorageChange(e.target.value)}
                  style={
                    errors.storage_id
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {storages.map(item => {
                    return (
                      <option key={item.storage_id} value={item.storage_id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
              <DivInput style={{
                border: '1px solid #ececec', flexDirection: 'row',
                flexWrap: 'wrap', minHeight: 42, maxHeight: 84,
                alignItems: 'center', overflowY: 'auto',
                fontSize: '0.8125rem', borderRadius: 10
              }}>
                <Label style={{ width: '100%', marginLeft: 5 }}>
                  Selected Storages:
                </Label>
                {storageID.map(storage => {
                  return (
                    <StoragesDiv key={storage.value}>
                      {storage.name}
                      <div style={{
                        width: 20, backgroundColor: '#E0E0E0',
                        borderRadius: 10
                      }}>
                        <ClearIcon
                          style={{ width: 20 }}
                          onClick={() => removeStorage(storage.storage_id)} />
                      </div>
                    </StoragesDiv>
                  )
                })}
              </DivInput>
            </Half2Div>
            <Half2Div>
              <DivInput >
                <Label style={errors.inventory_responsible ? { color: 'red' } : null}>
                  Inventory Responsible
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="inventory_responsible"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.inventory_responsible}
                  style={
                    errors.inventory_responsible
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {employees.map(employee => {
                    return (
                      <option key={employee.employee_id} value={employee.employee_id}>
                        {employee.first_name} {employee.last_name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.inventory_manager ? { color: 'red' } : null}>
                  Inventory Manager
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="inventory_manager"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.inventory_manager}
                  style={
                    errors.inventory_manager
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {employees.map(employee => {
                    return (
                      <option key={employee.employee_id} value={employee.employee_id}>
                        {employee.first_name} {employee.last_name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
            </Half2Div>
            <Half2Div>
              <DivInput >
                <Label style={errors.employee ? { color: 'red' } : null}>
                  Employee
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="employee"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employee}
                  style={
                    errors.employee
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {employees.map(employee => {
                    return (
                      <option key={employee.employee_id} value={employee.employee_id}>
                        {employee.first_name} {employee.last_name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
              <DivInput >
                <Label style={errors.customer_id ? { color: 'red' } : null}>
                  Customer
                          <span style={{ color: 'red' }}> *</span>
                </Label>
                <select
                  name="customer_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.customer_id ?
                      formik.values.customer_id :
                      customer
                  }
                  style={
                    errors.customer_id
                      ? { border: '1px solid red' }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Choose an option
                          </option>
                  {customers.map(customer => {
                    return (
                      <option key={customer.customer_id} value={customer.customer_id}>
                        {customer.first_name} {customer.last_name}
                      </option>
                    )
                  })}
                </select>
              </DivInput>
            </Half2Div>
            {inventoryEdit.storage_id &&
              <Half2Div>
                <DivInput>
                  <Label style={{ marginBottom: 10 }}>
                    QR Code
                    </Label>
                  <QRCode id="qrcode" value={setQrCodeValue()} />
                </DivInput>
              </Half2Div>
            }
            <FullDiv >
              <DivInput>
                <Label>Add photo</Label>
                <DivPicture>
                  {images.length > 0 &&
                    images.map((image, id) => {
                      if (image !== "undefined") {
                        return (
                          <div style={{
                            display: 'flex', flexDirection: 'column',
                            width: '200px', alignItems: 'center'
                          }}>
                            <DeleteImageButton
                              style={{ width: 30, height: 30, marginBottom: '5px' }}
                              onClick={() => deleteImages(id)}>
                              <ClearIcon
                                style={{ width: 20 }} />
                            </DeleteImageButton>
                            <img
                              style={{ width: '100%', marginRight: 0 }}
                              src={typeof image === 'object' ? URL.createObjectURL(image) : image}
                            // alt="photo"
                            />
                          </div>
                        )
                      }
                    })
                  }
                  <UploadButton for="upload-photo" style={{ marginBottom: 0 }}>
                    <img src={AddPhotos} ></img>
                         Add photo
                        </UploadButton>
                  <Field
                    name="avatar"
                    type="file"
                    className="overflow-hidden"
                    id="upload-photo"
                    style={{ opacity: 0, zIndex: -1 }}
                    onChange={event => {
                      const photo = event.currentTarget.files[0]
                      setImages(prevState => [...prevState, photo])
                    }}
                    onBlur={formik.handleBlur}
                  />
                </DivPicture>
              </DivInput>
            </FullDiv>
          </ContainerInformations>
          <LineHorizontal style={{ marginBottom: '30px' }} />
          <NextStepContainer >
            {inventoryEdit.inventory_id ?
              <ButtonsDiv>
                <DeleteButton
                  type="button"
                  onClick={() => deleteInventory()}
                  style={{ alignSelf: 'flex-start' }}>
                  {loadingDelete ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Delete'
                  )}
                </DeleteButton>
                <SubmitButton type="submit">
                  {loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Edit'
                  )}
                </SubmitButton>
              </ButtonsDiv>
              :
              <SubmitButton type="submit">
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  'Save'
                )}
              </SubmitButton>
            }
          </NextStepContainer>
        </Form>
      </Formik >
    )
  }

  const inventoryItems = () => {
    return (
      <>
        <HeaderInformations >
          <button
            onClick={() => goToNewItem()}
            type="button">New Item</button>
        </HeaderInformations>
        <MUIDataTable
          title={`Inventory ID: ${inventory_id}`}
          data={inventoriesItems}
          columns={columns}
          options={options}

        />
      </>
    )
  }

  const goToNewItem = async () => {
    let job = jobs.find(job => job.job_number == inventoryEdit.job_number)
    await setJobId(job.job_id)
    history.push({
      pathname: "/itemregistration",
    });
  }

  useEffect(() => {
    getJobs()
    getStorages()
    getEmployee()
    getCustomers()
    getIP()
    getInventories()
    if (customerInfo) {
      formik.setFieldValue('job_number', customerInfo.job_number)
      formik.setFieldValue('customer_id', customerInfo.customer_id)
    }
  }, []);

  useEffect(() => {
    getInventoriesItems()
    if (inventory_id) {
      formik.setFieldValue('job_number', inventoryEdit.job_number)
      formik.setFieldValue('storage_id', inventoryEdit.storage_id)
      formik.setFieldValue('inventory_responsible', inventoryEdit.inventory_responsible)
      formik.setFieldValue('inventory_manager', inventoryEdit.inventory_manager)
      formik.setFieldValue('employee', inventoryEdit.employee)
      formik.setFieldValue('customer_id', inventoryEdit.customer_id)
      formik.setFieldValue('photos_url', inventoryEdit.photos_url)
    }
  }, [inventoryEdit]);

  const handleCustomerChange = (customerInfo) => {
    if (customerInfo) {
      let filteredCustomerJobs = jobs.filter(job => job.customer_id == customerInfo.customer_id)
      setCustomerJobs(filteredCustomerJobs)
    }
  }

  const content = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center', flexDirection: 'column',
        height: '500px', fontSize: '20px',
      }}>
        <div style={{
          display: 'flex', alignItems: 'flex-end',
          width: '95%', height: '40px', fontFamily: 'roboto'
        }}>
          Inventory Details
        </div>
        <LineHorizontal />
        <div style={{ width: '90%', overflowY: 'auto' }}>
          <table style={{
            width: '100%', textAlign: 'center',
            marginTop: '20px', overflowY: 'auto'
          }}>
            <tr style={{ color: '#8C8C8C' }}>
              <th style={{ color: '#618095', backgroundColor: '#E6EBEE', height: '50px' }}>Item Name</th>
              <th style={{ color: '#618095', backgroundColor: '#E6EBEE', height: '50px' }}>Type</th>
              <th style={{ color: '#618095', backgroundColor: '#E6EBEE', height: '50px' }}>Condition</th>
              <th style={{ color: '#618095', backgroundColor: '#E6EBEE', height: '50px' }}>Room</th>
            </tr>
            <tr style={{ color: '#8C8C8C' }}>
              <td style={{ textAlign: 'center', height: '40px', borderBottom: '1px solid #E9E9E9' }}>{itemDetails[2]}</td>
              <td style={{ textAlign: 'center', height: '40px', borderBottom: '1px solid #E9E9E9' }}>{itemDetails[3]}</td>
              <td style={{ textAlign: 'center', height: '40px', borderBottom: '1px solid #E9E9E9' }}>{itemDetails[5]}</td>
              <td style={{ textAlign: 'center', height: '40px', borderBottom: '1px solid #E9E9E9' }}>{itemDetails[1]}</td>
            </tr>
          </table>
          <div style={{
            color: '#618095', backgroundColor: '#E6EBEE',
            height: '50px', marginTop: '20px', display: 'flex',
            justifyContent: 'center', alignItems: 'center'
          }}>
            Product Photos
        </div>
          <div style={{
            display: 'flex', flexWrap: 'wrap',
            justifyContent: 'center',
          }}>
            {
              itemDetails[7].length >= 1 && itemDetails[7][0] !== "undefined" ?
                itemDetails[7].map(photo => {
                  return (
                    <img src={photo} height="150" width="150" alt="photos" />
                  )
                })
                :
                <div>There are no photos yet.</div>
            }
          </div>
        </div>
      </div>
    )
  }

  const searchContent = () => {
    return (
      <div>
        <div style={{ width: '100%', textAlign: 'center', fontSize: 22, marginTop: 15 }}>
          Search Customer
        </div>
        <LineHorizontal />
        <SearchContainer>
          <div style={{ width: '48%' }}>
            <Label style={{ marginTop: 5 }}>
              Customer Name
            </Label>
            <AutoCompleteDiv style={{ marginTop: 5, width: '100%' }}>
              <Autocomplete
                name="job_number"
                id="combo-box-demo"
                size="small"
                options={customers}
                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                onChange={(event, value) => handleCustomerChange(value)}
                onBlur={formik.handleBlur}
                style={{ width: '100%', marginTop: 15 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </AutoCompleteDiv>
          </div>
          <div style={{ width: '48%' }}>
            <Label style={{ marginTop: 10 }}>
              Customer Phone
          </Label>
            <AutoCompleteDiv style={{ marginTop: 5, width: '100%' }}>
              <Autocomplete
                name="job_number"
                id="combo-box-demo"
                size="small"
                options={customers}
                getOptionLabel={(option) => option.business_phone}
                onChange={(event, value) => handleCustomerChange(value)}
                onBlur={formik.handleBlur}
                style={{ width: '100%', marginTop: 15 }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </AutoCompleteDiv>
          </div>
          <div style={{ width: '100%', display: customerJobs.length > 0 ? 'inline' : 'none' }}>
            <div style={{ width: '100%', textAlign: 'center', fontSize: 22 }}>
              Jobs
            </div>
            <LineHorizontal />
            <ListSettingsWrapper>
              {customerJobs.map(customerJob => {
                return (
                  <ListSettings key={customerJob.job_number}>
                    <h3 onClick={() =>
                    (formik.setFieldValue('job_number', customerJob.job_number),
                      setOpenSearchModal(false),
                      formik.setFieldValue('customer_id', customerJob.customer_id),
                      setCustomerJobs([]))}>
                      {customerJob.job_number}
                    </h3>
                  </ListSettings>
                )
              })}
            </ListSettingsWrapper>
          </div>
        </SearchContainer>
      </div >

    )
  }

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show && <SuccessPopUp show={() => handlePopUp()} />}
          {showToast.show && (
            <ToastContainer messages={showToast} show={() => handleToast()} />
          )}
          {openModal && <TransitionsModal openModal={true} closeModal={setOpenModal} content={content()} />}
          {openSearchModal && <TransitionsModal openModal={true} closeModal={setOpenSearchModal} content={searchContent()} />}
          {openQrCodeModal && <TransitionsModal openModal={true} closeModal={setOpenQrCodeModal} content={contentQrCode()} />}

          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                {inventory_id ?
                  <text>Inventory Details</text>
                  :
                  <text>Inventory Registration</text>
                }
              </div>
            </ContainerHeader>
            <NavTab>
              <li
                onClick={() => setTabInformation('inventoryInformation')}
                style={
                  { backgroundColor: tabInformation === 'inventoryInformation' ? '#618095' : null }
                }
              >
                <a>1. Inventory Information</a>
              </li>
              {inventory_id &&
                <li
                  onClick={() => setTabInformation('inventoryItems')}
                  style={
                    {
                      backgroundColor: tabInformation === 'inventoryItems' ? '#618095' : null,
                      marginLeft: 10
                    }
                  }
                >
                  <a>2. Inventory Items</a>
                </li>
              }
            </NavTab>
            {tabInformation === 'inventoryInformation' && inventoryInformation()}
            {tabInformation === 'inventoryItems' && inventoryItems()}
          </ ContainerForms>
        </ ContainerBackground>
      </ ContainerColumn>
    </Container>
  );
}

export default index;