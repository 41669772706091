import React from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  TaskInformation,
  Footer
} from './styles';


const TaskCard = ({ item, index }) => {
  let history = useHistory();

  return (
    <div>
      <TaskInformation onClick={() => history.push(`/jobs/activityedit/${item.activity_id}`)}>
        <p>{item.activity_name}</p>
        <div className="secondary-details">
          <p>
            <span>
              {item.description}
            </span>
          </p>
        </div>
      <Footer>
        <span>
          {item.status}
        </span>
        <span>
          {item.job_job_number}
        </span>
        <span>
          {item.due_date ? format(new Date(item.due_date),  'MM/dd/yyyy') : 'due date invalid'}
        </span>
      </Footer>
      </TaskInformation>
    </div>
  )}

export default TaskCard;
