import styled from 'styled-components';
import { lighten } from 'polished';

export const DivPopUp = styled.div`
  display: flex;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  padding: 30px;
  height: 300px;
  width: 500px;

  z-index: 999;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background-color: #fff;

  img {
    height: 101px;
    width: 101px;
    margin-bottom: 20px;
  }
`;

export const PopUpButton = styled.button`
  border: none;
  text-align: center;
  border-radius: 3px;
  transition-duration: 0.4s;

  width: 84px;
  height: 32px;
  margin-top: 24px;
  color: #ffffff;
  background: #618095;
  box-shadow: 0px 3px 8px rgba(97, 128, 149, 0.3);
  border-radius: 3px;

  &:hover {
    background: ${lighten(0.15, '#618095')};
    cursor: pointer;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;
