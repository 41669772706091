import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Axios from 'axios'
import api from '../../services/api';

import MUIDataTable from "mui-datatables";
import Popover from '@material-ui/core/Popover';

import SideBar from '../../components/SideBar'
import Header from '../../components/Header'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';

import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'

import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmModal from '../../components/ConfirmModal';


import {
  NavTab,
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  TemplateTypeDiv,
  MoreHorizon,
  PopoverDiv,
  DarkContainer

} from './styles';

const index = () => {
  const [templates, setTemplates] = useState([])
  const [templatesContracts, setTemplatesContracts] = useState([])
  const [templateId, setTemplateId] = useState(null)
  const [contractTemplateId, setContractTemplateId] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [ip, setIp] = useState(null)
  const [loading, setLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [openPopUpContract, setOpenPopUpContract] = useState({ show: false });
  const [content, setContent] = useState('templateList')


  let history = useHistory();

  const columnsTemplate = [
    {
      name: "template_type",
      label: "Template Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TemplateTypeDiv onClick={(event) => openPopoverTemplate(null, tableMeta.rowData[3], 'openTemplate')}>
              <span>{`${tableMeta.rowData?.[0]}`}</span>
            </TemplateTypeDiv>
          )
        }
      },
    },
    {
      name: "template_name",
      label: "Template Name",
      options: {
        filter: true,
        display: true,
        viewColumns: false,
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: 'flex',
              justifyContent: 'center', backgroundColor: setBackGroundColor(tableMeta.rowData[2])
            }}>
              <span>{`${tableMeta.rowData[2]} `}</span>
            </div>
          )
        }
      },
    },
    {
      name: 'id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
              paddingLeft: '14px'
            }}>
              <MoreHorizon onClick={(event) => openPopoverTemplate(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => goEdit(templateId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                  Edit
                </PopoverDiv>
                <PopoverDiv onClick={() => removeTemplate()}>
                  {
                    loading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      <div>
                        <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                        Delete
                      </div>
                    )
                  }
                </PopoverDiv>
              </Popover>
            </div >
            // <EditButton onClick={() => goEdit(value)} type="button">
            //   Edit
            // </EditButton>
          )
        }
      },
    },
  ];


  const columnsContract = [
    {
      name: "template_name",
      label: "Template Name",
      options: {
        filter: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TemplateTypeDiv onClick={(event) => openPopoverContract(null, tableMeta.rowData[2], 'openTemplate')}>
              <span>{`${tableMeta.rowData?.[0]}`}</span>
            </TemplateTypeDiv>
          )
        }
      },
    },
    {
      name: "created_by",
      label: "Created By",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            'Admin admin'
          )
        }
      },
    },
    {
      name: 'id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
              paddingLeft: '14px'
            }}>
              <MoreHorizon onClick={(event) => openPopoverContract(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => goEditContractTemplate(contractTemplateId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                  Edit
                </PopoverDiv>
                <PopoverDiv onClick={() => removeContractTemplate()}>
                  {
                    loading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      <div>
                        <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                        Delete
                      </div>
                    )
                  }
                </PopoverDiv>
              </Popover>
            </div >
            // <EditButton onClick={() => goEdit(value)} type="button">
            //   Edit
            // </EditButton>
          )
        }
      },
    },
  ];

  const options = {
    display: "false",
    filterType: 'checkbox',
    selectableRows: "none",
    // onRowsDelete: (rowsDeleted) => {
    //   rowsDeleted.data.map(d => {
    //     setCustomersId(prevState => [...prevState, customers[d.dataIndex].customer_id])
    //     setOpenPopUp({ show: true })
    //   })
    // }
  };

  const setBackGroundColor = (priority) => {
    if (priority === 'Low')
      return '#9CD97F'
    else if (priority === 'Normal')
      return '#859DF0'
    else
      return '#FF7777'
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopoverTemplate = (event, value, openTemplate) => {
    if (event)
      handleClick(event)
    setTemplateId(value)
    if (openTemplate)
      goEdit(value)
  }

  const openPopoverContract = (event, value, openTemplate) => {
    if (event)
      handleClick(event)
    setContractTemplateId(value)
    if (openTemplate)
      goEditContractTemplate(value)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goEdit = (value) => {
    const template = templates.find(item => item.id === value);
    history.push({
      pathname: "/templateedit",
      state: template
    });
    setTemplateId(null)
  }

  const goEditContractTemplate = (value) => {
    const templateContract = templatesContracts.find(item => item.id === value);
    history.push({
      pathname: `/contract-template/${value}`,
      templateContract
    });
    setContractTemplateId(null)
  }

  async function getTemplates() {
    api
      .get('/template', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setTemplates(res.data)
      });
  }

  async function getTemplatesContracts() {
    api
      .get('/contract-template/all', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setTemplatesContracts(res.data.contractTemplates)
      });
  }


  const removeTemplate = () => {
    handleClose()
    setOpenPopUp({ show: true })
  }

  const removeContractTemplate = () => {
    handleClose()
    setOpenPopUpContract({ show: true })
  }

  async function handleDeleteTemplate() {
    setLoading(true)
    api
      .delete(`/template/${templateId}/${ip}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setLoading(false)
        window.location.reload();
        setTemplateId(null)
      });
  }

  async function handleDeleteContractTemplate() {
    setLoading(true)
    api
      .delete(`/contract-template/${contractTemplateId}/${ip}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setLoading(false)
        window.location.reload();
        setContractTemplateId(null)
      });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  function handlePopUpContract() {
    setOpenPopUpContract({ show: false });
  }

  useEffect(() => {
    getTemplates()
    getTemplatesContracts()
    getIP()
  }, [])

  const openPop = Boolean(anchorEl);

  const templateListContent = () => {
    return (
      <>
        <HeaderInformations>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ViewCompactIcon style={{
              color: '#6096BA', fontSize: '2rem',
              marginRight: '10px'
            }} />
            Template Information
          </div>
          <Link to="/templateregistration">
            <button type="button">New Template</button>
          </Link>
        </HeaderInformations >
        <LineHorizontal />

        <MUIDataTable
          title={"Templates List"}
          data={templates}
          columns={columnsTemplate}
          options={options}
        />
      </>
    )
  }

  const contractsTemplateContent = () => {
    return (
      <>
        <HeaderInformations>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ViewCompactIcon style={{
              color: '#6096BA', fontSize: '2rem',
              marginRight: '10px'
            }} />
            Contracts Template
          </div>
          <Link to="/contract-templateregistration">
            <button type="button">New Template</button>
          </Link>
        </HeaderInformations >
        <LineHorizontal />

        <MUIDataTable
          title={"Templates List"}
          data={templatesContracts}
          columns={columnsContract}
          options={options}
        />
      </>
    )
  }
  return (
    <Container >
      <SideBar />
      <ContainerColumn >
        <Header />
        <ContainerBackground>
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show &&
            <ConfirmModal
              show={() => handlePopUp()}
              deleteUser={handleDeleteTemplate}
            />
          }
          {openPopUpContract.show && <DarkContainer />}
          {openPopUpContract.show &&
            <ConfirmModal
              show={() => handlePopUpContract()}
              deleteUser={handleDeleteContractTemplate}
            />
          }
          <ContainerForms >
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Templates</text>
              </div>
            </ContainerHeader>
            <NavTab >
              <li
                onClick={() => setContent('templateList')}
                style={content == 'templateList' ? { backgroundColor: '#618095' } :
                  { backgroundColor: '#cfdbd5' }}
              >
                1. Template List
              </li>
              <li
                onClick={() => setContent('contractsTemplate')}
                style={content == 'contractsTemplate' ? { backgroundColor: '#618095' } :
                  { backgroundColor: '#cfdbd5' }}
              >
                2. Contracts Template
              </li>
            </NavTab>
            {content == 'templateList' && templateListContent()}
            {content == 'contractsTemplate' && contractsTemplateContent()}

          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container >
  );
}

export default index;
