import React, { useEffect, useState, useContext } from 'react';

import Axios from 'axios'
import { JobContext } from '../../contexts/Job';
import { Link, useHistory } from 'react-router-dom';
import MUIDataTable from "mui-datatables";

import Popover from '@material-ui/core/Popover';

import api from '../../services/api';

import {
  Container,
  ContainerColumn,
  ContainerBackground,
  ContainerForms,
  PaginationButtonsContainer,
  TableContainer,
  ContainerHeader,
  LineVertical,
  LineHorizontal,
  HeaderInformations,
  ContainerInformations,
  EditButton,
  DarkContainer,
  MoreHorizon,
  PopoverDiv,
  CustomerNameDiv,
  ButtonLinkList,
} from './styles';

import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import ConfirmModal from '../../components/ConfirmModal';
import ToastContainer from '../../components/ToastContainer';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ViewDetails from '../../assets/icons/ViewDetails.svg'
import EditInventory from '../../assets/icons/EditInventory.svg'
import DeleteInventory from '../../assets/icons/DeleteInventory.svg'


import searchByIcon from '../../assets/icons/searchBy.png';
import { CompassCalibrationOutlined } from '@material-ui/icons';

function Customer( ) {
  const [openPopUp, setOpenPopUp] = useState({ show: false });
  const [showToast, setShowToast] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersId, setCustomersId] = useState([]);
  const [ip, setIp] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  const { setJobId } = useContext(JobContext)


  let history = useHistory();

  function goEdit(id) {
    const customer = customers.find(items => items.customer_id === id);
    history.push({
      pathname: "/customer/edit",
      state: customer
    });
    setCustomerId(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover = (event, value, OpenCustomer) => {
    if (event)
      handleClick(event)
    setCustomerId(value)
    if (OpenCustomer)
      goEdit(value)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeCostumer = () => {
    handleClose()
    setOpenPopUp({ show: true })
    // deleteCustomer()

  }

  const openPop = Boolean(anchorEl);
  const columns = [
    {
      name: "first_name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <CustomerNameDiv onClick={(event) => openPopover(null, tableMeta.rowData[6], 'OpenCustomer')}>
              <span>{`${tableMeta.rowData?.[0]} ${tableMeta.rowData?.[1]}`}</span>
            </CustomerNameDiv>
          )
        }
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "business_phone",
      label: "Phone",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "postal_code",
      label: "Zip Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "job_number",
      label: "Job Number",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value){
            return value.map((item, index)=> {
              return(
                <>
                  <ButtonLinkList
                    type="button"
                    onClick={() => {
                      setJobId(item.jobId)
                      localStorage.setItem('jobId', item.jobId);
                      history.push({
                        pathname: `/jobs/viewer/`,
                        state: item.jobId
                      });
                    }}
                  >
                    {item.jobNumber}
                  </ButtonLinkList>
                  <span>{` ${value[index + 1] ? '| ' : ''}`}</span>
                </>
              )
            })
          }
          return <span>{'-'}</span>
        }
      },
    },
    {
      name: 'customer_id',
      label: "Options",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              width: '100%', display: 'flex',
              paddingLeft: '14px'
            }}>
              <MoreHorizon onClick={(event) => openPopover(event, value)}>
                <MoreHorizIcon fontSize={'40px'} style={{
                  color: '#618095',
                  fontSize: '30px'
                }}

                />
              </MoreHorizon>
              <Popover
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <PopoverDiv onClick={() => goEdit(customerId)}>
                  <img height={20} src={EditInventory} style={{ marginRight: '20px' }}></img>
                    Edit
                  </PopoverDiv>
                <PopoverDiv onClick={() => removeCostumer()}>
                  <img height={20} src={DeleteInventory} style={{ marginRight: '20px' }}></img>
                    Delete
                  </PopoverDiv>
              </Popover>
            </div>
            // <EditButton onClick={() => goEdit(value)} type="button">
            //   Edit
            // </EditButton>
          )
        }
      },
    },
  ];

  const options = {
    filter: "false",
    selectableRows: "none",
  };

  async function deleteCustomer() {
    setLoading(true)
    api
      .delete(
        `/customer/${customerId}/${ip}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
          },
        },
      )
      .then(() => {
        setLoading(false)
        window.location.reload();
        setOpenPopUp({ show: false })
        setCustomerId(null)
        return setShowToast({
          show: true,
          type: 'success',
          title: 'Delete successfully',
          description: 'Customer successfully deleted',
        });
      })
      .catch(err => {
        setLoading(false)
        setOpenPopUp({ show: false })
        console.error(err);
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error deleting',
          description: 'Error deleting customer',
        });
      });
    // });
  }

  function handlePopUp() {
    setOpenPopUp({ show: false });
  }

  function handleToast() {
    setShowToast({ show: false });
  }

  async function getIP() {
    Axios.get('https://api.ipify.org/')
      .then(response => {
        setIp(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  function setJobNumbers(data){
    let customers = data;
    let customersWithJobNumber = [] 
    customersWithJobNumber = customers.map(async (customer, index) => {
      let customerObj = customer
      await api.get(`/job/list-by-customer/${customer.customer_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
        .then(res => {  
          let jobNumbers = []
          res.data.map(job => {
            jobNumbers.push({
              jobNumber: job.job_number,
              jobId: job.job_id
            })
          })
          customerObj.job_number = jobNumbers
        })
        return(customerObj)
    })
    Promise.all(customersWithJobNumber).then(function(results) {
      setCustomers(results)
    })
  }

  async function getCustomers() {
    api
      .get('/customer', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },
      })
      .then(res => {
        setJobNumbers(res.data)
        setCustomers(res.data)
      });
  }

  useEffect(() => {
    getCustomers();
    getIP();
  }, []);

  return (
    <Container>
      <SideBar />
      <ContainerColumn>
        <Header />
        <ContainerBackground>
          {showToast.show && (
            <ToastContainer
              messages={showToast}
              show={() => handleToast()}
            />
          )}
          {openPopUp.show && <DarkContainer />}
          {openPopUp.show &&
            <ConfirmModal
              show={() => handlePopUp()}
              deleteUser={() => deleteCustomer()}
            />
          }
          <ContainerForms>
            <ContainerHeader>
              <div>
                <LineVertical />
                <text>Customer</text>


              </div>
            </ContainerHeader>
            <ContainerInformations>
              <HeaderInformations>
                <div>
                  <img src={searchByIcon} alt="searchByIcon" />
                  <text>Search by:</text>
                </div>
                <Link to="/customer/register">
                  <button type="button">New Customer</button>
                </Link>
              </HeaderInformations>
              <LineHorizontal />
              {
                <MUIDataTable
                  title={"Customer List"}
                  data={customers}
                  columns={columns}
                  options={options}
                />
              }
            </ContainerInformations>
          </ContainerForms>
        </ContainerBackground>
      </ContainerColumn>
    </Container>
  );
}

export default Customer;
