import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const toastTypes = {
  info: css`
    background-color: #cde2fd;
    color: #3172b7;
  `,
  success: css`
    background: #c5f7dc;
    color: #2e656a;
  `,
  error: css`
    background: #facecb;
    color: #c53030;
  `,
};

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 50px;
  padding: 30px;

  z-index: 999;
`;

export const Toast = styled.div`
  width: 100%;

  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;

  ${props => toastTypes[props.type || 'info']}

  > svg {
    margin: 1px 12px 0 0;
  }

  div {
    flex: 1;
  }

  p {
    margin-top: 4px;
    font-size: 14px;
    opacity: 0.8;
    line-height: 20px;
  }

  button {
    position: absolute;
    right: 16px;
    top: 18px;
    opacity: 0.6;
    border: 0;
    background-color: transparent;
    color: inherit;

    &:hover {
      cursor: pointer;
    }
  }

  & + div {
    margin-top: 8px;
  }

  ${props =>
    !props.hasDescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`;
