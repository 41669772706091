import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, useFormik } from 'formik';
import api from '../../../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';

import ToastContainer from '../../../../components/ToastContainer';

import { 
  Container, 
  ContainerInformations, 
  HeaderInformations,
  GI1,
  DivInput,
  Label,
  Button,
} from './styles';

function Payments() {

  const [invoice, setInvoice] = useState();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);
  const [showToast, setShowToast] = useState({ show: false });
  const jobId = localStorage.getItem('jobId');
  const invoiceId = localStorage.getItem('@Propack:invoice_id')

  useEffect(async ()=>{
    await refreshToken();
  }, [])

  function handleToast() {
    setShowToast({ show: false });
  }

    async function refreshToken(){
      api.get(`payments/refresh-token`
      ).then(async res => {
        // ;
        await getCustomerQuickBooks();
      })
    }

    async function getInvoice(){ 
      setLoading(true);
      api.get(`payments/invoice/${invoiceId}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },}
      ).then(res => {
        setInvoice(res.data)
        setLoading(false);
      })
    } 
    
    async function getCustomerQuickBooks(){ 
      setLoading(true);
      api.get(`payments/customerlist/${jobId}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@Propack:token')}`,
        },}
      ).then(res => {
        // ;
        setCustomer(res.data);
        getInvoice();
        setLoading(false);
      }).catch(error => {
        setLoading(false);
      })
    } 

    async function CreateInvoice(objInvoice) {
      setLoading(true);
      api.post('payments/create-invoice', {
        value: objInvoice.value,
        description: objInvoice.description,
        CustomerQBId: customer.paymentapi_id,
        jobId,
      }
      ).then(res => {
        setInvoice(res.data)
        localStorage.setItem('@Propack:invoice_id', res.data.Invoice.Id);
        setShowToast({
          show: true,
          type: 'success',
          title: 'Create Invoice Success',
        });
        setLoading(false)
      }).catch(err => {
        return setShowToast({
          show: true,
          type: 'error',
          title: 'Error in creating Invoice',
          description: err.response.data.message,
        });
      })
    }

    async function sendInvoice() {
      setSendLoading(true);
      api.post('payments/invoice/send-invoice', {
        invoiceId: invoice.Invoice.Id, 
        email: customer.email
      }
      ).then(res => {
        setShowToast({
          show: true,
          type: 'success',
          title: 'Send Email Success',
        });
        setSendLoading(false)
      })
      .catch(err => {
        setShowToast({
          show: true,
          type: 'error',
          title: 'Error in sending Email',
          description: err.response.data.message,
        });
      })
    }

  const address = customer && `${customer.address}, ${customer.city}, ${customer.state}, ${customer.country}`
  const currency = invoice && invoice.Invoice.CurrencyRef.value;
  const amount = invoice && `${currency} ${parseFloat(invoice.Invoice.TotalAmt).toFixed(2)}`

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      description: '',
      value: '',
    },
    onSubmit: values => {
      CreateInvoice(values);
    },
  });

  function formCreateInvoice() {
    return(
      <Formik>
          <Form onSubmit={formik.handleSubmit}>
            <div style={{
              boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
              borderRadius: '4px', padding: '5px 15px 15px 15px', marginTop: '20px',
              backgroundColor: '#fff'
            }}>
            { loading 
              ? <CircularProgress color="inherit" size={25} /> :
            (
              <>
                <GI1>
                  <DivInput>
                    <Label>
                      Customer: 
                    </Label>
                    <Field
                      name="name"
                      as="input"
                      disabled={customer ? false : true}
                      placeholder="Customer's name"
                      onChange={'formik.handleChange'}
                      onBlur={'formik.handleBlur'}
                      value={customer && `${customer.first_name} ${customer.last_name}`}
                    />
                  </DivInput>
                  <DivInput>
                    <Label>
                      Address: 
                    </Label>
                    <Field
                      name="address"
                      as="input"
                      disabled={customer ? false : true}
                      placeholder="Customer's address"
                      onChange={'formik.handleChange'}
                      onBlur={'formik.handleBlur'}
                      value={customer && address}
                    />
                  </DivInput>
                </GI1>
                <GI1>
                  <DivInput>
                    <Label>
                      Description: 
                    </Label>
                    <Field
                      name="description"
                      as="textarea"
                      placeholder="Customer's description"
                      onChange={formik.handleChange}
                      onBlur={'formik.handleBlur'}
                      value={formik.values.description}
                    />
                  </DivInput>
                  <DivInput>
                    <Label>
                      Value: 
                    </Label>
                    <Field
                      name="value"
                      as="input"
                      type="number"
                      step=".01"
                      placeholder="Customer's value"
                      onChange={formik.handleChange}
                      onBlur={'formik.handleBlur'}
                      value={formik.values.value}
                    />
                  </DivInput>
                </GI1>
                <GI1>
                  <DivInput>          
                    <Button
                      type='submit'
                      // onClick={() => sendInvoice()}
                    >
                      Create Invoice
                    </Button>
                  </DivInput>
                </GI1>
              </>
            )}
            </div>
          </Form>
      </Formik>
    )
  }
  
  function formInvoice() {
    return(
          <div style={{
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
            borderRadius: '4px', padding: '5px 15px 15px 15px', marginTop: '20px',
            backgroundColor: '#fff'
          }}>
           { loading 
            ? <CircularProgress color="inherit" size={25} /> :
            (
              <>
                <GI1>
                  <DivInput>
                    <Label>
                      Customer: 
                    </Label>
                    <input
                      name="name"
                      disabled
                      value={customer && `${customer.first_name} ${customer.last_name}`}
                    />
                  </DivInput>
                  <DivInput>
                    <Label>
                      Address: 
                    </Label>
                    <input
                      name="address"
                      disabled
                      value={customer && address}
                    />
                  </DivInput>
                </GI1>
                <GI1>
                  <DivInput>
                    <Label>
                      Description: 
                    </Label>
                    <textarea
                      name="description"
                      disabled
                      value={invoice && invoice.Invoice.Line[0].Description}
                    />
                  </DivInput>
                  <DivInput>
                    <Label>
                      Value: 
                    </Label>
                    <input
                      name="value"
                      disabled
                      value={invoice && amount}
                    />
                  </DivInput>
                </GI1>
                <GI1>
                  <DivInput>          
                    <Button
                      type='submit'
                      onClick={() => sendInvoice()}
                    >
                      {sendLoading ? <CircularProgress size={16} /> : 'Send Invoice'}
                    </Button>
                  </DivInput>
                </GI1>
              </>
            )}
          </div>
    )
  }

  return (
    <ContainerInformations>
      <HeaderInformations style={{ marginTop: '20px' }}>
        <div style={{
          display: 'flex', alignItems: 'inherit',
          fontSize: '1.25rem', letterSpacing: '0.0075em', fontWeight: '500', lineHeight: 1.6,
          fontFamily: "Roboto, Helvetica, Arial, Sans-serif"
        }}>
          Payment Information
        </div>
      </HeaderInformations>
      {showToast.show && (
        <ToastContainer messages={showToast} show={() => handleToast()} />
      )}
      {
        invoice 
        ? formInvoice() 
        : formCreateInvoice()
      }

    </ContainerInformations>
  );
}

export default Payments;